/**
 * Created by gantushig on 6/14/19.
 */

import TopBarProgress from "react-topbar-progress-indicator";
import React, {Component} from "react";
import {connect} from "react-redux";

TopBarProgress.config({
    barColors: {
        "0": "#5f6875",
        "0.5": "#4a535f",
        "1.0": "#373d45",
    },
    shadowBlur: 0,
    barThickness: 15
});


const ProgressBar = ( {isUpdating} ) => {
        if(isUpdating) {
            return(<TopBarProgress />)
        } else {
            return('');
        }
};


const mapStateToProps = (state, ownProps) => {
    return {
        isUpdating: state.ui.isUpdating
    }
};

export default connect(mapStateToProps, null)(ProgressBar)

