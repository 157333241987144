/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer, useState} from "react";
import {Link, useLocation, useParams, useRouteMatch} from "react-router-dom";
import dayjs from "dayjs";
import {fetchGcp, removeGcp} from "./actions/actions";
import {TOKEN} from "./Constants";
import {css} from "@emotion/react";
import dayjsPluginUTC from "dayjs-plugin-utc";
import {confirm} from "react-confirm-box";
import {toast} from "react-toastify";
import history from "./history";
import gcpStatusData from "./data/gcp-status.json"

dayjs.extend(dayjsPluginUTC)


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    gcp: {},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_GCP_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_GCP_SUCCESS':
            return {
                ...state,
                gcp: action.gcp,
                loading: false
            }
        default:
            return state;
    }
};

const GcpView = () => {

    const {companyId, gcpId} = useParams();
    let { path, url } = useRouteMatch();
    const {pathname}  = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        gcp,
        loading
    } = state;

    useEffect(() => {
        console.log('useEffect called');
        fetchGcp(dispatch, token, gcpId);
    }, [gcpId]);


    // let modalClass = classnames({
    //     modal: true,
    //     'is-active': isOpen
    // });
    //
    // const handleDelete = (id) => {
    //     console.log('delete');
    //     setIsOpen(true)
    // };
    //
    // const handleDeleteCodeYes = () => {
    //     // dispatch(removeGcp(token, gcpId))
    //     // deleteGcp(token, gcpId);
    //     setIsOpen(false)
    //
    // }
    // const handleDeleteCodeCancel = () => {
    //     setIsOpen(false)
    // }
    const optionsWithLabelChange = {
        closeOnOverlayClick: false,
        labels: {
            confirmable: "Устгах",
            cancellable: "Болих"
        }
    };
    const deleteGcp = async () => {
        const result = await confirm("Gcp устгах уу?", optionsWithLabelChange);
        if (result) {
            console.log("You click yes delete Gcp!");
            removeGcp(dispatch, gcpId).then(response => {
                    toast.success("Амжилттай !");
                    history.push(`/company/${companyId}/gcps`)
                }
            ).catch(error => {
                alert(error)
            });
        }
        console.log("You click No!");
    };

    const barcodeTypeLabel = (g) => {
        let ret = ""
        if (g === 0) {
            ret = "GTIN"
        } else if ( g === 1) {
            ret = "GLN"
        } else if ( g === 2) {
            ret = "SSCC"
        }

        return ret
    }

    const statusLabel = (g) => {
        let ret = ""
        let ra = gcpStatusData.filter(b=>b.id === g);
        if (ra.length>0) {
            ret = ra[0].name;
        }
        return ret
    };


    return (
        <>
            {/*<CompanyViewHeader tab={2} companyId={id}/>*/}

            <div className="buttons has-addons is-right">
            <Link to={`${url}/edit`} className="button">
                Өөрчлөх
            </Link>

            <a className="button" onClick={()=>deleteGcp()}>
                Устгах
            </a>
            </div>
            {/*<ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>*/}
            <table className="table is-bordered is-fullwidth">
                <tbody>
                <tr>
                    <th className="company-title">Баркод Төрөл</th>
                    <td className="company-value">{gcp && barcodeTypeLabel(gcp.barcode_type)}</td>
                </tr>
                <tr>
                    <th className="company-title">GCP</th>
                    <td className="company-value">{gcp && gcp.prefix}</td>
                </tr>
                <tr>
                    <th className="company-title">GCP Төрөл</th>
                    <td className="company-value">GCP{gcp && gcp.size}</td>
                </tr>
                <tr>
                    <th>Олгосон огноо</th>
                    <td>{gcp && gcp.issued && dayjs.utc(gcp.issued).format("MM/DD/YYYY")}</td>
                </tr>
                <tr>
                    <th>Төлөв</th>
                    <td>{gcp && statusLabel(gcp.status)}</td>
                </tr>
                {/*<tr>*/}
                {/*    <th>Хаасан</th>*/}
                {/*    <td><input type="checkbox" disabled*/}
                {/*                   checked={gcp && gcp.closed} /></td>*/}
                {/*</tr>*/}

                </tbody>
            </table>
            {/*<div className={modalClass}>*/}
            {/*    <div className="modal-background"></div>*/}
            {/*    <div className="modal-card">*/}
            {/*        <header className="modal-card-head">*/}
            {/*            <p className="modal-card-title">Устгах?</p>*/}
            {/*            <button className="delete" aria-label="close"></button>*/}
            {/*        </header>*/}
            {/*        <section className="modal-card-body">*/}
            {/*            Та устгахдаа итгэлтэй байна уу?*/}
            {/*        </section>*/}
            {/*        <footer className="modal-card-foot">*/}
            {/*            <button className="button is-danger" onClick={handleDeleteCodeYes}>Тийм</button>*/}
            {/*            <button className="button" onClick={handleDeleteCodeCancel}>Үгүй</button>*/}
            {/*        </footer>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
}

export default GcpView;