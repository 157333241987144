/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import React, {Component, useEffect, useState} from "react";
import {
    fetchAllProducts, fetchAllProducts14,
    fetchClassifs, menuClicked,
    printAllProducts,
    selectProduct,
    selectProductHeader
} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {Menu, Page, PageTitle, TOKEN} from "./Constants";
import history from "./history";

dayjs.extend(dayjsPluginUTC)

const Products14Page = ({
                          location: {search}
                      }) => {
    const dispatch = useDispatch();

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let order = parsed[Page.ORDER];
    let pagesize = parsed[Page.PAGESIZE];
    let asc = parsed[Page.ASC];


    if (page === undefined) {
        page = "0";
    }
    if (order === undefined) {
        order = "barcode";
    }
    if (asc === undefined) {
        asc = "true";
    }
    if (pagesize === undefined) {
        pagesize = "25";
    }


    const stateProducts = useSelector(state => state.products);
    let selections = stateProducts.selections;

    useEffect(() => {
        dispatch(menuClicked(Menu.PRODUCTS14, PageTitle.PRODUCTS14));
    }, []);


    useEffect(() => {
        console.log('useEffect called');
        dispatch(fetchAllProducts14(
            page, pagesize, order, asc));
    }, [ page, pagesize, order, asc]);

    const isItemSelected = id => selections.has(id);

    let meta = stateProducts.meta;
    if (meta === null) {
        meta = {};
        meta.totalPages = 0;
    }
    const products = stateProducts.products;
    let counter = 0;
    for (let key in products) {
        let item = products[key];
        counter++;
        item.counter = Number(page) * Number(pagesize) + counter;
    }


    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed))
    };

    const handleSearch = (e) => {
        e.preventDefault();
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = "0";
        history.push("?" + qs.stringify(parsed))
    };


    // const handleExcel = (e) => {
    //     e.preventDefault();
    //     dispatch(printAllProducts(token, companyName, name, barcode, barcodeType, main, sub, unit, isEmpty, created, from, to));
    // };


    const handleSelect = (id) => {
        dispatch(selectProduct(id))
    }

    const handleSelectHeader = () => {
        dispatch(selectProductHeader())
    }

    const handleOrderClick = (f) => {
        let parsed = qs.parse(search);
        if (parsed[Page.ORDER] === f) {
            let o = parsed[Page.ASC];
            if (o === "true") {
                o = "false";
            } else {
                o = "true";
            }
            parsed[Page.ASC] = o;
        } else {
            parsed[Page.ORDER] = f;
        }


        history.push("?" + qs.stringify(parsed))

    }

    const appts2 = products.map(p => {
        return (
            <tr key={p.id}>
                <td>
                    <input className="checkbox" type="checkbox" checked={isItemSelected(p.id)}
                           onChange={() => handleSelect(p.id)}/>
                </td>
                <td>
                    <Link to={`/product/${p.id}`}>
                        {p.counter}
                    </Link>
                </td>
                <td>{p.company_name}</td>
                <td>
                    <Link to={`/product/${p.id}`}>
                        {p.name}
                    </Link>
                </td>
                <td>{p.brand_name}</td>
                <td>{p.barcode}</td>
                <td>{p.packaging} {p.packaging_unit}</td>
                <td>{p.created && dayjs.utc(p.created).format("MM/DD/YYYY")}</td>
            </tr>
        )
    });

    return (

        <div className="inner-container">

            <br/>
            <div>
                <h5 style={{marginBottom: "10px"}}><strong>Нийт: {meta.totalElements}</strong>
                </h5>

                <nav className="pagination">
                    <ReactPaginate previousLabel={"Өмнөх"}
                                   nextLabel={"Дараах"}
                                   initialPage={Number(page)}
                                   breakLabel={"..."}
                                   breakClassName={"pagination-ellipsis"}
                                   pageCount={meta.totalPages}
                                   marginPagesDisplayed={2}
                                   pageRangeDisplayed={5}
                                   activeLinkClassName={"is-current"}
                                   pageLinkClassName={"pagination-link"}
                                   onPageChange={(e) => handlePageClick(e)}
                                   disableInitialCallback={true}
                                   previousClassName={"pagination-previous"}
                                   nextClassName={"pagination-next"}
                                   containerClassName={"pagination-list"}
                                   forcePage={Number(page)}

                    />
                </nav>


            </div>
            <br/>
            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th><input className="checkbox" type="checkbox"
                               onChange={() => handleSelectHeader()}/></th>
                    <th><a onClick={() => handleOrderClick("id")}>#</a></th>
                    <th><a onClick={() => handleOrderClick("company")}>Компани</a></th>
                    <th><a onClick={() => handleOrderClick("name")}>Нэр</a></th>
                    <th>Брэнд Нэр</th>
                    <th>Зур.код</th>
                    <th>Савлалтын хэмжээ /нэгж, багц/</th>
                    <th><a onClick={() => handleOrderClick("created")}>Үүсгэсэн</a></th>
                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               disableInitialCallback={true}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>
            <br/><br/>
        </div>
    )

}


export default Products14Page

