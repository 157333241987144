/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect} from "react";
import {
    fetchClassifsNouv,
    fetchCountries,
    fetchPackagingUnits,
    fetchProductNew,
    updateProduct
} from "./actions/actions";
import {useParams} from "react-router-dom";
import ProductEditComponent from "./ProductEditComponent"
import {toast} from "react-toastify";
import {GLN, GTIN, SSCC, TOKEN} from "./Constants"
import {createGlnDto, createGtinDto, createSsccDto, validateProduct} from "./utils/productUtils";
import history from "./history";
import {css} from "@emotion/react";
//import countriesData from './data/countries3.json';
//import {confirmAlert} from 'react-confirm-alert'; // Import
//import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {getReducer} from "./reducers/productReducer"

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;



const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}


const changePackaging = (unit) => {
    return {
        type: 'CHANGE_PACKAGING',
        unit
    }
}

const changeGcp = (gcp) => {
    return {
        type: 'CHANGE_GCP',
        gcp
    }
}

const changeMain = (main) => {
    return {
        type: 'CHANGE_MAIN',
        main
    }
}

const changeSub = (sub) => {
    return {
        type: 'CHANGE_SUB',
        sub
    }
}

const changeUnit = (unit) => {
    return {
        type: 'CHANGE_UNIT',
        unit
    }
}

const clickMain = () => {
    return {
        type: 'CLICK_MAIN'
    }
}
const clickSub = () => {
    return {
        type: 'CLICK_SUB'
    }
}
const clickUnit = () => {
    return {
        type: 'CLICK_UNIT'
    }
}


const copyCat = () => {
    return {
        type: 'COPY_CAT'
    }
}

const pasteCat = () => {
    return {
        type: 'PASTE_CAT'
    }
}

const addCountry =(tag) => {
    return {
        type: 'ADD_COUNTRY',
        tag
    }
}

const deleteCountry =(i) => {
    return {
        type: 'DELETE_COUNTRY',
        i
    }
}


const ProductEdit = ({onUpdate}) => {
    const {companyId, productId} = useParams();

    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = getReducer(); //useReducer(reducer, initialState);
    const {product, loading, units, selectedCountries, gcps, showMainOptions, showSubOptions, showUnitOptions, mainOptions, subOptions, unitOptions, countries} = state;

    useEffect(() => {
        fetchCountries(dispatch);
    }, []);

    useEffect(() => {
        fetchProductNew(dispatch, token, productId)
    }, [productId]);

    useEffect(() => {
            console.log('useEffect fetchPackagingUnits');
            fetchPackagingUnits(dispatch)
    }, []);



    const handleChangeField = (e) => {
        if (e.target.name === 'is_14') {
            dispatch(changeField(e.target.name, !product.is_14)); //e.target.checked));
        } else {
            dispatch(changeField(e.target.name, e.target.value));
        }
    }

    const handleChangePackagingUnit = (e) => {
        dispatch(changePackaging(e.target.value))
    };

    const handleSave = () => {
        let validated = validateProduct(product);
        // let validated = false;
        // if (product.gcp_id === null) {
        //     confirmAlert({
        //         title: 'Alert',
        //         message: 'Gcp заавал сонгоно.',
        //         buttons: [
        //             {
        //                 label: 'Yes',
        //                 onClick: () => {
        //                     return;
        //                 }
        //             },
        //         ]
        //     });
        // }
        //
        // if (product.gcp_id) {
        //     if (product.barcode.startsWith(product.gcp_prefix) ) {
        //         validated = true;
        //     } else {
        //         confirmAlert({
        //             title: 'Alert',
        //             message: 'Gcp зөрж байна.',
        //             buttons: [
        //                 {
        //                     label: 'Yes',
        //                     onClick: () => {
        //                         return;
        //                     }
        //                 },
        //             ]
        //         });
        //     }
        // }
        // if (validated) {
        //     // check the unit classification and its code
        //     if(!product.unit) {
        //         validated = false;
        //         confirmAlert({
        //             title: 'Alert',
        //             message: 'Нэгж ангилал заавал оруулах.',
        //             buttons: [
        //                 {
        //                     label: 'Yes',
        //                     onClick: () => {
        //                         return;
        //                     }
        //                 },
        //             ]
        //         });
        //     } else {
        //         // check the code
        //         let el = unitOptions.find(el => { return (
        //             el.id === product.unit
        //         )});
        //         if (el === undefined) {
        //             validated = false;
        //             confirmAlert({
        //                 title: 'Alert',
        //                 message: 'Нэгж ангилал code оруулах.',
        //                 buttons: [
        //                     {
        //                         label: 'Yes',
        //                         onClick: () => {
        //                             return;
        //                         }
        //                     },
        //                 ]
        //             });
        //
        //         } else {
        //             if ( el.code === null ) {
        //                 validated = false;
        //                 confirmAlert({
        //                     title: 'Alert',
        //                     message: 'Нэгж ангилал code оруулах.',
        //                     buttons: [
        //                         {
        //                             label: 'Yes',
        //                             onClick: () => {
        //                                 return;
        //                             }
        //                         },
        //                     ]
        //                 });
        //
        //             }
        //         }
        //     }
        //
        // }
        if (!validated) {
            return;
        }
        let dto = {}
        if (product.barcode_type === null || product.barcode_type === GTIN) {
            dto = createGtinDto(product)
        } else if (product.barcode_type === GLN) {
            dto = createGlnDto(product)
        } else if (product.barcode_type === SSCC) {
            dto = createSsccDto(product)
        }
        dto.exp_countries = selectedCountries;
        // let ex = "";
        // selectedCountries.map(m=>{
        //     ex += m.id;
        //     ex += ',';
        // })
        // dto.exp_countries_office = ex;

        dto.gcp_id = product.gcp_id;
        dto.gcp_prefix = product.gcp_prefix;
        dto.gcp_size =product.gcp_size;

        updateProduct(dispatch, productId, dto).then(response => {
                toast.success("Амжилттай илгээлээ!");
                onUpdate();
                history.goBack();
            }
        ).catch(error => {
            alert(error)
        })
    };

    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
    };

    const handleCopyCat = () => {
        console.log('copy cat');
        dispatch(copyCat());
    };

    const handlePasteCat = () => {
        console.log('paste cat');
        dispatch(pasteCat());
    };

    const handleCountryDelete = (i)=> {
        dispatch(deleteCountry(i));
    }

    const handleCountryAdd = (tag)=> {

        if (countries.some(e => e.id === tag.id)) {
            dispatch(addCountry(tag));
        }
    }

    const handleChangeGcp = (e) => {
        let v = e.target.value;
        dispatch(changeGcp(v))
    };

    //main
    const handleClickMainInput = (e) => {
        dispatch(clickMain());
        fetchClassifsNouv(dispatch, "main",0);
    };
    const handleChangeMainOption = (e) => {
        let id = e.target.value;
        dispatch(changeMain(id));
        fetchClassifsNouv(dispatch, "sub",id);
    };

    //sub
    const handleClickSubInput = (e) => {
        dispatch(clickSub());
        fetchClassifsNouv(dispatch, "sub", product.main);
    };
    const handleChangeSubOption = (e) => {
        let id = e.target.value;
        dispatch(changeSub(id));
        fetchClassifsNouv(dispatch, "unit",id);
    };

    //unit
    const handleClickUnitInput = (e) => {
        dispatch(clickUnit());
        fetchClassifsNouv(dispatch, "unit", product.sub);
    };

    const handleChangeUnitOption = (e) => {
        let id = e.target.value;
        dispatch(changeUnit(id));
    };

    return (
        <>
            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Бүтээгдэхүүний Мэдээлэл Өөрчлөх</h1>
                </div>
            </div>
            <hr/>


            <ProductEditComponent product={product} onChangeField={handleChangeField} onCopyCat={handleCopyCat} onPasteCat={handlePasteCat}
                                  onSave={handleSave} onCancel={handleCancel}
                                  packaging_units={units} onChangePackagingUnit={handleChangePackagingUnit}
                                  countriesData={countries} selectedCountries={selectedCountries} onCountryAdd={handleCountryAdd}  onCountryDelete={handleCountryDelete}
                                  gcps={gcps} onChangeGcp={handleChangeGcp} gcpDisabled={false}
                                  onClickMainInput = {handleClickMainInput} onClickSubInput={handleClickSubInput} onClickUnitInput={handleClickUnitInput}
                                  showMainOptions={showMainOptions} showSubOptions={showSubOptions} showUnitOptions={showUnitOptions}
                                  mainOptions={mainOptions} subOptions={subOptions} unitOptions={unitOptions}
                                  onChangeMainOption={handleChangeMainOption} onChangeSubOption={handleChangeSubOption}  onChangeUnitOption={handleChangeUnitOption}
            />


        </>

    )

}


export default ProductEdit;