/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import dayjs from "dayjs";
import {fetchInvoiceMailTemplate, fetchInvoiceMailTemplateList, removeInvoiceMailTemplate} from "./actions/actions";
import {ActionTypes, TOKEN} from "./Constants";
import {css} from "@emotion/react";
import dayjsPluginUTC from "dayjs-plugin-utc";
import {toast} from "react-toastify";
import history from './history'

dayjs.extend(dayjsPluginUTC)


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    invoice_templates: [],
    meta: {},
    subject: "",
    mail: "",
    current: '0',
    currentNameType:'INVOICE_7',
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {


        case ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                meta: action.meta,
                invoice_templates: action.invoice_templates,
                loading: false
            }
        case ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                mail: action.mail,
                subject: action.subject,
                loading: false
            }
        case ActionTypes.REMOVE_INVOICE_MAIL_TEMPLATE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.REMOVE_INVOICE_MAIL_TEMPLATE_SUCCESS:
            let k = state.invoice_templates.filter( item => item.id !== action.id)
            return {
                ...state,
                invoice_templates:k,
                mail: '',
                subject: '',
                loading: false
            }


        case 'CHANGE_CURRENT':
            let m = state.mail;
            let s = state.subject;
            if (action.current === "0") {
                s = "";
                m = "";
            }
            return {
                ...state,
                current: action.current,
                mail: m,
                subject: s
            }
        case 'CHANGE_NAME_TYPE':
            return {
                ...state,
                currentNameType: action.current,
            }
        case 'RESET_FIELDS':
            return {
                ...state,
                mail: "",
                subject: "",

            }
        default:
            return state;
    }
};

const changeDays = (current) => {
    return {
        type: 'CHANGE_CURRENT',
        current
    }
}

const changeNameType = (current) => {
    return {
        type: 'CHANGE_NAME_TYPE',
        current
    }
}

const InvoiceMailTemplateView = () => {
    let {path, url} = useRouteMatch();

    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        subject, name, mail, current, invoice_templates, currentNameType,
        loading
    } = state;

    useEffect(() => {
        console.log('useEffect called');
        fetchInvoiceMailTemplateList(dispatch, token);
    }, []);

    useEffect(() => {
        console.log('useEffect called');
        if (current !== "0") {
            fetchInvoiceMailTemplate(dispatch, token, current);
        }

    }, [current]);
    const templatesOptions = () => {
        let items = [];
        for (let key in invoice_templates) {
            let s = invoice_templates[key]
            items.push(<option key={s.id} value={s.id}>{s.name}</option>);
        }
        return items;
    };


    const handleChangeCurrent = (e) => {
        dispatch(changeDays(e.target.value))
    }
    const handleDelete = (e) => {
        if (current !== '0') {
            removeInvoiceMailTemplate(dispatch, token, current).then(response => {
                    toast.success("Амжилттай хадгаллаа!");

                    //history.push(`/product/${productId}`)
                }
            ).catch(error => {
                alert(error)
            });
        }
    }
    const handleEdit = (e) => {
        if (current !== '0') {
            history.push(`${url}/${current}/edit`)
        }
    }
    const handleCreate = (e) => {
            history.push(`${url}/create`)
    }
    return (
        <>
            <div className="buttons has-addons is-right">

                <button onClick={handleEdit} className="text-white border p-3 hover:text-blue-800 bg-blue-700">Өөрчлөх</button>
            </div>

            <div className="field">
                <label className="label">Templates</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select name="current" value={current} onChange={handleChangeCurrent}>
                            <option key='0' value="0"></option>
                            {templatesOptions()}
                        </select>
                    </div>
                    <button onClick={handleDelete}>Устгах</button>
                </div>
            </div>
            <div className="p-3">Subject</div>
            <div className="bg-white p-3 mb-2">{subject}</div>

            <div className="p-3">Body</div>
            <div className="bg-white p-3"
                 dangerouslySetInnerHTML={{__html: mail}}
            />
        </>
    )
}

export default InvoiceMailTemplateView;