/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useState} from "react";
import {connect} from "react-redux";
import { updateSector} from "./actions/actions";
import {Redirect} from "react-router-dom";
import SectorEditComponent from "./SectorEditComponent"

const SectorEdit = ({token, sector, sectorId, edit, isUpdating}) => {
    const [cancelClicked, setCancelClicked] = useState(false);
    const [sendClicked, setSendClicked] = useState(false);

    const handleSave = ( name) => {
        console.log('save');
        let dto = {
            name
        };
        edit(token, sectorId, dto)
        setSendClicked(true)
    };
    const handleCancel = () => {
        console.log('cancel');
        setCancelClicked(true)

    };
    if (cancelClicked || (sendClicked && !isUpdating) ) {
        return <Redirect to={`/sectors`}/>;
    }
    return (
    <div style={{background: 'white'}}>
        <div className="level">
            <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
            </div>
        </div>
        <hr/>

        <SectorEditComponent
            pname={sector.name}

            onSave={handleSave} onCancel={handleCancel}/>

    </div>

    )

}


const mapStateToProps = (state, ownProps) => {
    const sectorId = ownProps.match.params.id
    let isUpdating = state.ui.isUpdating
    let token = localStorage.getItem('id_token') || null;
    let sectors = state.sectors.sectors;

    let sector = {}
    for (let key in sectors) {
        let c = sectors[key]
        if (c.id === Number(sectorId)) {
            sector = c;
            break;
        }
    }

    return {
        token,
        sectorId,
        sector,
        isUpdating,

    }
}


const mapDispatchToProps = (dispatch) => {
    return {

        edit: (token, sectorId, info) => {
            dispatch(updateSector(token, sectorId, info))
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SectorEdit)