/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import {
    activateCompanyApplication,
    downloadCompanyApplDoc,
    fetchCompanyApplication,
    removeCompanyApplication
} from "./actions/actions";
import {useParams} from "react-router-dom";
import {ActionTypes, TOKEN} from './Constants';
import dayjs from "dayjs";
import classnames from "classnames";
import {toast} from "react-toastify";
import history from "./history";
import aimaksData from "./data/aimaks.json"

const initialState = {
    request: {},
    loading: false,
    isOpen:false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_COMPANY_APPLICATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_COMPANY_APPLICATION_SUCCESS:
            return {
                ...state,
                request: action.request,
                loading: false
            }
        case ActionTypes.DELETE_COMPANY_APPLICATION_REQUEST:{
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.DELETE_COMPANY_APPLICATION_SUCCESS:{
            return {
                ...state,
                loading: false
            }
        }
        case "CHANGE_REG_NUMBER":{
            let p = {...state.request};
            p.registration_number = action.reg;
            return {
                ...state,
                request: p
            }
        }
        case 'SET_IS_OPEN':
            return {
                ...state,
                isOpen: action.v
            }
        default:
            return state;
    }
};



const setIsOpen = (v) => {
    return {
        type: "SET_IS_OPEN",
        v
    }
}

const changeRegNumber = (reg) => {
    return {
        type: "CHANGE_REG_NUMBER",
        reg
    }
}

const CompanyApplicationEdit = () => {

    //request, requestId,
    //{
    //                            isUpdating, aimaks, districts,
    //                                     getCompanyApplDoc
    //                      }
    const {applId} = useParams();
    //const [cancelClicked, setCancelClicked] = useState(false);
    //const [sendClicked, setSendClicked] = useState(false);
    //const [isOpen, setIsOpen] = useState(false);
    //const [registrationNumber, setRegistrationNumber] = useState("");

    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        request,
        loading,
        isOpen,
    } = state;

    let token = localStorage.getItem(TOKEN) || null;

    useEffect(() => {
        console.log('useEffect called');
        fetchCompanyApplication(dispatch, token, applId);
    }, []);
    const handleActivate = (  ) => {
        console.log('activate');
        let dto = {
            registration_number:request.registration_number
        };
        activateCompanyApplication(dispatch, token, applId, dto).then(response => {
                toast.success("Амжилттай !");
                history.goBack();
            }
        ).catch(error => {
            alert(error)
        });
        //setSendClicked(true)
    };
    let modalClass = classnames({
        modal: true,
        'is-active': isOpen
    });

    const handleDeny = (  ) => {
        console.log('delete');
        dispatch(setIsOpen(true))
    };

    const handleDeleteYes = () => {
        console.log('remove company appl');
        let dto = {
            status:"DENIED",
        };
        removeCompanyApplication(dispatch, token, applId).then(response => {
                toast.success("Амжилттай !");
                dispatch(setIsOpen(false));
                history.goBack();
                //setSendClicked(true)

            }
        ).catch(error => {
            alert(error)
        });
        //dispatch(removeCompanyApplication(token, requestId, dto));

    }
    const handleDeleteCancel = () => {
        dispatch(setIsOpen(false));
    }

    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
        //setCancelClicked(true)

    };

    const aimakToString = (a) => {
        let ret = ""
        let ra = aimaksData.filter(b=>b.id === a);
        if (ra.length>0) {
            ret = ra[0].name;
        }
        // if (aimaks.has(a)) {
        //     ret = aimaks.get(a)
        // }
        return ret
    };
    const districtToString = (aimak, d) => {
        let ret = ""
        if (aimak) {
            let ra = aimaksData.filter(b=>b.id === aimak);
            if (ra.length>0) {
                let sa  = ra[0].units.filter(c=>c.id === d);
                if (sa.length > 0) {
                    ret = sa[0].name;
                }

            }
            // let soums = all_soums.get(aimak);
            // if (soums.has(d)) {
            //     ret = soums.get(d)
            // }
        }
        //
        // if (districts.has(d)) {
        //     ret = districts.get(d)
        // }
        return ret

    };
    const handleChangeRegistrationNumber = (e) => {
        dispatch(changeRegNumber(e.target.value))
    };

    // const handleChangeBarcode = (e) => {
    //     setBarcode(e.target.value)
    // };

    // if (cancelClicked) {
    //     return <Redirect to={`/company-appls`}/>;
    // }
    // if (sendClicked && !isUpdating) {
    //     return <Redirect to={`/company-appls`}/>;
    // }

    const handleDownloadDoc = (s) => {
        downloadCompanyApplDoc(dispatch, token, applId, s);
    };

    return (
        <div className="inner-container">
            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>

            <div className="field">
                <label className="label">Хуулийн этгээдийн нэр, /Хувь хүн бол овог нэр/</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.name}  disabled={true}
                           />

                </div>
            </div>

            <div className="field">
                <label className="label">Нэр Англиар</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.name_english}  disabled={true}
                    />

                </div>
            </div>
            <div className="field">
                <label className="label">Улсын бүртгэлийн дугаар</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.state_reg_number} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Регистрийн дугаар</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.registration_number} onChange={(e) => handleChangeRegistrationNumber(e)} disabled={false}
                    />
                </div>
            </div>

            <div className="field">
                <label className="label">Байгуулагдсан огноо</label>
                <div className="control has-icons-left">
                    {request && request.established && dayjs(request.established).format("MM/DD/YYYY")}
                </div>
            </div>
            <div className="field">
                <label className="label">Аймаг/Хот</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {aimakToString(request.aimak)} disabled={true}
                    />

                </div>
            </div>
            <div className="field">
                <label className="label">Сум/ Дүүрэг</label>
                <div className="control has-icons-left">
                    <div className="control has-icons-left">
                        <input className="input" type="text"  value = {districtToString(request.aimak, request.district)} disabled={true}
                        />
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="label">Баг / Хороо</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {request.soum} disabled={true} />
                </div>
            </div>

            <div className="field">
                <label className="label">Гудамж/Тоот</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {request.address} disabled={true} />
                </div>
            </div>
            <div className="field">
                <label className="label">Утас</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {request.phone} disabled={true} />

                </div>
            </div>
            <div className="field">
                <label className="label">Шуудангийн хайрцаг:</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {request.postal_address} disabled={true} />

                </div>
            </div>
            <div className="field">
                <label className="label">Мэйл</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {request.email} disabled={true} />
                </div>
            </div>
            <div className="field">
                <label className="label">Сошиал хаяг</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {request.social} disabled={true} />
                </div>
            </div>
            <div className="field">
                <label className="label">Вебхуудас</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {request.web} disabled={true} />

                </div>
            </div>
            <div className="field">
                <label className="label">Зип код:</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {request.zipcode} disabled={true} />

                </div>
            </div>
            <div className="field">
                <label className="label">Шуудангийн код:</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {request.postal_code} disabled={true} />
                </div>
            </div>


            <div className="field">
                <label className="label">Экспорт хийдэг эсэх</label>
                <div className="control has-icons-left">
                    <input className="checkbox" type="checkbox" checked={request.is_exported} disabled={true}
                           />
                </div>
            </div>
            <div className="field">
                <label className="label">Тийм бол экспорт хийж буй улсын нэр/ Үгүй бол хийхээр төлөвлөж байгаа улс</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {request.is_exported_countries} disabled={true} />

                </div>
            </div>

            <div className="field">
                <label className="label">Үйл ажиллагааны чиглэл</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  value = {request.sector_name} disabled={true} />
                </div>
            </div>
            <div className="field">
                <label className="label">Албан Тоот</label>
                <div className="control has-icons-left">
                    { request && request.exists_letter_att &&
                    <a onClick={(e) => handleDownloadDoc(1)}>{request.letter_file_name}</a>
                    }
                </div>
            </div>
            <div className="field">
                <label className="label">Гэрчилгээ/Үнэмлэх</label>
                <div className="control has-icons-left">
                    { request && request.exists_doc_att &&
                    <a onClick={(e) => handleDownloadDoc(2)}>{request.doc_file_name}</a>
                    }
                </div>
            </div>
            <div className="field">
                <label className="label">Гэрчилгээ/Үнэмлэх /Арын Хуудас/ </label>
                <div className="control has-icons-left">
                    { request && request.exists_doc2_att &&
                    <a onClick={(e) => handleDownloadDoc(3)}>{request.doc2_file_name}</a>
                    }
                </div>
            </div>
            <div className="field">
                <label className="label">Захирал: Овог</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.ceo_lastname} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Захирал: Нэр</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.ceo_name} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Захирал: Албан тушаал</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.ceo_position} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Захирал: Утас</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.ceo_phone} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Захирал: Емэйл</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.ceo_email} disabled={true}
                    />
                </div>
            </div>

            <div className="field">
                <label className="label">Зураасан код хариуцсан ажилтан: Овог</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.admin_lastname} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Зураасан код хариуцсан ажилтан: Нэр</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.admin_name} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Зураасан код хариуцсан ажилтан: Албан тушаал</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.admin_position} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Зураасан код хариуцсан ажилтан: Утас</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.admin_phone} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Зураасан код хариуцсан ажилтан: Емэйл</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.admin_email} disabled={true}
                    />
                </div>
            </div>

            <div className="field">
                <label className="label">Илгээсэн: Нэр</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.sender_name} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Илгээсэн: Албан тушаал</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.sender_position} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Илгээсэн: Утас</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.sender_phone} disabled={true}
                           />
                </div>
            </div>


            <div className="buttons is-right" style={{paddingTop: '10px'}}>
                <button className="button" type="primary" onClick={() => handleCancel()}>Болих</button>
                <button className="button is-danger" type="primary"
                        onClick={() => handleDeny()}>
                    Устгах
                </button>
                <button className="button is-success" type="primary"
                        onClick={() => handleActivate( )}>
                    Идэвхжүүлэх
                </button>

            </div>

            <br/><br/>
            <div className={modalClass}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Устгах?</p>
                        <button className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        Та устгахдаа итгэлтэй байна уу?
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={handleDeleteYes}>Тийм</button>
                        <button className="button" onClick={handleDeleteCancel}>Үгүй</button>
                    </footer>
                </div>
            </div>

        </div>

    )

}

//
// const mapStateToProps = (state, ownProps) => {
//     const requestId = ownProps.match.params.applId
//
//     let isUpdating = state.ui.isUpdating
//     let token = localStorage.getItem(TOKEN) || null;
//     let request = state.applications.company
//     let aimaks = state.aimaks.aimaks;
//     let districts = state.aimaks.districts;
//     return {
//         token,
//         requestId,
//         request,
//         isUpdating,
//         aimaks,
//         districts
//     }
// }




//export default connect(mapStateToProps)(CompanyApplicationEdit)
export default CompanyApplicationEdit;