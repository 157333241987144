/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer, useState} from "react";
import {
    activateProductApplication,
    fetchClassifsNouv, fetchCountries,
    fetchPackagingUnits,
    fetchProductApplication
} from "./actions/actions";
import {ActionTypes, GLN, GTIN, SSCC} from "./Constants";
import history from "./history";
import {toast} from "react-toastify";
import ProductEditComponentSender from "./ProductEditComponentSender";
import ProductEditComponentGln from "./ProductEditComponentGln";
import ProductEditComponentGtin from "./ProductEditComponentGtin";
import {useParams} from "react-router-dom";
//import countriesData from "./data/countries3.json";
import ProductEditComponentSscc from "./ProductEditComponentSscc";
import {confirmAlert} from "react-confirm-alert";
import {checkGtin, validateProduct} from "./utils/productUtils";


const initialState = {
    request: {barcode_type: GTIN, main:null, main_name:null, main_code:null, sub:null,  sub_name:null, sub_code:null, unit:null, unit_name:null, unit_code:null},
    loading: false,
    gcps:[],
    units:[],
    barcode:'',

    selectedCountries:[{id:"496",name:"MONGOLIA"}],

    showMainOptions:false,
    showSubOptions:false,
    showUnitOptions:false,
    mainOptions:[],
    subOptions:[],
    unitOptions:[],
    countries:[]
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_PRODUCT_APPLICATION_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.LOAD_COUNTRIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                countries: action.countries
            }
        }
        case ActionTypes.GET_PRODUCT_APPLICATION_SUCCESS: {
            let exp = [{id:"496", name:"MONGOLIA"}]

            // if (action.request.exp_countries_json){
            //     // let str = action.request.exp_countries_office; //'MN,RU,FR,';
            //     //
            //     // //remove the last comma
            //     // str = str.replace(/,\s*$/, "");
            //     //
            //     // let test = str.split(','); //['MN','RU','FR'];
            //     action.request.exp_countries_json.map(el => {
            //         const c = state.countries.find(e => e.id === el)
            //         exp = [...exp, c]
            //     })
            // }
            let p = action.request;
            p.barcode = sessionStorage.getItem("barcode");

            // check gcp
            let gcp_id = sessionStorage.getItem("gcp_id");
            action.gcps.map(g=>{
                if (g.id === gcp_id) {
                    p.gcp_id = gcp_id;
                }
            });

            //init classifs from session storage
            p.main = sessionStorage.getItem("a_main", '0');
            p.sub = sessionStorage.getItem("a_sub", '0');
            p.unit = sessionStorage.getItem("a_unit", '0');

            p.main_code = sessionStorage.getItem("a_main_code", '0');
            p.sub_code = sessionStorage.getItem("a_sub_code", '0');
            p.unit_code = sessionStorage.getItem("a_unit_code", '0');

            p.main_name = sessionStorage.getItem("a_main_name", '0');
            p.sub_name = sessionStorage.getItem("a_sub_name", '0');
            p.unit_name = sessionStorage.getItem("a_unit_name", '0');

            return {
                ...state,
                request: p,
                selectedCountries: exp,
                gcps:action.gcps,
                loading: false
            }
        }
        case ActionTypes.ACTIVATE_PRODUCT_APPLICATION_SUCCESS: {
            let p = action.request;
            return {
                ...state,
                request: p, //action.request,
                loading: false
            }
        }

        case 'COPY_CAT': {
            let p = {...state.request};
            localStorage.setItem("main_name", p.main_name);
            localStorage.setItem("sub_name", p.sub_name);
            localStorage.setItem("unit_name", p.unit_name);
            localStorage.setItem("main_code", p.main_code);
            localStorage.setItem("sub_code", p.sub_code);
            localStorage.setItem("unit_code", p.unit_code);
            localStorage.setItem("main", p.main);
            localStorage.setItem("sub", p.sub);
            localStorage.setItem("unit", p.unit);
            return {
                ...state,
            }
        }
        case 'PASTE_CAT': {
            let mainName = localStorage.getItem("main_name");
            let subName = localStorage.getItem("sub_name");
            let unitName = localStorage.getItem("unit_name");
            let mainCode = localStorage.getItem("main_code");
            let subCode = localStorage.getItem("sub_code");
            let unitCode = localStorage.getItem("unit_code");
            let main = localStorage.getItem("main");
            let sub = localStorage.getItem("sub");
            let unit = localStorage.getItem("unit");

            let p = {...state.request};
            p["main_name"] = mainName;
            p["sub_name"] = subName;
            p["unit_name"] = unitName;
            p["main_code"] = mainCode;
            p["sub_code"] = subCode;
            p["unit_code"] = unitCode;
            p["main"] = main;
            p["sub"] = sub;
            p["unit"] = unit;
            return {
                ...state,
                request: p
            }
        }
        case ActionTypes.LOAD_CLASSIF_NOUV_MAIN_SUCCESS: {
            return {
                ...state,
                loading: false,
                mainOptions:action.classifs,
            }
        }
        case ActionTypes.LOAD_CLASSIF_NOUV_SUB_SUCCESS: {
            return {
                ...state,
                loading: false,
                subOptions:action.classifs,
            }
        }
        case ActionTypes.LOAD_CLASSIF_NOUV_UNIT_SUCCESS: {
            return {
                ...state,
                loading: false,
                unitOptions:action.classifs
            }
        }

        case ActionTypes.LOAD_PACKAGING_UNITS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.LOAD_PACKAGING_UNITS_SUCCESS: {
            return {
                ...state,
                units:action.units,
                loading: false
            }
        }
        case ActionTypes.LOAD_CLASSIF_NOUV_MAIN_REQUEST:
        case ActionTypes.LOAD_CLASSIF_NOUV_SUB_REQUEST:
        case ActionTypes.LOAD_CLASSIF_NOUV_UNIT_REQUEST:{

            return {
                ...state,
                loading: true
            }
        }
        case 'INIT_CLASSIFS': {

            let p ={...state.request}
            p.main = sessionStorage.getItem("a_main", '0');
            p.sub = sessionStorage.getItem("a_sub", '0');
            p.unit = sessionStorage.getItem("a_unit", '0');

            p.main_code = sessionStorage.getItem("a_main_code", '0');
            p.sub_code = sessionStorage.getItem("a_sub_code", '0');
            p.unit_code = sessionStorage.getItem("a_unit_code", '0');

            p.main_name = sessionStorage.getItem("a_main_name", '0');
            p.sub_name = sessionStorage.getItem("a_sub_name", '0');
            p.unit_name = sessionStorage.getItem("a_unit_name", '0');

            return {
                ...state,
                request:p,
            }
        }
        case 'CHANGE_MAIN': {
            let c = {...state.request};
            c.main = action.main;
            c.sub = null;
            c.sub_name = null;
            c.sub_code = null;
            c.unit = null;
            c.unit_name = null;
            c.unit_code = null;

            sessionStorage.setItem('a_main', action.main);
            let ma = state.mainOptions.filter(o=> o.id === Number(action.main))
            if (ma.length > 0) {
                sessionStorage.setItem('a_main_code', ma[0].code);
                sessionStorage.setItem('a_main_name', ma[0].name);
            } else {
                sessionStorage.setItem('a_main_code', "");
                sessionStorage.setItem('a_main_name', "");
            }

            sessionStorage.setItem('a_sub', "0");
            sessionStorage.setItem('a_sub_code', "");
            sessionStorage.setItem('a_sub_name', "");

            sessionStorage.setItem('a_unit', "0");
            sessionStorage.setItem('a_unit_code', "");
            sessionStorage.setItem('a_unit_name', "");

            return {
                ...state,
                request:c,
                subOptions:[],
                unitOptions:[],
                loading: false
            }
        }
        case 'CHANGE_SUB': {
            let c = {...state.request};
            c.sub = action.sub;
            c.unit = null;
            c.unit_name = null;
            c.unit_code = null;

            sessionStorage.setItem('a_sub', action.sub);
            let ma = state.subOptions.filter(o=> o.id === Number(action.sub))
            if (ma.length > 0) {
                sessionStorage.setItem('a_sub_code', ma[0].code);
                sessionStorage.setItem('a_sub_name', ma[0].name);
            } else {
                sessionStorage.setItem('a_sub_code', "");
                sessionStorage.setItem('a_sub_name', "");
            }

            sessionStorage.setItem('a_unit', "0");
            sessionStorage.setItem('a_unit_code', "");
            sessionStorage.setItem('a_unit_name', "");

            return {
                ...state,
                request: c,
                unitOptions:[],
                loading: false
            }
        }
        case 'CHANGE_UNIT': {
             let c = {...state.request};
             c.unit = action.unit;

            sessionStorage.setItem('a_unit', action.unit);
            let ma = state.unitOptions.filter(o=> o.id === Number(action.unit))
            if (ma.length > 0) {
                c.unit_code = ma[0].code;
                c.unit_name = ma[0].name;
                sessionStorage.setItem('a_unit_code', ma[0].code);
                sessionStorage.setItem('a_unit_name', ma[0].name);
            } else {
                sessionStorage.setItem('a_unit_code', "");
                sessionStorage.setItem('a_unit_name', "");
            }

            return {
                ...state,
                request: c,
                loading: false
            }
        }
        case 'CHANGE_GCP': {
            let c = {...state.request};
            if ( action.gcp === "0") {
                c.gcp_id = null;
                c.gcp_prefix = null;
                c.gcp_size = null;
                c.g_size = null;
            } else {
                let f = state.gcps.filter(item=>item.id === Number(action.gcp));
                c.gcp_id = f[0].id;
                c.gcp_prefix = f[0].prefix;
                c.gcp_size = f[0].size;
                c.g_prefix = f[0].prefix;
                c.g_size = f[0].size;

                if ( c.barcode ) {
                } else {
                    c.barcode = c.gcp_prefix;
                }
            }

            return {
                ...state,
                request: c,
                loading: false
            }
        }

        case 'CHANGE_PACKAGING': {
            let c = state.request;
            c.packaging_unit = action.unit;

            return {
                ...state,
                request: c,
                loading: false
            }
        }

        case 'CHANGE_FIELD':
            let c = {...state.request};
            c[action.name] = action.value
            return {
                ...state,
                request: c,
                loading: false
            }

        case 'ADD_COUNTRY':
            // check tag
            return {
                ...state,
                selectedCountries: [...state.selectedCountries, action.tag],
            }
        case 'DELETE_COUNTRY':
            return {
                ...state,
                selectedCountries: state.selectedCountries.filter((tag, index) => index !== action.i)

            }
        case 'CLICK_MAIN': {

            return {
                ...state,
                showMainOptions: true
            }
        }
        case 'CLICK_SUB': {

            return {
                ...state,
                showSubOptions: true
            }
        }
        case 'CLICK_UNIT': {

            return {
                ...state,
                showUnitOptions: true
            }
        }
        default:
            return state;
    }
};


const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}
const changeMain = (main) => {
    return {
        type: 'CHANGE_MAIN',
        main
    }
}

const changeSub = (sub) => {
    return {
        type: 'CHANGE_SUB',
        sub
    }
}

const changeUnit = (unit) => {
    return {
        type: 'CHANGE_UNIT',
        unit
    }
}
const changeGcp = (gcp) => {
    return {
        type: 'CHANGE_GCP',
        gcp
    }
}

const changePackaging = (unit) => {
    return {
        type: 'CHANGE_PACKAGING',
        unit
    }
}


const initClassifs = () => {
    return {
        type: 'INIT_CLASSIFS'
    }
}

const addCountry =(tag) => {
    return {
        type: 'ADD_COUNTRY',
        tag
    }
}

const deleteCountry =(i) => {
    return {
        type: 'DELETE_COUNTRY',
        i
    }

}

const clickMain = () => {
    return {
        type: 'CLICK_MAIN'
    }
}
const clickSub = () => {
    return {
        type: 'CLICK_SUB'
    }
}
const clickUnit = () => {
    return {
        type: 'CLICK_UNIT'
    }
}

const ProductApplicationEdit = () => {

    const {applId} = useParams();

    const [state, dispatch] = useReducer(reducer, initialState);
    const {request, loading, units, selectedCountries, gcps, showMainOptions, showSubOptions, showUnitOptions, mainOptions, subOptions, unitOptions, countries} = state;

    useEffect(() => {
        fetchCountries(dispatch);
    }, []);

    useEffect(() => {
        fetchProductApplication(dispatch, applId);
    }, [applId]);

    // useEffect(() => {
    //     dispatch(initClassifs());
    // }, [applId]);

    useEffect(() => {
        fetchPackagingUnits(dispatch)
    }, []);

    useEffect(() => {
            console.log('useEffect fetchClassifsNew');
            fetchClassifsNouv(dispatch, "main", 0);
    }, []);


    const handleActivate = () => {
        console.log('save');
        let validated = validateProduct(request);
        if (!validated) {
            return;
        }

        // let validated = false;
        // if (request.gcp_id === null) {
        //     confirmAlert({
        //         title: 'Alert',
        //         message: 'Gcp заавал сонгоно.',
        //         buttons: [
        //             {
        //                 label: 'Yes',
        //                 onClick: () => {
        //                     return;
        //                 }
        //             },
        //         ]
        //     });
        // }
        //
        // if (request.gcp_id) {
        //     if (request.barcode.startsWith(request.gcp_prefix) ) {
        //         validated = true;
        //     } else {
        //         confirmAlert({
        //             title: 'Alert',
        //             message: 'Gcp зөрж байна.',
        //             buttons: [
        //                 {
        //                     label: 'Yes',
        //                     onClick: () => {
        //                         return;
        //                     }
        //                 },
        //             ]
        //         });
        //     }
        // }
        // if (!validated) {
        //     return;
        // }

        let dto = request;
         dto.main = Number(request.main);
         dto.sub = request.sub === null ? null : Number(request.sub);
         dto.unit = request.unit === null ? null : Number(request.unit);

        //     dto = {
        //     barcode:request.barcode,
        //     gcp_id:request.gcp_id,
        //     gcp_prefix:request.gcp_prefix,
        //     gcp_size:request.gcp_size,
        //     main: Number(main),
        //     sub: sub === null ? null : Number(sub),
        //     unit: unit === null ? null : Number(unit),
        // };
        dto.exp_countries = selectedCountries;
        // let ex = "";
        // selectedCountries.map(m=>{
        //     ex += m.id;
        //     ex += ',';
        // })
        // dto.exp_countries_office = ex;
        activateProductApplication(dispatch, applId, dto).then(response => {
                sessionStorage.setItem("barcode",request.barcode);
                sessionStorage.setItem("gcp_id",request.gcp_id);
                toast.success("Амжилттай идэвхжүүллээ!");
                history.push("/prod-appls")
            }
        ).catch(error => {
            alert(error)
        })
    };
    const handleActivate2 = () => {
        let validated = validateProduct(request);
        if (!validated) {
            return;
        }
        // let validated = false;
        // if (request.gcp_id === null) {
        //     confirmAlert({
        //         title: 'Alert',
        //         message: 'Gcp заавал сонгоно.',
        //         buttons: [
        //             {
        //                 label: 'Yes',
        //                 onClick: () => {
        //                     return;
        //                 }
        //             },
        //         ]
        //     });
        // }
        //
        // if (request.gcp_id) {
        //     if (request.barcode.startsWith(request.gcp_prefix) ) {
        //         validated = true;
        //     } else {
        //         confirmAlert({
        //             title: 'Alert',
        //             message: 'Gcp зөрж байна.',
        //             buttons: [
        //                 {
        //                     label: 'Yes',
        //                     onClick: () => {
        //                         return;
        //                     }
        //                 },
        //             ]
        //         });
        //     }
        // }
        // if (!validated) {
        //     return;
        // }

        let dto = request;
         dto.main = Number(request.main);
         dto.sub = request.sub === null ? null : Number(request.sub);
         dto.unit = request.unit === null ? null : Number(request.unit);

        // let dto = {
        //     barcode:request.barcode,
        //     gcp_id:request.gcp_id,
        //     gcp_prefix:request.gcp_prefix,
        //     gcp_size:request.gcp_size,
        //     main: Number(main),
        //     sub: sub === null ? null : Number(sub),
        //     unit: unit === null ? null : Number(unit),
        // };
        // let ex = "";
        // selectedCountries.map(m=>{
        //     ex += m.id;
        //     ex += ',';
        // })
        // dto.exp_countries_office = ex;
        dto.exp_countries = selectedCountries;
        activateProductApplication(dispatch, applId, dto).then(response => {
            sessionStorage.setItem("barcode",request.barcode);
            toast.success("Амжилттай идэвхжүүллээ!");
                history.push("/company/" + request.company_id )
            }
        ).catch(error => {
            alert(error)
        })
    };

    const handleDeny = () => {
        console.log('save');
        history.push("/prod-appl/deny/" + applId)
    };
    const handleCancel = () => {
        console.log('cancel');
        history.goBack()
    };

    const handleChangeField = (e) => {
        if (e.target.name === 'is_14') {
            dispatch(changeField(e.target.name, !request.is_14)); //e.target.checked));
        } else {
            dispatch(changeField(e.target.name, e.target.value));
        }
//        dispatch(changeField(e.target.name, e.target.value))
    }

    // main
    const handleClickMainInput = (e) => {
        dispatch(clickMain());
        fetchClassifsNouv(dispatch, "main",0);
    };
    const handleChangeMain = (e) => {
        let id = e.target.value;
        dispatch(changeMain(id));
        fetchClassifsNouv(dispatch, "sub",id);
    };

    //sub
    const handleClickSubInput = (e) => {
        dispatch(clickSub());
        fetchClassifsNouv(dispatch, "sub", request.main);
    };
    const handleChangeSub = (e) => {
        //dispatch(changeSub(e.target.value))
        let id = e.target.value;
        dispatch(changeSub(id));
        fetchClassifsNouv(dispatch, "unit",id);
    };

    //unit
    const handleClickUnitInput = (e) => {
        dispatch(clickUnit());
        fetchClassifsNouv(dispatch, "unit", request.sub);
    };
    const handleChangeUnit = (e) => {
        dispatch(changeUnit(e.target.value))
    };

    const handleChangeGcp = (e) => {
        let v = e.target.value;
        dispatch(changeGcp(v))
    };

    const handleChangePackagingUnit = (e) => {
        dispatch(changePackaging(e.target.value))
    };

    const handleCountryDelete = (i)=> {
        dispatch(deleteCountry(i));
    }

    const handleCountryAdd = (tag)=> {
        if (countries.some(e => e.id === tag.id)) {
            dispatch(addCountry(tag));
        }
    }

    // const checkGtin = (sNumber) => {
    //     if (!sNumber) {
    //         return false;
    //     }
    //     let output = []
    //     // separate numbers
    //     for (let i = 0, len = sNumber.length; i < len; i += 1) {
    //         output.push(+sNumber.charAt(i));
    //     }
    //
    //     // gtin 13
    //     if (output.length === 13) {
    //         let sum = 0;
    //         for (let i = 0; i < output.length - 1; i++) {
    //             if (i % 2 === 0) {
    //                 sum += output[i] * 1
    //             } else {
    //                 sum += output[i] * 3
    //             }
    //         }
    //         //
    //         let ceil = Math.ceil(sum / 10) * 10;
    //         let check = ceil - sum;
    //         return check === output[12];
    //     } else {
    //         return false;
    //     }
    // }
    return (
        <div className="inner-container">
            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>

            <div className="field">
                <label className="label">Байгууллагын нэр</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={request.company_name} disabled={true}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">GCP</label>
                <div className="select">
                    <select name="gcp_id" value={request.gcp_id}  onChange={handleChangeGcp}>
                        <option value="0"></option>
                        {gcps.map(gcp => {
                            return (
                                <option key={gcp.id} value={gcp.id}>{gcp.prefix}</option>
                            )
                        })
                        }
                    </select>
                </div>
            </div>
            <div className="field ">
                <label className="label relative">
                        Зураасан код *
                </label>
                <div className="relative">
                    <div className={"flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"}>
                        {checkGtin(request.barcode) ? <div className={"block w-2 p-2 bg-green-500"}></div> :
                            <span
                                className={"inline-block align-middle w-6 p-2 h-fit text-gray-200 bg-red-500  "}></span>}
                    </div>
                    <input className="p-1 pl-10 border border-gray-300"  type="text" name="barcode" value={request.barcode}
                           onChange={(e) => handleChangeField(e)}
                    />
                </div>
            </div>
            {(request.barcode_type === null || request.barcode_type === GTIN || request.barcode_type === SSCC) &&
            <div className="field">
                <label className="label">Бүтээгдэхүүний нэр *</label>
                <div className="control">
                    <input className="input" type="text" name="name" value={request.name}
                           onChange={(e) => handleChangeField(e)} disabled={false}
                    />

                </div>
            </div>
            }
            {(request.barcode_type === null || request.barcode_type === GTIN) &&
            <div>
                <ProductEditComponentGtin packaging_units={units} onChangePackagingUnit={handleChangePackagingUnit}
                                          product={request} onChangeField={handleChangeField} disabled={false}
                                          countriesData={countries} selectedCountries={selectedCountries} onCountryAdd={handleCountryAdd}  onCountryDelete={handleCountryDelete}
                />

                <div className="field">
                    <label className="label">ҮНДСЭН АНГИЛАЛ</label>
                    {!showMainOptions &&
                    < div className="control has-icons-left">
                        <div className="border border-gray-300 p-2" onClick={(e)=>handleClickMainInput(e)}>{request.main_name===null?' - ':request.main_name}  { request.main_code===null?'':' ['+ request.main_code +']'}</div>
                    </div>
                    }
                    {showMainOptions &&
                    <div className="control">
                        <div className="select">
                            <select value={request.main} onChange={(e) => handleChangeMain(e)}>
                                <option value="0"></option>
                                {mainOptions.map(item=> {
                                    return (
                                        <option key={item.id} value={item.id}>{item.name}-[{item.code}]</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                    }
                </div>
                <div className="field">
                    <label className="label">ДЭД АНГИЛАЛ</label>
                    {!showSubOptions &&
                    < div className="control has-icons-left">
                        <div className="border border-gray-300 p-2" onClick={handleClickSubInput}>{request.sub_name===null?' - ':request.sub_name}  { request.sub_code===null?'':' ['+ request.sub_code +']'}</div>
                    </div>
                    }
                    {showSubOptions &&
                    <div className="control">
                        <div className="select">
                            <select value={request.sub} onChange={(e) => handleChangeSub(e)}
                                    >
                                <option value="0"></option>
                                {subOptions.map(item => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.name}-[{item.code}]</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                    }
                </div>
                <div className="field">
                    <label className="label">НЭГЖ АНГИЛАЛ</label>
                    {!showUnitOptions &&
                    < div className="control has-icons-left">
                        <div className="border border-gray-300 p-2" onClick={handleClickUnitInput}>{request.unit_name===null?' - ':request.unit_name}  { request.unit_code===null?'':' ['+ request.unit_code +']'}</div>
                    </div>
                    }
                    {showUnitOptions &&

                    <div className="control">
                        <div className="select">
                            <select name="unit" value={request.unit} onChange={(e) => handleChangeUnit(e)}
                                    >
                                <option value="0"></option>
                                {unitOptions.map(item => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.name}-[{item.code}]</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                    }
                </div>
            </div>
            }

            {(request.barcode_type === GLN) &&
                <ProductEditComponentGln  product={request} onChangeField={handleChangeField} disabled={false}/>
            }
            {(request.barcode_type === SSCC) &&
                <ProductEditComponentSscc product={request} onChangeField={handleChangeField} disabled={false}/>
            }

            <div className="field">
                <label className="label">GS1-14</label>
                <div className="control has-icons-left">
                    <input className="checkbox" type="checkbox"  name="is_14"  checked={request.is_14}
                           onChange={handleChangeField}/>
                </div>
            </div>


            <ProductEditComponentSender product={request} onChangeField={null} disabled={true}/>

            <div className="buttons is-right" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                <button className="button" type="primary" onClick={() => handleCancel()}>Болих</button>
                <button className="button is-danger" type="primary"
                        onClick={() => handleDeny()}>
                    Буцаах
                </button>
                <button className="button is-success" type="primary"
                        onClick={() => handleActivate()}>
                    Идэвхжүүлэх
                </button>
                <button className="button is-success" type="primary"
                        onClick={() => handleActivate2()}>
                    Идэвхжүүлэх 2
                </button>

            </div>
        </div>

    )

}


export default ProductApplicationEdit;