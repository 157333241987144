/**
 * Created by gantushig on 11/6/15.
 */

import {connect} from "react-redux";
import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {createInvoice, fetchInvoices, removeInvoice, printInvoice} from "./actions/actions";
import {createBrowserHistory} from "history";
import {ActionTypes, TOKEN} from "./Constants";
import dayjs from "dayjs";
import CompanyViewHeader from "./CompanyViewHeader";


const initialState = {
    invoices: [],
    loading: false,
};

const reducer = (state, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_INVOICES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_INVOICES_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: action.invoices,
            }
        default:
            return state;
    }
};



const CompanyInvoices = ({token, invoices, companyId, gcpId, loadInvoices, invoices_ids_by_order, print, remove, create}) => {

 //   const [state, dispatch] = useReducer(reducer, initialState);
//    let {invoices, loading} = state

    useEffect(() => {

        loadInvoices(token, gcpId)
    }, []);

    if (invoices_ids_by_order === null || invoices_ids_by_order === undefined) {
        return (
            <div className="inner-container">
                <CompanyViewHeader tab={2} companyId={companyId}/>
                <div className="buttons is-right">
                    <a className="button" onClick={()=>handleCreate()}>
                        Үүсгэх
                    </a>
                </div>

                <h1>Нэхэмжлэх</h1>

                <table className="table is-bordered is-fullwidth">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Утга</th>
                        <th>Дүн</th>
                        <th>Үүсгэсэн</th>
                        <th>Үйлдэл</th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
                <div className="subtitle">No Items</div>

            </div>
        )
    }
    ;

    const handlePrint = (id) => {
        print(token, id)
    };
    const handleRemove = (id) => {
        remove(token, id)
    };
    const handleCreate = () => {
        create(token, gcpId)
    };

    const items = invoices_ids_by_order.map(
        (id) => {
            return (
                <tr key={id}>
                    <td>{invoices[id].counter}</td>
                    <td><Link to={`/company/${companyId}/gcp/${gcpId}/invoice/${id}`}>
                        {invoices[id].label}</Link>
                    </td>
                    <td>{invoices[id].total_amount}</td>
                    <td>{ invoices[id].created && dayjs(invoices[id].created).format("MM/DD/YYYY")}</td>
                    <td>
                        <a className="button is-outlined" onClick={(е) => handlePrint(id)}>Print</a> <a className="button is-outlined" onClick={(е) => handleRemove(id)}>Устгах</a>
                    </td>
                </tr>
            )
        });

    return (
        <div className="inner-container">
            <CompanyViewHeader tab={2} companyId={companyId}/>
            <div className="buttons is-right">
                <a className="button" onClick={()=>handleCreate()}>
                    Үүсгэх
                </a>
            </div>

            <h1>Нэхэмжлэх</h1>

            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Утга</th>
                    <th>Дүн</th>
                    <th>Үүсгэсэн</th>
                    <th>Үйлдэл</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <br/><br/>

        </div>
    )

}


const mapStateToProps = (state, ownProps) => {
    let isUpdating = state.ui.isUpdating;
    let token = localStorage.getItem(TOKEN) || null;

    const gcpId = ownProps.match.params.gcpId
    const companyId = ownProps.match.params.id
    let invoices = state.invoices.invoices;
    let counter = 0

    const invoices_ids_by_order =
        Object.values(invoices)
            .reduce((ordered_ids, b) => {
                ordered_ids[b.order] = b.id
                return ordered_ids
            }, []);

    invoices_ids_by_order.map(
        (id) => {
            counter++
            invoices[id].counter = counter
        });


    return {
        token,
        isUpdating,
        invoices,
        invoices_ids_by_order,
        gcpId,
        companyId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        loadInvoices: (token, gcpId) => {
            dispatch(fetchInvoices(token, gcpId))
        },
        create: (token, gcpId) => {
            dispatch(createInvoice(token, gcpId))
        },
        print: (token, id) => {
            dispatch(printInvoice(token, id))
        },
        remove: (token, id) => {
            dispatch(removeInvoice(token, id))
        },

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyInvoices)


