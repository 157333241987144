/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer, useState} from "react";
import Form from "./components/Form";
import TextInput from "./components/TextInput";
import SubmitButton from "./components/SubmitButton";
import aimaksData from "./data/aimaks.json";
import {createCompanySelf, fetchSectorsAllNoAuth2, signupNewBusiness} from "./actions/actions";
import {toast} from "react-toastify";
import history from "./history";
import {ActionTypes} from "./Constants";
import {Link} from "react-router-dom";


const initialState = {
    sectors: [],
    loading: false,
    saved:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_SECTORS_ALL_NO_AUTH_2_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_SECTORS_ALL_NO_AUTH_2_SUCCESS: {
            return {
                ...state,
                sectors: action.sectors,
                loading: false
            }
        }
        default:
            return state;
    }
};




const SignupIndividual = () => {
    const [isExported, setIsExported] = useState(false);
    const [aimak, setAimak] = useState("");
    const [district, setDistrict] = useState("");
    const [sector, setSector] = useState("");

    const [errors, setErrors] = useState({
        aimak:'',
        district:'',
        sector:'',
    });

    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        sectors,
        loading
    } = state;

    useEffect(() => {
        fetchSectorsAllNoAuth2(dispatch);
    }, []);


    const handleChangeIsExported = (e) => {
        setIsExported(e.target.checked)
    };

    const handleClick=(data)=> {

        let ret = validate()
        if (!ret) {
            // save
            console.log("to save")
            let dto = {
                business_type:"INDVDL",
                name:data.fields["name"].value,
                name_english:data.fields["name_english"].value,
                registration_number:data.fields["registration_number"].value,
                aimak:aimak,
                district:district,
                soum:data.fields["soum"].value,
                address:data.fields["address"].value,
                phone:data.fields["phone"].value,
                postal_address:data.fields["postal_box"].value,
                email:data.fields["email"].value,
                social:data.fields["social"].value,
                zipcode:data.fields["zipcode"].value,
                postal_code:data.fields["postal_code"].value,
                sector:sector,
                is_exported:isExported,
                is_exported_countries:data.fields["exported_countries"].value,
            }
            console.log(dto);
            signupNewBusiness(dispatch, dto).then (response => {
                         toast.success("Амжилттай илгээлээ!", { delay: 8000 });
                         history.push("/company/register/completed")
                     }
            ).catch(error=> {
                     alert(error)
            });
        }
        console.log(data.fields)
    }

    const districtOptions = () => {
        let items = [];

        if (aimak) {
            let ra = aimaksData.filter(a=>a.id === aimak);
            if (ra.length>0) {
                ra[0].units.map(item=>{
                    items.push(<option key={item.id} value={item.id}>{item.name}</option>);
                })

            }
        }
        return items;
    };
    const aimaksOptions = () => {
        let items = [];
        aimaksData.map(item=>{
            items.push(<option key={item.id} value={item.id}>{item.name}</option>);
        })

        return items;
    };
    const sectorsOptions = () => {
        let items = [];
        for (let key in sectors) {
            let s = sectors[key]
            items.push(<option key={s.id} value={s.id}>{s.name}</option>);
        }
        return items;
    };

    const handleChangeDistrict = (e) => {
        setDistrict(e.target.value)
    };
    const handleChangeAimak = (e) => {
        setAimak(e.target.value)
    };
    const handleChangeSector = (e) => {
        setSector(Number(e.target.value))
    };

    const validate =(e) => {
        let ret = false;
        let errors = {
            aimak:'',
            district:'',
        };
        if (!aimak) {
            errors.aimak = 'Аймаг/Хот заавал оруулна.';
            ret = true;
        }
        if (!district) {
            errors.district = 'Дүүрэг заавал оруулна.';
            ret = true;
        }
        setErrors(errors);
        return ret;
    }

    return (
        <div className="container pb-20 bg-white py-10">
            <div className="block md:hidden flex  flex-row w-12/12 bg-orange-200 rounded">
                <div
                    className="column-2 w-full h-full mx-2 my-2 rounded-md">
                    <h2 className="mt-8 mb-4 ml-4 font-bold text-xl" style={{color: "#002c6c"}}>Бүртгүүлэх зөвлөмж</h2>
                    <ul className="ml-8 list-disc  text-sm">
                        <li>Монголоор бичнэ. /галигаар бичихгүй/</li>
                        <li>Одтой хэсгийг заавал бөглөнө.</li>
                        <li>Бүрэн оруулсаны дараа Илгээх товчлуур дарна уу.</li>
                        <li>Албан бичгийн загвар татах боломжтой.</li>
                    </ul>
                    <h2 className="mt-8 mb-4 ml-4 font-bold text-xl" style={{color: "#002c6c"}}>
                        Албан бичгийн загвар</h2>
                    <ul className="ml-8 list-disc text-sm">
                        <li><Link to="/files/alban-bichig.docx" target="_blank" download>Энд дарж татаж авна
                            уу!</Link>
                        </li>
                        <li>Албан бичиг, компанийн гэрчилгээний хуулбар, иргэний үнэмлэхний хуулбар (хувь хүн бол)
                            болон
                            төлбөрийн баримтыг <a href="mailto: info@gs1mn.org"> info@gs1mn.org</a> хаягаар илгээнэ
                            үү.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="flex flex-row">
                <div className="column-1 md:w-9/12 w-12/12 h-screen bg-custom-blue overflow-auto justify-center">
                    <h1 className="p-2">Гишүүний анкет (ХУВЬ ХҮН) оруулах</h1>
            <Form>
                <TextInput
                    id="name"
                    label="Овог нэр, монголоор  *"
                    placeholder="Овог нэр монголоор оруулах"
                    validate="required"
                    displayName="Овог нэр монголоор"
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <TextInput
                    id="name_english"
                    label="Овог нэр, англиар * "
                    placeholder="Овог нэр, англиар оруулах"
                    validate="required"
                    displayName="Овог нэр англиар"
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                    }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <TextInput
                    id="registration_number"
                    label="Регистрийн дугаар *"
                    placeholder="Регистрийн дугаар оруулах"
                    validate="required"
                    displayName="Регистрийн дугаар"
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                    }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <div className="w-full p-3">
                    <label className="font-bold my-2 mr-2">Аймаг/Хот *</label>
                    <div className="mb-2">
                        <div className="border ">
                            <select value={aimak}
                                    className="block w-full px-4 py-3 text-base text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    onChange={(e) => handleChangeAimak(e)}>
                                <option value=""></option>
                                {aimaksOptions()}
                            </select>
                        </div>
                    </div>
                    {errors.aimak != '' && <span className="text-red-500">{errors.aimak}</span>}
                </div>
                <div className="w-full p-3">
                    <label className="font-bold my-2 mr-2">Сум/Дүүрэг *</label>
                    <div className="mb-2">
                        <div className="border">
                            <select value={district}
                                    className="block w-full px-4 py-3 text-base text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    onChange={(e) => handleChangeDistrict(e)}>
                                <option value=""></option>
                                {districtOptions()}
                            </select>
                        </div>
                    </div>
                    {errors.district != '' && <span className="text-red-500">{errors.district}</span>}
                </div>

                <TextInput
                    id="soum"
                    label="Баг/Хороо *"
                    placeholder="Баг/Хороо * оруулах"
                    validate="required"
                    displayName="Баг/Хороо *"
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                    }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <TextInput
                    id="address"
                    label="Гудамж/байрны тоот *"
                    placeholder="Гудамж/байрны тоот оруулах"
                    validate="required"
                    displayName="Гудамж/байрны тоот "
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                    }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <TextInput
                    id="phone"
                    label="Утас *"
                    placeholder="Утас оруулах"
                    validate="required|numeric|maxLength-8|minLength-8"
                    displayName="Утас"
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                    }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <TextInput
                    id="postal_box"
                    label="Шуудангийн хайрцаг"
                    placeholder="Шуудангийн хайрцаг оруулах"
                    displayName="Шуудангийн хайрцаг"
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                    }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <TextInput
                    id="email"
                    label="И-мэйл *"
                    placeholder="И-мэйл оруулах"
                    validate="required|email"
                    displayName="И-мэйл"
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                    }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <TextInput
                    id="social"
                    label="Сошиал хаяг: Fb, Wechat гм *"
                    placeholder="Сошиал хаяг: Fb, Wechat гм оруулах"
                    validate="required"
                    displayName="Сошиал хаяг"
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                    }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <TextInput
                    id="zipcode"
                    label="Зип код"
                    placeholder="Зип код оруулах"
                    displayName="Зип код"
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                    }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <TextInput
                    id="postal_code"
                    label="Шуудангийн код"
                    placeholder="Шуудангийн код оруулах"
                    displayName="Шуудангийн код"
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                    }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <div className="w-full p-3">
                    <label className="font-bold my-2 mr-2">Үйл ажиллагааны чиглэл *</label>
                    <div className="mb-2">
                        <div className="border">
                            <select value={sector}
                                    className="block w-full px-4 py-3 text-base text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    onChange={(e) => handleChangeSector(e)}>
                                <option value=""></option>
                                {sectorsOptions()}
                            </select>
                        </div>
                    </div>
                    {errors.sector != '' && <span className="text-red-500">{errors.sector}</span>}
                </div>

                <div className="w-full p-3">
                    <label className="font-bold my-2 mr-2">Экспорт хийдэг эсэх</label>
                    <div className="mb-2">
                        <input type="checkbox" className="p-3  mt-2" checked={isExported}
                               onChange={(e) => handleChangeIsExported(e)}/>
                    </div>
                </div>
                <TextInput
                    id="exported_countries"
                    label="Тийм бол экспорт хийж буй улсын нэр/ Үгүй бол хийхээр төлөвлөж байгаа улс"
                    placeholder="Тийм бол экспорт хийж буй улсын нэр/ Үгүй бол хийхээр төлөвлөж байгаа улс оруулах"
                    displayName="Экспорт хийж буй улсын нэр/ хийхээр төлөвлөж байгаа улс"
                    classes={{
                        labelClass:"font-bold my-2 mr-2",
                        fieldContClass:"mb-2",
                        fieldClass:" border p-3 w-full mt-2",
                        contClass:"w-full p-3",
                        errorClass:"text-red-500"
                    }}
                    events={{
                        onChange: data => console.log(data),
                        onFocus: val => console.log("focused!"),
                        onBlur: value => console.log("blurred!")
                    }}
                />
                <SubmitButton id="submit"
                              classes={{
                                  contClass: "w-full p-3",
                                  fieldClass: " border p-3 w-full mt-2 bg-orange-500 text-white font-bold",
                              }}
                              events={{
                                  onClick: handleClick//data => console.log(data)
                              }}
                >hgf</SubmitButton>

            </Form>
                </div>
                <div className="hidden md:block flex flex-col flex-row w-3/12">
                    <div
                        className="column-2 w-full h-full mx-2 my-2 rounded-md">
                        <h2 className="mt-8 mb-4 ml-4 font-bold text-xl" style={{color: "#002c6c"}}>Бүртгүүлэх зөвлөмж</h2>
                        <ul className="ml-8 list-disc  text-sm">
                            <li>Монголоор бичнэ. /галигаар бичихгүй/</li>
                            <li>Одтой хэсгийг заавал бөглөнө.</li>
                            <li>Бүрэн оруулсаны дараа Илгээх товчлуур дарна уу.</li>
                            <li>Албан бичгийн загвар татах боломжтой.</li>
                        </ul>
                        <h2 className="mt-8 mb-4 ml-4 font-bold text-xl" style={{color: "#002c6c"}}>
                            Албан бичгийн загвар</h2>
                        <ul className="ml-8 list-disc text-sm">
                            <li><Link to="/files/alban-bichig.docx" target="_blank" download>Энд дарж татаж авна
                                уу!</Link>
                            </li>
                            <li>Албан бичиг, компанийн гэрчилгээний хуулбар, иргэний үнэмлэхний хуулбар (хувь хүн бол)
                                болон
                                төлбөрийн баримтыг <a href="mailto: info@gs1mn.org"> info@gs1mn.org</a> хаягаар илгээнэ
                                үү.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default SignupIndividual