import React, { useContext, useEffect } from "react";
import { FormCtx } from "./Form";

const SubmitButton = props => {
    const {
        displayName,
            events,
            classes,
            loadingClass,
            loadingText,
            shouldUseDefaultClasses
    } = props


    const { fields, validateForm, errors } = useContext(
        FormCtx
    );
    const { onClick, ...restEvents } = events;
    const { contClass, fieldClass } = classes;

    const handleClick = (event) => {
        event.preventDefault()
        validateForm ();
        console.log("fdf")
        if (errors && Object.values(errors).join('').length === 0) {
            console.log("+" + fields);
            onClick({
                fields,
            });
        }
    };

    // useEffect(() => {
    //     if (value !== undefined) {
    //         validateField(id);
    //     }
    // }, [value, id]);

    // useEffect(() => {
    //     addField({
    //         field: props,
    //         value
    //     });
    // }, []);

    // const fieldProps = {
    //     ...restEvents,
    //     id,
    //     name,
    //     type,
    //     value,
    //     validate,
    //     placeholder,
    //     className: fieldClass,
    //     onClick: handleClick
    // };


    return (
        <div className={contClass}>
                <input
                    {...restEvents}
                    type="submit" onClick={(event) => {
                    event.preventDefault();
                    validateForm ();
                    if (errors && Object.values(errors).join('').length === 0) {
                        console.log("+" + fields);
                        onClick({
                            fields,
                        });
                    }
                    }
                }
                    className={fieldClass} value="Илгээх"/>
        </div>
    );
};

export default SubmitButton;