/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";
import {GLN, GTIN, SSCC,} from "./Constants"
import ProductEditComponentSender from "./ProductEditComponentSender";
import ProductEditComponentSscc from "./ProductEditComponentSscc";
import ProductEditComponentGln from "./ProductEditComponentGln";
import ProductEditComponentGtin from "./ProductEditComponentGtin";
import {checkGtin} from "./utils/productUtils";
import glnData from "./data/glns.json";

// const checkGtin = (sNumber) => {
//     if (!sNumber) {
//         return false;
//     }
//     let output = []
//     // separate numbers
//     for (let i = 0, len = sNumber.length; i < len; i += 1) {
//         output.push(+sNumber.charAt(i));
//     }
//
//     // gtin 13
//     if (output.length === 13) {
//         let sum = 0;
//         for (let i = 0; i < output.length - 1; i++) {
//             if (i % 2 === 0) {
//                 sum += output[i] * 1
//             } else {
//                 sum += output[i] * 3
//             }
//         }
//         //
//         let ceil = Math.ceil(sum / 10) * 10;
//         let check = ceil - sum;
//         return check === output[12];
//     } else {
//         return false;
//     }
// }

const ProductEditComponent = ({  product,
                                  onChangeField, onPasteCat, onSave, onCancel,
                                  packaging_units, onChangePackagingUnit,
                                  countriesData, selectedCountries, onCountryAdd, onCountryDelete,
                                  gcps, onChangeGcp, gcpDisabled,
                                  onClickMainInput,onClickSubInput,onClickUnitInput,
                                  showMainOptions, showSubOptions, showUnitOptions,
                                  mainOptions, subOptions, unitOptions,
                                  onChangeMainOption, onChangeSubOption, onChangeUnitOption
                              }) => {
    //const glns = useSelector(state => state.glns);

    if (product.barcode_type === null) {
        product.barcode_type = "GTIN";
    }

    const barcodeTypeOptions = () => {
        let items = [];
        glnData.barcodeTypes.map(item=>{
            items.push(<option key={item.id} value={item.id}>{item.name}</option>);
        })
        return items;
        // let items = [];
        // for (let [key, value] of glns.barcodeTypes) {
        //     items.push(<option key={key} value={key}>{value}</option>);
        // }
        // return items;
    };

    return (
        <div>

            <div className="field">
                <label className="label">Зураасан кодны төрөл *</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select name="barcode_type" value={product.barcode_type}
                                onChange={onChangeField}>
                            <option value=""></option>
                            {barcodeTypeOptions()}
                        </select>
                    </div>
                </div>
            </div>
            {!gcpDisabled &&
                <div className="field">
                    <label className="label">GCP</label>
                    <div className="select">
                        <select name="gcp_id" value={product.gcp_id} onChange={onChangeGcp}>
                            <option value="0"></option>
                            {gcps.map(gcp => {
                                return (
                                    <option key={gcp.id} value={gcp.id}>{gcp.prefix}</option>
                                )
                            })
                            }
                        </select>
                    </div>
                </div>
            }
            {gcpDisabled &&
                <div className="field">
                <label className="label">GCP</label>
                <div className="control">
                    <input name="gcp_prefix" className="input" type="text" value={product.gcp_prefix}
                           disabled={true}
                           placeholder="GCP"/>
                </div>
            </div>
            }
            <div className="field">
                <label className="label">Нэр</label>
                <div className="control">
                    <input className="input" name="name" type="text" value={product.name}
                           onChange={onChangeField} placeholder="Нэр оруулах"/>
                </div>


            </div>
            <div className="field">
                <label className="label">Зураасан код</label>
                {/*<div className="control">*/}
                {/*    <input className="input" name="barcode" type="text" value={product.barcode}*/}
                {/*           onChange={onChangeField} placeholder="Дугаар оруулах"/>*/}
                {/*</div>*/}

                <div className="relative">
                    <div className={"flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"}>
                        {checkGtin(product.barcode) ? <div className={"block w-2 p-2 bg-green-500"}></div> :
                            <span
                                className={"inline-block align-middle w-6 p-2 h-fit text-gray-200 bg-red-500  "}></span>}
                    </div>
                    <input className="p-1 pl-10 border border-gray-300"  type="text" name="barcode" value={product.barcode}
                           onChange={(e) => onChangeField(e)}
                    />
                </div>
            </div>

            {(product.barcode_type === GTIN) &&
                <>
                <ProductEditComponentGtin packaging_units={packaging_units} onChangePackagingUnit={onChangePackagingUnit}
                                              product={product} onChangeField={onChangeField} disabled={false}
                                          countriesData={countriesData} selectedCountries={selectedCountries} onCountryDelete={onCountryDelete} onCountryAdd={onCountryAdd}/>
                <div className="field">
                    <label className="label">ҮНДСЭН АНГИЛАЛ</label>
                    {!showMainOptions &&
                    <div className="control">
                        <div className="border border-gray-300 p-2" onClick={onClickMainInput}>{product.main_name===null?' - ':product.main_name}  { product.main_code===null?'':' ['+ product.main_code +']'}</div>
                    </div>
                    }
                    {showMainOptions &&
                    <div className="control">
                        <div className="select">
                            <select name="main" value={product.main} onChange={onChangeMainOption}>
                                <option value="0"></option>
                                {mainOptions.map(opt => {
                                    return (
                                        <option key={opt.id} value={opt.id}>{opt.name}-[{opt.code}]</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                    }

                </div>
                <div className="field">
                    <label className="label">ДЭД АНГИЛАЛ</label>
                    {!showSubOptions &&
                    <div className="control">
                        <div className="border border-gray-300 p-2" onClick={onClickSubInput}>{product.sub_name===null?' - ':product.sub_name}  { product.sub_code===null?'':' ['+ product.sub_code +']'}</div>
                    </div>
                    }
                    {showSubOptions &&
                    <div className="control">
                        <div className="select">
                            <select name="sub" value={product.sub} onChange={(onChangeSubOption)}>
                                <option value="0"></option>
                                {subOptions.map(opt => {
                                    return (
                                        <option key={opt.id} value={opt.id}>{opt.name}-[{opt.code}]</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                    }
                </div>
                <div className="field">
                    <label className="label">НЭГЖ АНГИЛАЛ</label>
                    {!showUnitOptions &&
                    <div className="control">
                        <div className="border border-gray-300 p-2" onClick={onClickUnitInput}>{product.unit_name===null?' - ':product.unit_name}  { product.unit_code===null?'':' ['+ product.unit_code +']'}</div>
                    </div>
                    }
                    {showUnitOptions &&
                    <div className="control">
                        <div className="select">
                            <select name="unit" value={product.unit} onChange={onChangeUnitOption}>
                                <option value="0"></option>
                                {unitOptions.map(opt => {
                                    return (
                                        <option key={opt.id} value={opt.id}>{opt.name}-[{opt.code}]</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                    }
                </div>
            </>
            }
            {(product.barcode_type === GLN) &&
                <ProductEditComponentGln product={product} onChangeField={onChangeField} disabled={false}/>
            }
            {(product.barcode_type === SSCC) &&
                <ProductEditComponentSscc product={product} onChangeField={onChangeField} disabled={false}/>
            }

            <div className="field">
                <label className="label">GS1-14</label>
                <div className="control has-icons-left">
                    <input className="checkbox" type="checkbox"  name="is_14"  checked={product.is_14}
                           onChange={onChangeField}/>
                </div>
            </div>

            <ProductEditComponentSender product={product} onChangeField={onChangeField} disabled={false}/>

            <div className="buttons is-right" style={{paddingTop: '10px', marginLeft: '10px'}}>
                <button className="button" type="primary" onClick={() => onCancel()}>Буцах</button>
                <button className="button is-success" type="primary"
                        onClick={() => onPasteCat()}>
                    Ангилал Хуулах
                </button>
                <button className="button is-success" type="primary"
                        onClick={() => onSave()}>
                    Хадгалах
                </button>


            </div>
        </div>
    )

}


export default ProductEditComponent

