/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useReducer, useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {connect, useDispatch, useSelector} from "react-redux";
import {
    createInvoiceMailTemplate, fetchClassifs, fetchProductNew,
    resetCatInputTapped, resetErrors, resetProductUpdateStatus, fetchInvoiceMailTemplate, updateInvoiceMailTemplate
} from "./actions/actions";
import {Redirect} from "react-router-dom";
import {toast} from "react-toastify";
import {ActionTypes, GLN, GTIN, SSCC, TOKEN} from "./Constants"
import {
    checkGlnFieldsFilled,
    checkGtinFieldsFilled,
    checkSsccFieldsFilled,
    createGlnDto,
    createGtinDto, createSsccDto
} from "./utils/productUtils";
import history from "./history";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import InvoiceMailTemplateEditComponent from "./InvoiceMailTemplateEditComponent";


// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

const initialState = {
    mail:"",
    name:"",
    subject:"",
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_INVOICE_MAIL_TEMPLATE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.CREATE_INVOICE_MAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                mail:action.mail,
                id: action.id,
                subject: action.subject,
                name: action.name,
            }
        case 'CHANGE_MAIL':
            return {
                ...state,
                mail: action.mail
            }
        case 'CHANGE_NAME':
            return {
                ...state,
                name: action.name
            }
        case 'CHANGE_SUBJECT':
            return {
                ...state,
                subject: action.subject
            }
        default:
            return state;
    }
};



const changeMail = (mail) => {
    return {
        type: 'CHANGE_MAIL',
        mail
    }
}


const InvoiceMailTemplateCreate = () => {
//    const [value, setValue] = useState('');
    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {mail, name, subject, loading} = state;

    // let modules = {
    //     toolbar: [
    //         [{ 'header': [1, 2, false] }],
    //         ['bold', 'italic', 'underline','strike', 'blockquote'],
    //         [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    //         ['link', 'image'],
    //         ['clean']
    //     ],
    // }
    //
    // let    formats = [
    //         'header',
    //         'bold', 'italic', 'underline', 'strike', 'blockquote',
    //         'list', 'bullet', 'indent',
    //         'link', 'image'
    //     ]
    //

    const handleChangeMail = (e) => {
        dispatch({ type: 'CHANGE_MAIL', mail: e });
        //dispatch(changeMail( e.target.value))
    };
    const handleChangeName = (e) => {
        dispatch({ type: 'CHANGE_NAME', name: e.target.value });
        //dispatch(changeMail( e.target.value))
    };
    const handleChangeSubject = (e) => {
        dispatch({ type: 'CHANGE_SUBJECT', subject: e.target.value });
        //dispatch(changeMail( e.target.value))
    };

    const handleSave = (
    ) => {

        let dto = {}
        dto.mail = mail; //value;
        dto.name = name; //value;
        dto.subject = subject; //value;

        createInvoiceMailTemplate(dispatch, token, dto).then(response => {
            toast.success("Амжилттай хадгаллаа!");

                //history.push(`/product/${productId}`)
            }
        ).catch(error => {
            alert(error)
        });

    };

    // const handleCancel = () => {
    //     console.log('cancel');
    //     dispatch(resetErrors())
    //     history.goBack();
    // };

    // <div className="inner-container">
    //
    //     <div className="level">
    //     <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
    //         <h1 className="title is-size-4  has-text-info">Invoice Mail Template</h1>
    //     </div>
    // </div>
    // <hr/>
    //  <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
    //     < className="field">
    //         <label className="label">Name</label>
    //
    //         <input name="name"  className="border-gray-500 border p-3" type="text" value={name}
    //                   onChange={handleChangeName} placeholder="Enter template name"/>
    //
    //     </div>
    //     <div className="field">
    //         <label className="label">Subject</label>
    //
    //             <input className="border-gray-500 p-3 border w-full" name="subject"  type="text" value={subject}
    //                    onChange={handleChangeSubject} placeholder="Enter the subject"/>
    //
    //     </div>
    //     <ReactQuill theme="snow" value={mail} modules={modules}
    //                 formats={formats} onChange={handleChangeMail}/>
    //     <button className="bg-green-500 text-white p-3 mt-3" type="primary"
    //             onClick={() => handleSave()}>
    //         Хадгалах
    //     </button>

    return (
        <>
        <InvoiceMailTemplateEditComponent mail={mail} subject={subject} name={name} loading={loading}
        onSave={handleSave} onChangeName={handleChangeName}
                                          onChangeMail={handleChangeMail} onChangeSubject={handleChangeSubject}/>
    </>

    )

}


export default InvoiceMailTemplateCreate