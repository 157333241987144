/**
 * Created by gantushig on 11/6/15.
 */

import {Link, useParams} from "react-router-dom";
import React, {useEffect, useReducer} from "react";
import {fetchProductNew, verifyProduct} from "./actions/actions";
import history from "./history";
import {ActionTypes, GTIN, TOKEN} from "./Constants";
import {css} from "@emotion/react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import dayjs from "dayjs";
import {toast} from "react-toastify";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const initialState = {
    product: {barcode_type: GTIN, barcode: "", id: null},
    prevProductId: null,
    nextProductId: null,
    currProductId: null,
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_PRODUCT_REQUEST:
        case ActionTypes.DELETE_PRODUCT_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case ActionTypes.GET_PRODUCT_SUCCESS: {
            // if (action.product.exp_countries_office){
            //     let str = action.product.exp_countries_office; //'MN,RU,FR,';
            //     //remove the last comma
            //     str = str.replace(/,\s*$/, "");
            //
            //     let test = str.split(','); //['MN','RU','FR'];
            //     let ret = "";
            //     test.map(el => {
            //         const c = countriesData.find(e => e.id === el)
            //         if (c) {
            //             ret += c.name;
            //             ret += "/" + c.id;
            //             ret += ", ";
            //         }
            //     })
            //     if (ret !== "") {
            //         ret = ret.replace(/,\s*$/, "");
            //         action.product.exp = ret;
            //     }
            // }

            return {
                ...state,
                product: action.product,
                loading: false
            }
        }

        case ActionTypes.VERIFY_PRODUCT_SUCCESS: {
            let p = {...state.product};
            p.hq_verified = new Date()
            return {
                ...state,
                product: p, //action.product,
                loading: false
            }
        }
        case "REMOVE_IMAGE": {

            let p = {...state.product}
            p.nullify_image_link = true;
            p.image_link = null;
            return {
                ...state,
                product: p
            }
        }
        default:
            return state;
    }
};

const getExpCountriesLabel = (p) => {
    let ret = "";
    if (p.exp_countries) {

        p.exp_countries.map(el => {
            ret += el.name;
            ret += "/" + el.id;
            ret += ", ";
        })
        if (ret !== "") {
            ret = ret.replace(/,\s*$/, "");
        }
    }
    return ret;
}


const removeImage = () => {
    return {
        type: 'REMOVE_IMAGE'
    }
}

const HqProductVerify = () => {
    let {productId} = useParams()

    const [state, dispatch] = useReducer(reducer, initialState);
    let {product, loading} = state;


    let token = localStorage.getItem(TOKEN) || null;


    useEffect(() => {
        fetchProductNew(dispatch, false, productId);
    }, [productId]);

    if (product === null) {
        return (
            <div>
                No Product
            </div>
        )
    }

    const handleCheckImage = () => {

        //fetchNextProduct(dispatch, false, currProductId);
    }
    const handleRemoveImage = () => {
        dispatch(removeImage())
    }

    const handleVerify = () => {

        let dto = {}
        if (product.nullify_image_link) {
            dto.nullify_image_link = true;
        } else {
            dto.nullify_image_link = false;
        }

        verifyProduct(dispatch, productId, dto).then(response => {
            toast.success("Success"); //history.push(`/products`)
        })
    }
    const handleChange = () => {
        alert("change")
    }

    return (
        <>
            <div className="buttons has-addons is-right">
            </div>

            <table className="table table-bordered is-bordered is-fullwidth">
                <tbody>
                <tr>
                    <th>Licence</th>
                    <td>{product.gcp_prefix}
                    </td>
                </tr>
                <tr>
                    <th>GTIN</th>
                    <td>{product.barcode}</td>
                </tr>
                <tr>
                    <th>Brand name</th>
                    <td>{product.brand_name}</td>
                </tr>
                <tr>
                    <th>Нэр</th>
                    <td>{product.name}</td>
                </tr>
                <tr>
                    <th>Савлалт</th>
                    <td>{product.packaging} {product.packaging_unit}</td>
                </tr>
                <tr>
                    <th>Бүтээгдэхүүний зураг байршсан линк</th>
                    <td>

                        {product.image_link &&
                            <div>
                                <span className="p-2">{product.image_link}</span>

                            <button onClick={handleRemoveImage}
                                    className="w-32 bg-red-300  p-1 border border-gray-500">Устгах
                            </button>
                            </div>
                        }
                    </td>
                </tr>
                <tr>
                    <th>Тухайн бараа бүтээгдэхүүн борлуулж байгаа улс /office/</th>
                    <td>{getExpCountriesLabel(product)}</td>
                </tr>

                <tr>
                    <th>НЭГЖ АНГИЛАЛ</th>
                    <td>{product && product.unit_name} - [{product && product.unit_code}]</td>
                </tr>
                <tr>
                    <td colSpan={2}>Created: {dayjs(product.created).format("MM/DD/YYYY HH:mm")} - Modified: {dayjs(product.modified).format("MM/DD/YYYY HH:mm")}]</td>
                </tr>

                </tbody>
            </table>

            {product.hq_verified &&
                <div className="border-2 border-green-300 p-2 w-64 m-2">
                    Verified: {dayjs(product.hq_verified).format("MM/DD/YYYY HH:mm")}
                </div>
            }
            <div className="control buttons">

                <button onClick={() => handleVerify()} className="button bg-green-500">
                    <span style={{color: "white"}}>Verify</span>

                </button>

                <Link to={`/product/${productId}/edit`} className="button bg-gray-500">
                    <span className="text-white">Өөрчлөх</span>
                </Link>

            </div>

        </>
    )

}


export default HqProductVerify;

