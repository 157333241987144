import {confirmAlert} from "react-confirm-alert";

function checkGtinFieldsFilled  (p) {
    return !!(p.name
        && p.packaging && p.constituent && p.characteristics
        && p.handling && p.instruction && p.storage
    );
}

//name,
//    barcode,
  //  packaging,
    //constituent,
    //characteristics,
    //handling,
    //instruction,
    //storage,
//    main:Number(main),
//    sub:Number(sub),
//    unit:Number(unit),
    // sender_name,
    // sender_position,
    // sender_phone,
    // sender_email

const removeLeadingZeros = (input)=> {
    let result = input;
    while (result[0] === '0') {
        result = result.substring(1);
    }
    return result;
}


function createProduct8Dto  (p) {
    return {
        company_id:p.company_id,
        barcode: p.barcode,
        name: p.name,
        packaging: p.packaging,
        constituent: p.constituent,
        characteristics: p.characteristics,
        handling: p.handling,
        instruction: p.instruction,
        storage: p.storage
    };
}


function createGtinDto  (p) {
    return {
        barcode_type: p.barcode_type,
        barcode: p.barcode,
        name: p.name,
        brand_name: p.brand_name,
        packaging: p.packaging,
        packaging_unit: p.packaging_unit,
        constituent: p.constituent,
        characteristics: p.characteristics,
        handling: p.handling,
        instruction: p.instruction,
        storage: p.storage,
        coo:p.coo,
        main:Number(p.main),
        sub:Number(p.sub),
        unit:Number(p.unit),
        image_link:p.image_link,
        is_14:p.is_14,
        exp_countries_member:p.exp_countries_member,
        sender_name: p.sender_name,
        sender_position: p.sender_position,
        sender_phone: p.sender_phone,
        sender_email: p.sender_email
    };
}

function checkGlnFieldsFilled  (p) {
    const ret =
        (p.location && p.location_type
            && p.state && p.aimak && p.soum
            && p.email && p.web && p.social && p.zip_code
            ) ? true:false;

    return ret;
}

function createGlnDto  (p) {
    return {
        barcode_type: p.barcode_type,
        barcode: p.barcode,
        location: p.location,
        location_type: p.location_type,
        state: p.state,
        aimak: p.aimak,
        soum: p.soum,
        phone: p.phone,
        email: p.email,
        web: p.web,
        social: p.social,
        zip_code: p.zip_code,
        sender_name: p.sender_name,
        sender_position: p.sender_position,
        sender_phone: p.sender_phone,
        sender_email: p.sender_email
    };
}


function checkSsccFieldsFilled  (p) {
    let ret;
    ret = !!(p.name
        && p.unit_weight && p.exporting_country && p.package_quantity && p.pallet_quantity
        );

    return ret;
}


function createSsccDto  (p) {
    return  {
        barcode_type: p.barcode_type,
        barcode: p.barcode,
        name: p.name,
        unit_weight: p.unit_weight,
        exporting_country: p.exporting_country,
        package_quantity: p.package_quantity,
        pallet_quantity: p.pallet_quantity,
        sender_name: p.sender_name,
        sender_position: p.sender_position,
        sender_phone: p.sender_phone,
        sender_email: p.sender_email
    };

    //return dto;
}

const checkGtin = (sNumber) => {
    if (!sNumber) {
        return false;
    }
    let output = []
    // separate numbers
    for (let i = 0, len = sNumber.length; i < len; i += 1) {
        output.push(+sNumber.charAt(i));
    }

    // gtin 13
    if (output.length === 13) {
        let sum = 0;
        for (let i = 0; i < output.length - 1; i++) {
            if (i % 2 === 0) {
                sum += output[i] * 1
            } else {
                sum += output[i] * 3
            }
        }
        //
        let ceil = Math.ceil(sum / 10) * 10;
        let check = ceil - sum;
        return check === output[12];
    } else {
        return false;
    }
}

const validateProduct = (product) => {
    let validated = false;
    if (product.gcp_id === null) {
        confirmAlert({
            title: 'Alert',
            message: 'Gcp заавал сонгоно.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        return;
                    }
                },
            ]
        });
    }

    if (product.gcp_id) {
        if (product.barcode.startsWith(product.g_prefix) ) {
            validated = true;
        } else {
            if (product.is_14) {
                // do not validate gcp prefix if is_14
                validated = true;
            } else {
                confirmAlert({
                    title: 'Alert',
                    message: 'Gcp зөрж байна.',
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () => {
                                return;
                            }
                        },
                    ]
                });
            }
        }
    }
    if (validated) {
        // check the unit classification and its code
        let unitValidated = false;
        if (product.is_14) {
            // do not validate unit if is_14
            unitValidated = true;
        } else {
            if (product.unit) {
                if (product.unit_code) {
                    if (Number(product.unit_code)) {
                        unitValidated = true;
                    }
                }
            }
        }
        if (!unitValidated) {
            validated = false;
            confirmAlert({
                title: 'Alert',
                message: 'Нэгж ангилал заавал оруулах.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            return;
                        }
                    },
                ]
            });
        }
    }

    return validated;
}

export {checkGtinFieldsFilled, createGtinDto, checkGlnFieldsFilled, createGlnDto,
    checkSsccFieldsFilled,createSsccDto, createProduct8Dto,
    checkGtin, validateProduct, removeLeadingZeros };