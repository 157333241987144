/**
 * Created by gantushig on 11/6/15.
 */

import {connect} from "react-redux";
import {Link} from "react-router-dom";
import classnames from "classnames";
import React, {useEffect, useState} from "react";
import packageJson from '../package.json';

const Profile = () => {
    return (
        <div className="inner-container">
            Profile<br/>


            <br/><br/>
            Version {packageJson.version}
        </div>
    )

}




export default  Profile

