/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import React, {Component, useEffect, useReducer, useState} from "react";
import {
    deleteHQGcps,
    deleteHQProducts,
    fetchAllProducts,
    fetchClassifs, menuClicked,
    printAllProducts, updateProductMulti,
} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, Menu, Page, PageTitle, TOKEN} from "./Constants";
import history from "./history";
import {isNumber} from "bulma-extensions/bulma-carousel/src/js/utils/type";
import {confirm} from "react-confirm-box";
import {toast} from "react-toastify";

dayjs.extend(dayjsPluginUTC)



const initialState = {
    gcps: [],
    selectionsArr: [],
    batch:null
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.DELETE_HQ_GCPS_REQUEST:{
            return {
                ...state,
                loading:true,
                batch: null
            }
        }
        case ActionTypes.DELETE_HQ_GCPS_SUCCESS:{
            return {
                ...state,
                selectionsArr: [],
                gtins:[],
                loading:false,
                batch: action.batch
            }
        }
        case "ADD_ITEMS": {
            let g = [...state.gcps]
            let c = g.concat(action.items);
            var d = c.filter((item, pos) => c.indexOf(item) === pos)

            return {
                ...state,
                gcps: d,
                selectionsArr: []
            }
        }
        case "REMOVE_ITEMS": {
            let g = [...state.gcps]

            let toDel = [...state.selectionsArr]
            let toDelSet = new Set(toDel);
            const d = g.filter((obj) => {
                // return those elements not in the namesToDeleteSet
                return !toDelSet.has(obj);
            });

            return {
                ...state,
                gcps: d
            }
        }
        case "SELECT_HEADER": {

            let n3 = []
            if (action.checked) {
                //if (state.selectionsArr.length === 0) {
                    state.gcps.map(p => {
                        n3.push(p)
                    })
                //}
            }
            return {
                ...state,
                selectionsArr: n3
            }
        }
        case "SELECT_ITEM": {

            let g = [...state.selectionsArr];
            let ind = g.indexOf(action.obj.licence)
            if ( ind >= 0 ) {
                g.splice(ind, 1);
            } else {
                g.push(action.obj.licence)
            }

            return {
                ...state,
                selectionsArr: g
            }
        }
        case 'CHANGE_STATUS': {
            let arr = [...state.gcps];
            let c = arr[action.index];
            c.status = action.status;

            return {
                ...state,
                gcps: arr,
                loading: false
            }
        }
        default:
            return state;
    }
};



const addItems =(items) => {
    return {
        type: 'ADD_ITEMS',
        items
    }
}

const removeItems =() => {
    return {
        type: 'REMOVE_ITEMS'
    }
}

const selectItem =(obj) => {
    return {
        type: 'SELECT_ITEM',
        obj
    }
}

const selectHeader =(checked) => {
    return {
        type: 'SELECT_HEADER',
        checked
    }
}

const changeStatus =( status, index) => {
    return {
        type: 'CHANGE_STATUS',
        status, index
    }
}


const HqGcpDeletePage = () => {
    const [gcpsText, setGcpsText] = useState(null);

    const [state, dispatch] = useReducer(reducer, initialState);
    const {gcps, selectionsArr, batch} = state;

    useEffect(() => {
        console.log('useEffect called');
    }, []);

    const isItemSelected = obj => selectionsArr.includes(obj.licence);



    // let counter = 0;
    // for (let key in gtins) {
    //     let item = gtins[key];
    //     counter++;
    //     item.counter = counter;
    // }

    const handleChangeGcps = (e) => {
        setGcpsText(e.target.value);
    };


    const handleAdd = (e) => {
        e.preventDefault();
        let n = gcpsText.split("\n");
        let n2 = []
        for (let k of n ) {
            let a = k.trim()
            if ( a !== "") {
                n2.push(a)
            }
        }
        dispatch(addItems(n2))
        setGcpsText("")
    };

    const handleRemove = (e) => {
        e.preventDefault();
        dispatch(removeItems())
    };

    const handleSelect = (e, obj) => {
        dispatch(selectItem(obj))
    }

    const handleSelectHeader = (e) => {

        dispatch(selectHeader(e.target.checked))
    }

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    const optionsWithLabelChange = {
        closeOnOverlayClick: false,
        labels: {
            confirmable: "Устгах",
            cancellable: "Болих"
        }
    };
    const handleHQDeleteGcps = async () => {
        for (let a of gcps ) {
            //a = a.trim()
            //if (!isNumeric(a.licence)) {
            if (!isNumeric(a)) {
                alert("check");
                return;
            }
        }

        let dto = {}
        dto.to_delete_list = gcps;
        const result = await confirm("Gcp устгахаар илгээх үү?", optionsWithLabelChange);
        if (result) {
            deleteHQGcps(
                 dispatch,
                 dto).then(response => {
                toast.success("Success"); //history.push(`/products`)
            });

            console.log("You click yes delete Gcp!");
        } else {
            console.log("You click No!");
        }
    }



    const appts2 = gcps.map((p,index) => {
        return (
            <tr key={index}>

                <td>
                    <input className="checkbox" type="checkbox" checked={isItemSelected(p)}
                           onChange={(e) => handleSelect(e,p)}/>
                </td>
                <td>{index+1}</td>
                <td>{p}</td>
            </tr>
        )
    });

    return (

        <div className="inner-container">

            <GcpsSearchPanel
                gcpps={gcps}
                onChangeGcps={handleChangeGcps}
                onClickAdd={handleAdd}
                onClickRemove={handleRemove}
            />

            <br/>
            <div>
                <h5 style={{marginBottom: "10px"}}><strong>Нийт: {gcps.length}</strong>
                </h5>

            </div>
            <div>
                <span>{batch && <div>Batch Id: {batch}</div>}</span>

            </div>
            <br/>
            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th><input className="checkbox" type="checkbox"
                               onChange={(e) => handleSelectHeader(e)}/></th>
                    <th>#</th>
                    <th>Gcp</th>
                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>

            <button onClick={handleHQDeleteGcps} className="button bg-red-500">
                <span style={{color: "white"}}>HQ Delete </span>

            </button>

            <br/><br/>
        </div>
    )

}


const GcpsSearchPanel = ({
                                 gcps,
                                onChangeGcps,
                                 onClickAdd,
                                 onClickRemove
                             }) => {

    return (
        <div>

            <div className="field">
                <label className="label">Gcps</label>
                <div className="field-body ">
                    <div className="control ">
                        <textarea className="input" value={gcps} style={{width: '400px'}}
                               onChange={onChangeGcps} placeholder="Оруулах"/>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label">

                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control buttons">

                            <button onClick={onClickAdd} className="button bg-orange">
                                <span style={{color: "white"}}>Add</span>

                            </button>
                            <button onClick={onClickRemove} className="button bg-red-500">
                                <span style={{color: "white"}}>Remove</span>

                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HqGcpDeletePage

