/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";

const ContactEditComponent = ({contact, onChangeField,  onSave, onCancel}) => {

    return (
        <div style={{background: 'white'}}>
            <div className="field">
                <label className="label">Овог</label>
                <div className="control has-icons-left">
                    <input className="input" name="surname" type="text" value={contact.surname}
                           onChange={(e) => onChangeField(e)} placeholder="Нэр оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Нэр</label>
                <div className="control has-icons-left">
                    <input className="input" name="name" type="text" value={contact.name}
                           onChange={(e) => onChangeField(e)} placeholder="Нэр оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Хүйс</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select value={contact.gender}  name="gender" onChange={(e) => onChangeField(e)}>
                            <option value=""></option>
                            <option value="MALE">Ноён</option>
                            <option value="FEMALE">Хатагтай</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">Албан тушаал</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select value={contact.position_type}  name="position_type" onChange={(e) => onChangeField(e)}>
                            <option value=""></option>
                            <option value="CEO">Захирал</option>
                            <option value="GS1">Хариуцах ажилтан</option>
                            <option value="ACC">Нябо</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="label">Албан тушаал (Бусад)</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  name="position" value={contact.position}
                           onChange={(e) => onChangeField(e)} placeholder="Бусад Албан тушаал оруулах"/>
                </div>
            </div>

            <div className="field">
                <label className="label">Утас</label>
                <div className="control has-icons-left">
                    <input className="input"  name="phone" type="text" value={contact.phone}
                           onChange={(e) => onChangeField(e)} placeholder="Утас оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Утас 2</label>
                <div className="control has-icons-left">
                    <input className="input"  name="phone2" type="text" value={contact.phone2}
                           onChange={(e) => onChangeField(e)} placeholder="Утас оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Мэйл</label>
                <div className="control has-icons-left">
                    <input className="input"  name="email" type="text" value={contact.email}
                           onChange={(e) => onChangeField(e)} placeholder="Мэйл оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Мэйл 2</label>
                <div className="control has-icons-left">
                    <input className="input"  name="email2" type="text" value={contact.email2}
                           onChange={(e) => onChangeField(e)} placeholder="Мэйл оруулах"/>
                </div>
            </div>

            <div class="buttons is-right" style={{paddingTop: '10px'}}>
                <button class="button" type="primary" onClick={() => onCancel()}>Болих</button>
                <button class="button is-success" type="primary"
                        onClick={() => onSave()}>Хадгалах
                </button>
            </div>
        </div>
    )

}


export default ContactEditComponent