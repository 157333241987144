/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";

import {useParams} from "react-router-dom";
import GcpEditComponent from "./GcpEditComponent"
import dayjs from "dayjs"
import history from "./history"
import {css} from "@emotion/react";
import {ActionTypes, TOKEN} from "./Constants";
import {toast} from "react-toastify";
import {fetchGcp, updateGcp} from "./actions/actions";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    gcp: {},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_GCP_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FETCH_GCP_SUCCESS:
            let c = {...action.gcp}
            if ('null' != c.issued) {
                c.issued = new Date(c.issued); //"2019-06-12T10:20:30Z"); //// dayjs("2019-06-12 00:00:00"); c.issued);
            }
            c.barcode_type = c.barcode_type.toString();
            return {
                ...state,
                gcp: c,
                loading: false
            }
        case 'CHANGE_FIELD': {
            let c = {...state.gcp};
            c[action.name] = action.value
            return {
                ...state,
                gcp: c,
                loading: false
            }
        }
        default:
            return state;
    }
};



const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}


const GcpEditOne = ({onUpdate}) => {
    const { gcpId} = useParams();
    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        gcp,
        loading
    } = state;

    useEffect(() => {
        fetchGcp(dispatch, token, gcpId);
    }, [gcpId]);

    const handleSave = () => {
        let issued_str = null;
        if (gcp.issued !== null) {
            gcp.issued = gcp.issued.getTime()
            issued_str = dayjs(gcp.issued).format('YYYY-MM-DD');
        }
        // if (issued !== null ) {
        //     issued = issued.getTime()
        // }
        // let issued_str = dayjs(issued).format('YYYY-MM-DD');
        console.log('send');
        let dto = {
            barcode_type:gcp.barcode_type,
            size:gcp.size,
            prefix:gcp.prefix,
            issued:gcp.issued,
            issued_str,
            status:gcp.status

        };
        updateGcp(dispatch, token, gcpId, dto).then(response => {
                toast.success("Амжилттай өөрчиллөө!");
                //onUpdate();
                //history.push(`/company/${companyId}/gcps`)
            }
        ).catch(error => {
            alert(error)
        });
    };

    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
    };
    const handleChangeCheckField = (e) => {
        dispatch(changeField(e.target.name, e.target.checked))
    }
    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }
    const handleChangeIssued = (date) => {
        dispatch(changeField("issued", date))
    };
    return (
        <>
            <div className="level">
                <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                    <h1 className="title is-size-4  has-text-info">Gcp </h1>
                </div>
            </div>
            <hr/>

            <GcpEditComponent gcp={gcp}
               onChangeField={handleChangeField} onChangeCheckField={handleChangeCheckField} onChangeIssued={handleChangeIssued} onSave={handleSave} onCancel={handleCancel}/>
        </>
    )

}



export default GcpEditOne;