/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useReducer, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import ContactEditComponent from './ContactEditComponent';
import {Redirect, useLocation, useParams, useRouteMatch} from "react-router-dom";
import CompanyViewHeader from "./CompanyViewHeader";
import {ActionTypes, Endpoints, EndpointsExpress, TOKEN} from "./Constants";
import history from "./history";
import {css} from "@emotion/react";
import axios from "./axiosConfig";
import ClipLoader from "react-spinners/ClipLoader";
import {toast} from "react-toastify";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    contact: {},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_CONTACT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FETCH_CONTACT_SUCCESS:
            return {
                ...state,
                contact: action.contact,
                loading: false
            }
        case 'CHANGE_FIELD':
            let c = {...state.contact};
            c[action.name] = action.value
            return {
                ...state,
                contact: c,
                loading: false
            }
        default:
            return state;
    }
};

const fetchContactRequest = () => {
    return {
        type: ActionTypes.FETCH_CONTACT_REQUEST
    }
};

const fetchContactSuccess = (status, contact) => {
    return {
        type: ActionTypes.FETCH_CONTACT_SUCCESS,
        status,
        contact
    }
};

const fetchContact = (dispatch, token, contactId) => {

    dispatch(fetchContactRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.CONTACT}/${contactId}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchContactSuccess(response.data.status, response.data.contact))
        })
        .catch(error => {

            throw(error);

        });
}

const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}



const updateContactRequest = () => {
    return {
        type: ActionTypes.UPDATE_CONTACT_REQUEST
    }
};

const updateContactSuccess = (contact) => {
    return {
        type: ActionTypes.UPDATE_CONTACT_SUCCESS,
        contact
    }
};

const updateContact = (dispatch, token, contactId, info) => {

    return new Promise(function (resolve, reject) {
        dispatch(updateContactRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.CONTACT}/${contactId}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(updateContactSuccess(response.data.contact))
                resolve(response);
            })
            .catch(error => {
                reject(error);
                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
            });
    });
};

//{match: {params: {companyId, contactId}}}
const ContactEdit = ({onUpdate}) => {
    const {companyId, contactId} = useParams();
    let { path, url } = useRouteMatch();
    const {pathname}  = useLocation();

    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        contact,
        loading
    } = state;
    useEffect(() => {
        console.log('useEffect called');
        fetchContact(dispatch, token, contactId);
    }, [contactId]);

    const handleSave = () => {
        console.log('send');
        let dto = {
            name:contact.name,
            surname:contact.surname,
            gender:contact.gender,
            position_type:contact.position_type,
            position:contact.position,
            phone:contact.phone,
            phone2:contact.phone2,
            email:contact.email,
            email2:contact.email2
        };

        updateContact(dispatch, token, contactId, dto).then(response => {
                toast.success("Амжилттай өөрчиллөө!");
                onUpdate()
                history.push(`/company/${contact.company_id}/contacts`)
            }
        ).catch(error => {
            alert(error)
        });

    };
    const handleCancel = () => {
        console.log('cancel');
        history.goBack() //push(`/company/${contact.company_id}/contacts`)
    };

    const handleChangeField =(e)=> {
        dispatch(changeField(e.target.name, e.target.value))
    }
    return (
        <>
            <div className="level">
                <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>

            <ContactEditComponent contact={contact}
                                  onChangeField={handleChangeField}
                                  onSave={handleSave} onCancel={handleCancel}/>

        </>
    )
}


export default ContactEdit;