/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useReducer, useState} from "react";
import {
    failure,
    fetchGcpsAll,
    fetchHqBatch,
    fetchHqBatches,
    fetchHqProducts,
    getVerifiedCount, getVerifiedGcpCount, getVerifiedGcpList, hqSendVerifiedGcps,
    hqSendVerifiedGtins,
    menuClicked,
    menuClickedTitle, verifyProduct
} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, Endpoints, EndpointsExpress, Menu, Page, PageTitle, TOKEN} from "./Constants";
import history from "./history";
import axios from "axios";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import gcpOptionsData from "./data/gcps.json"
import gcpStatusData from "./data/gcp-status.json"
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";

dayjs.extend(dayjsPluginUTC)


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const initialState = {
    list: [],
    count: 0,
    loading: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_VERIFIED_GCP_COUNT_REQUEST:
        case ActionTypes.GET_VERIFIED_GCP_LIST_REQUEST:
            return {
                ...state,
                loading:true
            }
        case ActionTypes.GET_VERIFIED_GCP_COUNT_SUCCESS:
            return {
                ...state,
                count: action.count,
                loading:false
            }
        case ActionTypes.GET_VERIFIED_GCP_LIST_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading:false
            }

        default:
            return state;
    }
};


const HqGcpSend = () => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {count, loading, list   } = state;


    useEffect(() => {
        getVerifiedGcpCount(dispatch);
    }, []);

    useEffect(() => {
        getVerifiedGcpList(dispatch);
    }, []);


    const handleSend = (e) => {
            confirmAlert({
                title: 'Alert',
                message: 'Илгээх?.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            hqSendVerifiedGcps(dispatch).then(response => {
                                toast.success("Success"); //history.push(`/products`)
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    },
                ]
            });

    };
    const statusLabel = (g) => {
        let ret = ""
        let ra = gcpStatusData.filter(b=>b.id === g);
        if (ra.length>0) {
            ret = ra[0].name;
        }
        return ret
    };
    const appts2 = list.map(p => {
        return (
            <tr key={p.prefix}>
                <td>
                    {p.prefix}
                </td>
                <td>{p.licenseeName}</td>
                <td>
                    {statusLabel(p.status)}
                </td>
            </tr>
        )
    });


    return (

        <div className="inner-container">
            Modified: {count}

            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th>GCP</th>
                    <th>Company</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>
            <br/>
            <button onClick={handleSend} className="button bg-orange">
                <span style={{color: "white"}}>Send</span>
            </button>


        </div>
    )

}



export default HqGcpSend

