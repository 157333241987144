/**
 * Created by gantushig on 11/6/15.
 */

import React, {useEffect, useRef} from "react";
import ProductSenderFragment from "./ProductSenderFragment";
import glnData from "./data/glns.json";
import JsBarcode from "jsbarcode";
import {removeLeadingZeros} from "./utils/productUtils";

const ProductGLNView = ({
                         p
                     }) => {

    const containerRef = useRef(null);
    const locationToString = (a) => {
        let ret = ""
        let ra = glnData.locations.filter(b=>b.id === a);
        if (ra.length>0) {
            ret = ra[0].name;
        }

        return ret
    };
    const locationTypeToString = (a) => {
        let ret = ""
        let ra = glnData.locationTypes.filter(b=>b.id === a);
        if (ra.length>0) {
            ret = ra[0].name;
        }

        return ret
    };

    useEffect(() => {
        if (p && p.barcode) {
            let format = null;
            let currBarcode = removeLeadingZeros(p.barcode);
            // if starts with 0 remove all 0
            if (currBarcode.length === 13) {
                format = "EAN13";
            } else if (currBarcode.length === 14) {
                format = "ITF14";
            } else if (currBarcode.length === 8) {
                format = "EAN8";
            }
            if (format) {
                const canvas = document.createElement("canvas");
                canvas.setAttribute("id", "mycanvas")
                let n = {
                    format: format,
                    textAlign: "center",
                    textPosition: "bottom",
                }
                if (containerRef.current.children[0]) {
                    containerRef.current.removeChild(containerRef.current.children[0])
                }
                JsBarcode(canvas, currBarcode, n);
                containerRef.current.appendChild(canvas);
            }
        }
    }, [p]);

    function download(){
        let canvas1 = document.getElementById("mycanvas");
        let url = canvas1.toDataURL("image/png");
        let link = document.createElement('a');
        link.download = p.barcode+'-GLN-'+p.name+'.png';
        link.href = url;
        link.click();
    }
    return (

            <table className="table is-bordered is-fullwidth">
                <tbody>
                <tr>
                    <th className="company-title">Баркод/GTIN</th>
                    <td className="company-value">{p && p.barcode}
                        <div ref={containerRef}>

                        </div>

                        <button onClick={download} className="m-2 p-2 border-2">Download</button>
                    </td>
                </tr>
                <tr>
                    <th>Байршлын нэр</th>
                    <td>{p && p.name}</td>
                </tr>
                <tr>
                    <th>Байршил</th>
                    <td>{p && locationToString(p.location)}</td>
                </tr>
                <tr>
                    <th colSpan={2}>Байршлын хаяг</th>
                </tr>
                <tr>
                    <th>Улс, хот</th>
                    <td>{p && p.state}</td>
                </tr>
                <tr>
                    <th>Дүүрэг/аймаг</th>
                    <td>{p && p.aimak}</td>
                </tr>
                <tr>
                    <th>Сум/хороо</th>
                    <td>{p && p.soum}</td>
                </tr>
                <tr>
                    <th>Утас, факс</th>
                    <td>{p && p.phone}</td>
                </tr>
                <tr>
                    <th>И-мэйл</th>
                    <td>{p && p.email}</td>
                </tr>
                <tr>
                    <th>Вэбсайт</th>
                    <td>{p && p.web}</td>
                </tr>
                <tr>
                    <th>Сошиал хаяг</th>
                    <td>{p && p.social}</td>
                </tr>
                <tr>
                    <th>ЗИП код</th>
                    <td>{p && p.zip_code}</td>
                </tr>
                <tr>
                    <th colSpan={2}></th>

                </tr>
                <tr>
                    <th>Байршлын төрөл</th>
                    <td>{p && locationTypeToString(p.location_type)}</td>
                </tr>

                <ProductSenderFragment p={p}/>

                </tbody>
            </table>
    )

}


export default ProductGLNView

