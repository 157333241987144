/**
 * Created by gantushig on 11/6/15.
 */

import {Link} from "react-router-dom";
import React from "react";

const CompanyViewHeader = ({tab, companyId}) => {

    return (
        <div style={{marginBottom:"20px"}}>

            <div className="tabs is-toggle is-fullwidth is-medium">
                <ul>
                    <li className={tab === 0  ? 'is-active' : ''}>
                        <Link to={`/company/${companyId}/product8s`}>
                            <span className="icon is-small"><i className="fas fa-image" aria-hidden="true"></i></span>
                            <span>Бүтээгдэхүүн</span>
                        </Link>
                    </li>
                    <li className={tab === 1  ? 'is-active' : ''}>
                        <Link to={`/company/${companyId}/product8-files`}>

                            <span className="icon is-small"><i className="fas fa-music" aria-hidden="true"></i></span>
                            <span>Файлууд</span>
                        </Link>
                    </li>
                </ul>
            </div>

        </div>
    )

}


export default CompanyViewHeader;


