import React, {Component} from "react";
import "./tailwind.output.css";
import {compose} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ProgressBar from "./ProgressBar";
import {Helmet} from "react-helmet";
import HeaderTop from "./headerTop";

const MainWrapper = WrappedComponent => (props) => {

    return (


        <div>
            <Helmet
                bodyAttributes={{
                    class: "toolkit",
                }}
            />

            <header>
                <HeaderTop></HeaderTop>
            </header>

            <div>
                <div className="container">
                    <ProgressBar/>
                    <WrappedComponent {...props}/>
                </div>
            </div>



        </div>

    );

}


const mapStateToProps = (state) => {
    let token = localStorage.getItem('id_token') || null;
    return {
        token
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}


const composedCompanySelfWrap = compose(connect(mapStateToProps, mapDispatchToProps), MainWrapper);

export default composedCompanySelfWrap;
