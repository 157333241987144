/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useState} from "react";
import {connect} from "react-redux";
import {loginAccEmail} from "./actions/actions";
import {Redirect} from "react-router-dom";

const LoginEmailOld = ({isAuthenticated, login}) => {
    const [hidden, setHidden] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        console.log('useEffect called');
    }, []);


    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    };

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    };


    const handleSend = () => {
        console.log('send');
        login(email, password)
    };

    const toggleShow = () => {
        setHidden(!hidden);
    }
    if (isAuthenticated) {
        return (
            <Redirect to="/"/>
        )
    } else {

        return (
            <div className="columns">
                <div class="column"></div>
                <div className="column is-narrow">
                    <div className="box" style={{width: '420px', marginTop: '200px'}}>
                        <div className="level">
                            <div className="level-item" style={{paddingTop: '10px'}}>
                                <h1 className="title is-size-5" style={{color: '#002cbc'}}> GS1 Mongolia Members</h1>
                            </div>
                        </div>
                        <hr/>

                        <div className="field">
                            <div className="control">
                                <input className="input" type="text" value={email}
                                       onChange={(e) => handleChangeEmail(e)} placeholder="Username"/>
                            </div>
                        </div>

                        <div className="field has-addons">

                            <div className="control is-expanded">
                                <input className="input"
                                       type={hidden ? "password" : "text"}
                                       value={password}
                                       onChange={(e) => handleChangePassword(e)} placeholder="Password"/>

                            </div>
                            <div className="control">
                                <button className="button" onClick={() => toggleShow()}>

                                    <span className="icon is-small">
                                        {hidden &&
                                        <i className="fas fa-eye-slash"></i>
                                        }
                                        {!hidden &&
                                        <i className="fas fa-eye"></i>
                                        }

    </span></button>
                            </div>
                        </div>

                        <div class="buttons" style={{paddingTop: '30px'}}>
                            <button class="button is-fullwidth is-size-5" type="primary"
                                    style={{backgroundColor: '#002cbc', color: 'white'}} onClick={() => handleSend()}>
                                Login
                            </button>
                        </div>
                    </div>
                </div>
                <div class="column"></div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const isAuthenticated = state.auth.isAuthenticated
    return {
        isAuthenticated
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

        login: (username, password) => {
            dispatch(loginAccEmail(username, password))
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginEmailOld)