const validations = {
    email : {
        rule    : () => /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
        formatter (fieldName) {
            return `${fieldName} нь зөв и-мэйл биш байна`
        }
    },
    required : {
        rule    : () => /\S/,
        formatter (fieldName) {
            return `${fieldName} заавал оруулна.`
        }
    },
    numeric : {
        rule : () => /^\d+$/,
        formatter (fieldName) {
            return `${fieldName} нь зөвхөн тооноос бүрдэх ёстой.`
        }
    },
    alphaNumeric : {
        rule : () => /^[a-z0-9]+$/i,
        formatter (fieldName) {
            return `${fieldName} should not contain special characters, please use only alphabets and numbers.`
        }
    },
    alphabetic : {
        rule : () => /^[a-z]+$/i,
        formatter (fieldName) {
            return `${fieldName} should contain only alphabets.`
        }
    },
    maxLength : {
        rule : (number) => ({
            test : (value) => value.length <= number
        }),
        formatter (fieldName, number) {
            return number
                ? `${fieldName} нь хамгийн ихдээ ${number} үсэгтэй байна.`
                : `${fieldName} хүлээлтээс олон үсэгтэй байна.`
        }
    },
    minLength : {
        rule : (number) => ({
            test : (value) => value.length >= number
        }),
        formatter (fieldName, number) {
            return number
                ? `${fieldName} нь хамгийн багадаа ${number} үсэгтэй байна.`
                : `${fieldName} хүлээлтээс цөөн үсэгтэй байна.`
        }
    }
}

export default validations
