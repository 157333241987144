/**
 * Created by gantushig on 11/6/15.
 */

import {Link, Route, Switch, useLocation, useParams, useRouteMatch} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import classnames from "classnames";
import ContactEditMenu from "./ContactEditMenu"
import {ActionTypes, TOKEN} from "./Constants";
import history from "./history";
import {css} from "@emotion/react";
import {toast} from "react-toastify";
import ContactEdit from "./ContactEdit";
import ContactCreate from "./ContactCreate";
import {fetchContacts, removeContact} from "./actions/actions";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    companyName:"",
    contacts: [],
    loading: false,
    refresh:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_CONTACTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FETCH_CONTACTS_SUCCESS:
            return {
                ...state,
                contacts: action.contacts,
                loading: false
            }
        case ActionTypes.DELETE_CONTACT_REQUEST:{
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.DELETE_CONTACT_SUCCESS:{
            return {
                ...state,
                loading: false
            }
        }
        case 'REFRESH':
            return {
                ...state,
                refresh: !state.refresh
            }
        default:
            return state;
    }
};





const refreshPage = () => {
    return {
        type: "REFRESH",
    }
}

const CompanyContacts2 = () => {
    const {companyId} = useParams();
    let { path, url } = useRouteMatch();
    const {pathname}  = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [currContactId, setCurrContactId] = useState(0);

    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        contacts,
        loading,
        refresh,
    } = state;

    let token = localStorage.getItem(TOKEN) || null;
    useEffect(() => {
        console.log('useEffect called');
        fetchContacts(dispatch, token, companyId);
    }, [companyId, refresh]);

    if (contacts === null || contacts === undefined) {
        return (
            <div>
                No Items
            </div>
        )
    }
    ;
    let modalClass = classnames({
        modal: true,
        'is-active': isOpen
    });

    const handleEdit = (e, lid) => {
        e.preventDefault();
        console.log('edit');
        history.push(`${url}/${lid}`)
        //history.push(`/company/${companyId}/contact/${lid}`)
    };
    const handleDelete = (e, lid) => {
        e.preventDefault();
        setIsOpen(true)
        setCurrContactId(lid)
    }

    const handleYes = () => {
        setIsOpen(false)
        removeContact(dispatch, token, currContactId).then(response => {
                toast.success("Амжилттай !");
                dispatch(refreshPage());
            }
        ).catch(error => {
            alert(error)
        });
    }
    const handleCancel = () => {
        setIsOpen(false)
    }

    const handleUpdate = () => {
        dispatch(refreshPage());
    }
    const genderLabel = (g) => {
        let ret = ""
        if (g === "MALE") {
            ret = "Ноён"
        } else if ( g === "FEMALE") {
            ret = "Хатагтай"
        }

        return ret
    }
    const positionTypeLabel = (p) => {
        let ret = ""
        if (p.position_type === "CEO") {
            ret = "Захирал"
        } else if ( p.position_type === "GS1") {
            ret = "Код хариуцсан ажилтан"
        } else if ( p.position_type === "ACC") {
            ret = "Нябо"
        }

        if (p.position!==null && p.position !== "") {
            ret += " / " + p.position;
        }
        return ret
    }

    let c = 0;
    const items = contacts.map(
        (p) => {
            c++;
            return (
                <tr key={p.id} style={{marginBottom:'10px'}}>
                    <td>{c}</td>
                    <td>{genderLabel(p.gender)} {p.surname} {p.name}</td>
                    <td>{positionTypeLabel(p)}</td>
                    <td>{p.phone}</td>
                    <td>{p.phone2}</td>
                    <td>{p.email}</td>
                    <td>{p.email2}</td>
                    <td>
                        <ContactEditMenu id={p.id} onEdit={handleEdit}  onDelete={handleDelete}/>
                    </td>
                </tr>
            )
        });

    return (
        <>


            <Switch>
                <Route exact path={path}>
                    <div className="buttons is-right">
                        <Link to={`${url}/create`} className="button">
                            Үүсгэх
                        </Link>
                    </div>
                    <table className="table is-fullwidth">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Нэр</th>
                            <th>Албан Тушаал</th>
                            <th>Утас</th>
                            <th>Утас2</th>
                            <th>Мэйл</th>
                            <th>Мэйл2</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {items}
                        </tbody>
                    </table>

                    <div className={modalClass}>
                        <div className="modal-background"></div>
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title">Устгах?</p>
                                <button className="delete" aria-label="close"></button>
                            </header>
                            <section className="modal-card-body">
                                Устгахдаа итгэлтэй байна уу?
                            </section>
                            <footer className="modal-card-foot">
                                <button className="button is-danger" onClick={handleYes}>Тийм</button>
                                <button className="button" onClick={handleCancel}>Үгүй</button>
                            </footer>
                        </div>
                    </div>
                </Route>
                <Route  path={`${path}/create`}>
                    <ContactCreate onUpdate={handleUpdate}/>
                </Route>
                <Route  path={`${path}/:contactId`}>
                    <ContactEdit onUpdate={handleUpdate}/>
                </Route>
            </Switch>
        </>
    )

}


export default CompanyContacts2;


