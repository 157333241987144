// First we need to import axios.js
import axios from 'axios';
import {ActionTypes} from "./Constants";
import {toast} from "react-toastify";

const instance = axios.create({

    withCredentials: true,
    //baseURL:BASE_URL
});

const setupInterceptors = (store, history) => {
    instance.interceptors.response.use((response) => {
            // Do something with response data
            return response;
        }, error => {
            // Do something with response error
            if (error.response.status === 401) {
                // DELETE YOUR AUTHENTICATE_USER item from localStorage

                // if (store.getState().auth.isAuthenticated) {
                //     console.log("gen authed");
                //
                // } else {
                //     console.log("gen not authed");
                //     toast.error("Session Expired")
                // }
                // if (store.getState().auth.isAuthenticatedExpress) {
                //     console.log("express authed");
                // } else {
                //     console.log("express not authed");
                // }

                store.dispatch({type:ActionTypes.LOGOUT_SUCCESS})

                //localStorage.removeItem('id_token')
                //localStorage.removeItem('auth_express')
                history.push(`/login`)

            }
            return Promise.reject(error);
        }
    )
    ;
}

export default {instance, setupInterceptors};