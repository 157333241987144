/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import {hqProductGet} from "./actions/actions";

import {ActionTypes, GTIN} from "./Constants"
import {css} from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    items: [],
    loading: false,
};


const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.HQ_PRODUCT_REQUEST: {

            return {
                ...state,
                loading: true
            }
        }
        // {
        //     "gtin": "08658000424511",
        //     "gpcCategoryCode": "50000000",
        //     "brandName": [
        //     {
        //         "language": "mn-MN",
        //         "value": "."
        //     }
        // ],
        //     "productDescription": [
        //     {
        //         "language": "mn-MN",
        //         "value": "Гэрийн боорцог"
        //     }
        // ],
        //     "netContent": [
        //     {
        //         "unitCode": "KGM",
        //         "value": "1"
        //     }
        // ],
        //     "countryOfSaleCode": [
        //     {
        //         "numeric": "496",
        //         "alpha2": "MN",
        //         "alpha3": "MNG"
        //     }
        // ],
        //     "licenseeName": "Пүрэв Энхжаргал",
        //     "dateCreated": "2023-01-10T12:34:09.0183663Z",
        //     "dateUpdated": "2023-01-10T12:34:09.0183663Z",
        //     "gs1Licence": {
        //     "licenceKey": "86580004245",
        //         "licenceType": "GCP",
        //         "licenseeName": "Пүрэв Энхжаргал",
        //         "licensingMO": {
        //         "moName": "GS1 Mongolia"
        //     },
        //     "dateCreated": "2023-01-07T02:18:34.27702Z",
        //         "dateUpdated": "2023-01-07T02:18:34.27702Z"
        // },
        //     "isComplete": false
        // }
        case ActionTypes.HQ_PRODUCT_SUCCESS: {

            return {
                ...state,
                items: action.items,
                loading: false
            }
        }
        default:
            return state;
    }
};



const HqProductView = ({match: {params: {barcode}}}) => {


    const [state, dispatch] = useReducer(reducer, initialState);
    const {items, loading } = state;

    useEffect(() => {
        console.log('useEffect called');
        let dto = {}
        dto.gtins = []
        dto.gtins.push("0"+barcode);

        hqProductGet(dispatch, dto);

    }, []);


    return (
        <>
            <hr/>
            <pre>{JSON.stringify(items, null, 2) }</pre>

        </>

    )

}

export default HqProductView;