/**
 * Created by gantushig on 11/6/15.
 */

import {Link, useRouteMatch} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ProductSenderFragment from "./ProductSenderFragment";
//import BarcodeGen from "./components/BarcodeGen";
import JsBarcode from "jsbarcode";
import classNames from "classnames";

const ProductBarcodeImageView = (
{
    match: {params: {barcode}}
}
                     ) => {
    const containerRef = useRef(null);
    const [barcodeText, setBarcodeText] = useState(barcode);
    const [barcodeHeight, setBarcodeHeight] = useState(100);
    const [barcodeWidth, setBarcodeWidth] = useState(2);
    const [barcodeFormat, setBarcodeFormat] = useState("EAN13");
    const [barcodeFontSize, setBarcodeFontSize] = useState(20);


    useEffect(() => {
        setBarcodeText(barcode)
    }, []);


    useEffect(() => {
        if (barcode) {
            const canvas = document.createElement("canvas");
            canvas.setAttribute("id", "mycanvas")
            let n = {
                format: barcodeFormat,
                flat: false,
                height: barcodeHeight,
                width: barcodeWidth,
                textAlign: "center",
                textPosition: "bottom",
                text:barcodeText,
                fontSize:barcodeFontSize
            }
            if (containerRef.current.children[0]) {
                containerRef.current.removeChild(containerRef.current.children[0])
            }
            JsBarcode(canvas, barcode, n);
            containerRef.current.appendChild(canvas);

        }
    }, [barcode, barcodeText, barcodeHeight, barcodeWidth, barcodeFormat, barcodeFontSize]);


    function download(){
        let canvas1 = document.getElementById("mycanvas");
        let url = canvas1.toDataURL("image/png");
        let link = document.createElement('a');
        link.download = barcode+'barcode.png';
        link.href = url;
        link.click();
    }


    const handleChangeBarcodeText = (e) => {
        setBarcodeText(e.target.value)
    }
    const handleChangeBarcodeHeight = (e) => {
        setBarcodeHeight(e.target.value)
    }
    const handleChangeBarcodeWidth = (e) => {
        setBarcodeWidth(e.target.value)
    }
    const handleChangeBarcodeFontSize = (e) => {
        setBarcodeFontSize(e.target.value)
    }

    const handleChangeBarcodeFormat = (e, f) => {
        setBarcodeFormat(f)
    }

    var btnClassEAN13 = classNames({
        'm-2 p-2 border-2': true,
        'bg-gray-300': barcodeFormat === "EAN13",
        'bg-white': barcodeFormat !== "EAN13",
    });
    var btnClassCODE39 = classNames({
        'm-2 p-2 border-2': true,
        'bg-gray-300': barcodeFormat === "CODE39",
        'bg-white': barcodeFormat !== "CODE39",
    });


    return (

            <table className="table table-bordered is-bordered is-fullwidth">
                <tbody>
                <tr>
                    <th className="company-title">Дугаар</th>
                    <td className="company-value">{barcode}</td>
                </tr>
                <tr>
                    <th>barcode</th>
                    <td>
                            <div ref={containerRef}>

                            </div>
                        <label className="label">Формат</label>
                        <div className="control has-icons-left">
                            <button className={btnClassEAN13} onClick={e=>handleChangeBarcodeFormat(e, "EAN13")}>EAN13</button>
                            <button className={btnClassCODE39} onClick={e=>handleChangeBarcodeFormat(e, "CODE39")}>CODE39</button>
                        </div>

                        <label className="label">Текст</label>
                        <div className="control has-icons-left">
                            <input className="input" type="text" value={barcodeText}
                                   onChange={(e) => handleChangeBarcodeText(e)} />
                        </div>
                        <label className="label">Өндөр</label>
                        <div className="control has-icons-left">
                            <input className="input" type="text" value={barcodeHeight}
                                   onChange={(e) => handleChangeBarcodeHeight(e)} />
                        </div>
                        <label className="label">1 Бар Өргөн</label>
                        <div className="control has-icons-left">
                            <input className="input" type="text" value={barcodeWidth}
                                   onChange={(e) => handleChangeBarcodeWidth(e)} />
                        </div>
                        <label className="label">Font Size</label>
                        <div className="control has-icons-left">
                            <input className="input" type="text" value={barcodeFontSize}
                                   onChange={(e) => handleChangeBarcodeFontSize(e)} />
                        </div>
                        <button onClick={download} className="m-2 p-2 border-2">Download</button>
                    </td>
                </tr>


                </tbody>
            </table>
    )

}


export default ProductBarcodeImageView

