/**
 * Created by gantushig on 4/24/19.
 */


import {connect} from "react-redux";
import {Link} from "react-router-dom";
import classNames from "classnames";
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';

import React, {useEffect, useState} from "react";


const NoteEditMenu = ({id, onDelete}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    let dropdownClass = classNames({
        dropdown: true,
        'is-active': isOpen
    });

    return (
            <div className={dropdownClass}>
                <div className="dropdown-trigger">
                    <button className="button is-white" aria-haspopup="true" aria-controls="dropdown-menu">
                        <DotsHorizontalIcon color="#00B7DE" style={{width:'30px',height:'30px'}} onClick={(e)=>toggle(e)}/>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        <a className="dropdown-item" onClick={()=>onDelete(id)}>
                            Устгах
                        </a>
                    </div>
                </div>
            </div>

    )

}


export default NoteEditMenu;




