/**
 * Created by gantushig on 11/6/15.
 */

import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {
    fetchSmsItems,  sendSms, fetchSmsItem
} from "./actions/actions";
import qs from "query-string";
import {ActionTypes, Page, TOKEN} from "./Constants";
import ReactPaginate from "react-paginate";
import classnames from "classnames";
import history from "./history";
import {toast} from "react-toastify";
import {confirm} from "react-confirm-box";


const initialState = {
    items: [],
    loading: false,
    totalPages:0,
    totalElements:0,
};

const reducer = (state, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_SMS_ITEMS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_SMS_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.items,
                totalElements: action.meta.totalElements,
                totalPages: action.meta.totalPages,
            }
        case ActionTypes.FETCH_SMS_ITEM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FETCH_SMS_ITEM_SUCCESS: {
            const ps = state.items.map(a => ({...a}));

            const itemIndex = ps.findIndex(p => p.id === action.item.id);
            if (itemIndex !== -1) {
                ps[itemIndex] = action.item;

            }

            return {
                ...state,
                loading: false,
                items: ps,
            }
        }
        case ActionTypes.SEND_SMS_SUCCESS:

            let status = action.status;
            if (action.status === 'SEND') {
                status = "SENDING";
            } else if (action.status === 'CANCEL') {
                status = "CANCELLED";
            }
            const ps = state.items.map(a => ({...a}));

            const itemIndex = ps.findIndex(p => p.id === action.id);
            if (itemIndex !== -1) {
                ps[itemIndex].status = status;
                ps[itemIndex].phone = action.phone;
            }
            return {
                ...state,
                items: ps,
            }
        default:
            return state;
    }
};

const SmsItemsPage = ({
                          location: {search}
                      }) => {
    const [showModal, setShowModal] = React.useState(false);
    const [phone, setPhone] = React.useState('');
    const [currId, setCurrId] = React.useState(0);

    let token = localStorage.getItem(TOKEN) || null;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let order = parsed[Page.ORDER];
    let pagesize = parsed[Page.PAGESIZE];
    let asc = parsed[Page.ASC];

    if (page === undefined) {
        page = "0";
    }
    if (order === undefined) {
        order = "id";
    }
    if (asc === undefined) {
        asc = "false";
    }
    if (pagesize === undefined) {
        pagesize = "25";
    }

    const [state, dispatch] = useReducer(reducer, initialState);
    const {items, totalPages, totalElements
    } = state;

    useEffect(() => {

        console.log('useEffect called');
        fetchSmsItems(dispatch, token,  page, pagesize, order, asc            );
    }, [ page, pagesize, order, asc]);

    //let totalPages = 0;


    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed))
    };

    const handleSearch = (e) => {
        console.log('search invoice');

        e.preventDefault();
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = "0";
        history.push("?" + qs.stringify(parsed))
    };
    const getNameTypeLabel = (t) => {
        if (t === 'SMS_INVOICE_PAID') {
            return 'Нэхэмжлэх төлсөн'
        } else if (t === 'SMS_FILE_READY') {
            return 'Баркод файл бэлэн'
        } else {
            return "";
        }

    }

    const handleOrderClick = (f) => {
        let parsed = qs.parse(search);
        if (parsed[Page.ORDER] === f) {
            let o = parsed[Page.ASC];
            if (o === "true") {
                o = "false";
            } else {
                o = "true";
            }
            parsed[Page.ASC] = o;
        } else {
            parsed[Page.ORDER] = f;
        }


        history.push("?" + qs.stringify(parsed))
    }

    const handleChangePhone = (e) => {
        setPhone(e.target.value)
    };
    const handleSendSms = () => {
        //e.preventDefault();
        setShowModal(false);
        console.log('useEffect called');
        let info = {}
        info.status = 'SEND'
        info.phone = phone
        sendSms(dispatch, currId, info );
    }

    const handleSend = (id) => {
        console.log('useEffect called');
        let info = {}
        info.status = 'SEND'
        sendSms(dispatch, id, info );

    }
    const handleRefresh = (id) => {
        console.log('useEffect called');
        fetchSmsItem(dispatch, id );

    }

    const handleOpenDialog = (phone, id) => {
        setPhone(phone)
        setCurrId(id)
        setShowModal(true)
    }

    const optionsWithLabelChange = {
        closeOnOverlayClick: false,
        labels: {
            confirmable: "Устгах",
            cancellable: "Болих"
        }
    };
    const handleCancel = async (id) => {
        const result = await confirm("Cancel Send?", optionsWithLabelChange);
        if (result) {

            let info = {}
            info.status = 'CANCEL'

            sendSms(dispatch, id , info);
        }
        console.log("You click No!");
    }

    //{() => setShowModal(true)}
    //<button className="bg-green-500 border p-2 m-2 text-white" onClick={()=>handleSend(item.id)}>Send</button>
    const renderButtons = (item) => {
        if (item.status === 'TO_BE_SENT') {
            return (
                <>
                    <button className="bg-green-500 border p-2 m-2 text-white"
                            onClick={() => handleOpenDialog(item.phone, item.id)}>Send
                    </button>
                    <button className="bg-orange-500 border p-2 m-2 text-white"
                            onClick={() => handleCancel(item.id)}>Cancel
                    </button>
                </>
            )
        } else if (item.status === 'SENDING') {
            return (<>
                    {item.status}
                    <button type="button" onClick={()=>handleRefresh(item.id)}
                            className="m-2 text-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800">
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 30 30" width="30px" height="30px"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"/>
                        </svg>
                    </button>
                </>
            )
        } else {
            return item.status;
        }
    }
    let counter = Number(page) * Number(pagesize)
    const displayItems = items.map(
        (mail) => {
                   counter++;
           return (
                <tr key={mail.id}>
                    <td>{counter}</td>
                    <td>
                        <Link to={`/company/${mail.company_id}`}>
                            {mail.company_name}
                        </Link>

                    </td>
                    <td>{getNameTypeLabel(mail.name_type)}</td>
                    <td>{mail.phone}</td>
                    <td>{renderButtons(mail)}{

                        }</td>
                </tr>
            )
        });

    return (
        <div className="pt-2 pr-2 pl-2 bg-white">
            <br/>
            <Link className="my-12 p-2 w-24 border-gray-300 bg-green-500   rounded" to="/sms-mass-bodies"><span className="text-white font-bold ">SMS Send</span></Link>
            <br/>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Компани</th>
                    <th>Sms</th>
                    <th>Утас</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {displayItems}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={page}
                               breakLabel={<a href="">...</a>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                />
            </nav>


            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative my-2 mx-auto w-1/3 max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                <div className="relative p-2 flex-auto">
                                    <input type="text" className="form-control block w-full px-2 py-2         text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"  value={phone}
                                              onChange={(e) => handleChangePhone(e)} placeholder="Дугаар оруулах"/>

                                </div>
                                {/*footer*/}
                                <div
                                    className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Хаах
                                    </button>
                                    <button
                                        className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => handleSendSms()}
                                    >
                                        Илгээх
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

        </div>
    )

}


export default SmsItemsPage


