/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import {Link, Redirect, useParams, Switch, Route, useRouteMatch, useLocation, matchPath} from "react-router-dom";
import {useDispatch} from "react-redux";
import CompanyEditComponent from './CompanyEditComponent';
import CompanyViewHeader from "./CompanyViewHeader";

import history from "./history";
import dayjs from "dayjs"
import {toast} from "react-toastify";
import {ActionTypes, Endpoints, EndpointsExpress, TOKEN} from "./Constants";
import {css} from "@emotion/react";
import   axios from './axiosConfig'
import ClipLoader from "react-spinners/ClipLoader";
import SmsMassEditComponent from "./SmsMassEditComponent";
import {fetchSmsMassBody} from "./actions/actions";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    company: {name:"", sms:"", sms2:"", sms3:"", length:0, length2:0, length3:0, send_audience:"ALL"},
    loading: false,
    saved:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_SMS_MASS_BODY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_SMS_MASS_BODY_SUCCESS: {
            let c = {...action.main};
            c["length"] = c.sms.length;
            c["length2"] = c.sms2.length;
            c["length3"] = c.sms3.length;
            return {
                ...state,
                loading: false,
                company: c,
            }
        }
        case 'CHANGE_FIELD':
            let c = {...state.company};
            c[action.name] = action.value
            if (action.name === "sms") {
                c["length"] = action.value.length;
            } else if (action.name === "sms2") {
                c["length2"] = action.value.length;
            } else if (action.name === "sms3") {
                c["length3"] = action.value.length;
            }
            return {
                ...state,
                company: c,
                loading: false
            }
        default:
            return state;
    }
};

const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}
//
// const setCompany = (company) => {
//     return {
//         type: 'SET_COMPANY',
//         company
//     }
// };




const SmsMassEdit = (
    {
        match: {params: {id}}
    }
) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {company, loading} = state;

    useEffect(() => {
        console.log('useEffect called');
        fetchSmsMassBody(dispatch, id);

    }, []);

    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }


    const handleSave = () => {

        async function saveEdit() {
            let dto = {
                name:company.name,
                sms:company.sms,
                sms2:company.sms2,
                sms3:company.sms3,
                send_audience:company.send_audience
            };
            let header = {}

            header["Accept"] = "application/json"
            header["Content-Type"] = "application/json"

            let config = {}
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.SMS_MASS_BODY}/${id}`;
            config["method"] = "post";
            config["data"] = JSON.stringify(dto);
            try {
                const response = await axios.instance(config);
            } catch(e) {
                alert(e)
            }
        }
        console.log('send');
        saveEdit();
        toast.success("Success!");
        history.push("/sms-mass-bodies")
    };
    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
    };


    return (
        <>
            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>

            <SmsMassEditComponent obj={company}
                                  onChangeField={handleChangeField}
                                  onSave={handleSave} onCancel={handleCancel}/>

        </>
    )

}


export default SmsMassEdit;