/**
 * Created by gantushig on 11/6/15.
 */

import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {
    fetchSmsItems, sendSms, fetchSmsItem, fetchSmsMassBodies
} from "./actions/actions";
import qs from "query-string";
import {ActionTypes, Page, TOKEN} from "./Constants";
import ReactPaginate from "react-paginate";
import classnames from "classnames";
import history from "./history";
import {toast} from "react-toastify";
import {confirm} from "react-confirm-box";


const initialState = {
    items: [],
    loading: false,
    totalPages:0,
    totalElements:0,
};

const reducer = (state, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_SMS_MASS_BODIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_SMS_MASS_BODIES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.items,
                totalElements: action.meta.totalElements,
                totalPages: action.meta.totalPages,
            }
        default:
            return state;
    }
};

const SmsMassItemsPage = ({
                          location: {search}
                      }) => {

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];


    if (page === undefined) {
        page = "0";
    }

    if (pagesize === undefined) {
        pagesize = "25";
    }

    const [state, dispatch] = useReducer(reducer, initialState);
    const {items, totalPages, totalElements
    } = state;

    useEffect(() => {

        console.log('useEffect called');
        fetchSmsMassBodies(dispatch, page, pagesize );
    }, [ page, pagesize]);


    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed))
    };

    let counter = Number(page) * Number(pagesize)
    const displayItems = items.map(
        (mail) => {
                   counter++;
           return (
                <tr key={mail.id}>
                    <td>{counter}</td>
                    <td>
                        <Link to={`/sms-mass-body/${mail.id}`}>
                            {mail.name}
                        </Link>

                    </td>
                    <td>{mail.created}</td>
                    <td>{mail.status}</td>
                </tr>
            )
        });

    return (
        <div className="pt-2 pr-2 pl-2 bg-white">
            <Link to="/sms-mass-body-create" className="block m-2 p-2 w-16 border-gray-300 bg-green-500  text-white font-bold  rounded"><span className="text-white hover:text-blue-800 visited:text-purple-600">Create</span></Link>
            <br/>
            Нийт : {totalElements}
            <br/>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Created</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {displayItems}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={page}
                               breakLabel={<a href="">...</a>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                />
            </nav>

        </div>
    )

}


export default SmsMassItemsPage


