/**
 * Created by gantushig on 11/6/15.
 */

import {Link, useRouteMatch} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ProductSenderFragment from "./ProductSenderFragment";
//import BarcodeGen from "./components/BarcodeGen";
import JsBarcode from "jsbarcode";
import classNames from "classnames";
import {removeLeadingZeros} from "./utils/productUtils";

const ProductGTINView = ({
                         p, image1, image2, image3
                     }) => {
    let {path, url} = useRouteMatch();
    const containerRef = useRef(null);
    // const [barcodeText, setBarcodeText] = useState(p.barcode);
    // const [barcodeHeight, setBarcodeHeight] = useState(100);
    // const [barcodeWidth, setBarcodeWidth] = useState(2);
    // const [barcodeFormat, setBarcodeFormat] = useState("EAN13");
    // const [barcodeFontSize, setBarcodeFontSize] = useState(20);


    // useEffect(() => {
    //     setBarcodeText(p.barcode)
    // }, [p]);

    // const removeLeadingZeros = (input)=> {
    //     let result = input;
    //     while (result[0] === '0') {
    //         result = result.substring(1);
    //     }
    //     return result;
    // }
    useEffect(() => {
        if (p && p.barcode) {
            let format = null;
            let currBarcode = removeLeadingZeros(p.barcode);
            // if starts with 0 remove all 0
            if (currBarcode.length === 13) {
                format = "EAN13";
            } else if (currBarcode.length === 14) {
                format = "ITF14";
            } else if (currBarcode.length === 8) {
                format = "EAN8";
            }
            if (format) {
                const canvas = document.createElement("canvas");
                canvas.setAttribute("id", "mycanvas")
                let n = {
                    format: format,
                    textAlign: "center",
                    textPosition: "bottom",
                }
                if (containerRef.current.children[0]) {
                    containerRef.current.removeChild(containerRef.current.children[0])
                }
                JsBarcode(canvas, currBarcode, n);
                containerRef.current.appendChild(canvas);
            }
        }
    }, [p]);

    function download(){
        let canvas1 = document.getElementById("mycanvas");
        let url = canvas1.toDataURL("image/png");
        let link = document.createElement('a');
        link.download = p.barcode+'-'+p.name+'-'+p.packaging+'-'+p.packaging_unit+'.png';
        link.href = url;
        link.click();
    }

    const getGcpStatus =(g)=> {
        let ret = "";
        if (g === 'A') {
            ret = "ИДЭВХТЭЙ";
        } else  if (g === 'C') {
            ret = "ХААСАН";
        } else {
            ret = "ERROR";
        }
        return ret;
    }

    const getExpCountriesLabel = (p)=>{
        let ret = "";
        if (p.exp_countries) {

            p.exp_countries.map(el => {
                    ret += el.name;
                    ret += "/" + el.id;
                    ret += ", ";
            })
            if (ret !== "") {
                ret = ret.replace(/,\s*$/, "");
            }
        }
        return ret;
    }



    return (

            <table className="table table-bordered is-bordered is-fullwidth">
                <tbody>
                <tr>
                    <th>GCP</th>
                    <td>{p && p.gcp_id &&
                    "GCP" + p.g_size + ": " + p.g_prefix} {p.g_status && p.g_status ==='A' ?
                        <span className={"inline-block align-middle w-2 p-2 h-fit text-gray-200 bg-green-500"}></span> :
                        <span
                            className={"inline-block align-middle w-2 p-2 h-fit text-gray-200 bg-orange-500  "}></span>}
                    </td>
                </tr>

                <tr>
                    <th className="company-title">Баркод/GTIN</th>
                    <td className="company-value">{p && p.barcode}
                        <div ref={containerRef}>

                        </div>

                        <button onClick={download} className="m-2 p-2 border-2">Download</button>
                    </td>
                </tr>
                <tr>
                    <th>Нэр</th>
                    <td>{p && p.name}</td>
                </tr>
                <tr>
                    <th>Брэнд Нэр</th>
                    <td>{p && p.brand_name}</td>
                </tr>
                <tr>
                    <th>Савлалтын хэмжээ</th>
                    <td>{p && p.packaging}</td>
                </tr>
                <tr>
                    <th>Савлалтууд (кг, ширхэг, хайрцаг...)</th>
                    <td>{p && p.packaging_unit}</td>
                </tr>
                <tr>
                    <th>Орц найрлага</th>
                    <td>{p && p.constituent}</td>
                </tr>
                <tr>
                    <th>Шинж чанар</th>
                    <td>{p && p.characteristics}</td>
                </tr>
                <tr>
                    <th>Зориулалт</th>
                    <td>{p && p.handling}</td>
                </tr>
                <tr>
                    <th>Хэрэглэх заавар</th>
                    <td>{p && p.instruction}</td>
                </tr>
                <tr>
                    <th>Хадгалах нөхцөл/хугацаа</th>
                    <td>{p && p.storage}</td>
                </tr>
                <tr>
                    <th>Экспорт хийж байгаа улс</th>
                    <td>{p && p.exporting_countries_list}</td>
                </tr>
                <tr>
                    <th>Аль улсаас гарал үүсэлтэй</th>
                    <td>{p && p.coo}</td>
                </tr>
                <tr>
                    <th>Бүтээгдэхүүний зураг байршсан линк</th>
                    <td>{p && p.image_link}</td>
                </tr>
                <tr>
                    <th>Тухайн бараа бүтээгдэхүүн борлуулж байгаа улс /member/</th>
                    <td>{p && p.exp_countries_member}</td>
                </tr>
                <tr>
                    <th>Тухайн бараа бүтээгдэхүүн борлуулж байгаа улс /office/</th>
                    <td>{p && getExpCountriesLabel(p)}</td>
                </tr>

                <tr>
                    <th>ҮНДСЭН АНГИЛАЛ</th>
                    <td>{p && p.main_name} - [{p && p.main_code}]</td>
                </tr>
                <tr>
                    <th>ДЭД АНГИЛАЛ</th>
                    <td>{p && p.sub_name} - [{p && p.sub_code}]</td>
                </tr>
                <tr>
                    <th>НЭГЖ АНГИЛАЛ</th>
                    <td>{p && p.unit_name} - [{p && p.unit_code}]</td>
                </tr>
                <tr>
                    <th>Бүтээгдэхүүний Зураг</th>
                    <td>
                        <Link to={`${url}/images`} className="button is-small">
                            Үзэх
                        </Link>

                    </td>
                </tr>
                <tr>
                    <th>Зураг 1</th>
                    <td>
                        { p.image1_url &&
                            <div>
                                <img src={p.image1_url} width={300}/>
                            </div>
                        }
                    </td>
                </tr>
                <tr>
                    <th>Зураг 2</th>
                    <td>
                        { p.image2_url &&
                            <div>
                                <img src={p.image2_url} width={300}/>
                            </div>
                        }
                    </td>
                </tr>
                <tr>
                    <th>Зураг 3</th>
                    <td>
                        { p.image3_url &&
                            <div>
                                <img src={p.image3_url} width={300}/>
                            </div>
                        }
                    </td>
                </tr>
                <tr>
                    <th>GS1-14</th>
                    <td><input type="checkbox" disabled
                               checked={p && p.is_14}/></td>
                </tr>
                <ProductSenderFragment p={p}/>

                </tbody>
            </table>
    )

}


export default ProductGTINView

