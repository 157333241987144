/**
 * Created by gantushig on 11/6/15.
 */


import React, {useEffect,  useState} from "react";
import qs from "query-string";
import "react-datepicker/dist/react-datepicker.css";
import   axios from './axiosConfig'
import {EndpointsExpress} from "./Constants";
import {format, parseISO, parse} from "date-fns";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
dayjs.extend(dayjsPluginUTC)




const SentEmailsPage = () => {
    const [data, setData] = useState({items:[]});

    useEffect(() => {
        let page = 0;
        let pagesize = 25;
        async function getItems() {
            let header = {};
            header["Accept"] = "application/json";
            header["Content-Type"] = "application/json";

            let config = {};
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.SENT_EMAILS}/?` + qs.stringify({ page:page, pagesize:pagesize}, {skipNull:true});

            const response = await axios.instance(config);
            setData(response.data);
        }
        getItems();
    }, [ ]);



    const appts2 = data.items.map(p => {
        return (
            <tr key={p.id}>
                <td>{p.email}</td>
                <td>{p.invoice_id}</td>
                {/*<td>{format(parse(p.created), "yyyy-MM-dd HH:mm")}</td>*/}
                {/*<td>{parse(p.created)}</td>*/}
                <td>{dayjs.utc(p.created).format("YYYY-MM-DD HH:mm")}</td>
            </tr>
        )
    });

    return (

        <div className="inner-container">
            <br/>
            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Нэхэмжлэх</th>
                    <th>Created</th>
                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>


            <br/><br/>
        </div>
    )

}

export default SentEmailsPage

