/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useReducer, useState} from "react";
import {
    failure, fetchGcpsAll, fetchHqBatch, fetchHqBatches, fetchHqProducts, menuClicked,
    menuClickedTitle
} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, Endpoints, EndpointsExpress, Menu, Page, PageTitle, TOKEN} from "./Constants";
import history from "./history";
import axios from "axios";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import gcpOptionsData from "./data/gcps.json"
import gcpStatusData from "./data/gcp-status.json"


dayjs.extend(dayjsPluginUTC)


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const initialState = {
    resp: {},
    loading: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_GCPS_REQUEST:
            return {
                ...state,
                loading:true
            }
        case ActionTypes.LOAD_HQ_BATCH_SUCCESS:
            return {
                ...state,
                resp: JSON.parse(action.resp),
                loading:false
            }

        default:
            return state;
    }
};


const HqBatchPage = ({
                      location: {search}
                  }) => {
     const [batchId, setBatchId] = useState("");

    const [state, dispatch] = useReducer(reducer, initialState);
    const {resp, loading, batches, meta  } = state;

    let parsed = qs.parse(search);
    let qbatchid = parsed["b"];

    if (qbatchid === undefined) {
        qbatchid = null;
    }

    useEffect(() => {
        if (qbatchid) {
            qbatchid = qbatchid.replace(/^"(.*)"$/, '$1');
            fetchHqBatch(dispatch,
                qbatchid);
        }
    }, [qbatchid]);


    const handleChangeBatchId = (e) => {
        setBatchId(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        let parsed = qs.parse(search);
        parsed["b"] = batchId;
        history.push("?" + qs.stringify(parsed))
    };


    return (

        <div className="inner-container">

            <BatchSearchPanel
                batchId={batchId} onChangeBatchId={handleChangeBatchId}
                onClickSearch={handleSearch}

            />

            <br/>
            <br/>
            <pre>{JSON.stringify(resp, null, 2) }</pre>

        </div>
    )

}


const BatchSearchPanel = ({
                            batchId,
                            onChangeBatchId,
                            onClickSearch
                        }) => {



    return (
        <div>
            <div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Batch Id</label>
                    </div>
                    <div className="field-body">
                        <div className="control">
                            <input className="input" type="text" value={batchId} style={{width: '400px'}}
                                   onChange={onChangeBatchId} placeholder="Оруулах"/>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label">

                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <div className="buttons">
                                    <button onClick={onClickSearch} className="button bg-orange">
                                        <span style={{color: "white"}}>Хайх</span>

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HqBatchPage

