/**
 * Created by gantushig on 11/6/15.
 */

import {Link, Route, Switch, useLocation, useParams, useRouteMatch} from "react-router-dom";
import React, {useEffect, useReducer} from "react";
import {fetchProducts} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import {ActionTypes, Page, TOKEN} from "./Constants";
import history from "./history";
import {css} from "@emotion/react";
import ContactCreate from "./ContactCreate";
import ProductView from "./ProductView";
import ProductEdit from "./ProductEdit";
import ProductCopy from "./ProductCopy";
import ProductImagesView from "./ProductImagesView";
import dayjs from "dayjs";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    products: [],
    meta: {},
    loading: false,
    totalPages: 0,
    totalElements: 0,
    refresh:false
};

const reducer = (state, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                meta: action.meta,
                products: action.products,
                totalElements: action.meta.totalElements,
                totalPages: action.meta.totalPages,
            }
        case 'REFRESH':
            return {
                ...state,
                refresh: !state.refresh
            }
        default:
            return state;
    }
};


const refreshPage = () => {
    return {
        type: "REFRESH",
    }
}
//{match: {params: {id}}, location: {search}}
const CompanyProducts = () => {
    let {companyId} = useParams();
    let {search} = useLocation();
    let {path, url} = useRouteMatch();
    const [state, dispatch] = useReducer(reducer, initialState);
    let {products, meta, loading, refresh} = state

    let token = localStorage.getItem(TOKEN) || null;
    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let order = parsed[Page.ORDER];
    let pagesize = parsed[Page.PAGESIZE];
    let asc = parsed[Page.ASC];
    if (page === undefined) {
        page = "0";
    }
    if (order === undefined) {
        order = "barcode";
    }
    if (asc === undefined) {
        asc = "true";
    }
    if (pagesize === undefined) {
        pagesize = "25";
    }

//    let products = stateProducts.products;
    //  let meta = stateProducts.meta;
    let counter = 0
    for (let key in products) {
        let item = products[key]
        counter++
        item.counter = Number(page) * Number(pagesize) + counter
    }

    useEffect(() => {
        fetchProducts(dispatch, token, companyId, page, pagesize, order, asc);
    }, [companyId, page, order, asc, pagesize,refresh]);

    if (products === undefined) {
        return (
            <>
                <table className="table is-bordered is-fullwidth">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Нэр</th>
                        <th>Брэнд Нэр</th>
                        <th>Дугаар</th>
                        <th>Савлалтын хэмжээ /нэгж, багц/</th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
                <div className="subtitle">No Items</div>

            </>
        )
    }
    ;

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };
    const handleUpdate = () => {
        dispatch(refreshPage());
    }
    const handleOrderClick = (f) => {
        let lorderField = order;
        let lisAsc = asc;

        if (f === lorderField) {
            if (lisAsc === 'true') {
                lisAsc = 'false';
            } else {
                lisAsc = 'true';
            }
        } else {
            lorderField = f;
            lisAsc = true;
        }

        let parsed = qs.parse(search);
        parsed[Page.ORDER] = lorderField;
        parsed[Page.ASC] = lisAsc;
        history.push("?" + qs.stringify(parsed))
    }

    const items = products.map(
        (p) => {
            return (
                <tr key={p.id}>
                    <td>
                        <Link to={`${url}/${p.id}`}>
                            {p.counter}
                        </Link>
                    </td>
                    <td>
                        <Link to={`${url}/${p.id}`}>
                            {p.name}
                        </Link>
                    </td>
                    <td>
                        {p.brand_name}
                    </td>
                    <td>
                        {p.barcode}
                    </td>
                    <td>{p.packaging} {p.packaging_unit}</td>
                    <td>{p.created && dayjs.utc(p.created).format("MM/DD/YYYY")}</td>
                </tr>
            )
        });

    return (
        <>
            {/*<CompanyViewHeader tab={3} companyId={id}/>*/}

            {/*<ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>*/}
            <Switch>
                <Route exact path={path}>
                    <table className="table is-fullwidth">
                        <thead>
                        <tr>
                            <th><a onClick={() => handleOrderClick("id")}>#</a></th>
                            <th><a onClick={() => handleOrderClick("name")}>Нэр</a></th>
                            <th>Брэнд Нэр</th>
                            <th><a onClick={() => handleOrderClick("barcode")}>Зур.код</a></th>
                            <th>Савлалтын хэмжээ /нэгж, багц/</th>
                            <th><a onClick={() => handleOrderClick("created")}>Үүсгэсэн</a></th>
                        </tr>
                        </thead>
                        <tbody>
                        {items}
                        </tbody>
                    </table>

                    <nav className="pagination">
                        <ReactPaginate previousLabel={"Өмнөх"}
                                       nextLabel={"Дараах"}
                                       initialPage={Number(page)}
                                       breakLabel={<a href="">...</a>}
                                       breakClassName={"pagination-ellipsis"}
                                       pageCount={meta.totalPages}
                                       marginPagesDisplayed={2}
                                       pageRangeDisplayed={5}
                                       activeLinkClassName={"is-current"}
                                       pageLinkClassName={"pagination-link"}
                                       onPageChange={(e) => handlePageClick(e)}
                                       previousClassName={"pagination-previous"}
                                       disableInitialCallback={true}
                                       nextClassName={"pagination-next"}
                                       containerClassName={"pagination-list"}
                                       forcePage={Number(page)}
                        />
                    </nav>
                    <br/><br/>
                </Route>
                <Route path={`${path}/create`}>
                    <ContactCreate/>
                </Route>
                <Route exact path={`${path}/:productId`}>
                    <ProductView onUpdate={handleUpdate}/>
                </Route>
                <Route path={`${path}/:productId/edit`}>
                    <ProductEdit onUpdate={handleUpdate}/>
                </Route>
                <Route path={`${path}/:productId/copy`}>
                    <ProductCopy onUpdate={handleUpdate}/>
                </Route>
                <Route path={`${path}/:productId/images`}>
                    <ProductImagesView/>
                </Route>
            </Switch>
        </>
    )

}

export default CompanyProducts


