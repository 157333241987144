/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    changeTempProduct,
    fetchProduct8,
    resetErrors,
    resetProductUpdateStatus,
    updateProduct8
} from "./actions/actions";
import {toast} from "react-toastify";
import {ActionTypes, GTIN, TOKEN} from "./Constants"
import {checkGtinFieldsFilled, createProduct8Dto} from "./utils/productUtils";
import history from "./history";
import Product8EditComponent from "./Product8EditComponent";
import {css} from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    product: {barcode_type:GTIN},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_PRODUCT8_REQUEST:{
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.GET_PRODUCT8_SUCCESS: {
            return {
                ...state,
                product: action.product,
                loading: false
            }
        }
        case 'CHANGE_FIELD':
            let c = {...state.product};
            c[action.name] = action.value
            return {
                ...state,
                product: c
            }
        default:
            return state;
    }
};


const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}


const Product8Edit = ({match: {params: {productId}}}) => {

    let reduxDispatch = useDispatch();
    let token = localStorage.getItem(TOKEN) || null;
    //const tempProduct = useSelector(state => state.tempProduct);
    const [state, dispatch] = useReducer(reducer, initialState);
    const {product} = state;

    //let initialStateCompany = [{value:product.company_id, label : product.company_name}]

    useEffect(() => {
        console.log('useEffect called');
        fetchProduct8(dispatch, token, productId)
    }, [productId]);

    useEffect(() => {
        console.log('resetProductUpdateStatuscalled');
        return () => {
            reduxDispatch(resetProductUpdateStatus())
        }
    }, [productId]);


    const handleChangeCompany = (newValue) => {
        //dispatch(changeSelectedOption(newValue));
        dispatch(changeField('company_id', newValue.value))
        //setSelectedOption(newValue);
        //dispatch(changeTempProduct('company_id', newValue.value))
    };

    const handleChangeField =(e)=> {
        dispatch(changeField(e.target.name, e.target.value))
    }
    const handleSave = () => {

        // let checkFailed = false;
        // if (!checkGtinFieldsFilled(product)) {
        //     console.log('save check gtin');
        //     checkFailed = true;
        // }
        // if (checkFailed) {
        //     console.log('check ');
        //     alert("Та одоор * тэмдэглэсэн заавал оруулах талбаруудыг оруулна уу!")
        //     return;
        // }

        let dto = {}

        dto = createProduct8Dto(product)


        reduxDispatch(updateProduct8(token, productId, dto)).then(response => {
                toast.success("Амжилттай илгээлээ!");
                history.push(`/product8s`)
            }
        ).catch(error => {
            alert(error)
        })
    };

    const handleCancel = () => {
        console.log('cancel');
        reduxDispatch(resetErrors())
        history.goBack();
    };

    // if (isSuccess) {
    //     toast.success("Амжилттай хадгалагдлаа!");
    //     return <Redirect to={`/products`}/>;
    // }

    // if (emptyDataWarning) {
    //     toast.warning("Өгөгдлүүдийг оруулна уу!");
    // }

    return (
        <div className="inner-container">

            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Бүтээгдэхүүний Мэдээлэл Өөрчлөх</h1>
                </div>
            </div>
            <hr/>

            <Product8EditComponent
                product={product}
                onChangeField={handleChangeField}
                onChangeCompany={handleChangeCompany}
                onSave={handleSave} onCancel={handleCancel}/>

        </div>

    )

}


export default Product8Edit