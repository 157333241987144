import "./App.scss";
import {Link, Redirect} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";

const Auth = WrappedComponent => (props) => {
    const {isAuthenticated, isAuthenticatedExpress} = props;


    if (isAuthenticated) {
        if (isAuthenticatedExpress) {
            return <WrappedComponent { ...props }/>
        } else {
            return <Redirect to="/login-express"/>;
        }

    } else {
        return <Redirect to="/login"/>;
    }
}


const mapStateToProps = (state) => {
    const isAuthenticated = state.auth.isAuthenticated;
    const isAuthenticatedExpress = state.auth.isAuthenticatedExpress;
    return {
        isAuthenticated,
        isAuthenticatedExpress,
    }
}

const requireAuth = compose(connect(mapStateToProps), Auth)
export default requireAuth;



