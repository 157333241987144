import {ActionTypes, GTIN} from "../Constants";
import countriesData from "../data/countries3.json";
import {useReducer} from "react";

const initialState = {
    product: {barcode_type: GTIN, gcp_id:null},
    loading: false,
    gcps:[],
    units:[],
    selectedCountries:[],
    showMainOptions:false,
    showSubOptions:false,
    showUnitOptions:false,
    mainOptions:[],
    subOptions:[],
    unitOptions:[],
    countries:[]
};


const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_PRODUCT_REQUEST:
        case ActionTypes.LOAD_PACKAGING_UNITS_REQUEST:
        {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.LOAD_COUNTRIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                countries: action.countries
            }
        }

        case ActionTypes.GET_PRODUCT_SUCCESS: {
            let exp = []

            // if (action.product.exp_countries_json){
            //     // let str = action.product.exp_countries_office; //'MN,RU,FR,';
            //     //
            //     // //remove the last comma
            //     // str = str.replace(/,\s*$/, "");
            //     //
            //     // let test = str.split(','); //['MN','RU','FR'];
            //     action.product.exp_countries_json.map(el => {
            //         const c = state.countries.find(e => e.id === el)
            //         exp = [...exp, c]
            //     })
            // }
            // if (action.product.exp_countries_office){
            //     let str = action.product.exp_countries_office; //'MN,RU,FR,';
            //
            //     //remove the last comma
            //     str = str.replace(/,\s*$/, "");
            //
            //     let test = str.split(','); //['MN','RU','FR'];
            //     test.map(el => {
            //         const c = countriesData.find(e => e.id === el)
            //         exp = [...exp, c]
            //     })
            // }


            return {
                ...state,
                gcps:action.gcps,
                selectedCountries: action.product.exp_countries,
                product: action.product,
                loading: false
            }
        }
        case ActionTypes.UPDATE_PRODUCT_SUCCESS: {
            let p = state.product;
            localStorage.setItem("main_name", p.main_name);
            localStorage.setItem("sub_name", p.sub_name);
            localStorage.setItem("unit_name", p.unit_name);
            localStorage.setItem("main_code", p.main_code);
            localStorage.setItem("sub_code", p.sub_code);
            localStorage.setItem("unit_code", p.unit_code);
            localStorage.setItem("main", p.main);
            localStorage.setItem("sub", p.sub);
            localStorage.setItem("unit", p.unit);

            return {
                ...state,
                //product: action.product,
                loading: false
            }
        }
        case 'COPY_CAT': {
            let p = state.product;
            localStorage.setItem("main_name", p.main_name);
            localStorage.setItem("sub_name", p.sub_name);
            localStorage.setItem("unit_name", p.unit_name);
            localStorage.setItem("main_code", p.main_code);
            localStorage.setItem("sub_code", p.sub_code);
            localStorage.setItem("unit_code", p.unit_code);
            localStorage.setItem("main", p.main);
            localStorage.setItem("sub", p.sub);
            localStorage.setItem("unit", p.unit);
            return {
                ...state,
            }
        }
        case 'PASTE_CAT': {
            let mainName = localStorage.getItem("main_name");
            let subName = localStorage.getItem("sub_name");
            let unitName = localStorage.getItem("unit_name");
            let mainCode = localStorage.getItem("main_code");
            let subCode = localStorage.getItem("sub_code");
            let unitCode = localStorage.getItem("unit_code");
            let main = localStorage.getItem("main");
            let sub = localStorage.getItem("sub");
            let unit = localStorage.getItem("unit");

            let p = state.product;
            p["main_name"] = mainName;
            p["sub_name"] = subName;
            p["unit_name"] = unitName;
            p["main_code"] = mainCode;
            p["sub_code"] = subCode;
            p["unit_code"] = unitCode;
            p["main"] = main;
            p["sub"] = sub;
            p["unit"] = unit;
            return {
                ...state,
                product: p
            }
        }
        case ActionTypes.LOAD_CLASSIF_NOUV_MAIN_SUCCESS: {
            return {
                ...state,
                loading: false,
                mainOptions:action.classifs,
            }
        }
        case ActionTypes.LOAD_CLASSIF_NOUV_SUB_SUCCESS: {
            return {
                ...state,
                loading: false,
                subOptions:action.classifs,
            }
        }
        case ActionTypes.LOAD_CLASSIF_NOUV_UNIT_SUCCESS: {
            return {
                ...state,
                loading: false,
                unitOptions:action.classifs
            }
        }
        case ActionTypes.LOAD_PACKAGING_UNITS_SUCCESS: {
            return {
                ...state,
                units:action.units,
                loading: false
            }
        }
        case 'CHANGE_GCP': {
            let c = {...state.product};
            if ( action.gcp === "0") {
                c.gcp_id = null;
                c.gcp_prefix = null;
                c.gcp_size = null;
                c.g_prefix = null;
            } else {
                let f = state.gcps.filter(item=>item.id === Number(action.gcp));
                c.gcp_id = f[0].id;
                c.gcp_prefix = f[0].prefix;
                c.g_prefix = f[0].prefix;
                c.gcp_size = f[0].size;
                c.barcode = c.gcp_prefix;
            }

            return {
                ...state,
                product: c,
                loading: false
            }
        }
        case 'SET_GCP': {
            let c = {...state.product};
            c.gcp_id = action.gcp.id;
            c.gcp_prefix = action.gcp.prefix;
            c.gcp_size = action.gcp.size;

            c.g_prefix = action.gcp.prefix;

            return {
                ...state,
                product: c,
                loading: false
            }
        }
        case 'CHANGE_MAIN': {
            let c = {...state.product};
            c.main = action.main;
            console.log("main" + action.main);
            // fill name and code
            if (state.mainOptions && state.mainOptions.length>0) {
                let opt = state.mainOptions.find(el=> {
                    return el.id === action.main;
                });
                if (opt!== undefined) {
                    c.main_name = opt.name;
                    c.main_code = opt.code
                }
            }
            c.sub = null;
            c.sub_name = null;
            c.sub_code = null;
            c.unit = null;
            c.unit_name = null;
            c.unit_code = null;

            return {
                ...state,
                product: c,
                subOptions:[],
                unitOptions:[],
                loading: false
            }
        }
        case 'CHANGE_SUB': {
            let c = {...state.product};
            c.sub = action.sub;

            // fill name and code
            let opt = state.subOptions.find(el=> {
                return el.id === action.sub;
            });
            if (opt!== undefined) {
                c.sub_name = opt.name;
                c.sub_code = opt.code
            }

            c.unit = null;
            c.unit_name = null;
            c.unit_code = null;

            return {
                ...state,
                product: c,
                unitOptions:[],
                loading: false
            }
        }

        case 'CHANGE_UNIT': {
            let c = {...state.product};
            c.unit = action.unit;

            // fill name and code
            let opt = state.unitOptions.find(el=> {
                return el.id === Number(action.unit);
            });
            if (opt!== undefined) {
                c.unit_name = opt.name;
                c.unit_code = opt.code
            }

            return {
                ...state,
                product: c,
                loading: false
            }
        }
        case 'CHANGE_PACKAGING': {
            let c = {...state.product};
            c.packaging_unit = action.unit;

            return {
                ...state,
                product: c,
                loading: false
            }
        }

        case 'CHANGE_FIELD':
            let c = {...state.product};
            c[action.name] = action.value
            return {
                ...state,
                product: c,
                loading: false
            }

        case 'ADD_COUNTRY':
            return {
                ...state,
                selectedCountries: [...state.selectedCountries, action.tag],
            }
        case 'INIT_COUNTRIES':
            return {
                ...state,
                selectedCountries: action.countries,
            }
        case 'DELETE_COUNTRY':
            return {
                ...state,
                selectedCountries: state.selectedCountries.filter((tag, index) => index !== action.i)

            }
        case 'CLICK_MAIN': {

            return {
                ...state,
                showMainOptions: true
            }
        }
        case 'CLICK_SUB': {

            return {
                ...state,
                showSubOptions: true
            }
        }
        case 'CLICK_UNIT': {

            return {
                ...state,
                showUnitOptions: true
            }
        }
        case 'SET_GCP_PREFIX': {
            let p = {...state.product};
            p.gcp_prefix = action.prefix;
            p.g_prefix = action.prefix;
            p.barcode = action.prefix;
            return {
                ...state,
                product: p

            }
        }
        default:
            return state;
    }
};

export const getReducer = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useReducer(reducer, initialState);
};