/**
 * Created by gantushig on 11/6/15.
 */

import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    createInvoicesBulk,
    fetchAllInvoices,
    removeAllInvoicesBulk,
    printInvoice, sendSms
} from "./actions/actions";
import qs from "query-string";
import {ActionTypes, Endpoints, EndpointsExpress, Page, TOKEN} from "./Constants";
import ReactPaginate from "react-paginate";
import classnames from "classnames";
import history from "./history";
import FileDownload from "js-file-download";
import axios from "./axiosConfig";
import {toast} from "react-toastify";


const initialState = {
    invoices: [],
    loading: false,
};

const reducer = (state, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_INVOICES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_INVOICES_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: action.invoices,
            }
        default:
            return state;
    }
};

const InvoicesPage = ({
                          location: {search}
                      }) => {
    //   const [state, dispatch] = useReducer(reducer, initialState);
//    let {invoices, loading} = state


    const [name, setName] = useState("");
    const [month, setMonth] = useState("");
    const [label, setLabel] = useState("");
    const [gcp, setGcp] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [showModal, setShowModal] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [currId, setCurrId] = React.useState(0);


    const reduxDispatch = useDispatch();
    let token = localStorage.getItem(TOKEN) || null;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let order = parsed[Page.ORDER];
    let pagesize = parsed[Page.PAGESIZE];
    let asc = parsed[Page.ASC];
    let qname = parsed["n"];
    let qlabel = parsed["l"];
    let qmonth = parsed["m"];
    let qgcp = parsed["g"];

    if (page === undefined) {
        page = "0";
    }
    if (order === undefined) {
        order = "id";
    }
    if (asc === undefined) {
        asc = "true";
    }
    if (pagesize === undefined) {
        pagesize = "25";
    }
    if (qname === undefined) {
        qname = "";
    }
    if (qlabel === undefined) {
        qlabel = "";
    }
    if (qmonth === undefined) {
        qmonth = "0";
    }
    if (qgcp === undefined) {
        qgcp = "";
    }

    const stInvoices = useSelector(state => state.allInvoices);
    let invoices = stInvoices.invoices;

    useEffect(() => {

        console.log('useEffect called');
        reduxDispatch(fetchAllInvoices(token, qname, qlabel, qmonth, qgcp,
            page, pagesize, order, asc
            ));
    }, [qname, qlabel, qmonth, qgcp, page, pagesize, order, asc]);

    let totalPages = 0;
    if (stInvoices.meta != null) {
        totalPages = stInvoices.meta.totalPages;
    }


    let modalClass = classnames({
        modal: true,
        'is-active': isOpen
    });

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed))
        //dispatch(invoicesPageSelected(data.selected));
    };

    const handleSearch = (e) => {
        console.log('search invoice');

        e.preventDefault();
        let parsed = qs.parse(search);
        parsed["n"] = name;
        parsed["g"] = gcp;
        parsed["l"] = label;
        parsed["m"] = month;
        parsed[Page.PAGE] = "0";
        history.push("?" + qs.stringify(parsed))
    };

    const handlePrintOneInvoice = (id) => {
        console.log('print one invoice');
        reduxDispatch(printInvoice(token, id));
    };

    const handleRemove = () => {
        console.log('delete');
        setIsOpen(true)
    };
    const handleChangeName = (e) => {
        setName(e.target.value);
    };
    const handleChangeMonth = (e) => {
        setMonth(e.target.value);
    };
    const handleChangeLabel = (e) => {
        setLabel(e.target.value);
    };
    const handleChangeGcp = (e) => {
        setGcp(e.target.value);
    };

    const handleCreate = () => {
        console.log('Create Invoice');
        let dto = {
            month
        };
        reduxDispatch(createInvoicesBulk(token, dto));
    };

    const handleDeleteYes = () => {
        console.log('remove invoice');
        reduxDispatch(removeAllInvoicesBulk(token));
        setIsOpen(false)

    }
    const handleDeleteCancel = () => {
        setIsOpen(false)
    }

    const handleOrderClick = (f) => {
        let parsed = qs.parse(search);
        if (parsed[Page.ORDER] === f) {
            let o = parsed[Page.ASC];
            if (o === "true") {
                o = "false";
            } else {
                o = "true";
            }
            parsed[Page.ASC] = o;
        } else {
            parsed[Page.ORDER] = f;
        }


        history.push("?" + qs.stringify(parsed))

    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    };

    const handleSendMail = () => {
        //e.preventDefault();
        setShowModal(false);
        console.log('useEffect called');
        let info = {}
        info.status = 'SEND'
        info.email = email;

        async function sendMail() {
            let header = {}
            header["Authorization"] = token
            header["Accept"] = "application/json"
            header["Content-Type"] = "application/json"

            let config = {}
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.INVOICE_ADMIN}/${currId}/send_mail`;
            config["method"] = "post";
            config["data"] = JSON.stringify(info);
            let response = await axios.instance(config);


            //toast.success("Email Sent");
        }

        sendMail(  );
        // toast.promise(p, {
        //     success: "Everything went smoothly.",
        //     error: "Uh oh, there was an error!",
        // });
    }

    const handleOpenDialog = (email, id) => {
        setEmail(email)
        setCurrId(id)
        setShowModal(true)
    }

    let c = 0;
    const items = invoices.map(
        (item) => {
            c++;
            let c1 = page * pagesize + c;

            return (
                <tr key={item.id}>
                    <td>{c1}</td>
                    <td><Link to={`/company/${item.company_id}`}>
                        {item.company_name}
                        </Link>
                    </td>
                    <td>{item.company_phone}</td>
                    <td>{item.company_email}</td>
                    <td>{item.gcp}           </td>
                    <td><Link to={`/company/${item.company_id}/gcp/${item.gcp_id}/invoice/${item.id}`}>
                        {item.label}</Link>
                    </td>
                    <td>{item.total_amount.toLocaleString()+'₮'}</td>
                    <td>
                        <button className="bg-green-500 rounded-md border p-2 m-2 text-white"
                                onClick={() => handleOpenDialog(item.company_email, item.id)}>Send
                        </button>
                        <button className="bg-green-500 rounded-md border p-2 m-2 text-white" onClick={()=>handlePrintOneInvoice(item.id)}>
                            Print
                        </button>
                    </td>
                </tr>
            )
        });

    return (
        <div style={{background: 'white', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}>

            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Нэр</label>
                </div>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={name} style={{width: '400px'}}
                               onChange={(e) => handleChangeName(e)} placeholder="Нэр Оруулах"/>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Сар</label>
                </div>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={month} style={{width: '400px'}}
                               onChange={(e) => handleChangeMonth(e)} placeholder="Сар Оруулах"/>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Жил</label>
                </div>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={label} style={{width: '400px'}}
                               onChange={(e) => handleChangeLabel(e)} placeholder="Жил Оруулах"/>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Gcp</label>
                </div>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={gcp} style={{width: '400px'}}
                               onChange={(e) => handleChangeGcp(e)} placeholder=" Gcp Оруулах"/>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label">

                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="buttons">
                            <button onClick={(e) => handleSearch(e)} className="button is-primary">
                                Хайх
                            </button>
                            <Link className="underline text-blue-500 " to={'/sent-emails'} >Явуулсан мэйлууд</Link>
                        </div>
                    </div>
                </div>
            </div>

            <br/><br/>

            <div className="field is-horizontal">
                <div class="field-label is-normal">
                    <label className="label">Сар</label>
                </div>
                <div class="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={month} style={{width: '400px'}}
                               onChange={(e) => handleChangeMonth(e)} placeholder="Оруулах"/>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label">

                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="buttons">
                            <button onClick={(e) => handleCreate(e)} className="button is-primary">
                                Үүсгэх
                            </button>
                            {/*<button onClick={(e) => handlePrint(e)} className="button is-info">*/}
                            {/*    Print (All)*/}
                            {/*</button>*/}
                            <button onClick={(e) => handleRemove(e)} className="button is-danger">
                                Устгах (All)
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <br/><br/>

            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th><a onClick={() => handleOrderClick("id")}>#</a></th>
                    <th><a onClick={() => handleOrderClick("name")}>Компани</a></th>
                    <th>Утас</th>
                    <th>Email</th>
                    <th><a onClick={() => handleOrderClick("gcp")}>GCP</a></th>
                    <th><a onClick={() => handleOrderClick("label")}>Утга</a></th>
                    <th>Дүн</th>
                    <th>-</th>
                </tr>
                </thead>
                <tbody>
                {items}
                <tr>
                    <td className="font-bold text-xl" colspan="6">Нийт дүн</td>
                    <td className="font-bold text-xl">{stInvoices.total_amount!==null?stInvoices.total_amount.toLocaleString()+'₮':"0.0"}</td>
                    <td></td>
                </tr>
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={page}
                               breakLabel={<a href="">...</a>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                />
            </nav>
            <br/><br/>
            <div className={modalClass}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Устгах?</p>
                        <button className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        Та устгахдаа итгэлтэй байна уу?
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={handleDeleteYes}>Тийм</button>
                        <button className="button" onClick={handleDeleteCancel}>Үгүй</button>
                    </footer>
                </div>
            </div>

            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative my-2 mx-auto w-1/3 max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                <div className="relative p-2 flex-auto">
                                    <input type="text" className="form-control block w-full px-2 py-2         text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"  value={email}
                                           onChange={(e) => handleChangeEmail(e)} placeholder="Email оруулах"/>

                                </div>
                                {/*footer*/}
                                <div
                                    className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Хаах
                                    </button>
                                    <button
                                        className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => handleSendMail()}
                                    >
                                        Илгээх
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

        </div>
    )

}


export default InvoicesPage


