/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useState} from "react";
import {connect} from "react-redux";
import { changeAdminUserPassword} from "./actions/actions";
import {Redirect} from "react-router-dom";


const AdminUserPasswordChange = ({token, userId,  change, isUpdating}) => {
    const [pwd, setPwd] = useState("");
    const [cancelClicked, setCancelClicked] = useState(false);
    const [sendClicked, setSendClicked] = useState(false);


    const handleChangePwd = (e) => {
        setPwd(e.target.value)
    };

    const handleSave = () => {
        console.log('save');
        let dto = {
            pwd
        };
        change(token, userId, dto)
        setSendClicked(true)
    };
    const handleCancel = () => {
        console.log('cancel');
        setCancelClicked(true)

    };

    return (
    <div style={{background: 'white'}}>
        <div className="level">
            <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                <h1 className="title is-size-4  has-text-info">Admin User add</h1>
            </div>
        </div>



        <div style={{background: 'white'}}>

            <div className="field">
                <div className="control has-icons-left">
                    <input className="input" type="text" value={pwd}
                           onChange={(e) => handleChangePwd(e)} placeholder="Password оруулах"/>
                </div>
            </div>

            <div className="buttons" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                <button className="button is-primary" type="primary" onClick={() => handleSave()}>Хадгалах</button>
                <button className="button is-primary" type="primary" onClick={() => handleCancel()}>Болих</button>
            </div>
        </div>
    </div>

    )

}


const mapStateToProps = (state, ownProps) => {
    const userId = ownProps.match.params.id
    let isUpdating = state.ui.isUpdating
    let token = localStorage.getItem('id_token') || null;
    return {
        token,
        userId,
        isUpdating
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

        change: (token, userId, info) => {
            dispatch(changeAdminUserPassword(token, userId, info))
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AdminUserPasswordChange)