const NullUtils = (value) => {
    if (
        value === "" ||
        value === 0 ||
        value === "NullUtils" ||
        value === false ||
        value === NaN ||
        value === null ||
        value === undefined ||
        value.length === 0 ||
        value === -1 ||
        value === ""
    ) {
        return true;
    } else {
        return false;
    }
};

export default NullUtils;
