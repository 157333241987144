/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useReducer, useState} from "react";

import 'react-quill/dist/quill.snow.css';
import {connect, useDispatch, useSelector} from "react-redux";
import {
    fetchSmsTemplate, updateSmsTemplate, fetchProductNew,
    resetCatInputTapped, resetErrors, resetProductUpdateStatus, fetchInvoiceMailTemplate, updateInvoiceMailTemplate
} from "./actions/actions";
import {toast} from "react-toastify";
import {ActionTypes, GLN, GTIN, SSCC, TOKEN} from "./Constants"
import history from "./history";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    mail:"",
    name_type:"SMS_INVOICE_PAID",
    sms:"",
    subject:"",
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_SMS_TEMPLATE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_SMS_TEMPLATE_SUCCESS:
            return {
                ...state,
                sms: action.sms,
                loading: false
            }
        case ActionTypes.UPDATE_SMS_TEMPLATE_REQUEST:{
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.UPDATE_SMS_TEMPLATE_SUCCESS: {

            return {
                ...state,
                loading: false
            }
        }
        case 'CHANGE_NAME_TYPE':
            return {
                ...state,
                name_type: action.current
            }
        case 'CHANGE_SMS':
            return {
                ...state,
                sms: action.sms
            }
        default:
            return state;
    }
};

const changeNameType = (current) => {
    return {
        type: 'CHANGE_NAME_TYPE',
        current
    }
}






const SmsTemplateEdit = ({match: {params: {id}}}) => {
    const [value, setValue] = useState('');
    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {name_type, sms, loading} = state;


    useEffect(() => {
            fetchSmsTemplate(dispatch, name_type)
    }, [name_type]);


    const nameTypeOptions = () => {
        let items = [];


        items.push(<option key={'SMS_INVOICE_PAID'} value={'SMS_INVOICE_PAID'}>Нэхэмжлэх төлсөн</option>);
        items.push(<option key={'SMS_FILE_READY'} value={'SMS_FILE_READY'}>Баркод файл бэлэн</option>);

        return items;
    };
    const handleChangeCurrentNameType = (e) => {
        dispatch(changeNameType(e.target.value))
    }

    const handleChangeSms = (e) => {
        dispatch({ type: 'CHANGE_SMS', sms: e.target.value });
    };

    const handleSave = (
    ) => {

        let dto = {}

        dto.sms= sms;


        updateSmsTemplate(dispatch,  name_type, dto).then(response => {
            toast.success("Амжилттай хадгаллаа!");

                //history.push(`/product/${productId}`)
            }
        ).catch(error => {
            alert(error)
        });

    };


    return (
        <div className="inner-container">

            <div className="level">
                <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                    <h1 className="title is-size-4  has-text-info">Sms template</h1>
                </div>
            </div>
            <hr/>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <div className="field">
                <label className="label">Name types</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select name="currentNameType" value={name_type} onChange={handleChangeCurrentNameType}>
                            <option key='0' value="0"></option>
                            {nameTypeOptions()}
                        </select>
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">Sms</label>

                <input className="border-gray-500 p-3 border w-full" name="sms"  type="text" value={sms}
                       onChange={handleChangeSms} placeholder="Enter the subject"/>

            </div>
            <button className="bg-green-500 text-white p-3 mt-3" type="primary"
                    onClick={() => handleSave()}>
                Хадгалах
            </button>
        </div>



    )

}


export default SmsTemplateEdit