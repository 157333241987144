/**
 * Created by gantushig on 11/6/15.
 */

import {Link, Route, Switch, useLocation, useParams, useRouteMatch} from "react-router-dom";
import classnames from "classnames";
import React, {useEffect, useReducer, useState} from "react";
import {confirm} from "react-confirm-box";
import GcpEditMenu from "./GcpEditMenu"
import {ActionTypes, TOKEN} from './Constants';
import dayjs from "dayjs";
import history from "./history";
import {css} from "@emotion/react";
import dayjsPluginUTC from "dayjs-plugin-utc";
import {toast} from "react-toastify";
import {createNote, fetchGcps, removeGcp, removeNote} from "./actions/actions";
import GcpCreate from "./GcpCreate";
import GcpView from "./GcpView";
import GcpEdit from "./GcpEdit";
import {FaTimes} from 'react-icons/fa';
import gcpStatusData from "./data/gcp-status.json";

dayjs.extend(dayjsPluginUTC)


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    gcps: [],
    notes:[],
    loading: false,
    refresh: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_GCPS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FETCH_GCPS_SUCCESS:
            return {
                ...state,
                gcps: action.gcps,
                notes: action.notes,
                loading: false
            }
        case 'REFRESH':
            return {
                ...state,
                refresh: !state.refresh
            }
        default:
            return state;
    }
};


const refreshPage = () => {
    return {
        type: "REFRESH",
    }
}

// {
//     match: {params: {companyId}}
// }
const CompanyGcps = () => {
    let {companyId} = useParams();
    let {path, url} = useRouteMatch();
    const {pathname} = useLocation();
    const [showModal, setShowModal] = React.useState(false);
    const [note, setNote] = React.useState('');

//    const [editClicked, setEditClicked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [currGcpId, setCurrGcpId] = useState(0);

    const token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        gcps,
        notes,
        loading,
        refresh
    } = state;

    useEffect(() => {
        console.log('useEffect called');
        fetchGcps(dispatch, companyId);
    }, [companyId, refresh]);


    let modalClass = classnames({
        modal: true,
        'is-active': isOpen
    });

    const handleEdit = (e, id) => {
        e.preventDefault();
        console.log('edit');
        history.push(`/company/${companyId}/gcps/${id}/edit`)
    };

    const handleDelete = (e, id) => {
        e.preventDefault();
        console.log('delete');
        setIsOpen(true)
        setCurrGcpId(id)

    };


    const optionsWithLabelChange = {
        closeOnOverlayClick: false,
        labels: {
            confirmable: "Устгах",
            cancellable: "Болих"
        }
    };

    const deleteNote = async (noteId, options) => {
        const result = await confirm("Тэмдэглэл устгах уу?", options);
        if (result) {
            console.log("You click yes!");
            removeNote(dispatch, noteId).then(response => {
                    toast.success("Амжилттай !");
                    //onUpdate()
                    dispatch(refreshPage());
                }
            ).catch(error => {
                alert(error)
            });
        }
        console.log("You click No!");
    };

    const deleteGcp = async (e, id) => {
        const result = await confirm("Gcp устгах уу?", optionsWithLabelChange);
        if (result) {
            console.log("You click yes delete Gcp!");
            removeGcp(dispatch, id).then(response => {
                    toast.success("Амжилттай !");
                    dispatch(refreshPage());
                }
            ).catch(error => {
                alert(error)
            });
        }
        console.log("You click No!");
    };
    const handleDeleteCodeYes = () => {
        setIsOpen(false)
        removeGcp(dispatch, currGcpId).then(response => {
                toast.success("Амжилттай !");
                dispatch(refreshPage());
            }
        ).catch(error => {
            alert(error)
        });
//        reduxDispatch(removeGcp(token, currGcpId))

    }
    const handleDeleteCodeCancel = () => {
        setIsOpen(false)
    }

    const handleUpdate = () => {
        dispatch(refreshPage());
    }

    const barcodeTypeLabel = (g) => {
        let ret = ""
        if (g === 0) {
            ret = "GTIN"
        } else if (g === 1) {
            ret = "GLN"
        } else if (g === 2) {
            ret = "SSCC"
        }

        return ret
    }
    let counter = 0
    // for (let key in gcps) {
    //     let item = gcps[key]
    //     counter ++
    //     item.order = counter
    // }

    // const gcp_ids_by_order =
    //     Object.values(gcps)
    //         .reduce((ordered_ids, gcp) => {
    //             ordered_ids[gcp.order] = gcp.id
    //             return ordered_ids
    //         }, []);
    const statusLabel = (g) => {
        let ret = ""
        let ra = gcpStatusData.filter(b=>b.id === g);
        if (ra.length>0) {
            ret = ra[0].name;
        }
        return ret
    };
    const handleChangeNote = (e) => {
        setNote(e.target.value)
    };
    const handleSaveNote = () => {
        //e.preventDefault();
        setShowModal(false);
//        setIsNoteCreateOpen(true)

        let dto = {
            note:note,
            note_type:"GCP"
        };
        createNote(dispatch, companyId, dto).then(response => {
                toast.success("Амжилттай !");
                setNote("");
                //onUpdate()
                dispatch(refreshPage());
            }
        ).catch(error => {
            alert(error)
        });
    }

    const items = gcps.map(
        (item) => {
            counter++;
            return (
                <tr key={item.id}>
                    <td>
                        {counter}
                    </td>
                    <td>
                        {barcodeTypeLabel(item.barcode_type)}
                    </td>
                    <td>
                        <Link to={`${url}/${item.id}`}>
                            {item.prefix}
                        </Link>
                    </td>
                    <td>{"GCP" + item.size}</td>
                    <td>{(item.issued === null) ? "" : dayjs.utc(item.issued).format("YYYY-MM-DD")}</td>
                    <td>{statusLabel(item.status)}</td>  {/*{(item.closed) ? "ХААСАН" : ''}</td>*/}
                    <td>
                        <Link to={`/company/${companyId}/gcp/${item.id}/${item.prefix}/${item.size}/product-create`}>
                            Үүсгэх
                        </Link>
                    </td>

                    <td>
                        <Link to={`/company/${companyId}/gcp/${item.id}/barcodes`}>
                            Файлууд
                        </Link>
                    </td>
                    <td><Link to={`/company/${companyId}/gcp/${item.id}/payments`}>Харах</Link></td>
                    <td><Link to={`/company/${companyId}/gcp/${item.id}/invoices`}>Харах</Link></td>
                    <td>
                        <GcpEditMenu id={item.id} onEdit={handleEdit} onDelete={deleteGcp}/>
                    </td>
                </tr>
            )
        });


    const noteItems = notes.map(
        (p) => {
            return (
                <tr key={p.id}>
                    <td className="note-item-container">
                        <div className="note-element-container">{p.note}
                            <span><small>{dayjs(p.created).format("MM/DD/YYYY HH:mm")}</small></span>
                        </div>
                        <button
                            onClick={() => {
                                deleteNote(p.id, optionsWithLabelChange);
                            }}
                        >
            {" "}
                            <FaTimes/>{" "}
          </button>
                    </td>
                </tr>
            )
        });

    return (
        <>

            {/*<CompanyViewHeader tab={2} companyId={companyId}/>*/}
            <Switch>
                <Route exact path={path}>
                    <div className="buttons is-right">
                        <button
                            className="bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => setShowModal(true)}
                        >
                            Шинээр Тэмдэглэл Үүсгэх
                        </button>


                        <Link to={`${url}/create`} className="button">
                            Үүсгэх
                        </Link>
                    </div>
                    {/*<ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>*/}
                    <table className="table is-fullwidth">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Төрөл</th>
                            <th>GCP</th>
                            <th>GCP Төрөл</th>
                            <th>Олгосон</th>
                            <th>Төлөв</th>
                            <th>Бүтээгдэхүүн</th>
                            <th>Баркод</th>
                            <th>Төлбөр</th>
                            <th>Нэхэмжлэх</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {items}
                        </tbody>
                    </table>

                    {noteItems.length > 0 &&
                    <>
                        <h1 style={{marginBottom: "20px"}}>Тэмдэглэлүүд</h1>
                        <table className="table is-bordered is-fullwidth">
                            <tbody>
                            {noteItems}
                            </tbody>
                        </table>
                    </>
                    }
                    <div className={modalClass}>
                        <div className="modal-background"></div>
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title">Устгах?</p>
                                <button className="delete" aria-label="close"></button>
                            </header>
                            <section className="modal-card-body">
                                Та устгахдаа итгэлтэй байна уу?
                            </section>
                            <footer className="modal-card-foot">
                                <button className="button is-danger" onClick={handleDeleteCodeYes}>Тийм</button>
                                <button className="button" onClick={handleDeleteCodeCancel}>Үгүй</button>
                            </footer>
                        </div>
                    </div>
                </Route>
                <Route path={`${path}/create`}>
                    <GcpCreate onUpdate={handleUpdate}/>
                </Route>
                <Route exact path={`${path}/:gcpId`}>
                    <GcpView/>
                </Route>
                <Route path={`${path}/:gcpId/edit`}>
                    <GcpEdit onUpdate={handleUpdate}/>
                </Route>
            </Switch>


            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative my-2 mx-auto w-1/3 max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                <div className="relative p-2 flex-auto">
                                    <textarea className="form-control block w-full px-2 py-2         text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" rows="3" value={note}
                                              onChange={(e) => handleChangeNote(e)} placeholder="Тэмдэглэл оруулах"/>

                                </div>
                                {/*footer*/}
                                <div
                                    className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Хаах
                                    </button>
                                    <button
                                        className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => handleSaveNote()}
                                    >
                                        Хадгалах
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

        </>
    )
}

export default CompanyGcps;

