/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {connect} from "react-redux";
import React, {Component, useEffect, useState} from "react";
import {fetchFees, updateFee} from "./actions/actions";
import gcpOptionsData from "./data/gcps.json";


const FeeConfig = ({token, fees, loadFees, fee_sizes_by_size,  edit}) => {
    const [barcodeType, setBarcodeType] = useState(0);
    const [size, setSize] = useState(11);
    const [id, setId] = useState(0);
    const [membership, setMembership] = useState("0");
    const [annual, setAnnual] = useState("0");

    useEffect(() => {
        console.log('useEffect called');
        loadFees(token)
    }, []);

    useEffect(() => {
        console.log('useEffect 2');
        if (Object.keys(fees).length > 0) {
            let fs = Object.values(fees).filter(p => {
                return (p.size === size && p.barcode_type === barcodeType)
            })
            if (fs.length > 0) {
                setMembership(fs[0].membership);
                setAnnual(fs[0].annual);
            } else {
                setMembership(0);
                setAnnual(0);

            }
        }
    }, [barcodeType, size]);

    const handleChangeBarcodeType = (e) => {
        setBarcodeType(Number(e.target.value))
    };

    const handleChangeSize = (e) => {
        setSize(Number(e.target.value))
    };


    const handleChangeFee = (e) => {
        let v = Number(e.target.value);
        setId(v);

        setMembership(fees[v].membership);
        setAnnual(fees[v].annual);

    };


    const handleChangeMembership = (e) => {
        setMembership(e.target.value)
    };
    const handleChangeAnnual = (e) => {
        setAnnual(e.target.value)
    };

    const handleSave = () => {
        let dto = {
            barcode_type:barcodeType,
            size:size,
            membership,
            annual
        };
        edit(token, dto)
    };



    const handleCancel = () => {

    };

    const gcpOptions = () => {
        let items = [];
        gcpOptionsData.map(item=>{
            return (items.push(<option key={item.id} value={item.id}>{item.name}</option>));
        })
        return items;
    };

    return (
        <div style={{background: 'white', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}>
            <div className="field">
                <label className="label">Баркод төрөл</label>
                <div className="control">
                    <label className="radio">
                        <input type="radio" value="0" checked={barcodeType === 0}
                               onChange={(e) => handleChangeBarcodeType(e)}/>
                        &nbsp;&nbsp;GTIN
                    </label>
                    <label className="radio">
                        <input type="radio" value="1" checked={barcodeType === 1}
                               onChange={(e) => handleChangeBarcodeType(e)}/>
                        &nbsp;&nbsp;GLN
                    </label>
                    <label className="radio">
                        <input type="radio" value="2" checked={barcodeType === 2}
                               onChange={(e) => handleChangeBarcodeType(e)}/>
                        &nbsp;&nbsp;SSCC
                    </label>
                </div>
            </div>

            <div className="field">
                <label className="label">Gcp төрөл</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select value={size} onChange={(e) => handleChangeSize(e)}>
                            {gcpOptions()}
                            {/*<option value="6">GCP6</option>*/}
                            {/*<option value="7">GCP7</option>*/}
                            {/*<option value="8">GCP8</option>*/}
                            {/*<option value="9">GCP9</option>*/}
                            {/*<option value="10">GCP10</option>*/}
                            {/*<option value="11">GCP11</option>*/}
                        </select>
                        {/*<select value={id} onChange={(e) => handleChangeFee(e)}>*/}
                        {/*    {fee_sizes_by_size.map(id => {*/}
                        {/*            return (*/}
                        {/*                <option key={id} value={id}>GCP{fees[id].size}</option>*/}
                        {/*            )*/}
                        {/*        })*/}
                        {/*    }*/}
                        {/*</select>*/}
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">ЭЛСЭЛТИЙН ХУРААМЖ</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={membership}
                           onChange={(e) => handleChangeMembership(e)} placeholder="Оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">ГИШҮҮНИЙ ТАТВАР</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={annual}
                           onChange={(e) => handleChangeAnnual(e)} placeholder="Оруулах"/>
                </div>
            </div>
            <div className="buttons" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                <button className="button is-primary" type="primary" onClick={() => handleSave()}>Хадгалах</button>
                <button className="button is-primary" type="primary" onClick={() => handleCancel()}>Болих</button>
            </div>


        </div>
    )

}


const mapStateToProps = (state, ownProps) => {
    const status = ownProps.match.params.status
    const fees = state.fees.fees

    const fee_sizes_by_size =
        Object.values(fees)
            .reduce((ordered_ids, fee) => {
                ordered_ids[fee.size] = fee.id
                return ordered_ids
            }, []);


    let token = localStorage.getItem('id_token') || null;
    return {
        token,
        fees,
        status,
        fee_sizes_by_size
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        loadFees: (token) => {
            dispatch(fetchFees(token))
        },
        edit: (token, info) => {
            dispatch(updateFee(token, info))
        }

    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FeeConfig)

