/**
 * Created by gantushig on 4/16/17.
 */

import React, {useReducer} from "react";
import {useParams} from "react-router-dom";
import GcpEditComponent from "./GcpEditComponent"
import {toast} from 'react-toastify';
import dayjs from "dayjs"
import {TOKEN} from "./Constants";
import history from "./history";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import {createGcp} from "./actions/actions";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    gcp: {prefix:"865", size:"11", barcode_type:"0", issued:new Date()},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_FIELD': {
            let c = {...state.gcp};
            c[action.name] = action.value
            return {
                ...state,
                gcp: c,
                loading: false
            }
        }
        default:
            return state;
    }
};




const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}

// {
//     match: {params: {id, gcpId}}
//
// }
const GcpCreate = ({onUpdate}) => {

    const {companyId, gcpId} = useParams();
    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        gcp,
        loading
    } = state;

    const handleChangeCheckField = (e) => {
        dispatch(changeField(e.target.name, e.target.checked))
    }
    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }
    const handleChangeIssued = (date) => {
        dispatch(changeField("issued", date))

    };
    const handleSave = () => {
        let issued_str = null;
        if (gcp.issued !== null) {
            gcp.issued = gcp.issued.getTime()
            issued_str = dayjs(gcp.issued).format('YYYY-MM-DD');
        }
        let dto = {
            barcode_type:gcp.barcode_type,
            size:gcp.size,
            prefix:gcp.prefix,
            issued:gcp.issued,
            issued_str,
            status:gcp.status
        };
        createGcp(dispatch, token, companyId,  dto).then(response => {
                toast.success("GCP Амжилттай үүсгэлээ!");
                onUpdate();
                history.push(`/company/${companyId}/gcps`)
            }
        ).catch(error => {
            alert(error)
        });
    };
    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
    };

    return (
        <>
            {/*<CompanyViewHeader tab={2} companyId={id}/>*/}

            <div className="level">
                <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                    <h1 className="title is-size-4  has-text-info">Дугаар үүсгэх</h1>
                </div>
            </div>
            <hr/>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <GcpEditComponent gcp={gcp}
                onChangeField={handleChangeField} onChangeCheckField={handleChangeCheckField} onChangeIssued={handleChangeIssued}
                onSave={handleSave} onCancel={handleCancel}/>
        </>
    )

}

export default GcpCreate;