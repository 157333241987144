/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useReducer, useState} from "react";
import {
    failure,
    fetchGcpsAll,
    fetchHqBatch,
    fetchHqBatches,
    fetchHqProducts,
    getVerifiedCount, getVerifiedGtinCount,
    hqSendVerifiedGtins,
    menuClicked,
    menuClickedTitle, verifyProduct
} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, Endpoints, EndpointsExpress, Menu, Page, PageTitle, TOKEN} from "./Constants";
import history from "./history";
import axios from "axios";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import gcpOptionsData from "./data/gcps.json"
import gcpStatusData from "./data/gcp-status.json"
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";

dayjs.extend(dayjsPluginUTC)


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const initialState = {
    count: 0,
    loading: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_VERIFIED_GTIN_COUNT_REQUEST:
            return {
                ...state,
                loading:true
            }
        case ActionTypes.GET_VERIFIED_GTIN_COUNT_SUCCESS:
            return {
                ...state,
                count: action.count,
                loading:false
            }

        default:
            return state;
    }
};


const HqGtinSend = () => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {count, loading   } = state;


    useEffect(() => {
            getVerifiedGtinCount(dispatch);
    }, []);



    const handleSend = (e) => {
            confirmAlert({
                title: 'Alert',
                message: 'Илгээх?.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            hqSendVerifiedGtins(dispatch).then(response => {
                                toast.success("Success"); //history.push(`/products`)
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    },
                ]
            });

    };


    return (

        <div className="inner-container">
            Verified: {count}

            <br/>
            <button onClick={handleSend} className="button bg-orange">
                <span style={{color: "white"}}>Send</span>
            </button>


        </div>
    )

}



export default HqGtinSend

