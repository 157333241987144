/**
 * Created by gantushig on 11/6/15.
 */

import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {
    fetchInvoiceMailItems
} from "./actions/actions";
import qs from "query-string";
import {ActionTypes, Page, TOKEN} from "./Constants";
import ReactPaginate from "react-paginate";
import classnames from "classnames";
import history from "./history";
import {toast} from "react-toastify";


const initialState = {
    mails: [],
    loading: false,
    totalPages:0,
    totalElements:0,
};

const reducer = (state, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_INVOICE_MAIL_ITEMS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_INVOICE_MAIL_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                mails: action.items,
                totalElements: action.meta.totalElements,
                totalPages: action.meta.totalPages,
            }
        default:
            return state;
    }
};

const InvoicesMailItemsPage = ({
                          match: {params: {id}}, location: {search}
                      }) => {
    const [isOpen, setIsOpen] = useState(false);


    let token = localStorage.getItem(TOKEN) || null;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let order = parsed[Page.ORDER];
    let pagesize = parsed[Page.PAGESIZE];
    let asc = parsed[Page.ASC];

    if (page === undefined) {
        page = "0";
    }
    if (order === undefined) {
        order = "id";
    }
    if (asc === undefined) {
        asc = "true";
    }
    if (pagesize === undefined) {
        pagesize = "25";
    }

    const [state, dispatch] = useReducer(reducer, initialState);
    const {mails,
    } = state;

    useEffect(() => {

        console.log('useEffect called');
        fetchInvoiceMailItems(dispatch, token, id, page, pagesize, order, asc            );
    }, [id, page, pagesize, order, asc]);

    let totalPages = 0;

    let modalClass = classnames({
        modal: true,
        'is-active': isOpen
    });

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed))
        //dispatch(invoicesPageSelected(data.selected));
    };

    const handleSearch = (e) => {
        console.log('search invoice');

        e.preventDefault();
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = "0";
        history.push("?" + qs.stringify(parsed))
    };


    const handleRemove = () => {
        console.log('delete');
        setIsOpen(true)
    };

    // const handleSend = () => {
    //     console.log('Send mails');
    //     // let dto = {
    //     //     month
    //     // };
    //     sendInvoiceMails(dispatch, token, id);
    //     toast.success("Илгээлээ!");
    // };

    const handleDeleteYes = () => {
        console.log('remove invoice');
//        dispatch(removeAllInvoicesBulk(token));
        setIsOpen(false)

    }
    const handleDeleteCancel = () => {
        setIsOpen(false)
    }

    const handleOrderClick = (f) => {
        let parsed = qs.parse(search);
        if (parsed[Page.ORDER] === f) {
            let o = parsed[Page.ASC];
            if (o === "true") {
                o = "false";
            } else {
                o = "true";
            }
            parsed[Page.ASC] = o;
        } else {
            parsed[Page.ORDER] = f;
        }


        history.push("?" + qs.stringify(parsed))
        //dispatch(setOrderInvoicesSearch(f))
    }
    let counter = Number(page) * Number(pagesize)
    const items = mails.map(
        (mail) => {
                   counter++;
           return (
                <tr key={mail.id}>
                    <td>{counter}</td>
                    <td>
                        <Link to={`/company/${mail.company_id}`}>
                            {mail.company_name}
                        </Link>

                    </td>
                    <td>{mail.name}</td>
                    <td>{mail.surname}</td>
                    <td>{mail.email}</td>
                    <td>{mail.status1}</td>
                </tr>
            )
        });

    return (
        <div className="pt-2 pr-2 pl-2 bg-white">

            {/*<div className="m-2">*/}
            {/*                <button onClick={(e) => handleSend(e)} className="bg-blue-500 p-3 text-white">*/}
            {/*                    Send Mails*/}
            {/*                </button>*/}
            {/*</div>*/}


            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Компани</th>
                    <th>Нэр</th>
                    <th>Овог</th>
                    <th>Email</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={page}
                               breakLabel={<a href="">...</a>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                />
            </nav>
            <br/><br/>
            <div className={modalClass}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Устгах?</p>
                        <button className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        Та устгахдаа итгэлтэй байна уу?
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={handleDeleteYes}>Тийм</button>
                        <button className="button" onClick={handleDeleteCancel}>Үгүй</button>
                    </footer>
                </div>
            </div>

        </div>
    )

}


export default InvoicesMailItemsPage


