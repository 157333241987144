/**
 * Created by gantushig on 1/16/21.
 */

import React, {useState} from "react";
import {toast} from "react-toastify";
import history from "./history";
import {EndpointsExpress, TOKEN} from "./Constants";
import   axios from './axiosConfig'
import {useParams} from "react-router-dom";

const ProductImageRequestNoteCreate = () => {
    const {applId} = useParams();

    const [note, setNote] = useState("");


    const handleChangeNote = (e) => {
        setNote(e.target.value)
    };

    const handleSend = () => {
        async function deny(id) {

            let dto = {
                note:note
            };

            let header = {}

            header["Accept"] = "application/json"
            header["Content-Type"] = "application/json"

            let config = {}
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.PRODUCT_IMAGE_ADMIN}/${id}/deny`;
            config["method"] = "post";
            config["data"] = JSON.stringify(dto);
            try {
                const response = await axios.instance(config);
            } catch(e) {
                alert(e)
            }
        }
        deny(applId);
        toast.success("Хүсэлтийг буцаалаа!");
        history.push("/prod-image-appls")

        // console.log('save');
        // let dto = {
        //      note:note
        // };
        //
        // dispatch(denyProductImageApplication(token, applId, dto)).then(response => {
        //         toast.success("Хүсэлтийг буцаалаа!");
        //         history.push("/prod-image-appls")
        //     }
        // ).catch(error => {
        //     alert(error)
        // })
    };
    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
    };
    return (
        <div style={{background: 'white'}}>
            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Тэмдэглэл оруулах</h1>
                </div>
            </div>


            <div style={{background: 'white'}}>

                <div className="field">

                    <div className="control has-icons-left">
                    <textarea className="textarea" type="text" value={note}
                              onChange={(e) => handleChangeNote(e)} placeholder="Тэмдэглэл оруулах"/>
                    </div>
                </div>


                <div className="buttons" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                    <button className="button is-primary" type="primary" onClick={() => handleSend()}>Илгээх</button>
                    <button className="button is-primary" type="primary" onClick={() => handleCancel()}>Болих</button>
                </div>
            </div>
        </div>

    )

}


export default ProductImageRequestNoteCreate;