/**
 * Created by gantushig on 11/6/15.
 */

import React from "react";

const ProductSenderFragment = ({
                             p
                         }) => {


    return (
        <>
            <tr>
                <th>Илгээсэн: Нэр</th>
                <td>{p && p.sender_name}</td>
            </tr>

            <tr>
                <th>Илгээсэн: Албан тушаал</th>
                <td>{p && p.sender_position}</td>
            </tr>

            <tr>
                <th>Илгээсэн: Утас</th>
                <td>{p && p.sender_phone}</td>
            </tr>

            <tr>
                <th>Илгээсэн: Имэйл </th>
                <td>{p && p.sender_email}</td>
            </tr>
        </>
    )

}


export default ProductSenderFragment

