/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import AsyncSelect from 'react-select/async';
import {changeTempProduct, failure, fetchClassifs, fetchProduct8Success} from "./actions/actions";
import {Endpoints, GTIN, TOKEN} from "./Constants"
import axios from "axios";
//
// const initialState = {barcode_type :GTIN};
//
// function reducer (state, action) {
//     switch (action.type) {
//         case 'barcode':
//             return {barcode:state.barcode = action.payload};
//         default:
//             throw new Error();
//     }
// }


const Product8EditComponent = ({
                                   product, onChangeField, onChangeCompany, onSave, onCancel
                               }) => {

    let token = localStorage.getItem(TOKEN) || null;
    //let reduxDispatch = useDispatch();

    //const tempProduct = useSelector(state => state.tempProduct);

    let initialStateCompany = [{value:product.company_id, label : product.company_name}]

    if (product.barcode_type === null) {
        product.barcode_type = "GTIN";
    }

    const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        console.log('useEffect called');
        setSelectedOption(initialStateCompany[0]);
    }, [product.id]);

    const promiseOptions = inputValue =>
        new Promise((resolve, reject) => {
            let header = {};
            header["Authorization"] = token;
            header["Accept"] = "application/json";
            header["Content-Type"] = "application/json";

            let config = {};
            config["headers"] = header;
            config["url"] = `${Endpoints.COMPANIES}/name/?name=${inputValue}`;

            return axios(config)
                .then(response => {

                    resolve(response.data)
                })
                .catch(error => {

                    reject(error)

                });
        });


    const handleInputChange = (newValue) => {
        setSelectedOption(newValue);
        onChangeCompany(newValue);
        //dispatch(changeTempProduct('company_id', newValue.value))
    };


    return (

        <div>
            <div className="field">
                <label className="label">Компани</label>
                <div className="control has-icons-left">
                    <AsyncSelect
                        cacheOptions
                        defaultValue={initialStateCompany}
                        loadOptions={promiseOptions}
                        defaultOptions={true}
                        value={selectedOption}
                        onChange={(e)=>handleInputChange(e)}
                    />
                </div>
            </div>

            <div className="field">
                <label className="label">Нэр</label>
                <div className="control has-icons-left">
                    <input className="input" name="name" type="text" value={product.name}
                           onChange={onChangeField} placeholder="Нэр оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Зураасан код</label>
                <div className="control has-icons-left">
                    <input className="input" name="barcode" type="text" value={product.barcode}
                           onChange={onChangeField} placeholder="Дугаар оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Савлалтын хэмжээ</label>
                <div className="control has-icons-left">
                    <textarea className="textarea" name="packaging" type="text" value={product.packaging}
                              onChange={onChangeField} placeholder="Савлалтын хэмжээ оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Орц найрлага</label>
                <div className="control has-icons-left">
                <textarea className="textarea" name="constituent" type="text" value={product.constituent}
                          onChange={onChangeField} placeholder="Орц найрлага оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Шинж чанар</label>
                <div className="control has-icons-left">
                <textarea className="textarea" name="characteristics" type="text" value={product.characteristics}
                          onChange={onChangeField} placeholder="Шинж чанар оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Зориулалт</label>
                <div className="control has-icons-left">
                <textarea className="textarea" name="handling" type="text" value={product.handling}
                          onChange={onChangeField} placeholder="Зориулалт оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Хэрэглэх заавар</label>
                <div className="control has-icons-left">
                   <textarea className="textarea" name="instruction" type="text" value={product.instruction}
                             onChange={onChangeField} placeholder="Хэрэглэх заавар оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Хадгалах нөхцөл</label>
                <div className="control has-icons-left">
                <textarea className="textarea" name="storage" type="text" value={product.storage}
                          onChange={onChangeField} placeholder="Хадгалах нөхцөл оруулах"/>
                </div>
            </div>


            <div className="buttons is-right" style={{paddingTop: '10px', marginLeft: '10px'}}>
                <button className="button" type="primary" onClick={() => onCancel()}>Буцах</button>
                <button className="button is-success" type="primary"
                        onClick={() => onSave()}>                 Хадгалах               </button>
            </div>
        </div>
    )
}


export default Product8EditComponent

