/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import LoadingOverlay from 'react-loading-overlay';
import {createCompanySelf, fetchSectorsAllNoAuth, resetErrors} from "./actions/actions";
import CompanySelfEditComponent from "./CompanySelfEditComponent";
import {toast} from "react-toastify";
import history from './history';
import dayjs from "dayjs"
import doc from "./docs/Албан-бичиг-ДУГААРТАЙ.docx"

const Signup = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        console.log('useEffect called');
        dispatch(fetchSectorsAllNoAuth())
    }, []);

    useEffect(() => {
        console.log('useEffect reset errors called');
        return () => {
            dispatch(resetErrors())
            toast.dismiss()
        }
    }, []);

    const sui = useSelector(state=>state.ui)
    let isUpdating = sui.isUpdating

    const handleSave = (name, name_english, state_reg_number, registration_number,
                        established,
                        district, aimak, soum, address, phone, postal_address,
                        email, social, web,
                        sector,
                        is_exported, is_exported_countries,
                        ceo_lastname, ceo_name, ceo_position, ceo_phone, ceo_email,
                        admin_lastname, admin_name, admin_position, admin_phone, admin_email,
                        sender_name, sender_position, sender_phone, sender_email) => {

        if (name
            && name_english
            && registration_number
            && established
            && district
            && aimak
            && soum
            && address
            && phone
            && email
            && social
            && sector
            && ceo_lastname && ceo_name && ceo_position && ceo_phone && ceo_email
            && admin_lastname && admin_name && admin_position && admin_phone && admin_email
            && sender_name && sender_position && sender_phone && sender_email
        ) {
            //if (established !== null ) {
            established = established.getTime()
            //}
            let established_str = dayjs(established).format('YYYY-MM-DD');

            console.log('send');
            let dto = {
                name,
                name_english,
                state_reg_number,
                registration_number,
                established,
                soum,
                district,
                aimak,
                address,
                phone,
                postal_address,
                email,
                social,
                web,
                sector,
                is_exported,
                is_exported_countries,

                ceo_lastname,
                ceo_name,
                ceo_position,
                ceo_phone,
                ceo_email,

                admin_lastname,
                admin_name,
                admin_position,
                admin_phone,
                admin_email,

                sender_name,
                sender_position,
                sender_phone,
                sender_email,
                established_str
            };

            dispatch(createCompanySelf( dto)).then (response => {
                    toast.success("Амжилттай илгээлээ!", { delay: 8000 });
                    history.push("/company/register/completed")
                }
            ).catch(error=> {
                alert(error)
            });

            // dispatch(createCompanySelf(
            //     file1,
            //     file2,
            //     file3,
            //     name,
            //     name_english,
            //     state_reg_number,
            //     registration_number,
            //     established,
            //     soum,
            //     district,
            //     aimak,
            //     address,
            //     phone,
            //     postal_address,
            //     email,
            //     social,
            //     web,
            //     sector,
            //     is_exported,
            //     is_exported_countries,
            //
            //     ceo_lastname,
            //     ceo_name,
            //     ceo_position,
            //     ceo_phone,
            //     ceo_email,
            //
            //     admin_lastname,
            //     admin_name,
            //     admin_position,
            //     admin_phone,
            //     admin_email,
            //
            //     sender_name,
            //     sender_position,
            //     sender_phone,
            //     sender_email,
            //     established_str
            //
            // )).then (response => {
            //         toast.success("Амжилттай илгээлээ!");
            //         history.push("/company/register/completed")
            //     }
            // ).catch(error=> {
            //     alert(error)
            // });

        } else {
            alert("Та одоор * тэмдэглэсэн заавал оруулах талбаруудыг оруулна уу!")
        }
    };



    return (
        <LoadingOverlay
            active={isUpdating}
            spinner
            text='Илгээж байна. Веб хөтөчөө хаахгүй хүлээнэ үү...'
        >
        <div style={{backgroundColor: "white", width:"100%", paddingLeft: "10px", paddingRight: "10px"}}>

            <div className="row">
                <div className="column is-two-thirds">
                    <div className="level">
                        <div className="level-left">
                            <h1>Байгууллагын анкет оруулах</h1>
                        </div>
                    </div>

                    <CompanySelfEditComponent
                        pestablished={null}
                        onSave={handleSave}/>

                </div>

                <div className="column">
                    <h2 style={{marginTop:"20px", marginBottom:"10px", color: "#002c6c", fontWeight: 400, fontSize:"30px", lineHeight:1.1}}><span>Бүртгүүлэх зөвлөмж</span></h2>
                    <ul style={{listStyleType: "circle", marginLeft:"30px"}}>
                        <li>Монголоор бичнэ. /галигаар бичихгүй/</li>
                        <li>Одтой хэсгийг заавал бөглөнө.</li>
                        <li>Бүрэн оруулсаны дараа Илгээх товчлуур дарна уу.</li>
                        <li>Албан бичгийн загвар татах боломжтой.</li>
                    </ul>
                    <h2 style={{marginTop:"20px", marginBottom:"10px", color: "#002c6c", fontWeight: 400, fontSize:"30px", lineHeight:1.1}}><span>Албан бичгийн загвар</span></h2>
                    <ul style={{listStyleType: "circle", marginLeft:"30px"}}>
                        <li><Link to="/files/alban-bichig.docx" target="_blank" download>Энд дарж  татаж авна уу!</Link></li>
                        <li>Албан бичиг, компанийн гэрчилгээний хуулбар, иргэний үнэмлэхний хуулбар (хувь хүн бол) болон төлбөрийн баримтыг <a href="mailto: info@gs1mn.org"> info@gs1mn.org</a> хаягаар илгээнэ үү.
                            </li>
                    </ul>
                </div>
            </div>

                <div className="row">
                    <div className="column w-full" style={{paddingBottom:"20px", paddingTop:"20px"}}>
                        <hr/>
                        <br/>
                        <p><small>
                            GS1 Монгол. <strong>Хаяг:&nbsp;</strong>
                            &nbsp; Морьтон цогцолбор, 901 тоот, 9-р давхар, 19-р хороолол, 3-р хороо, Хан-Уул дүүрэг.{" "}
                            <strong>Утас</strong> +976-77 000 865.

                            <strong>Facebook хуудас:&nbsp;</strong>{" "} <a href="https://www.facebook.com/GS1Mongolia/">GS1 Mongolia</a>
                            <strong>Цахим Шуудан:&nbsp;</strong>{" "}<a href="mailto: info@gs1mn.org"> info@gs1mn.org</a>&nbsp;&nbsp;
                            <strong>Цахим хуудас:&nbsp;</strong>{" "} <a href="http://www.gs1mn.org"> www.gs1mn.org</a> &nbsp;&nbsp;
                            <strong>Твиттер:&nbsp;</strong>{" "}  GS1 Mongolia association


                        </small>
                        </p>
                    </div>

                </div>


        </div>
        </LoadingOverlay>
    )

}


export default Signup