import React, { createContext, Component } from "react";

import validations  from './validations'

export const FormCtx = createContext({
    fields: {},
    errors: {}
});

export default class Form extends Component {
    state = {
        fields: {},
        errors: {}
    };

    // constructor(props) {
    //
    //     super(props);
    //     this.validateForm=this.validateForm.bind(this);
    // }

    validateForm =() =>{
        const { fields } = this.state;
        for (const unit in fields) {
            const fieldData = fields[unit]
            if (fieldData) {
                this.validateField(unit)
            }
        }
    }


    render() {
        const { fields, errors } = this.state;
        const formCtx = {
            fields,
            errors,
            addField: data => {
                this.addField(data);
            },
            setFields: this.setFields,
            validateField: this.validateField,
            validateForm: this.validateForm,
        };

        return (
            <form action="">
                <FormCtx.Provider value={formCtx}>
                    {this.props.children}
                </FormCtx.Provider>
            </form>
        );
    }

    setFields = (event, { id }) => {
        event.persist();

        const { fields } = this.state;
        const field = fields[id];

        this.addField({
            field: {
                ...field,
                value: event.currentTarget.value
            }
        });
    };

    addField = ({ field }) => {
        const { id } = field;

        field = {
            value: "",
            ...field
        };

        if (id) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    fields: {
                        ...prevState.fields,
                        [id]: field
                    }
                };
            });

            return;
        }

        throw new Error(`please add 'id' field to the input: ${field}`);
    };


    validateRule({ id, value, displayName, ruleValue, ruleArgs, validation }) {
        if (validation) {
            const stringifiedValue = value.toString()
            const isRuleSatisfied = ( ruleValue !== 'required' && !stringifiedValue )
                ? true
                : validation.rule.apply(null, ruleArgs).test(value.toString())

            let error = ''

            if (!isRuleSatisfied) {
                error = validation.formatter.apply(null, [displayName || id, ...ruleArgs])

                return error
            }
        } else {
            throw `invalid validation rule: ${ruleValue}, please use an existing validation rule name or pass a custom function with same name through 'customRules' prop in Input: ${id}. Rule value should be an object with keys: 'rule' as an Regex and 'formatter' as a function, that formats the value.` // eslint-disable-line
        }

        return ''
    }

    validateField =                        id                      => {
        let error = "";
            const { fields } = this.state;
            const {
                value,
                validate,
                displayName,
                customRules = {}
            } = fields[id];
//        let error = ''
        const rules = validate ? validate.split('|') : ''

        if (rules.length) {
            for (const rule in rules) {
                const ruleName = rules[rule]
                const ruleDetails = ruleName.split('-')
                const [ruleValue, ...ruleArgs] = ruleDetails
                const validation = validations[ruleValue] || customRules[ruleValue]


                try {
                    error = this.validateRule({ id, value, displayName, ruleValue, ruleArgs, validation })

                    if (error !== '') {
                        break
                    }
                } catch (invalidRuleError) {
                    throw invalidRuleError
                }
            }

            this.setState((prevState) => ({
                ...prevState,
                errors : {
                    ...prevState.errors,
                    [id] : error
                }
            }))
        }
    }

    // validateField = id => {
    //     let error = "";
    //     const { fields } = this.state;
    //     const {
    //         value: fieldValue,
    //         validate,
    //         displayName,
    //         customRules = {}
    //     } = fields[id];
    //     const rules = validate ? validate.split("|") : "";
    //
    //     if (rules.length) {
    //         for (const rule in rules) {
    //             const ruleName = rules[rule];
    //             const validation = validations[ruleName] || customRules[ruleName];
    //             const isRuleSatisfied =
    //                 ruleName !== "required" && !fieldValue
    //                     ? true
    //                     : validation.rule().test(fieldValue.toString());
    //
    //             if (!isRuleSatisfied) {
    //                 error = validation.formatter.apply(null, [displayName || id]);
    //             }
    //
    //             if (error !== "") {
    //                 break;
    //             }
    //         }
    //
    //         this.setState(prevState => ({
    //             ...prevState,
    //             errors: {
    //                 ...prevState.errors,
    //                 [id]: error
    //             }
    //         }));
    //     }
    // };
}