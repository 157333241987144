/**
 * Created by gantushig on 11/6/15.
 */

import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {
    fetchSmsItems, sendSms, fetchSmsItem, fetchSmsMassItems, getSmsMassItemsCheckedCount
} from "./actions/actions";
import qs from "query-string";
import {ActionTypes, EndpointsExpress, Page, TOKEN} from "./Constants";
import ReactPaginate from "react-paginate";
import classnames from "classnames";
import history from "./history";
import {toast} from "react-toastify";
import {confirm} from "react-confirm-box";
import axios from "./axiosConfig";


const initialState = {
    main:{},
    items: [],
    loading: false,
    totalPages:0,
    totalElements:0,
    checkedCount:0,
    checkedChanged:false,
    mobiCount:0,
    uniCount:0,
    skyCount:0,

};

const reducer = (state, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_SMS_MASS_ITEMS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_SMS_MASS_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                main: action.main,
                items: action.items,
                totalElements: action.meta.totalElements,
                totalPages: action.meta.totalPages,
                checkedCount: action.checkedCount,
                mobiCount: action.meta.mobiCount,
                uniCount: action.meta.uniCount,
                skyCount: action.meta.skyCount,
            }
        case ActionTypes.GET_SMS_MASS_ITEMS_CHECKED_COUNT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_SMS_MASS_ITEMS_CHECKED_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                checkedCount: action.count
            }
        case ActionTypes.FETCH_SMS_ITEM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FETCH_SMS_ITEM_SUCCESS: {
            const ps = state.items.map(a => ({...a}));

            const itemIndex = ps.findIndex(p => p.id === action.item.id);
            if (itemIndex !== -1) {
                ps[itemIndex] = action.item;

            }

            return {
                ...state,
                loading: false,
                items: ps,
            }
        }
        case ActionTypes.SEND_SMS_SUCCESS:

            let status = action.status;
            if (action.status === 'SEND') {
                status = "SENDING";
            } else if (action.status === 'CANCEL') {
                status = "CANCELLED";
            }
            const ps = state.items.map(a => ({...a}));

            const itemIndex = ps.findIndex(p => p.id === action.id);
            if (itemIndex !== -1) {
                ps[itemIndex].status = status;
                ps[itemIndex].phone = action.phone;
            }
            return {
                ...state,
                items: ps,
            }
        case  'ITEM_CHECKED_CHANGED': {
            return {
                ...state,
                checkedCount: action.count,
            }

        }
        default:
            return state;
    }
};


const itemCheckedChanged =(count) => {
    return {
        type: 'ITEM_CHECKED_CHANGED',
        count
    }
}

const SmsMassItemsPage = ({
                          match: {params: {id}},
                          location: {search}
                          }) => {
    const [name, setName] = useState(null);
    let parsed = qs.parse(search);
    //let id = parsed["id"];
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];
    let qname = parsed["n"];

    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "25";
    }
    if (qname === undefined) {
        qname = null;
    }

    const [state, dispatch] = useReducer(reducer, initialState);
    const {main, items, totalPages, totalElements, checkedChanged, checkedCount, mobiCount, uniCount, skyCount
    } = state;

    useEffect(() => {

        console.log('useEffect called');
        fetchSmsMassItems(dispatch, id,  qname, page, pagesize);
    }, [ qname, page, pagesize]);


    // useEffect(() => {
    //     getSmsMassItemsCheckedCount(dispatch, id);
    // }, [ checkedChanged]);

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed))
    };

    const handleSearch = (data) => {
        let parsed = qs.parse(search);
        parsed["n"] = name;
        parsed[Page.PAGE] = "0";
        history.push("?" + qs.stringify(parsed))
    };

    const handlePrepare = () => {
        async function prepare() {

            let header = {}

            header["Accept"] = "application/json"
            header["Content-Type"] = "application/json"

            let config = {}
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.SMS_MASS_BODY}/${id}/prepare`;
            config["method"] = "post";

            try {
                const response = await axios.instance(config);
            } catch(e) {
                alert(e)
            }
        }
        console.log('prepare');
        prepare();
        toast.success("Success!");
    };

    const optionsWithLabelChange = {
        closeOnOverlayClick: false,
        labels: {
            confirmable: "Send",
            cancellable: "Cancel"
        }
    };

    const handleChange = async (event, item_id) => {

        async function check() {
            let dto = {
                checked:event.target.checked
            };

            let header = {}

            header["Accept"] = "application/json"
            header["Content-Type"] = "application/json"

            let config = {}
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.SMS_MASS_ITEM}/${item_id}/check`;
            config["method"] = "post";
            config["data"] = JSON.stringify(dto);
            try {
                const response = await axios.instance(config);
                dispatch(itemCheckedChanged(response.data.count));
            } catch(e) {
                alert(e)
            }
        }

        check();
    }
    const handleSend = async () => {
        async function send() {
            let header = {}

            header["Accept"] = "application/json"
            header["Content-Type"] = "application/json"

            let config = {}
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.SMS_MASS_BODY}/${id}/send`;
            config["method"] = "post";


            try {
                const response = await axios.instance(config);
            } catch(e) {
                alert(e)
            }
        }
        const result = await confirm("SMS-ууд илгээх үү?", optionsWithLabelChange);
        if (result) {
            console.log('send');
            send();
            toast.success("Success!");
        } else {
            console.log('cancelled');
        }
    };

    let counter = Number(page) * Number(pagesize)
    const displayItems = items.map(
        (mail) => {
                   counter++;
           return (
                <tr key={mail.id}>
                    <td><input
                        type="checkbox"
                        defaultChecked={mail.checked}
                        onChange={(e)=>handleChange( e ,mail.id)}
                    /></td>

                    <td>{counter}</td>
                    <td>
                            {mail.company_name}
                    </td>
                    <td>
                        {mail.contact_name}  {mail.contact_surname}
                    </td>
                    <td>{mail.phone}</td>
                    <td>{mail.sent}</td>
                </tr>
            )
        });
    const handleChangeName = (e) => {
        setName(e.target.value);
    };

    return (
        <div className="pt-2 pr-2 pl-2 bg-white">
            <div>
                <table className="m-2">
                    <tr><th>Name</th><td className="pl-2">{main.name}</td></tr>
                    <tr><th>Sms Mobi [{main.sms?main.sms.length:0}]</th><td className="pl-2">{main.sms}</td></tr>
                    <tr><th>Sms Unitel [{main.sms2?main.sms2.length:0}]</th><td className="pl-2">{main.sms2}</td></tr>
                    <tr><th>Sms Skytel [{main.sms3?main.sms3.length:0}]</th><td className="pl-2">{main.sms3}</td></tr>
                    <tr><th>Илгээх</th><td className="pl-2">{main.send_audience}</td></tr>
                    <tr><th>Status</th><td className="pl-2">{main.status}</td></tr>
                    <tr><th>Created</th><td className="pl-2">{main.created}</td></tr>

                </table>
            </div>

            <div className="field">
                <label className="label">Нэр</label>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={name} style={{width: '400px'}}
                               onChange={handleChangeName} placeholder="Оруулах"/>
                    </div>
                </div>
            </div>

            <div>
                <button onClick={handleSearch} className="m-2 p-2 w-24 border-gray-300 bg-purple-500  text-white font-bold  rounded"><span className="text-white hover:text-blue-800 visited:text-purple-600">Search</span></button>
                {main.status === "DRAFT" &&
                <button onClick={handlePrepare} className="m-2 p-2 w-24 border-gray-300 bg-purple-500  text-white font-bold  rounded"><span className="text-white hover:text-blue-800 visited:text-purple-600">Prepare</span></button>
                }
                {main.status !== "SENT" &&
            <Link to={`/sms-mass-body-edit/${main.id}`}  className="m-2 p-2 w-24 border-gray-300 bg-green-500  text-white font-bold  rounded"><span className="text-white hover:text-blue-800 visited:text-purple-600">Өөрчлөх</span></Link>
                }
                {main.status === "PREPARED" &&
                <button onClick={handleSend} className="m-2 p-2 w-24 border-gray-300 bg-purple-500  text-white font-bold  rounded"><span className="text-white hover:text-blue-800 visited:text-purple-600">Send</span></button>
                }
            </div><br/>


            <div>Нийт: {totalElements} Checked: {checkedCount}  Mobi: {mobiCount} Uni:{uniCount} Sky:{skyCount}</div>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th></th>
                    <th>#</th>
                    <th>Компани</th>
                    <th>Name</th>
                    <th>Утас</th>
                    <th>Sent</th>
                </tr>
                </thead>
                <tbody>
                {displayItems}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={page}
                               breakLabel={<a href="">...</a>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                />
            </nav>

        </div>
    )

}


export default SmsMassItemsPage


