/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useReducer, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    createProduct, createProduct8,
    fetchClassifs, initTempProduct,
    resetErrors,
    resetProductUpdateStatus, setGcpPrefix, setUiTitle
} from "./actions/actions";
import {toast} from "react-toastify";
import {ActionTypes, GLN, GTIN, SSCC, TOKEN} from "./Constants"
import {
    checkGlnFieldsFilled,
    checkGtinFieldsFilled,
    checkSsccFieldsFilled,
    createGlnDto,
    createGtinDto, createProduct8Dto, createSsccDto
} from "./utils/productUtils";
import history from "./history";
import Product8EditComponent from "./Product8EditComponent";
import {css} from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    product: {barcode_type:GTIN},
    loading: false,
    selectedOption:{},
    initialStateCompany:[]
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_PRODUCT8_REQUEST:{
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.GET_PRODUCT8_SUCCESS: {
            const initial = [{value:action.product.company_id, label : action.product.company_name}];
            return {
                ...state,
                product: action.product,
                loading: false,
                initialStateCompany:initial,
                selectedOption:initial[0]
            }
        }
        case 'CHANGE_FIELD':
            let c = {...state.product};
            c[action.name] = action.value
            return {
                ...state,
                product: c
            }
        case 'CHANGE_SEL_OPTION':{
            return {
                ...state,
                selectedOption: action.opt,
            }
        }
        default:
            return state;
    }
};


const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}


const Product8Create = () => {

    let reduxDispatch = useDispatch();
    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {product, selectedOption, initialStateCompany} = state;

    useEffect(() => {
        reduxDispatch(setUiTitle("GS1-8 Бүтээгдэхүүн үүсгэх"));
    }, []);

    const handleChangeCompany = (newValue) => {
        //dispatch(changeSelectedOption(newValue));
        dispatch(changeField('company_id', newValue.value))
        //setSelectedOption(newValue);
        //dispatch(changeTempProduct('company_id', newValue.value))
    };

    const handleChangeField =(e)=> {
        dispatch(changeField(e.target.name, e.target.value))
    }

    const handleSave = (
    ) => {
        // let checkFailed = false;
        //     if (!checkGtinFieldsFilled(tempProduct)) {
        //         console.log('save check gtin');
        //         checkFailed = true;
        //     }
        // if (checkFailed) {
        //     console.log('check ');
        //     alert("Та одоор * тэмдэглэсэн заавал оруулах талбаруудыг оруулна уу!")
        //     return;
        // }


        let dto = {}
        dto = createProduct8Dto(product)
        reduxDispatch(createProduct8(token, dto)).then(response => {
                toast.success("Амжилттай илгээлээ!");
                history.push(`/product8s`)
            }
        ).catch(error => {
            alert(error)
        })
    };
    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
    };

    return (

        <div className="inner-container">

            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл 8 оруулах</h1>
                </div>
            </div>
            <hr/>

            <Product8EditComponent
                product={product}
                onChangeField={handleChangeField}
                onChangeCompany={handleChangeCompany}
                onSave={handleSave} onCancel={handleCancel}/>

        </div>

    )

}


export default Product8Create;
