/**
 * Created by gantushig on 11/6/15.
 */

import {Link, matchPath, Route, Switch, useLocation, useParams, useRouteMatch} from "react-router-dom";
import React, {useEffect, useReducer} from "react";
import {fetchCompany} from "./actions/actions";
import dayjs from "dayjs";
import dayjsPluginUTC from 'dayjs-plugin-utc'
import {ActionTypes, Endpoints, TOKEN} from "./Constants";
import {css} from "@emotion/react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import CompanyViewComponent from "./CompanyViewComponent";
import CompanyEdit from "./CompanyEdit";
import CompanyContacts2 from "./CompanyContacts2";
import CompanyGcps from "./CompanyGcps";
import CompanyProducts from "./CompanyProducts";

dayjs.extend(dayjsPluginUTC)



const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    tab:0,
    company: {},
    notes:[],
    loading: false,
    refresh:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_COMPANY_REQUEST:
        case ActionTypes.CREATE_USER_REQUEST:
        case ActionTypes.UPDATE_USER_REQUEST:
        case ActionTypes.FETCH_CONTACT_REQUEST:
        case ActionTypes.FETCH_CONTACTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FETCH_GCPS_SUCCESS:
        case ActionTypes.FETCH_GCP_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case ActionTypes.GET_COMPANY_SUCCESS: {
            let c = {...action.company}
            if (c.joined !== null && 'null' != c.joined) {
                c.joined = Date.parse(c.joined); //"2019-06-12T10:20:30Z"); //// dayjs("2019-06-12 00:00:00"); c.issued);
            }
            if (c.established !== null && 'null' != c.established) {
                c.established = Date.parse(c.established); //"2019-06-29T01:00:00Z");//c.established);
            }

            return {
                ...state,
                company: c,
                notes:action.notes,
                loading: false
            }
        }
        case ActionTypes.CREATE_GCP_SUCCESS: {
            let c = {...state.company}
            let g = {...action.gcp}
            if ('null' != g.joined) {
                c.joined = Date.parse(g.joined); //"2019-06-12T10:20:30Z"); //// dayjs("2019-06-12 00:00:00"); c.issued);
            }
            return {
                ...state,
                company: c,
                loading: false
            }

        }

        case ActionTypes.FETCH_CONTACT_SUCCESS:
        case ActionTypes.FETCH_CONTACTS_SUCCESS:        {
            return {
                ...state,
                loading: false
            }
        }
        case 'REFRESH':
            let r = state.refresh;
            return {
                ...state,
                refresh: !r
            }

        case ActionTypes.UPDATE_COMPANY_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.UPDATE_COMPANY_SUCCESS: {
            let c = {...action.company}
            if ('null' != c.joined) {
                c.joined = Date.parse(c.joined); //"2019-06-12T10:20:30Z"); //// dayjs("2019-06-12 00:00:00"); c.issued);
            }
            if ('null' != c.established) {
                c.established = Date.parse(c.established); //"2019-06-29T01:00:00Z");//c.established);
            }
            return {
                ...state,
                company: c,
                loading: false
            }
        }
        case 'CHANGE_FIELD':
            let c = {...state.company};
            c[action.name] = action.value
            if (action.name === "sector") {
                c[action.name] = Number(action.value);
            }
            return {
                ...state,
                company: c,
                loading: false
            }

        default:
            return state;
    }
};


const refreshPage = () => {
    return {
        type: "REFRESH",
    }
}



const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}

const CompanyViewContainer = () => {
    const {companyId} = useParams();
    let { path, url } = useRouteMatch();
    const {pathname}  = useLocation();
    //let pn = location.pathname
    const matchContact = matchPath(pathname, {
        path: `/company/:id/contacts`,
        exact:false

    });
    const matchGcp = matchPath(pathname, {
        path: `/company/:id/gcps`,
        exact:false

    });
    const matchProducts = matchPath(pathname, {
        path: `/company/:id/products`,
        exact:false

    });

    let tab = 0;
    if (matchContact) {
        tab = 1;
    } else if ( matchGcp) {
        tab = 2;
    } else if ( matchProducts) {
        tab = 3;
    }

    const token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {company, notes, loading, refresh} = state;
    useEffect(() => {
        console.log('useEffect called');
        fetchCompany(dispatch,  companyId);
    }, [companyId, refresh]);

    const handleUpdate = () => {
        console.log('update');
        dispatch(refreshPage());
    };


    console.log(pathname);
    return (
        <div className="inner-container">
            <span className = 'block text-2xl mb-10 font-bold text-gray-800'>{company.name}</span>

            <div className="mb-5">
                <div className="tabs is-toggle is-fullwidth is-medium">
                    <ul>
                        <li className={tab === 0  ? 'is-active' : ''}>
                            <Link to={`${url}`}>
                                <span className="icon is-small"><i className="fas fa-image" aria-hidden="true"></i></span>
                                <span>Байгууллага</span>
                            </Link>
                        </li>
                        <li className={tab === 1  ? 'is-active' : ''}>
                            <Link to={`${url}/contacts`}>

                                <span className="icon is-small"><i className="fas fa-music" aria-hidden="true"></i></span>
                                <span>Хариуцсан ажилтан</span>
                            </Link>
                        </li>
                        <li className={tab === 2  ? 'is-active' : ''}>
                            <Link to={`${url}/gcps`}>
                                <span className="icon is-small"><i className="fas fa-film" aria-hidden="true"></i></span>
                                <span>GCP</span>
                            </Link>
                        </li>
                        <li className={tab === 3  ? 'is-active' : ''}>
                            <Link to={`${url}/products`}>
                                <span className="icon is-small"><i className="far fa-file-alt" aria-hidden="true"></i></span>
                                <span>Бүтээгдэхүүн</span>
                            </Link>
                        </li>
                    </ul>
                </div>

            </div>


            {/*<CompanyViewHeader tab={0} companyId={companyId}/>*/}
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <Switch>
                <Route exact path={path}>
                    <CompanyViewComponent company={company} notes = {notes} onUpdate={handleUpdate}/>
                </Route>
                <Route path={`${path}/edit`}>
                    <CompanyEdit
                        inCompany={company}
                        onUpdate={handleUpdate}/>
                </Route>
                <Route  path={`${path}/contacts`}>
                    <CompanyContacts2/>
                </Route>
                <Route  path={`${path}/gcps`}>
                    <CompanyGcps/>
                </Route>
                <Route  path={`${path}/products`}>
                    <CompanyProducts/>
                </Route>
            </Switch>
        </div>
    )

}


export default CompanyViewContainer;




