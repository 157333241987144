/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import gcpOptionsData from "./data/gcps.json";
import gcpStatusData from "./data/gcp-status.json"


const GcpEditComponent = ({gcp, onChangeField, onChangeIssued, onChangeCheckField, onSave, onCancel}) => {

    useEffect(() => {
        console.log('useEffect called');
    }, []);

    const gcpOptions = () => {
        let items = [];
        gcpOptionsData.map(item=>{
            return (items.push(<option key={item.id} value={item.id}>{item.name}</option>));
        })
        return items;
    };

    const gcpStatusOptions = () => {
        let items = [];
        gcpStatusData.map(item=>{
            items.push(<option key={item.id} value={item.id}>{item.name}</option>);
        })
        return items;
    };

    return (
        <div style={{background: 'white'}}>
            <div className="field">
                <label className="label">Баркод төрөл</label>
                <div className="control">
                    <label className="radio">
                        <input name="barcode_type" type="radio" value="0" checked={gcp.barcode_type === "0"}
                               onChange={onChangeField}/>
                        &nbsp;&nbsp;GTIN
                    </label>
                    <label className="radio">
                        <input name="barcode_type" type="radio" value="1" checked={gcp.barcode_type === "1"}
                               onChange={onChangeField}/>
                        &nbsp;&nbsp;GLN
                    </label>
                    <label className="radio">
                        <input name="barcode_type" type="radio" value="2" checked={gcp.barcode_type === "2"}
                               onChange={onChangeField}/>
                        &nbsp;&nbsp;SSCC
                    </label>
                </div>
            </div>

            <div className="field">
                <label className="label">Gcp төрөл</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select name="size"  value={gcp.size} onChange={onChangeField}>
                            {gcpOptions()}
                        </select>
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">Дугаар</label>
                <div className="control has-icons-left">
                    <input name="prefix" className="input" type="text" value={gcp.prefix}
                           onChange={onChangeField} placeholder="Дугаар оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Олгосон огноо</label>
                <div className="control has-icons-left">
                    <DatePicker className="input" selected={gcp.issued}
                                onChange={onChangeIssued} placeholderText="Олгосон огноо оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Төлөв</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select  name="status"  value={gcp.status} onChange={onChangeField}>
                            {gcpStatusOptions()}
                        </select>
                    </div>
                </div>
            </div>

            {/*<div className="field">*/}
            {/*    <label className="label">Хаасан</label>*/}
            {/*    <div className="control has-icons-left">*/}
            {/*        <input name="closed" className="checkbox" type="checkbox" checked={gcp.closed}*/}
            {/*               onChange={onChangeCheckField}/>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="buttons is-right" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                <button className="button" type="primary" onClick={onCancel}>Буцах</button>
                <button className="button is-success" type="primary"
                        onClick={onSave}>Хадгалах
                </button>
            </div>
        </div>
    )

}


export default GcpEditComponent