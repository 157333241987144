/**
 * Created by gantushig on 4/16/17.
 */


import React, {Component, useEffect, useState} from "react";
import {connect, useSelector, useDispatch} from "react-redux";
import { loginUserExpress} from "./actions/actions";
import logo from "./images/logo.png"
import history from "./history"
import './tailwind.output.css';

const LoginExpress = () => {
    const [hidden, setHidden] = useState(true);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    const isAuthenticatedExpress = useSelector(state => state.auth.isAuthenticatedExpress)

    const handleChangeUsername = (e) => {
        setUsername(e.target.value)
    };

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    };

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log('send');
        const data = new FormData(event.target);
        let cred = {
            username,
            password
        }
        dispatch(loginUserExpress(cred));
    };

    const toggleShow = () => {
        setHidden(!hidden);
    }
    if (isAuthenticatedExpress) {
        history.push("/")
        // return (
        //     <Redirect to="/"/>
        // )
    }
    //else {

    return (
        <div className="w-full">
            <div className="flex items-center  w-full p-0 bg-gray-300 h-28">
                <a href="/">
                    <img alt="GS1 logo" className="block p-4"
                         src={logo}/>
                </a>
                <p className="block">
                    <strong className="font-bold">
                        Welcome to GS1 Mongolia
                    </strong>
                    <br/>
                    The Global Language of Business</p>
            </div>
            <div className="flex  justify-center">
                <form onSubmit={(e) => handleSubmit(e)}
                      className="bg-white shadow-md rounded px-8 w-1/3 pt-6 pb-8 mb-4 mt-4">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                            Express Хэрэглэгч
                        </label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Хэрэглэгчийн нэр"
                            value={username}
                            onChange={(e) => handleChangeUsername(e)}/>
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Нууц үг
                        </label>
                        <input
                            type={hidden ? "password" : "text"}
                            id="password"
                            name="password"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="******************"
                            value={password}
                            onChange={(e) => handleChangePassword(e)}
                        />
                        <p className="text-red-500 text-xs italic">Нууц үгээ оруулна уу.</p>
                    </div>

                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded"
                            >
                            Нэвтрэх
                        </button>
                    </div>
                </form>
            </div>

            <p className="text-center text-gray-500 text-xs">
                &copy;2019-{new Date().getFullYear()} GS1 Mongolia. All rights reserved.
            </p>
        </div>
    )
    //}
}




export default LoginExpress;