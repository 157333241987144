/**
 * Created by gantushig on 4/16/17.
 */

import React, {useState} from "react";
import juram2 from "./images/Juram-002.jpg"
import juram3 from "./images/Juram-003.jpg"
import juram1 from "./images/Juram-001.jpg"
import juram4 from "./images/Juram-004.jpg"
import history from "./history";
import qs from "query-string";
import SignupModal from "./components/SignupModal";

const CompanySelfCreateTest = () => {
    const [agree, setAgree] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleChangeAgree = (e) => {
        setAgree(e.target.checked)
    };
    const handleSubmit = (e) => {

        // TODO show dialog box
        setShowModal(true);
        //history.push("/signup")
    };
    const handleIndividual = (e) => {
        setShowModal(false);
        history.push("/signup-individual")
    }
    const handleCompany = (e) => {
        setShowModal(false);
        history.push("/signup-company")
    }

    return (
<>
        <div style={{backgroundColor: "white", width: "100%", paddingLeft: "10px", paddingRight: "10px"}}>
                        <div className="row">
                <div className="column">
                    <h2 className="has-text-centered" style={{
                        marginTop: "20px",
                        marginBottom: "1px",
                        color: "#002c6c",
                        fontWeight: 600,
                        fontSize: "18px",
                        lineHeight: 1.1
                    }}><span>                        Та доорх гишүүнчлэлийн журам, төлбөрийн мэдээлэлтэй танилцаад дараагийн алхамыг хийнэ үү. </span>
                    </h2>

                    <img src={juram1} alt="juram1"/>
                    <img src={juram2} alt="juram2"/>
                    <img src={juram3} alt="juram3"/>
                    <img src={juram4} alt="juram4"/>

                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label"></label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox" >
                                        <input className="checkbox" type="checkbox" checked={agree} style={{marginRight:"8px"}}
                                               onChange={(e) => handleChangeAgree(e)}/></label>

                                    <span style={{
                                        color: "#002c6c",
                                        fontWeight: 600,
                                        fontSize: "18px",
                                    }}>Би журам, төлбөрийн мэдээллийг уншиж танилцаад, зөвшөөрч байна.</span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="has-text-centered" style={{paddingTop: '10px'}}>
                        <button className="button bg-orange" disabled={!agree} type="primary" style={{color: "white"}}
                                onClick={(e) => handleSubmit(e)}>
                            ДАРААХ
                        </button>
                    </div>

                </div>
            </div>
        </div>

    {showModal ? (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                                Сонгох
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}
                            >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                                Та хувь хүнээр/байгууллагаар бүртгүүлэхээ сонгоно уу!
                            </p>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                            <button
                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={handleIndividual}
                            >
                                Хувь хүн
                            </button>
                            <button
                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={handleCompany}
                            >
                                Байгууллага
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    ) : null}
</>
    )

}


export default CompanySelfCreateTest