/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";


const ProductEditComponentSender = ({ product,
                                  onChangeField, disabled
                              }) => {

    return (
        <>
            <div className="field">
                <label className="label">Илгээсэн: Нэр *</label>
                <div className="control">
                    <input name="sender_name"  className="input" type="text" value={product.sender_name}
                           onChange={onChangeField} placeholder="Илгээсэн хүний нэр оруулах" disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Илгээсэн: Албан тушаал *</label>
                <div className="control">
                    <input name="sender_position"  className="input" type="text" value={product.sender_position}
                           onChange={onChangeField}
                           placeholder="Илгээсэн хүний албан тушаал оруулах" disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Илгээсэн: Утас *</label>
                <div className="control">
                    <input name="sender_phone"  className="input" type="text" value={product.sender_phone}
                           onChange={onChangeField} placeholder="Илгээсэн хүний утас оруулах" disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Илгээсэн: Емайл *</label>
                <div className="control">
                    <input name="sender_email"  className="input" type="text" value={product.sender_email}
                           onChange={onChangeField} placeholder="Илгээсэн хүний емайл оруулах" disabled={disabled}/>
                </div>
            </div>
        </>
    )

}


export default ProductEditComponentSender

