/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";
import glnData from "./data/glns.json"

const ProductEditComponentGln = ({  product,
                                  onChangeField, disabled
                              }) => {

    const locationOptions = () => {
        let items = [];
        glnData.locations.map(item=>{
            items.push(<option key={item.id} value={item.id}>{item.name}</option>);
        })
        return items;
    };

    const locationTypeOptions = () => {
        let items = [];
        glnData.locationTypes.map(item=>{
            items.push(<option key={item.id} value={item.id}>{item.name}</option>);
        })
        return items;
    };

    const locationToString = (a) => {
        let ret = ""
        let ra = glnData.locations.filter(b=>b.id === a);
        if (ra.length>0) {
            ret = ra[0].name;
        }

        return ret
    };
    const locationTypeToString = (a) => {
        let ret = ""
        let ra = glnData.locationTypes.filter(b=>b.id === a);
        if (ra.length>0) {
            ret = ra[0].name;
        }

        return ret
    };

    return (
        <>
            {disabled ?
            <>
                <div className="form-group">
                    <label>Байршил</label>
                    <div className="control">
                        <div className="control">
                            <input className="input" type="text" value={locationToString(product.location)} disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </>
                :
                <>
                    <div className="field">
                        <label className="label">Байршил</label>
                        <div className="control">
                            <div className="select">
                                <select name="location" value={product.location} onChange={onChangeField}>
                                    <option value=""></option>
                                    {locationOptions()}
                                </select>
                            </div>
                        </div>
                    </div>
                </>
            }

            <div className="form-group">
                <label className="label">Байршлын хаяг* </label>
            </div>

            <div className="field">
                <label className="label">Улс, хот *</label>
                <div className="control">
                    <input name="state" className="input" type="text" value={product.state}
                           onChange={onChangeField} placeholder="Улс, хот оруулах" disabled={disabled}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Дүүрэг/аймаг *</label>
                <div className="control">
                    <input name="aimak" className="input" type="text" value={product.aimak}
                           onChange={onChangeField} placeholder="Дүүрэг/аймаг оруулах" disabled={disabled}
                    />
                </div>

            </div>
            <div className="field">
                <label className="label">Сум/хороо *</label>
                <div className="control">
                    <input name="soum" className="input" type="text" value={product.soum}
                           onChange={onChangeField} placeholder="Сум/хороо оруулах" disabled={disabled}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Утас, факс *</label>
                <div className="control">
                    <input name="phone" className="input" type="text" value={product.phone}
                           onChange={onChangeField} placeholder="Утас, факс оруулах" disabled={disabled}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">И-мэйл *</label>
                <div className="control">
                    <input name="email" className="input" type="text" value={product.email}
                           onChange={onChangeField} placeholder="И-мэйл  оруулах" disabled={disabled}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Вэбсайт *</label>
                <div className="control">
                    <input name="web" className="input" type="text" value={product.web}
                           onChange={onChangeField} placeholder="Вэбсайт оруулах" disabled={disabled}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Сошиал хаяг *</label>
                <div className="control">
                    <input name="social" className="input" type="text" value={product.social}
                           onChange={onChangeField} placeholder="Сошиал хаяг оруулах" disabled={disabled}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">ЗИП код (5 оронтой тоо) *</label>
                <div className="control">
                    <input name="zip_code" className="input" type="text" value={product.zip_code}
                           onChange={onChangeField} placeholder="ЗИП код оруулах " disabled={disabled}
                    />
                </div>
            </div>
            {disabled ? <>
                <div className="field">
                    <label className="label">Байршлын төрөл *</label>
                    <div className="control">
                        <div className="control has-icons-left">
                            <input className="input" type="text" value={locationTypeToString(product.location_type)} disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </> : <>
                <div className="field">
                    <label className="label">Байршлын төрөл *</label>
                    <div className="control">
                        <div className="select">
                            <select name="location_type" value={product.location_type} onChange={onChangeField}>
                                <option value=""></option>
                                {locationTypeOptions()}
                            </select>
                        </div>
                    </div>

                </div>
            </>

            }

        </>
    )

}

export default ProductEditComponentGln

