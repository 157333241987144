/**
 * Created by gantushig on 11/6/15.
 */

import {connect} from "react-redux";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import { fetchInvoice, printInvoice} from "./actions/actions";
import {createBrowserHistory} from "history";
import {TOKEN} from "./Constants";
import CompanyViewHeader from "./CompanyViewHeader";

const history = createBrowserHistory();


const InvoiceView = ({token, invoice, gcpId, invoiceId, loadInvoice, print, invoiceItems}) => {

    useEffect(() => {
        console.log('useEffect called');
        loadInvoice(token, invoiceId)
    }, []);

    if (invoice === null || invoice === undefined) {
        return (
            <div style={{background: 'white'}}>
                <div className="level">
                    <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                        <a className="button is-outline is-success" onClick={()=>handlePrint()}>
                            Print
                        </a>
                    </div>
                </div>

                <table className="table is-bordered is-fullwidth">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Жил</th>
                        <th>Дүн</th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
                <div className="subtitle">No Items</div>

            </div>
        )
    }
    ;

    const handlePrint = () => {
        console.log('print invoice');
        print(token, invoiceId)
    };


    const items = invoiceItems.map(
        (item) => {
            return (
                <tr key={item.id}>
                    <td>{item.counter}</td>
                    <td>{item.year}</td>
                    <td>{item.amount}₮</td>
                </tr>
            )
        });

    return (
        <div style={{background: 'white'}}>
            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <a className="button is-outline is-success" onClick={()=>handlePrint()}>
                        Print
                    </a>
                </div>
            </div>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Жил</th>
                    <th>Дүн</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            Нийт: {invoice.total_amount}₮
            <br/><br/>

        </div>
    )

}


const mapStateToProps = (state, ownProps) => {
    let isUpdating = state.ui.isUpdating;
    let token = localStorage.getItem(TOKEN) || null;

    const gcpId = ownProps.match.params.gcpId
    const invoiceId = ownProps.match.params.invoiceId
    let invoice = state.invoices.invoice;
    let invoiceItems = state.invoices.items;
    let counter = 0

    if (invoice === null || invoice === undefined || invoice.total_amount === undefined)  {
    } else {
        invoice.total_amount = invoice.total_amount.toLocaleString()
    }

    invoiceItems.map(
        (item) => {
            counter++
            item.counter = counter
            item.amount = item.amount.toLocaleString()
        });


    return {
        token,
        isUpdating,
        invoice,
        invoiceId,
        gcpId,
        invoiceItems
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        loadInvoice: (token, invoiceId) => {
            dispatch(fetchInvoice(token, invoiceId))
        },
        print: (token, id) => {
            dispatch(printInvoice(token, id))
        },

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(InvoiceView)


