/**
 * Created by gantushig on 11/6/15.
 */

import {Link} from "react-router-dom";
import React from "react";

const CompanyViewHeader = ({tab, companyId}) => {

    return (
        <div className="mb-5">
            <div className="tabs is-toggle is-fullwidth is-medium">
                <ul>
                    <li className={tab === 0  ? 'is-active' : ''}>
                        <Link to={`/company/${companyId}`}>
                            <span className="icon is-small"><i className="fas fa-image" aria-hidden="true"></i></span>
                            <span>Байгууллага</span>
                        </Link>
                    </li>
                    <li className={tab === 1  ? 'is-active' : ''}>
                        <Link to={`/company/${companyId}/contacts`}>

                            <span className="icon is-small"><i className="fas fa-music" aria-hidden="true"></i></span>
                            <span>Хариуцсан ажилтан</span>
                        </Link>
                    </li>
                    <li className={tab === 2  ? 'is-active' : ''}>
                        <Link to={`/company/${companyId}/gcps`}>
                            <span className="icon is-small"><i className="fas fa-film" aria-hidden="true"></i></span>
                            <span>GCP</span>
                        </Link>
                    </li>
                    <li className={tab === 3  ? 'is-active' : ''}>
                        <Link to={`/company/${companyId}/products`}>
                            <span className="icon is-small"><i className="far fa-file-alt" aria-hidden="true"></i></span>
                            <span>Бүтээгдэхүүн</span>
                        </Link>
                    </li>
                </ul>
            </div>

        </div>
    )

}


export default CompanyViewHeader;


