/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useReducer, useState} from "react";
import {
    failure, fetchGcpsAll, fetchHqGcps, menuClicked,
    menuClickedTitle
} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, Endpoints, EndpointsExpress, Menu, Page, PageTitle, TOKEN} from "./Constants";
import history from "./history";
import axios from "axios";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import gcpOptionsData from "./data/gcps.json"
import gcpStatusData from "./data/gcp-status.json"


dayjs.extend(dayjsPluginUTC)


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const initialState = {
    gcps: [],
    meta:{totalPages:0},
    loading: false,
    refresh:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_HQ_GCPS_REQUEST:
            return {
                ...state,
                loading:true
            }
        case ActionTypes.LOAD_HQ_GCPS_SUCCESS:
            return {
                ...state,
                gcps: action.gcps,
                meta: action.meta,
                loading:false
            }
        default:
            return state;
    }
};

const gcpStatusOptions = () => {
    let items = [];
    gcpStatusData.map(item=>{
        items.push(<option key={item.id} value={item.id}>{item.name}</option>);
    })
    return items;
};

const HqGcpsPage = ({
                      location: {search}
                  }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {gcps, loading, meta
    } = state;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];

    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "15";
    }
    let counter = 0
    for (let key in gcps) {
        let item = gcps[key]
        counter++
        item.counter = Number(page) * Number(pagesize) + counter
    }

    useEffect(() => {
        fetchHqGcps(dispatch,
             page, pagesize);
    }, [ page, pagesize]);


    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };


    const appts2 = gcps.map(p => {
        return (
            <tr key={p.id}>
                <td>{p.counter}</td>
                <td>{p.licenseeName}</td>
                <td><Link to={`/hq-products/?lk=${p.licenceKey}`}>{p.licenceKey}</Link></td>
                <td>{p.licenceStatus}</td>
                <td>{p.dateCreated}</td>
                <td>{p.dateUpdated}</td>
            </tr>
        )
    });

    return (

        <div className="inner-container">
            <br/>

            <div>
                <h5 style={{marginBottom: "10px"}}><strong>Нийт {meta.totalElements}</strong>
                </h5>

                <nav className="pagination">
                    <ReactPaginate previousLabel={"Өмнөх"}
                                   nextLabel={"Дараах"}
                                   initialPage={Number(page)}
                                   breakLabel={"..."}
                                   breakClassName={"pagination-ellipsis"}
                                   pageCount={meta.totalPages}
                                   marginPagesDisplayed={2}
                                   pageRangeDisplayed={5}
                                   activeLinkClassName={"is-current"}
                                   pageLinkClassName={"pagination-link"}
                                   onPageChange={(e) => handlePageClick(e)}
                                   previousClassName={"pagination-previous"}
                                   nextClassName={"pagination-next"}
                                   containerClassName={"pagination-list"}
                                   forcePage={Number(page)}
                    />
                </nav>


            </div>
            <br/>
            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Licensee Name</th>
                    <th>Licence Key</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>Updated</th>
                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>
            <br/><br/>
        </div>
    )

}

export default HqGcpsPage

