/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";


const ProductEditComponentSscc = ({ product,
                                  onChangeField, disabled
                              }) => {

    return (
        <>
            <div className="field">
                <label className="label">Нэгжийн жин /кг, гр, ш, / *</label>
                <div className="control has-icons-left">
                    <input name="unit_weight" className="input" type="text" value={product.unit_weight} disabled={disabled}
                           onChange={onChangeField} placeholder="Нэгжийн жин оруулах "
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Хайрцаг дахь тоо *</label>
                <div className="control has-icons-left">
                    <input name="package_quantity" className="input" type="text" value={product.package_quantity}  disabled={disabled}
                           onChange={onChangeField} placeholder="Хайрцаг дахь тоо оруулах "
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Тавиур дахь тоо *</label>
                <div className="control has-icons-left">
                    <input name="pallet_quantity" className="input" type="text" value={product.pallet_quantity}  disabled={disabled}
                           onChange={onChangeField} placeholder="Тавиур дахь тоо оруулах "
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Экспорт хийж буй улс *</label>
                <div className="control has-icons-left">
                    <input name="exporting_country" className="input" type="text" value={product.exporting_country}  disabled={disabled}
                           onChange={onChangeField}
                           placeholder="Экспорт хийж буй улс оруулах "
                    />
                </div>
            </div>
        </>
    )

}


export default ProductEditComponentSscc

