/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";
import "react-datepicker/dist/react-datepicker.css";

const SmsMassEditComponent = ({
                                  obj,  onChangeField,  onSave, onCancel}) => {


    const handleSave = (e) => {
        onSave()
    };


    return (
        <div style={{background: 'white'}}>

            <div className="field">
                <label className="label">Нэр</label>
                <div className="control has-icons-left">
                    <input  className="input"  name="name" type="text" value={obj.name}
                           onChange={(e) => onChangeField(e)} placeholder="Нэр оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Sms (Mobicom) <span className="text-sm font-light">[ {obj.length} үсэг]</span></label>
                <div className="control has-icons-left">
                    <input className="input"  name="sms" type="text" value={obj.sms}
                           onChange={(e) => onChangeField(e)} placeholder="Sms оруулах (Англиар)"/>
                </div>
            </div>

            <div className="field">
                <label className="label">Sms (Unitel) <span className="text-sm font-light">[ {obj.length2} үсэг]</span></label>
                <div className="control has-icons-left">
                    <input className="input"  name="sms2" type="text" value={obj.sms2}
                           onChange={(e) => onChangeField(e)} placeholder="Sms оруулах"/>
                </div>
            </div>

            <div className="field">
                <label className="label">Sms (Skytel) <span className="text-sm font-light">[ {obj.length3} үсэг]</span></label>
                <div className="control has-icons-left">
                    <input className="input"  name="sms3" type="text" value={obj.sms3}
                           onChange={(e) => onChangeField(e)} placeholder="Sms оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Илгээх </label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select value={obj.send_audience}  name="send_audience" onChange={(e) => onChangeField(e)}>
                            <option value="ALL">All</option>
                            <option value="SELECTED">Selected</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="buttons is-right" style={{paddingTop: '10px'}}>
                <button className="button" type="primary" onClick={() => onCancel()}>Буцах</button>
                <button className="button is-success" type="primary"
                        onClick={() => handleSave()}>                    Хадгалах                </button>

            </div>
        </div>
    )

}


export default SmsMassEditComponent