/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {connect} from "react-redux";
import React, {Component, useEffect, useState} from "react";
import {fetchSectors} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import {createBrowserHistory} from "history";

const history = createBrowserHistory();

const SectorsPage = ({token, sectors, meta, loadSectors}) => {
    useEffect(() => {
        console.log('useEffect called');
        loadSectors(token, 0, 25)
    }, []);


    const handlePageClick = (data) => {

        let selected = data.selected;
        loadSectors(token, selected, 25);

        let query = {};

        query.page = selected;
        query.size = 25;

        history.push("?" + qs.stringify(query))
    };


    if (!sectors.length) {
        return (
            <div  style={{background: 'white', paddingLeft:'20px', paddingRight:'20px', paddingTop:'20px'}}>
                <div className="level">
                    <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                        <Link to={`/sector-create`} className="button is-primary">
                            Үүсгэх
                        </Link>

                    </div>
                </div>
                <hr/>

                <table className="table is-striped is-fullwidth">
                    <thead>
                    <tr>
                        <th>Нэр</th>

                    </tr>
                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>
        )
    }
    ;

    const appts2 = sectors.map(
        (p) => {
            return (
                <tr key={p.id}>

                    <td>
                        <Link to={`/sector/${p.id}/edit`}>
                            {p.name}
                        </Link>
                    </td>
                </tr>
            )
        });

    return (
        <div  style={{background: 'white', paddingLeft:'20px', paddingRight:'20px', paddingTop:'20px'}}>
            <div className="level">
                <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                    <Link to={`/sector-create`} className="button is-primary">
                        Үүсгэх
                    </Link>

                </div>
            </div>
            <hr/>

            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th>Нэр</th>

                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>

            <ReactPaginate previousLabel={"<"}
            nextLabel={">"}
            initialPage={0}
            breakLabel={<a href="">...</a>}
            breakClassName={"break-me"}
            pageCount={meta.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(e)=>handlePageClick(e)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}/>
        </div>
    )

}


const mapStateToProps = (state, ownProps) => {
    const status = ownProps.match.params.status
    const items = state.sectors.sectors
    let sectors = [];
    for (let key in items) {
        sectors.push(items[key])
    }

    let meta = state.sectors.meta
    if (meta === null) {
        meta = {}
        meta.totalPages = 0
    }
    let token = localStorage.getItem('id_token') || null;
    return {
        token,
        sectors,
        status,
        meta
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        loadSectors: (token, page, pagesize) => {
            dispatch(fetchSectors(token,  page, pagesize))
        }
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SectorsPage)

