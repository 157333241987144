/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useState} from "react";
import {connect} from "react-redux";
import { createSector} from "./actions/actions";
import {Redirect} from "react-router-dom";
import SectorEditComponent from "./SectorEditComponent"

const SectorCreate = ({token,  add, isUpdating}) => {
    const [cancelClicked, setCancelClicked] = useState(false);
    const [sendClicked, setSendClicked] = useState(false);



    const handleSave = ( name ) => {
        console.log('save');
        let dto = {
            name
        };
        add(token,  dto)
        setSendClicked(true)
    };
    const handleCancel = () => {
        console.log('cancel');
        setCancelClicked(true)

    };
    if (cancelClicked || (sendClicked && !isUpdating) ) {
        return <Redirect to={`/sectors`}/>;
    }
    return (
    <div style={{background: 'white'}}>
        <div className="level">
            <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
            </div>
        </div>
        <hr/>

        <SectorEditComponent
            pname={""}
            onSave={handleSave} onCancel={handleCancel}/>

    </div>

    )

}


const mapStateToProps = (state, ownProps) => {
    let isUpdating = state.ui.isUpdating
    let token = localStorage.getItem('id_token') || null;
    return {
        token,
        isUpdating
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

        add: (token,  info) => {
            dispatch(createSector(token, info))
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SectorCreate)