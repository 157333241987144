import React from "react";
import {connect} from "react-redux";
import "./main.scss";
import {fetchDashboard, logoutUser, menuClicked} from "./actions/actions";
import {compose} from "redux";
import {Link} from "react-router-dom";
import ProgressBar from "./ProgressBar";
import {Helmet} from "react-helmet";
import classnames from "classnames";
import packageJson from '../package.json';
import {Menu, PageTitle} from "./Constants";

// const useGetDashboard = (token, getDashboard) => {
//     useEffect(() => {
//             console.log('useEffect App.js');
//             getDashboard(token)
//     }, []);
// };

const MainWrapper = WrappedComponent => (props) => {


    const {
        token,
        logoutUser,
        active,
        sideMenuClicked,
        sidemenu,
        title,
        tempClosed,
        removed,
        gcp,
        gtin,
        invoice,
        productAppl,
        companyAppl,
        getDashboard
    } = props


    const handleLogout = () => {
        logoutUser()
    }

    const handleMenuClick = (menu, title) => {
        sideMenuClicked(menu, title);
    };

    let dashboardClassName = classnames({

        'is-active': sidemenu === Menu.DASHBOARD
    });
    let companiesActiveClassName = classnames({

        'is-active': sidemenu === Menu.COMPANIES_ACTIVE
    });
    let companiesClosedClassName = classnames({

        'is-active': sidemenu === Menu.COMPANIES_TEMP_CLOSED
    });
    let companiesRemovedClassName = classnames({

        'is-active': sidemenu === Menu.COMPANIES_REMOVED
    });
    let companyApplsClassName = classnames({

        'is-active': sidemenu === Menu.COMPANY_APPLS
    });
    let profileClassName = classnames({

        'is-active': sidemenu === Menu.PROFILE
    });
    let invoicesClassName = classnames({

        'is-active': sidemenu === Menu.INVOICES
    });
    let mailsClassName = classnames({

        'is-active': sidemenu === Menu.MAILS
    });
    let smsClassName = classnames({

        'is-active': sidemenu === Menu.SMS
    });
    let prodApplsClassName = classnames({

        'is-active': sidemenu === Menu.PROD_APPLS
    });
    let settingsClassName = classnames({

        'is-active': sidemenu === Menu.SETTINGS
    });
    let productsClassName = classnames({
        'is-active': sidemenu === Menu.PRODUCTS
    });
    let gcpsClassName = classnames({
        'is-active': sidemenu === Menu.GCPS
    });
    let hqGcpsClassName = classnames({
        'is-active': sidemenu === Menu.HQGCPS
    });
    let hqBatchClassName = classnames({
        'is-active': sidemenu === Menu.HQBATCH
    });
    let product8sClassName = classnames({
        'is-active': sidemenu === Menu.PRODUCT8S
    });
    let products14ClassName = classnames({
        'is-active': sidemenu === Menu.PRODUCTS14
    });


    return (


        <div>
            <Helmet
                bodyAttributes={{
                    class: "toolkit",
                }}
            />


            <div style={{display: "flex", flexDirection: "row", height: "100vh", justifyContent: "flex-start"}}>
                <div className="left-pane bg-blue">
                    <div className="sidebar-header" style={{padding: "0px", paddingLeft: "20px", paddingBottom: "0px"}}>
                        <a href="/">
                            <img alt="GS1 logo" style={{maxHeight: "80px", width: "auto"}}
                                 src="https://firebasestorage.googleapis.com/v0/b/gs1web.appspot.com/o/home%2F71346922_529046761240803_5443492588046778368_n.jpg?alt=media&token=ea01a5c0-935e-4aee-80b4-5c2106fc36db"/>
                        </a>
                    </div>

                    <ul className="left-menu">
                        <li className={dashboardClassName}><Link to="/"
                                                                 onClick={() => handleMenuClick(Menu.DASHBOARD, "Dashboard")}>Dashboard</Link>
                        </li>
                        <li className={companiesActiveClassName}><Link to="/companies/active"
                                                                       onClick={() => handleMenuClick(Menu.COMPANIES_ACTIVE, PageTitle.COMPANIES_ACTIVE)}>{PageTitle.COMPANIES_ACTIVE} {active > 0 &&
                        <span>({active})</span>}</Link></li>
                        <li className={companiesRemovedClassName}><Link to="/companies/removed"
                                                                        onClick={() => handleMenuClick(Menu.COMPANIES_REMOVED, PageTitle.COMPANIES_REMOVED)}>{PageTitle.COMPANIES_REMOVED} {removed > 0 &&
                        <span>({removed})</span>}</Link></li>
                        <li className={companiesClosedClassName}><Link to="/companies/temp_closed"
                                                                       onClick={() => handleMenuClick(Menu.COMPANIES_TEMP_CLOSED, PageTitle.COMPANIES_TEMP_CLOSED)}>{PageTitle.COMPANIES_TEMP_CLOSED} {tempClosed > 0 &&
                            <span>({tempClosed})</span>}</Link></li>
                        <li className={gcpsClassName}><Link to="/gcps"
                                                            onClick={() => handleMenuClick(Menu.GCPS, PageTitle.GCPS)}>{PageTitle.GCPS}</Link>
                        </li>

                        <li className={productsClassName}><Link to="/products"
                                                                onClick={() => handleMenuClick(Menu.PRODUCTS, PageTitle.PRODUCTS)}>{PageTitle.PRODUCTS}</Link>
                        </li>
                        <li className={companyApplsClassName}><Link to="/company-appls"
                                                                    onClick={() => handleMenuClick(Menu.COMPANY_APPLS, PageTitle.COMPANY_APPLS)}>{PageTitle.COMPANY_APPLS} {companyAppl > 0 &&
                            <span>({companyAppl})</span>}</Link></li>
                        <li className={prodApplsClassName}><Link to="/prod-appls"
                                                                 onClick={() => handleMenuClick(Menu.PROD_APPLS, PageTitle.PROD_APPLS)}>{PageTitle.PROD_APPLS} {productAppl > 0 &&
                            <span>({productAppl})</span>}</Link></li>
                        <li className={invoicesClassName}><Link to="/invoices"
                                                                onClick={() => handleMenuClick(Menu.INVOICES, PageTitle.INVOICES)}>{PageTitle.INVOICES} {invoice > 0 &&
                        <span>({invoice})</span>}</Link></li>
                        <li className={mailsClassName}><Link to="/invoice-mails"
                                                             onClick={() => handleMenuClick(Menu.MAILS, "Mails")}>Mails</Link></li>
                        <li className={smsClassName}><Link to="/sms-items"
                                                             onClick={() => handleMenuClick(Menu.SMS, "Sms")}>Sms</Link></li>

                        <li className={product8sClassName}><Link to="/product8s"
                                                                onClick={() => handleMenuClick("GS1-8", "GS1-8")}>GS1-8</Link></li>
                        <li className={products14ClassName}><Link to="/products14"
                                                                 onClick={() => handleMenuClick("GS1-14", "GS1-14")}>GS1-14</Link></li>
                        <li className={settingsClassName}><Link to="/settings"
                                                                onClick={() => handleMenuClick(Menu.SETTINGS, PageTitle.SETTINGS)}>{PageTitle.SETTINGS}</Link>
                        </li>
                        <li className={profileClassName}><Link to="/profile"
                                                               onClick={() => handleMenuClick(Menu.PROFILE, PageTitle.PROFILE)}>{PageTitle.PROFILE}</Link>
                        </li>
                        <li className={hqGcpsClassName}><Link to="/hq-gcps"
                                                            onClick={() => handleMenuClick(Menu.HQGCPS, PageTitle.HQ_GCPS)}>{PageTitle.HQ_GCPS}</Link>
                        </li>
                        <li className={hqBatchClassName}><Link to="/hq-batches"
                                                              onClick={() => handleMenuClick(Menu.HQBATCH, PageTitle.HQ_BATCH)}>{PageTitle.HQ_BATCH}</Link>
                        </li>
                        <li><a href="#" onClick={() => handleLogout()}>Logout</a></li>
                    </ul>
                </div>

                <div style={{flexGrow: "1", display: "flex", flexDirection: "column", overflowX: "scroll"}}>
                    <div style={{height: "80px", borderBottom: "1px solid #cdcdcd", backgroundColor: "white"}}>
                        <h1 style={{paddingLeft: "20px", fontSize: "28px", fontWeight: 600, color: "#002c6c"}}>
                            {title}</h1>
                    </div>
                    <div className="menu-pane">

                        <ul className="left-menu">
                            <li className={dashboardClassName}><Link to="/"
                                                                     onClick={() => handleMenuClick(Menu.DASHBOARD, "Dashboard")}>Dashboard</Link>
                            </li>
                            <li className={companiesActiveClassName}><Link to="/companies/active"
                                                                           onClick={() => handleMenuClick(Menu.COMPANIES_ACTIVE, "Байгууллагууд")}>Байгууллагууд {active > 0 &&
                            <span>({active})</span>}</Link></li>
                            <li className={companiesRemovedClassName}><Link to="/companies/removed"
                                                                            onClick={() => handleMenuClick(Menu.COMPANIES_REMOVED, "Байгууллагууд - Хасагдсан")}>Хасагдсан {removed > 0 &&
                            <span>({removed})</span>}</Link></li>
                            <li className={companiesClosedClassName}><Link to="/companies/temp_closed"
                                                                           onClick={() => handleMenuClick(Menu.COMPANIES_TEMP_CLOSED, "Байгууллагууд - Түр Хаагдсан")}>Түр
                                Хаагдсан {tempClosed > 0 &&
                                <span>({tempClosed})</span>}</Link></li>
                            <li className={gcpsClassName}><Link to="/gcps"
                                                                onClick={() => handleMenuClick(Menu.GCPS, "Gcps")}>Gcps</Link>
                            </li>

                            <li className={productsClassName}><Link to="/products"
                                                                    onClick={() => handleMenuClick(Menu.PRODUCTS, "Бүтээгдэхүүнүүд")}>Бүтээгдэхүүнүүд</Link>
                            </li>
                            <li className={companyApplsClassName}><Link to="/company-appls"
                                                                        onClick={() => handleMenuClick(Menu.COMPANY_APPLS, "Шинэ Байгууллага")}>Шинэ
                                Байгууллага {companyAppl > 0 &&
                                <span>({companyAppl})</span>}</Link></li>
                            <li className={prodApplsClassName}><Link to="/prod-appls"
                                                                     onClick={() => handleMenuClick(Menu.PROD_APPLS, "Шинэ Бүтээгдэхүүн")}>Шинэ
                                Бүтээгдэхүүн {productAppl > 0 &&
                                <span>({productAppl})</span>}</Link></li>
                            <li className={invoicesClassName}><Link to="/invoices"
                                                                    onClick={() => handleMenuClick(Menu.INVOICES, "Нэхэмжлэхүүд")}>Нэхэмжлэхүүд {invoice > 0 &&
                            <span>({invoice})</span>}</Link></li>
                            <li className={mailsClassName}><Link to="/invoice-mails"
                                                                    onClick={() => handleMenuClick(Menu.MAILS, "Mails")}>Mails</Link></li>
                            <li className={smsClassName}><Link to="/sms-items"
                                                                 onClick={() => handleMenuClick(Menu.SMS, "Sms")}>Sms</Link></li>
                            <li className={product8sClassName}><Link to="/product8s"
                                                                    onClick={() => handleMenuClick("GS1-8", "GS1-8")}>GS1-8</Link></li>

                            <li className={settingsClassName}><Link to="/settings"
                                                                    onClick={() => handleMenuClick(Menu.SETTINGS, "Тохиргоо")}>Тохиргоо</Link>
                            </li>
                            <li className={profileClassName}><Link to="/profile"
                                                                   onClick={() => handleMenuClick(Menu.PROFILE, "Профайл")}>Профайл</Link>
                            </li>
                            <li><a href="#" onClick={() => handleLogout()}>Logout</a></li>
                        </ul>
                    </div>
                    <div style={{flexGrow: "1"}}>
                        <div style={{backgroundColor: "#efefef", padding: "10px"}}>
                            <ProgressBar/>
                            <WrappedComponent {...props}/>
                        </div>
                        <footer>
                            <div style={{marginLeft: "10px"}}>
                                <p>Copyright &copy;2019-{new Date().getFullYear()} GS1 Монгол Нийгэмлэг. The Global Language Of Business,
                                    Version {packageJson.version}</p>

                            </div>
                        </footer>
                    </div>
                </div>

            </div>


        </div>

    );
};


const mapStateToProps = (state) => {
    let token = localStorage.getItem('id_token') || null;

    const active = state.dashboard.companyActive
    const tempClosed = state.dashboard.companyTempClosed
    const removed = state.dashboard.companyRemoved
    const gcp = state.dashboard.gcp
    const gtin = state.dashboard.gtin
    const invoice = state.dashboard.invoice
    const productAppl = state.dashboard.productAppl
    const companyAppl = state.dashboard.companyAppl

    const title = state.ui.title;
    const sidemenu = state.ui.sidemenu;

    return {
        token,
        active,
        title,
        sidemenu,
        tempClosed,
        removed,
        gcp,
        gtin,
        invoice,
        productAppl,
        companyAppl
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: () => {
            dispatch(logoutUser())
        },
        sideMenuClicked: (menu, title) => {
            dispatch(menuClicked(menu, title))
        },
        getDashboard: (token) => {
            dispatch(fetchDashboard(token))
        },
    }
}


const composedWrapApp = compose(connect(mapStateToProps, mapDispatchToProps), MainWrapper);

export default composedWrapApp;
