/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {uploadBarcode} from "./actions/actions";
import CompanyViewHeader from "./CompanyViewHeader";
import DatePicker from "react-datepicker";
import {css} from "@emotion/react";
import { BeatLoader } from 'react-spinners';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;


const BarcodeCreate = ({token, companyId, gcpId, add, isUpdating}) => {
    const [issued, setIssued] = useState(new Date());
    const [selectedFile, setSelectedFile] = useState(null);
    const [cancelClicked, setCancelClicked] = useState(false);
    const [sendClicked, setSendClicked] = useState(false);

    useEffect(() => {
        console.log('useEffect called');

    }, []);
    const handleChangeIssued = (date) => {
        setIssued(date)
    };

    const handleChangeFile = (e) => {
        console.log(e.target.files[0])

        setSelectedFile(e.target.files[0])
    };

    const handleSave = () => {
        console.log('send');
        setSendClicked(true)
        add(token, gcpId, selectedFile, issued);
    };
    const handleCancel = () => {
        console.log('cancel');
        setCancelClicked(true)
    };

    if (cancelClicked ) {

        return <Redirect to={`/company/${companyId}/gcp/${gcpId}/barcodes`}/>;
    }
    if (sendClicked && !isUpdating) {
        return <Redirect to={`/company/${companyId}/gcp/${gcpId}/barcodes`}/>;
    }
    if (sendClicked && isUpdating) {
        return <Loading/>
    } else {
        return (
            <div style={{
                background: 'white',
                paddingLeft: '20px',
                paddingTop: '20px',
                paddingRight: '20px',
                paddingBottom: "20px"
            }}>
                <CompanyViewHeader tab={2} companyId={companyId}/>


                <div className="level">
                    <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                        <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                    </div>
                </div>
                <hr/>

                <div style={{background: 'white'}}>

                    <div className="field">
                        <label className="label">Олгогдсон огноо</label>
                        <div className="control has-icons-left">
                            <DatePicker className="input" selected={issued}
                                        onChange={handleChangeIssued} placeholderText="Олгогдсон огноо оруулах"/>
                        </div>
                    </div>

                    <div className="field">
                        <div className="control has-icons-left">
                            <div class="file has-name">
                                <label class="file-label">
                                    <input type="file" className="file-input" name="file"
                                           onChange={(e) => handleChangeFile(e)}/>
                                    <span class="file-cta">
      <span class="file-icon">
        <i class="fas fa-upload"></i>
      </span>
      <span class="file-label">
        Choose a file…
      </span>
    </span>
                                    <span class="file-name">
                                {selectedFile && selectedFile.name}
                                        {selectedFile === null && <span>No file chosen</span>}
    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="buttons is-right" style={{paddingTop: '10px'}}>
                    <button className="button" type="primary" onClick={() => handleCancel()}>Болих</button>
                    <button className="button is-success" type="primary"
                            onClick={(e) => handleSave(e)}>
                        Хадгалах
                    </button>
                </div>

            </div>
        )
    }
}

const Loading = () =>  {
    return (
        <div style={{background: 'white', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}>
            <div className='sweet-loading'>
                <BeatLoader
                    css={override}
                    sizeUnit={"px"}
                    size={15}
                    color={'#123abc'}
                    loading={true}
                />
            </div>
        </div>
    )
}


const mapStateToProps = (state, ownProps) => {
    let token = localStorage.getItem('id_token') || null;

    const companyId = ownProps.match.params.id
    const gcpId = ownProps.match.params.gcpId
    const isUpdating = state.ui.isUpdating
    return {
        token,
        gcpId,
        companyId,
        isUpdating
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

        add: (token, id, file, issued) => {
            dispatch(uploadBarcode(token, id, file, issued))
        },

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(BarcodeCreate)