/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import aimaksData from "./data/aimaks.json"


const CompanySelfEditComponent = ({pestablished, onSave}) => {
    const [name, setName] = useState("");
    const [nameEnglish, setNameEnglish] = useState("");

    const [stateRegNumber, setStateRegNumber] = useState("");
    const [registryNumber, setRegistryNumber] = useState("");
    const [address, setAddress] = useState("");
    const [soum, setSoum] = useState("");

    const [established, setEstablished] = useState(pestablished);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [social, setSocial] = useState("");
    const [web, setWeb] = useState("");
    const [district, setDistrict] = useState("");
    const [sector, setSector] = useState("");
    const [aimak, setAimak] = useState("");
    const [postalAddress, setPostalAddress] = useState("");

    const [isExported, setIsExported] = useState(false);
    const [isExportedCountries, setIsExportedCountries] = useState("");


    const [ceoLastname, setCeoLastname] = useState("");
    const [ceoName, setCeoName] = useState("");
    const [ceoPosition, setCeoPosition] = useState("");
    const [ceoPhone, setCeoPhone] = useState("");
    const [ceoEmail, setCeoEmail] = useState("");

    const [adminLastname, setAdminLastname] = useState("");
    const [adminName, setAdminName] = useState("");
    const [adminPosition, setAdminPosition] = useState("");
    const [adminPhone, setAdminPhone] = useState("");
    const [adminEmail, setAdminEmail] = useState("");

    const [senderName, setSenderName] = useState("");
    const [senderPosition, setSenderPosition] = useState("");
    const [senderPhone, setSenderPhone] = useState("");
    const [senderEmail, setSenderEmail] = useState("");

    const [errors, setErrors] = useState({
        name:'',
        nameEnglish:'',
        registryNumber:'',
        established:'',
        aimak:'',
        district:'',
        address:'',
        soum:'',
        phone:'',
        email:'',
        social:'',
        sector:'',

        ceoLastname:'',
        ceoName:'',
        ceoPosition:'',
        ceoPhone:'',
        ceoEmail:'',

        adminLastname:'',
        adminName:'',
        adminPosition:'',
        adminPhone:'',
        adminEmail:'',

        senderName:'',
        senderPosition:'',
        senderPhone:'',
        senderEmail:''

    });

    const ssectorsNoAuth = useSelector(state => state.sectorsNoAuth);
    let sectors = ssectorsNoAuth.sectors;


    useEffect(() => {
        console.log('useEffect called');
    }, []);


    const handleChangeName = (e) => {
        setName(e.target.value)
    };
    const handleChangeNameEnglish = (e) => {
        setNameEnglish(e.target.value)
    };
    const handleChangeStateRegNumber = (e) => {
        setStateRegNumber(e.target.value)
    };
    const handleChangeSector = (e) => {
        setSector(Number(e.target.value))
    };
    const handleChangeRegistryNumber = (e) => {
        setRegistryNumber(e.target.value)
    };
    const handleChangeIsExported = (e) => {
        setIsExported(e.target.checked)
    };
    const handleChangeIsExportedCountries = (e) => {
        setIsExportedCountries(e.target.value)
    };


    const handleChangeEstablished = (date) => {
        setEstablished(date)
    };
    const handleChangeSoum = (e) => {
        setSoum(e.target.value)
    };
    const handleChangePhone = (e) => {
        setPhone(e.target.value)
    };
    const handleChangeDistrict = (e) => {
        setDistrict(e.target.value)
    };
    const handleChangeAimak = (e) => {
        setAimak(e.target.value)
    };
    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    };
    const handleChangeSocial = (e) => {
        setSocial(e.target.value)
    };
    const handleChangeWeb = (e) => {
        setWeb(e.target.value)
    };

    const handleChangeAddress = (e) => {
        setAddress(e.target.value)
    };
    const handleChangePostalAddress = (e) => {
        setPostalAddress(e.target.value)
    };

    const districtOptions = () => {
        let items = [];
        // if (aimak) {
        //     let soums = all_soums.get(aimak);
        //     for (let [key, value] of soums) {
        //         items.push(<option key={key} value={key}>{value}</option>);
        //     }
        //
        // }

        if (aimak) {
            let ra = aimaksData.filter(a=>a.id === aimak);
            if (ra.length>0) {
                ra[0].units.map(item=>{
                    items.push(<option key={item.id} value={item.id}>{item.name}</option>);
                })

            }
        }
        return items;
    };
    const aimaksOptions = () => {
        let items = [];
        aimaksData.map(item=>{
            items.push(<option key={item.id} value={item.id}>{item.name}</option>);
        })

        return items;
    };
    const sectorsOptions = () => {
        let items = [];
        for (let key in sectors) {
            let s = sectors[key]
            items.push(<option key={s.id} value={s.id}>{s.name}</option>);
        }
        return items;
    };


    const handleChangeCeoLastname = (e) => {
        setCeoLastname(e.target.value)
    };
    const handleChangeCeoName = (e) => {
        setCeoName(e.target.value)
    };
    const handleChangeCeoPosition = (e) => {
        setCeoPosition(e.target.value)
    };

    const handleChangeCeoPhone = (e) => {
        setCeoPhone(e.target.value)
    };
    const handleChangeCeoEmail = (e) => {
        setCeoEmail(e.target.value)
    };

    const handleChangeAdminLastname = (e) => {
        setAdminLastname(e.target.value)
    };
    const handleChangeAdminName = (e) => {
        setAdminName(e.target.value)
    };
    const handleChangeAdminPosition = (e) => {
        setAdminPosition(e.target.value)
    };

    const handleChangeAdminPhone = (e) => {
        setAdminPhone(e.target.value)
    };
    const handleChangeAdminEmail = (e) => {
        setAdminEmail(e.target.value)
    };

    const handleChangeSenderName = (e) => {
        setSenderName(e.target.value)
    };
    const handleChangeSenderPhone = (e) => {
        setSenderPhone(e.target.value)
    };
    const handleChangeSenderPosition = (e) => {
        setSenderPosition(e.target.value)
    };
    const handleChangeSenderEmail = (e) => {
        setSenderEmail(e.target.value)
    };

    const validate =(e) => {
        let ret = false;
        let errors = {
            name:'',
            nameEnglish:'',
            registryNumber:'',
            established:'',
            aimak:'',
            district:'',
            address:'',
            soum:'',
            phone:'',
            email:'',
            social:'',
            sector:'',

            ceoLastname:'',
            ceoName:'',
            ceoPosition:'',
            ceoPhone:'',
            ceoEmail:'',

            adminLastname:'',
            adminName:'',
            adminPosition:'',
            adminPhone:'',
            adminEmail:'',

            senderName:'',
            senderPosition:'',
            senderPhone:'',
            senderEmail:''
        };
        if (!name) {
            errors.name = 'Нэр заавал оруулна!';
            ret = true;
        }
        if (!nameEnglish) {
            errors.nameEnglish = 'Нэр (Англиар) заавал оруулна!';
            ret = true;
        }
        if (!registryNumber) {
            errors.registryNumber = 'Регистрийн дугаар заавал оруулна!';
            ret = true;
        }
        if (!established) {
            errors.established = 'Байгуулагдсан огноо заавал оруулна!';
            ret = true;
        }
        if (!aimak) {
            errors.aimak = 'Аймаг/Хот * заавал оруулна!';
            ret = true;
        }
        if (!district) {
            errors.district = 'Дүүрэг заавал оруулна!';
            ret = true;
        }
        if (!address) {
            errors.address = 'Гудамж/Тоот заавал оруулна!';
            ret = true;
        }
        if (!soum) {
            errors.soum = 'Сум / Хороо заавал оруулна!';
            ret = true;
        }
        if (!phone) {
            errors.phone = 'Утас заавал оруулна!';
            ret = true;
        }
        if (!email) {
            errors.email = 'Мэйл заавал оруулна!';
            ret = true;
        }
        if (!social) {
            errors.social = 'Сошиал хаяг заавал оруулна!';
            ret = true;
        }
        if (!sector) {
            errors.sector = 'Үйл ажиллагааны чиглэл заавал оруулна!';
            ret = true;
        }

        if (!ceoLastname) {
            errors.ceoLastname = 'Захирлын овог заавал оруулна!';
            ret = true;
        }
        if (!ceoName) {
            errors.ceoName = 'Захирлын нэр заавал оруулна!';
            ret = true;
        }
        if (!ceoPosition) {
            errors.ceoPosition = 'Захирлын албан тушаал заавал оруулна!';
            ret = true;
        }
        if (!ceoPhone) {
            errors.ceoPhone = 'Захирлын утас заавал оруулна!';
            ret = true;
        }
        if (!ceoEmail) {
            errors.ceoEmail = 'Захирлын email заавал оруулна!';
            ret = true;
        }

        if (!adminLastname) {
            errors.adminLastname = 'Хариуцах ажилтны овог заавал оруулна!';
            ret = true;
        }
        if (!adminName) {
            errors.adminName = 'Хариуцах ажилтны нэр заавал оруулна!';
            ret = true;
        }
        if (!adminPosition) {
            errors.adminPosition = 'Хариуцах ажилтны албан тушаал заавал оруулна!';
            ret = true;
        }
        if (!adminPhone) {
            errors.adminPhone = 'Хариуцах ажилтны утас заавал оруулна!';
            ret = true;
        }
        if (!adminEmail) {
            errors.adminEmail = 'Хариуцах ажилтны email заавал оруулна!';
            ret = true;
        }

        if (!senderName) {
            errors.senderName = 'Илгээгчийн нэр заавал оруулна!';
            ret = true;
        }
        if (!senderPosition) {
            errors.senderPosition = 'Илгээгчийн албан тушаал заавал оруулна!';
            ret = true;
        }
        if (!senderPhone) {
            errors.senderPhone = 'Илгээгчийн утас заавал оруулна!';
            ret = true;
        }
        if (!senderEmail) {
            errors.senderEmail = 'Илгээгчийн email заавал оруулна!';
            ret = true;
        }

        setErrors(errors);
        return ret;
    }
    const handleSubmit = (e) => {
        let ret = validate()
        if (!ret) {
            onSave(
                name, nameEnglish, stateRegNumber, registryNumber,
                established,
                district, aimak, soum, address, phone, postalAddress,
                email, social, web,
                sector,
                isExported, isExportedCountries,
                ceoLastname, ceoName, ceoPosition, ceoPhone, ceoEmail,
                adminLastname, adminName, adminPosition, adminPhone, adminEmail,
                senderName, senderPosition, senderPhone, senderEmail
            )
        }
    }

    return (
        <div style={{paddingBottom: "20px"}}>

            <div className="field">
                <label className="label">Хуулийн этгээдийн нэр, /Хувь хүн бол овог нэр/ хэлбэр, төрөл  *</label>
                <div className="control">
                    <input className="input" type="text" value={name}
                           onChange={(e) => handleChangeName(e)} required={true} placeholder="Нэр оруулах"/>
                </div>
                {errors.name != '' && <p className="help is-danger">{errors.name}</p>}
            </div>
            <div className="field">
                <label className="label">Нэр Англиар * </label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={nameEnglish}
                           onChange={(e) => handleChangeNameEnglish(e)} placeholder="Нэр оруулах (Англиар)"/>
                </div>
                {errors.nameEnglish != '' && <p className="help is-danger">{errors.nameEnglish}</p>}
            </div>
            <div className="field">
                <label className="label">Улсын бүртгэлийн дугаар</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={stateRegNumber}
                           onChange={(e) => handleChangeStateRegNumber(e)}
                           placeholder="Улсын бүртгэлийн дугаар оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Регистрийн дугаар *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={registryNumber}
                           onChange={(e) => handleChangeRegistryNumber(e)} placeholder="Регистрийн дугаар оруулах"/>
                </div>
                {errors.registryNumber !== '' && <p className="help is-danger">{errors.registryNumber}</p>}
            </div>
            <div className="field">
                <label className="label">Байгуулагдсан огноо *</label>
                <div className="control has-icons-left">
                    <DatePicker className="input" selected={established}
                                onChange={handleChangeEstablished} placeholderText="Байгуулагдсан огноо оруулах"/>
                </div>
                {errors.established != '' && <p className="help is-danger">{errors.established}</p>}
            </div>
            <div className="field">
                <label className="label">Аймаг/Хот *</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select value={aimak} onChange={(e) => handleChangeAimak(e)}>
                            <option value=""></option>
                            {aimaksOptions()}
                        </select>
                    </div>
                </div>
                {errors.aimak != '' && <p className="help is-danger">{errors.aimak}</p>}
            </div>
            <div className="field">
                <label className="label">Сум/Дүүрэг *</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select value={district} onChange={(e) => handleChangeDistrict(e)}>
                            <option value=""></option>
                            {districtOptions()}
                        </select>
                    </div>
                </div>
                {errors.district != '' && <p className="help is-danger">{errors.district}</p>}
            </div>

            <div className="field">
                <label className="label">Баг/Хороо *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={soum}
                           onChange={(e) => handleChangeSoum(e)} placeholder="Сум оруулах"/>
                </div>
                {errors.soum != '' && <p className="help is-danger">{errors.soum}</p>}
            </div>

            <div className="field">
                <label className="label">Гудамж/Тоот *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={address}
                           onChange={(e) => handleChangeAddress(e)} placeholder="Гудамж/Тоот оруулах"/>
                </div>
                {errors.address != '' && <p className="help is-danger">{errors.address}</p>}
            </div>
            <div className="field">
                <label className="label">Утас *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={phone}
                           onChange={(e) => handleChangePhone(e)} placeholder="Утас оруулах"/>
                </div>
                {errors.phone != '' && <p className="help is-danger">{errors.phone}</p>}
            </div>
            <div className="field">
                <label className="label">Шуудангийн хаяг</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={postalAddress}
                           onChange={(e) => handleChangePostalAddress(e)} placeholder="Шуудангийн хаяг оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Мэйл *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={email}
                           onChange={(e) => handleChangeEmail(e)} placeholder="Мэйл оруулах"/>
                </div>
                {errors.email != '' && <p className="help is-danger">{errors.email}</p>}
            </div>
            <div className="field">
                <label className="label">Сошиал хаяг /Facebook URL (Жишээ нь https://www.facebook.com/GS1Mongolia )*</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={social}
                           onChange={(e) => handleChangeSocial(e)} placeholder="Сошиал хаяг оруулах"/>
                </div>
                {errors.social != '' && <p className="help is-danger">{errors.social}</p>}
            </div>
            <div className="field">
                <label className="label">Вебхуудас</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={web}
                           onChange={(e) => handleChangeWeb(e)} placeholder="Вебхуудас оруулах"/>
                </div>
            </div>


            <div className="field">
                <label className="label">Экспорт хийдэг эсэх</label>
                <div className="control has-icons-left">
                    <input className="checkbox" type="checkbox" checked={isExported}
                           onChange={(e) => handleChangeIsExported(e)}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Тийм бол экспорт хийж буй улсын нэр/ Үгүй бол хийхээр төлөвлөж байгаа
                    улс</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={isExportedCountries}
                           onChange={(e) => handleChangeIsExportedCountries(e)} placeholder="Улс оруулах"/>
                </div>
            </div>

            <div className="field">
                <label className="label">Үйл ажиллагааны чиглэл *</label>
                <div className="control has-icons-left">
                    <div className="select" style={{maxWidth:"300px"}}>
                        <select  value={sector} onChange={(e) => handleChangeSector(e)}>
                            <option value=""></option>
                            {sectorsOptions()}
                        </select>
                    </div>
                </div>
                {errors.sector != '' && <p className="help is-danger">{errors.sector}</p>}
            </div>

            <div className="field">
                <label className="label">Захирал</label>
            </div>
            <div className="field">
                <label className="label">Овог *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={ceoLastname}
                           onChange={(e) => handleChangeCeoLastname(e)} placeholder="Захирлын овог оруулах"/>
                </div>
                {errors.ceoLastname != '' && <p className="help is-danger">{errors.ceoLastname}</p>}
            </div>
            <div className="field">
                <label className="label">Нэр *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={ceoName}
                           onChange={(e) => handleChangeCeoName(e)} placeholder="Захирлын нэр оруулах"/>
                </div>
                {errors.ceoName != '' && <p className="help is-danger">{errors.ceoName}</p>}
            </div>
            <div className="field">
                <label className="label">Албан тушаал *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={ceoPosition}
                           onChange={(e) => handleChangeCeoPosition(e)} placeholder="Захирлын албан тушаал оруулах"/>
                </div>
                {errors.ceoPosition != '' && <p className="help is-danger">{errors.ceoPosition}</p>}
            </div>
            <div className="field">
                <label className="label">Утас *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={ceoPhone}
                           onChange={(e) => handleChangeCeoPhone(e)} placeholder="Захирлын утас оруулах"/>
                </div>
                {errors.ceoPhone != '' && <p className="help is-danger">{errors.ceoPhone}</p>}
            </div>
            <div className="field">
                <label className="label">Емайл *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={ceoEmail}
                           onChange={(e) => handleChangeCeoEmail(e)} placeholder="Захирлын емайл оруулах"/>
                </div>
                {errors.ceoEmail != '' && <p className="help is-danger">{errors.ceoEmail}</p>}
            </div>

            <div className="field">
                <label className="label">Хариуцах ажилтан /Зураасан код хариуцсан ажилтан/</label>
            </div>

            <div className="field">
                <label className="label">Овог *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={adminLastname}
                           onChange={(e) => handleChangeAdminLastname(e)} placeholder="Хариуцах ажилтны овог оруулах"/>
                </div>
                {errors.adminLastname != '' && <p className="help is-danger">{errors.adminLastname}</p>}
            </div>
            <div className="field">
                <label className="label">Нэр *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={adminName}
                           onChange={(e) => handleChangeAdminName(e)} placeholder="Хариуцах ажилтны нэр оруулах"/>
                </div>
                {errors.adminName != '' && <p className="help is-danger">{errors.adminName}</p>}
            </div>
            <div className="field">
                <label className="label">Албан тушаал *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={adminPosition}
                           onChange={(e) => handleChangeAdminPosition(e)}
                           placeholder="Хариуцах ажилтны албан тушаал оруулах"/>
                </div>
                {errors.adminPosition != '' && <p className="help is-danger">{errors.adminPosition}</p>}
            </div>
            <div className="field">
                <label className="label">Утас *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={adminPhone}
                           onChange={(e) => handleChangeAdminPhone(e)} placeholder="Хариуцах ажилтны утас оруулах"/>
                </div>
                {errors.adminPhone != '' && <p className="help is-danger">{errors.adminPhone}</p>}
            </div>
            <div className="field">
                <label className="label">Емайл *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={adminEmail}
                           onChange={(e) => handleChangeAdminEmail(e)} placeholder="Хариуцах ажилтны емайл оруулах"/>
                </div>
                {errors.adminEmail != '' && <p className="help is-danger">{errors.adminEmail}</p>}
            </div>



            <div className="field">
                <label className="label">Илгээсэн</label>
            </div>

            <div className="field">
                <label className="label">Илгээгч: Нэр *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={senderName}
                           onChange={(e) => handleChangeSenderName(e)} placeholder="Илгээгчийн нэр оруулах"/>
                </div>
                {errors.senderName != '' && <p className="help is-danger">{errors.senderName}</p>}
            </div>
            <div className="field">
                <label className="label">Илгээгч: Албан тушаал *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={senderPosition}
                           onChange={(e) => handleChangeSenderPosition(e)}
                           placeholder="Илгээгчийн албан тушаал оруулах"/>
                </div>
                {errors.senderPosition != '' && <p className="help is-danger">{errors.senderPosition}</p>}
            </div>
            <div className="field">
                <label className="label">Илгээгч: Утас *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={senderPhone}
                           onChange={(e) => handleChangeSenderPhone(e)} placeholder="Илгээгчийн утас оруулах"/>
                </div>
                {errors.senderPhone != '' && <p className="help is-danger">{errors.senderPhone}</p>}
            </div>
            <div className="field">
                <label className="label">Илгээгч: Емайл *</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={senderEmail}
                           onChange={(e) => handleChangeSenderEmail(e)} placeholder="Илгээгчийн email оруулах"/>
                </div>
                {errors.senderEmail != '' && <p className="help is-danger">{errors.senderEmail}</p>}
            </div>

            <div class="buttons is-right" style={{paddingTop: '10px'}}>
                <button className="button is-large bg-orange" type="primary" style={{color: "white"}}
                        onClick={(e) => handleSubmit(e)}>
                    Илгээх
                </button>
            </div>

        </div>
    )

}


export default CompanySelfEditComponent