/**
 * Created by gantushig on 4/16/17.
 */

import React, {useReducer} from "react";
import ContactEditComponent from './ContactEditComponent';
import {useParams} from "react-router-dom";
import {css} from "@emotion/react";
import {TOKEN} from "./Constants";
import history from "./history"
import {toast} from "react-toastify";
import {createContact} from "./actions/actions";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    contact: {},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_FIELD':
            let c = {...state.contact};
            c[action.name] = action.value
            return {
                ...state,
                contact: c,
                loading: false
            }
        default:
            return state;
    }
};


const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}



// {
//     match: {params: {id}
const ContactCreate = ({onUpdate}) => {
    let {companyId} = useParams()
    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        contact,
        loading
    } = state;

    const handleChangeField =(e)=> {
        dispatch(changeField(e.target.name, e.target.value))
    }


    const handleSave = () => {
        console.log('send');
        let dto = {
            name:contact.name,
            surname:contact.surname,
            gender:contact.gender,
            position_type:contact.position_type,
            position:contact.position,
            phone:contact.phone,
            email:contact.email
        };
        createContact(dispatch, token, companyId, dto).then(response => {
                toast.success("Амжилттай үүсгэлээ!");
                //alert("Амжилттай өөрчиллөө!")
                onUpdate();
                history.push(`/company/${companyId}/contacts`)
            }
        ).catch(error => {
            alert(error)
        });
    };

    const handleCancel = () => {
        history.goBack(); //push(`/company/${id}/contacts`)
    };

    return (
        <>
            <div className="level">
                <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>

            <ContactEditComponent contact={contact}
                                  onChangeField={handleChangeField}
                onSave={handleSave} onCancel={handleCancel}/>

        </>
    )
}

export default ContactCreate;