/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer, useState} from "react";
import DatePicker from "react-datepicker";
import CompanyViewHeader from "./CompanyViewHeader";
import {Endpoints, TOKEN} from "./Constants";
import history from "./history";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import {css} from "@emotion/react";


const initialState = {
    loading:false,
    file:{},
    issued:new Date()
};


const fetchFileSuccess =(file) => {
    return {
        type: 'FETCH_SUCCESS',
        file
    }
};

const reducer = (state, action) => {
    switch (action.type) {

        case 'UPDATE_SUCCESS':
            return {
                ...state,
                file:action.barcode_image,
                issued:Date.parse(action.barcode_image.issued),
                loading: false
            }

        case 'LOADING':
            return {
                ...state,
                loading: true
            }
        case 'LOADING_STOP':
            return {
                ...state,
                loading: false
            }
        case 'CHANGE_ISSUED':
            return {
                ...state,
                issued: action.date
            }
        case 'FETCH_SUCCESS':
            return {
                ...state,
                file: action.file,
                issued:Date.parse(action.file.issued),
                loading: false
            }
        default:
            throw new Error();
    }
};

const fetchFile = (dispatch, token, id) => {
    dispatch(loadingStart());
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.BARCODE}/${id}`;

    return axios(config)
        .then(response => {

            dispatch(fetchFileSuccess(response.data.file))
        })
        .catch(error => {
            dispatch(loadingStop());
                throw(error);
        });
}

const loadingStart =() => {
    return {
        type: 'LOADING'
    }
};

const changeIssued =(date) => {
    return {
        type: 'CHANGE_ISSUED',
        date:date
    }
};

const loadingStop =() => {
    return {
        type: 'LOADING_STOP'
    }
};


const updateBarcodeSuccess =(barcode_image) => {
    return {
        type: 'UPDATE_SUCCESS',
        barcode_image:barcode_image
    }
};

const updateFile = (dispatch, token, id, info) => {
    dispatch(loadingStart())

    let header = {}
    header["Authorization"] = token
    header["Accept"] = "application/json"
    header["Content-Type"] = "application/json"

    let config = {}
    config["headers"] = header;
    config["url"] = `${Endpoints.BARCODE}/${id}`;
    config["method"] = "post";
    config["data"] = JSON.stringify(info);

    return axios(config)
        .then(response => {

            dispatch(updateBarcodeSuccess(response.data.barcode_image))
        })
        .catch(error => {
            dispatch(loadingStop());
            throw(error);
        });

}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const BarcodeEdit = ({
                         match: {params: {id, gcpId, barcodeId}}
}) => {
    const [sendClicked, setSendClicked] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);

    let token = localStorage.getItem(TOKEN) || null;

    useEffect(() => {
        console.log('useEffect called');
        fetchFile(dispatch, token, barcodeId);
    }, []);

    const handleChangeIssued = (date) => {
        dispatch(changeIssued(date));
    };



    const handleSave = () => {
        console.log('send');
        let iss  = null

        if (state.issued !== null ) {
            // const d = state.issued.toISOString().replace('.000Z', '');
            // const g = new Date(d)
            iss =  state.issued.getTime()
        }
        let dto = {
            issued:iss,
        };

//        setSendClicked(true)
        updateFile(dispatch, token, barcodeId, dto)
    };
    const handleCancel = () => {
        console.log('cancel');
        history.push(`/company/${id}/gcp/${gcpId}/barcodes`);
    };

    // if (sendClicked) {
    //     return <Redirect to={`/company/${id}/gcp/${gcpId}/barcodes`}/>;
    // }
    return (
        <div style={{
            background: 'white',
            paddingLeft: '20px',
            paddingTop: '20px',
            paddingRight: '20px',
            paddingBottom: "20px"
        }}>
            <CompanyViewHeader tab={2} companyId={id}/>


            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>

            <div style={{background: 'white'}}>

                <div className="field">
                    <label className="label">Олгогдсон огноо</label>
                    <div className="control has-icons-left">
                        <DatePicker utcOffset={0} className="input" selected={state.issued}
                                    onChange={handleChangeIssued} placeholderText="Олгогдсон огноо оруулах"/>
                    </div>
                </div>
            </div>


            <div class="buttons is-right" style={{paddingTop: '10px'}}>
                <button className="button" type="primary" onClick={() => handleCancel()}>Буцах</button>
                <button className="button is-success" type="primary"
                        onClick={(e) => handleSave(e)}>
                    Хадгалах
                </button>
            </div>
            <ClipLoader color="#ffffff" loading={state.loading} css={override} size={150} />
        </div>
    )
}



export default BarcodeEdit;