/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer, useState} from "react";
import {
    activateProductApplication, activateProductImageApplication,
    fetchClassifsNouv, fetchCountries,
    fetchPackagingUnits,
    fetchProductApplication, fetchProductImage, fetchProductImageAppl, fetchProductNew
} from "./actions/actions";
import {ActionTypes, EndpointsExpress, GLN, GTIN, SSCC} from "./Constants";
import history from "./history";
import {toast} from "react-toastify";
import ProductEditComponentSender from "./ProductEditComponentSender";
import ProductEditComponentGln from "./ProductEditComponentGln";
import ProductEditComponentGtin from "./ProductEditComponentGtin";
import {useParams} from "react-router-dom";
//import countriesData from "./data/countries3.json";
import ProductEditComponentSscc from "./ProductEditComponentSscc";
import {confirmAlert} from "react-confirm-alert";
import {checkGtin, validateProduct} from "./utils/productUtils";
import axios from "./axiosConfig";


const initialState = {
    product: { exists_image_1:false, exists_image_2:false, exists_image_3:false},
    image_request: {image:null},
    loading: false,
    image:null,
    image1:null,image2:null,image3:null
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_PRODUCT_APPLICATION_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.GET_PRODUCT_APPLICATION_SUCCESS: {
            let exp = [{id:"496", name:"MONGOLIA"}]

            // if (action.request.exp_countries_json){
            //     // let str = action.request.exp_countries_office; //'MN,RU,FR,';
            //     //
            //     // //remove the last comma
            //     // str = str.replace(/,\s*$/, "");
            //     //
            //     // let test = str.split(','); //['MN','RU','FR'];
            //     action.request.exp_countries_json.map(el => {
            //         const c = state.countries.find(e => e.id === el)
            //         exp = [...exp, c]
            //     })
            // }
            let p = action.request;
            p.barcode = sessionStorage.getItem("barcode");

            // check gcp
            let gcp_id = sessionStorage.getItem("gcp_id");
            action.gcps.map(g=>{
                if (g.id === gcp_id) {
                    p.gcp_id = gcp_id;
                }
            });

            //init classifs from session storage
            p.main = sessionStorage.getItem("a_main", '0');
            p.sub = sessionStorage.getItem("a_sub", '0');
            p.unit = sessionStorage.getItem("a_unit", '0');

            p.main_code = sessionStorage.getItem("a_main_code", '0');
            p.sub_code = sessionStorage.getItem("a_sub_code", '0');
            p.unit_code = sessionStorage.getItem("a_unit_code", '0');

            p.main_name = sessionStorage.getItem("a_main_name", '0');
            p.sub_name = sessionStorage.getItem("a_sub_name", '0');
            p.unit_name = sessionStorage.getItem("a_unit_name", '0');

            return {
                ...state,
                request: p,
                selectedCountries: exp,
                gcps:action.gcps,
                loading: false
            }
        }
        case ActionTypes.ACTIVATE_PRODUCT_APPLICATION_SUCCESS: {
            let p = action.request;
            return {
                ...state,
                request: p, //action.request,
                loading: false
            }
        }

        case 'CHANGE_FIELD':
            let c = {...state.request};
            c[action.name] = action.value
            return {
                ...state,
                request: c,
                loading: false
            }

        case     ActionTypes.GET_PRODUCT_IMAGE_SUCCESS: {
            let im1 = state.image1;
            let im2 = state.image2;
            let im3 = state.image3;
            if (action.side ===1) {
                im1 = action.data;
            }
            if (action.side ===2) {
                im2 = action.data;
            }
            if (action.side ===3) {
                im3 = action.data;
            }


            return {
                ...state,
                image1:im1,
                image2:im2,
                image3:im3,
            }

        }

        case     ActionTypes.GET_PRODUCT_IMAGE_APPL_SUCCESS: {
            let im = state.image;
                im = action.data;
            return {
                ...state,
                image:im,
            }

        }
        default:
            return state;
    }
};


const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}


const ProductImageApplicationEdit = () => {

    const {applId, productId} = useParams();

    const [state, dispatch] = useReducer(reducer, initialState);
    const {product, request, loading, image} = state;


    useEffect(() => {
        fetchProductNew(dispatch, null, productId);
    }, [productId]);

    useEffect(() => {
        fetchProductImageAppl(dispatch,  applId);

    }, [applId]);


    const activateImage = ( id, side) => {
        async function activate(id, side) {

            let dto = {
                side:side
            };

            let header = {}

            header["Accept"] = "application/json"
            header["Content-Type"] = "application/json"

            let config = {}
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.PRODUCT_IMAGE_ADMIN}/${id}/activate`;
            config["method"] = "post";
            config["data"] = JSON.stringify(dto);
            try {
                const response = await axios.instance(config);
            } catch(e) {
                alert(e)
            }
        }
        activate(id, side);
        toast.success("Хүсэлтийг идэвхжүүллээ!");
        history.push("/prod-image-appls")

    }
    const handleActivate = () => {

        activateImage(applId, 1);
    };

    const handleActivate2 = () => {
        activateImage(applId, 2);
    };
    const handleActivate3 = () => {
        activateImage(applId, 3);
    };
    const handleDeny = () => {
        console.log('save');
        history.push("/prod-image-appl/deny/" + applId)
    };
    const handleCancel = () => {
        console.log('cancel');
        history.goBack()
    };


    // useEffect(() => {
    //     if (product.exists_image_1) {
    //         fetchProductImage(dispatch, null, productId, 1);
    //     }
    // }, [product.exists_image_1]);
    // useEffect(() => {
    //     if (product.exists_image_2) {
    //         fetchProductImage(dispatch, null, productId, 2);
    //     }
    // }, [product.exists_image_2]);
    // useEffect(() => {
    //     if (product.exists_image_3) {
    //         fetchProductImage(dispatch, null, productId, 3);
    //     }
    // }, [product.exists_image_3]);
    return (
        <div className="inner-container">

            {/*{ request && request.status ==="SENT"  &&*/}
            {/*<img src={image} width={300}/>*/}
            {/*}*/}
            {/*<hr/>*/}


            <h3>Одоо байгаа бүтээгдэхүүний зурагнууд</h3>
            <table>
            <tr>
                <th>Зураг 1</th>
                <td>
                    { product && product.image1_url &&
                        <div>
                            <img src={product.image1_url} width={300}/>
                        </div>
                    }
                </td>
            </tr>
            <tr>
                <th>Зураг 2</th>
                <td>
                    { product && product.image2_url &&
                        <div>
                            <img src={product.image2_url} width={300}/>
                        </div>
                    }
                </td>
            </tr>
            <tr>
                <th>Зураг 3</th>
                <td>
                    { product && product.image3_url &&
                        <div>
                            <img src={product.image3_url} width={300}/>
                        </div>
                    }
                </td>
            </tr>
            </table>


                <h2>Ирсэн зураг</h2>
                        <div>
                            <img src={image} width={300}/>
                        </div>


            <div className="buttons is-right" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                <button className="button" type="primary" onClick={() => handleCancel()}>Болих</button>
                <button className="button is-danger" type="primary"
                        onClick={() => handleDeny()}>
                    Буцаах
                </button>
                <button className="button is-success" type="primary"
                        onClick={() => handleActivate()}>
                    Идэвхжүүлэх 1
                </button>
                <button className="button is-success" type="primary"
                        onClick={() => handleActivate2()}>
                    Идэвхжүүлэх 2
                </button>
                <button className="button is-success" type="primary"
                        onClick={() => handleActivate3()}>
                    Идэвхжүүлэх 3
                </button>

            </div>
        </div>

    )

}


export default ProductImageApplicationEdit;