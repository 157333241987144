/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useReducer, useState} from "react";
import {Redirect} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";

import CompanyEditComponent from './CompanyEditComponent';
import { toast } from 'react-toastify';
import dayjs from "dayjs"
import {ActionTypes, Endpoints, EndpointsExpress, TOKEN} from "./Constants";
import {css} from "@emotion/react";
import   axios from './axiosConfig'
import history from "./history"
import ClipLoader from "react-spinners/ClipLoader";
import SmsMassEditComponent from "./SmsMassEditComponent";



const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    company: {name:"", sms:"", sms2:"", sms3:"", length:0, length2:0, length3:0, send_audience:"ALL"},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {

        case 'CHANGE_FIELD':
            let c = {...state.company};
            c[action.name] = action.value
            if (action.name === "sms") {
                c["length"] = action.value.length;
            } else if (action.name === "sms2") {
                c["length2"] = action.value.length;
            } else if (action.name === "sms3") {
                c["length3"] = action.value.length;
            }
            return {
                ...state,
                company: c
            }
        default:
            return state;
    }
};




const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}

const SmsMassCreate = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {company, loading} = state;

    const handleSave = ( ) => {
        async function saveCreate() {
            let dto = {
                name:company.name,
                sms:company.sms,
                sms2:company.sms2,
                sms3:company.sms3,
                send_audience:company.send_audience
            };
            let header = {}

            header["Accept"] = "application/json"
            header["Content-Type"] = "application/json"

            let config = {}
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.SMS_MASS_BODIES}`;
            config["method"] = "post";
            config["data"] = JSON.stringify(dto);
            try {
                const response = await axios.instance(config);
            } catch(e) {
                alert(e)
            }
        }
        console.log('send');
        saveCreate();
        toast.success("Sms uusgelee!");
        history.push("/sms-mass-bodies")
    };

    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }
    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
    };

    return (
        <div className="inner-container">
            <div className="level">
                <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <SmsMassEditComponent obj={company}
                                  onChangeField={handleChangeField}
                onSave={handleSave} onCancel={handleCancel}/>
        </div>
    )
}


export default SmsMassCreate;