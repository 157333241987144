/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useState} from "react";
import {connect} from "react-redux";
import { createProduct} from "./actions/actions";
import {Redirect} from "react-router-dom";

const SectorEditComponent = ({ pname, onSave, onCancel}) => {


    const [name, setName] = useState(pname);


    useEffect(() => {
        console.log('useEffect called');
    },[]);



    const handleChangeName = (e) => {
        setName(e.target.value)
    };

    return (
        <div style={{background: 'white'}}>

            <div className="field">
                <label className="label">Нэр</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" value={name}
                           onChange={(e) => handleChangeName(e)} placeholder="Нэр оруулах"/>
                </div>
            </div>

            <div className="buttons" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                <button className="button is-primary" type="primary" onClick={() => onSave( name )}>Хадгалах</button>
                <button className="button is-primary" type="primary" onClick={() => onCancel()}>Болих</button>
            </div>
        </div>
    )

}


export default SectorEditComponent