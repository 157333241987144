/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useReducer, useState} from "react";
import {
    failure, fetchGcpsAll, fetchHqBatch, fetchHqBatches, fetchHqProducts, menuClicked,
    menuClickedTitle
} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, Endpoints, EndpointsExpress, Menu, Page, PageTitle, TOKEN} from "./Constants";
import history from "./history";
import axios from "axios";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import gcpOptionsData from "./data/gcps.json"
import gcpStatusData from "./data/gcp-status.json"


dayjs.extend(dayjsPluginUTC)


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const initialState = {

    loading: false,
    batches:[],
    meta:{totalPages:0},
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_HQ_BATCHES_REQUEST:
            return {
                ...state,
                loading:true
            }
        case ActionTypes.LOAD_HQ_BATCHES_SUCCESS:
            return {
                ...state,
                batches: action.batches,
                meta: action.meta,
                loading:false
            }
        default:
            return state;
    }
};


const HqBatchesPage = ({
                      location: {search}
                  }) => {


    const [state, dispatch] = useReducer(reducer, initialState);
    const { loading, batches, meta  } = state;

    let parsed = qs.parse(search);

    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];

    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "15";
    }

    useEffect(() => {
        fetchHqBatches(dispatch,
            page, pagesize);
    }, [ page, pagesize]);

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };

    let counter = 0;
    const appts2 = batches.map(p => {
        counter++;
        return (
            <tr key={p.id}>
                <td>{counter}</td>
                <td><Link to={`/hq-batch/?b=${p.key_code}`}>{p.key_code}</Link></td>
                <td>{p.created}</td>
            </tr>
        )
    });
    return (

            <div className="inner-container">
                <br/>
                <div>

                    <Link to={`/hq-gtin-delete`} className="button bg-red-400">
                        <span style={{color: "white"}}>HQ GTIN Delete </span>
                    </Link>
                    <Link to={`/hq-gcp-delete`} className="button bg-red-500">
                        <span style={{color: "white"}}>HQ GCP Delete </span>
                    </Link>
                    <Link to={`/hq-gcp-send`} className="button bg-green-400">
                        <span style={{color: "white"}}>HQ GCP Send </span>
                    </Link>
                    <Link to={`/hq-gtin-send`} className="button bg-green-500">
                        <span style={{color: "white"}}>HQ GTIN Send </span>
                    </Link>

                    <Link to={`/hq-batch`} className="button bg-purple-400">
                        <span style={{color: "white"}}>Batch verify</span>
                    </Link>
                    <Link to={`/products-verify`} className="button bg-purple-600">
                        <span style={{color: "white"}}>Products verify</span>
                    </Link>
                    <h5 style={{marginBottom: "10px"}}><strong>Нийт {meta.totalElements}</strong>
                    </h5>

                    <nav className="pagination">
                        <ReactPaginate previousLabel={"Өмнөх"}
                                       nextLabel={"Дараах"}
                                       initialPage={Number(page)}
                                       breakLabel={"..."}
                                       breakClassName={"pagination-ellipsis"}
                                       pageCount={meta.totalPages}
                                       marginPagesDisplayed={2}
                                       pageRangeDisplayed={5}
                                       activeLinkClassName={"is-current"}
                                       pageLinkClassName={"pagination-link"}
                                       onPageChange={(e) => handlePageClick(e)}
                                       previousClassName={"pagination-previous"}
                                       nextClassName={"pagination-next"}
                                       containerClassName={"pagination-list"}
                                       forcePage={Number(page)}
                        />
                    </nav>


                </div>
                <br/>
                <table className="table is-striped is-fullwidth">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Batch</th>
                        <th>Created</th>
                    </tr>
                    </thead>
                    <tbody>
                    {appts2}
                    </tbody>
                </table>

                <nav className="pagination">
                    <ReactPaginate previousLabel={"Өмнөх"}
                                   nextLabel={"Дараах"}
                                   initialPage={Number(page)}
                                   breakLabel={"..."}
                                   breakClassName={"pagination-ellipsis"}
                                   pageCount={meta.totalPages}
                                   marginPagesDisplayed={2}
                                   pageRangeDisplayed={5}
                                   activeLinkClassName={"is-current"}
                                   pageLinkClassName={"pagination-link"}
                                   onPageChange={(e) => handlePageClick(e)}
                                   previousClassName={"pagination-previous"}
                                   nextClassName={"pagination-next"}
                                   containerClassName={"pagination-list"}
                                   forcePage={Number(page)}
                    />
                </nav>
                <br/><br/>



            </div>

    )

}



export default HqBatchesPage

