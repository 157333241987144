/**
 * Created by gantushig on 11/6/15.
 */

import {connect} from "react-redux";
import {Link, Redirect, useParams, useRouteMatch} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {deleteProductImage, fetchProductImage, fetchProductNew, uploadProductImage} from "./actions/actions";
import CompanyViewHeader from "./CompanyViewHeader";
import {ActionTypes, TOKEN} from "./Constants";
import {getViewReducer} from "./reducers/productViewReducer";


const ProductImagesView = () => {

    let token = localStorage.getItem(TOKEN) || null;
    let {companyId, productId} = useParams()

    useEffect(() => {
        console.log('useEffect called');
        fetchProductNew(dispatch, token, productId);
    }, [productId]);

    const [state, dispatch] = getViewReducer();
    //const [state, dispatch] = useReducer(reducer, initialState);
    let {image1, image2, image3, loading, product} = state

    // useEffect(() => {
    //     if (product.exists_image_1) {
    //         fetchProductImage(dispatch, token, productId, 1);
    //     }
    // }, [product.exists_image_1]);
    // useEffect(() => {
    //     if (product.exists_image_2) {
    //         fetchProductImage(dispatch, token, productId, 2);
    //     }
    // }, [product.exists_image_2]);
    // useEffect(() => {
    //     if (product.exists_image_3) {
    //         fetchProductImage(dispatch, token, productId, 3);
    //     }
    // }, [product.exists_image_3]);


    const handleFileOnChange = (e, number) => {
        const file = e.target.files[0]
        uploadProductImage(dispatch, token, productId, file, number);

    }
    const handleDeleteImage = (s) => {
        deleteProductImage(dispatch, token, productId, s);
    };


    return (
<>
            <table className="table is-bordered">
                <tbody>
                <tr>
                    <th>Зураг 1</th>
                    <td>
                        { product && !product.image1_url &&
                        <div className="file">
                            <label className="file-label">
                                <input className="file-input" type="file" name="resume"
                                       onChange={(e) => handleFileOnChange(e, 1)} multiple={false}/>
                                <span className="file-cta">
                                      <span className="file-icon">
                                            <i className="fas fa-upload"></i>
                                      </span>
                                      <span className="file-label">
                                            Choose a file…
                                      </span>
                                </span>
                            </label>
                        </div>
                        }
                        { product && product.image1_url &&
                        <div>
                            <img src={product.image1_url} width={300}/>
                            <a onClick={(e) => handleDeleteImage(1)} className="delete"></a>
                        </div>
                        }
                    </td>
                </tr>

                <tr>
                    <th>Зураг 2</th>
                    <td>
                        { product && !product.image2_url &&
                        <div className="file">
                            <label className="file-label">
                                <input className="file-input" type="file" name="resume"
                                       onChange={(e) => handleFileOnChange(e, 2)} multiple={false}/>
                                <span className="file-cta">
                                      <span className="file-icon">
                                            <i className="fas fa-upload"></i>
                                      </span>
                                      <span className="file-label">
                                            Choose a file…
                                      </span>
                                </span>
                            </label>
                        </div>
                        }
                        { product && product.image2_url &&
                        <div>
                            <img src={product.image2_url} width={300}/>
                            <a onClick={(e) => handleDeleteImage(2)} className="delete"></a>
                        </div>
                        }

                    </td>
                </tr>

                <tr>
                    <th>Зураг 3</th>
                    <td>
                        { product && !product.image3_url &&
                        <div className="file">
                            <label className="file-label">
                                <input className="file-input" type="file" name="resume"
                                       onChange={(e) => handleFileOnChange(e, 3)} multiple={false}/>
                                <span className="file-cta">
                                      <span className="file-icon">
                                            <i className="fas fa-upload"></i>
                                      </span>
                                      <span className="file-label">
                                            Choose a file…
                                      </span>
                                </span>
                            </label>
                        </div>
                        }
                        { product && product.image3_url &&
                        <div>

                            <img src={product.image3_url} width={300}/>
                            <a onClick={(e) => handleDeleteImage(3)} className="delete"></a>
                        </div>
                        }

                    </td>
                </tr>
                </tbody>
            </table>
        </>
    )

}


export default ProductImagesView;

