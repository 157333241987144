/**
 * Created by gantushig on 11/6/15.
 */

import {useDispatch, useSelector} from "react-redux";
import {Link, useParams, useLocation} from "react-router-dom";
import React, {useEffect, useReducer} from "react";
import {failure, fetchProducts, setUiTitle} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import CompanyViewHeader from "./CompanyViewHeader";
import {ActionTypes, Endpoints, Page, TOKEN} from "./Constants";
import history from "./history";
import axios from "axios";
import {css} from "@emotion/react";
import Company8ViewHeader from "./Company8ViewHeader";
import ClipLoader from "react-spinners/ClipLoader";
import {fetchProduct8s} from "./actions/actions";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    products: [],
    name:"",
    meta:{},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_COMPANY_PRODUCT8S_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_COMPANY_PRODUCT8S_SUCCESS:
            return {
                ...state,
                products: action.products,
                name:action.name,
                meta: action.meta,
                loading: false
            }

        default:
            return state;
    }
};



const CompanyProduct8s = () => {

    const {companyId} = useParams()
    const {search} = useLocation();
    const reduxDispatch = useDispatch();
    const token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        products,
        meta,
        name,
        loading,
    } = state;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let order = parsed[Page.ORDER];
    let pagesize = parsed[Page.PAGESIZE];
    let asc = parsed[Page.ASC];
    if (page === undefined) {
        page = "0";
    }
    if (order === undefined) {
        order = "barcode";
    }
    if (asc === undefined) {
        asc = "true";
    }
    if (pagesize === undefined) {
        pagesize = "25";
    }

    let counter = 0
    for (let key in products) {
        let item = products[key]
        counter++
        item.counter = Number(page) * Number(pagesize) + counter
    }

    useEffect(() => {
        reduxDispatch(setUiTitle(name))
    }, [name]);

    useEffect(() => {
        console.log('useEffect called');
        fetchProduct8s(dispatch, token, companyId, page, pagesize, order, asc);
    }, [companyId, page, order, asc, pagesize]);

    // if (products === undefined) {
    //     return (
    //         <div className="inner-container">
    //             <table className="table is-bordered is-fullwidth">
    //                 <thead>
    //                 <tr>
    //                     <th>#</th>
    //                     <th>Нэр</th>
    //                     <th>Дугаар</th>
    //                     <th>Савлалтын хэмжээ /нэгж, багц/</th>
    //                 </tr>
    //                 </thead>
    //                 <tbody>
    //                 </tbody>
    //             </table>
    //             <div className="subtitle">No Items</div>
    //
    //         </div>
    //     )
    // }
    // ;

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };

    const handleOrderClick = (f) => {
        let lorderField = order;
        let lisAsc = asc;

        if (f === lorderField) {
            if (lisAsc === 'true') {
                lisAsc = 'false';
            } else {
                lisAsc = 'true';
            }
        } else {
            lorderField = f;
            lisAsc = true;
        }


        let parsed = qs.parse(search);
        parsed[Page.ORDER] = lorderField;
        parsed[Page.ASC] = lisAsc;
        history.push("?" + qs.stringify(parsed))
    }

    const items = products.map(
        (p) => {
            return (
                <tr key={p.id}>
                    <td>
                        <Link to={`/product8/${p.id}`}>
                            {p.counter}
                        </Link>
                    </td>
                    <td>
                        <Link to={`/product8/${p.id}`}>
                            {p.name}
                        </Link>
                    </td>
                    <td>

                        {p.barcode}

                    </td>
                    <td>{p.packaging}</td>
                </tr>
            )
        });

    return (
        <div className="inner-container">

            <Company8ViewHeader tab={0} companyId={companyId}/>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th><a onClick={() => handleOrderClick("id")}>#</a></th>
                    <th><a onClick={() => handleOrderClick("name")}>Нэр</a></th>
                    <th><a onClick={() => handleOrderClick("barcode")}>Дугаар</a></th>
                    <th>Савлалтын хэмжээ /нэгж, багц/</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={<a href="">...</a>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               disableInitialCallback={true}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>
            <br/><br/>
        </div>
    )

}

export default CompanyProduct8s


