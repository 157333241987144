/**
 * Created by gantushig on 11/6/15.
 */

import {Link} from "react-router-dom";
import React from "react";
import {createBrowserHistory} from "history";

const history = createBrowserHistory();


const SettingsPage = () => {

    return (
        <div className="inner-container">
            <div className="level">
                <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                    Тохиргоонууд
                </div>
            </div>
            <hr/>
            <table className="table is-fullwidth">
                <thead>
                </thead>
                <tbody>
                <tr>
                    <td><Link to={`/sectors`}>
                        Үйл Ажиллагааны чиглэл
                    </Link></td>
                </tr>
                <tr>
                    <td><Link to={`/classifs`}>
                        Бүтээгдэхүүний ангилал
                    </Link></td>
                </tr>
                <tr>
                    <td><Link to={`/fees`}>
                        Гишүүнчлэл Төлбөр
                    </Link></td>
                </tr>
                <tr>
                    <td><Link to={`/invoice-mail-template`}>
                        Нэхэмжлэх мэйл
                    </Link></td>
                </tr>
                <tr>
                    <td><Link to={`/sms-template-edit`}>
                        SMS template
                    </Link></td>
                </tr>
                </tbody>
            </table>

        </div>
    )

}


export default SettingsPage

