/**
 * Created by gantushig on 1/16/21.
 */

import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {denyProductApplication} from "./actions/actions";
import {toast} from "react-toastify";
import history from "./history";
import {TOKEN} from "./Constants";

const ProductRequestNoteCreate = ({match: {params: {applId}}}) => {
    const [note, setNote] = useState("");

    const dispatch = useDispatch();

    let token = localStorage.getItem(TOKEN) || null;

    const handleChangeNote = (e) => {
        setNote(e.target.value)
    };

    const handleSend = () => {
        console.log('save');
        let dto = {
             note:note
        };
        dispatch(denyProductApplication(token, applId, dto)).then(response => {
                toast.success("Хүсэлтийг буцаалаа!");
                history.push("/prod-appls")
            }
        ).catch(error => {
            alert(error)
        })
    };
    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
    };
    return (
        <div style={{background: 'white'}}>
            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Тэмдэглэл оруулах</h1>
                </div>
            </div>


            <div style={{background: 'white'}}>

                <div className="field">

                    <div className="control has-icons-left">
                    <textarea className="textarea" type="text" value={note}
                              onChange={(e) => handleChangeNote(e)} placeholder="Тэмдэглэл оруулах"/>
                    </div>
                </div>


                <div className="buttons" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                    <button className="button is-primary" type="primary" onClick={() => handleSend()}>Илгээх</button>
                    <button className="button is-primary" type="primary" onClick={() => handleCancel()}>Болих</button>
                </div>
            </div>
        </div>

    )

}


export default ProductRequestNoteCreate;