/**
 * Created by gantushig on 11/6/15.
 */

import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {ActionTypes, Endpoints, TOKEN} from "./Constants";
import axios from "axios";
import dayjs from "dayjs";
import BarcodeEditMenu from "./BarcodeEditMenu";
import history from "./history";
import {failure, removeBarcode, uploadProduct8File} from "./actions/actions";
import classnames from "classnames";
import Company8ViewHeader from "./Company8ViewHeader";
import ClipLoader from "react-spinners/ClipLoader";
import {css} from "@emotion/react";
import {toast} from "react-toastify";

const FileDownload = require('js-file-download');


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    loading: false,
    name:"",
    page: 0,
    pagesize: 15,
    files: {},
    meta: {},
    selectedFileId: 0
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_FILES_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_FILES_SUCCESS':
            return {
                ...state,
                files: action.files,
                meta: action.meta,
                name:action.name,
                loading: false
            }

        case 'REMOVE_FILE_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'REMOVE_FILE_SUCCESS':
            return {
                ...state,
                files: action.files,
                meta: action.meta,
                loading: false
            }

        case 'CHANGE_PAGE':
            return {
                ...state,
                page: action.page
            };
        case 'SELECT_FILE':
            return {
                ...state,
                selectedFileId: action.id
            };
        case 'DOWNLOAD_SUCCESS':
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};


const fetchProduct8FilesRequest = () => {
    return {
        type: 'FETCH_FILES_REQUEST'
    }
};
const fetchProduct8FilesSuccess = (files, meta, name) => {
    return {
        type: 'FETCH_FILES_SUCCESS',
        files,
        meta,
        name
    }
};

const fetchProduct8Files = (dispatch, token, companyId, page, pagesize) => {

    dispatch(fetchProduct8FilesRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.PRODUCT8_FILE}/${companyId}/files?page=${page}&pagesize=${pagesize}`;

    return axios(config)
        .then(response => {

            let p = response.data.items.reduce((map, obj) => (map[obj.id] = obj, map), {});
            dispatch(fetchProduct8FilesSuccess(p, response.data.meta, response.data.company_name))
        })
        .catch(error => {

            throw(error);

        });
}




const removeFileRequest = () => {
    return {
        type: 'REMOVE_FILE_REQUEST'
    }
};

const removeFileSuccess = (file) => {
    return {
        type: 'REMOVE_FILE',
        file
    }
};

const removeFile = (dispatch, token,  id) => {

    return new Promise(function (resolve, reject) {
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.PRODUCT8_FILE}/${id}`;
        config["method"] = "delete";

        return axios(config)
            .then(response => {

                //fetchProduct8Files(dispatch, token, id, 0, 35)
                dispatch(removeFileSuccess(response.data.file))
                resolve(response);
            })
            .catch(error => {
                reject(error);
                //throw(error);
            });
    });
};

const loadingStart =() => {
    return {
        type: 'LOADING'
    }
};

const downloadSuccess =() => {
    return {
        type: 'DOWNLOAD_SUCCESS'
    }
};

const download = (dispatch, token, id) => {
    dispatch(loadingStart());
    let header = {};
    header["Authorization"] = token;

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.PRODUCT8_FILE}/${id}/download`;
    config["responseType"] = "arraybuffer";

    return axios(config)
        .then(response => {
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader]
            let fn = decodeURIComponent(suggestedFileName);
            dispatch(downloadSuccess());
            FileDownload(response.data, fn)

        })
        .catch(error => {

            throw(error);

        });
}




const selectFile =(id) => {
    return {
        type: 'SELECT_FILE',
        id
    }
};

const CompanyProduct8Files = ({match: {params: {companyId}}, location: {search}}) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {files, loading} = state;
    let editModalClass = classnames({
        modal: true,
        'is-active': openEditModal
    });
    let deleteModalClass = classnames({
        modal: true,
        'is-active': openDeleteModal
    });


    useEffect(() => {
        console.log('useEffect called');
        fetchProduct8Files(dispatch, token, companyId, 0, 35)
    }, []);

    const handleEdit = (e, fileId) => {
        e.preventDefault();
        history.push(`/product8-file/${fileId}`)
    };

    const handleDownload = (e, id) => {
        e.preventDefault();
        download(dispatch, token, id);
    };

    const handleDelete = (e,id) => {
        e.preventDefault();
        setOpenDeleteModal(true)
        dispatch(selectFile(id))
    };

    const handleSaveDeleteModal = () => {
        setOpenDeleteModal(false)
        //removeFile(dispatch, token, id, state.selectedFileId)
        removeFile(dispatch, token, state.selectedFileId).then(response => {
                toast.success("Амжилттай!");
                fetchProduct8Files(dispatch, token, companyId, 0, 35)
                //history.push(`/company/${companyId}/product8-files`)
            }
        ).catch(error => {
            alert(error)
        });


    }
    const handleCancelDeleteModal = () => {
        setOpenDeleteModal(false)
    }


    let counter = 0
    const file_ids_by_order =
        Object.values(state.files)
            .reduce((ordered_ids, b) => {
                ordered_ids[b.order] = b.id
                return ordered_ids
            }, []);

    file_ids_by_order.map(
        (id) => {
            counter++
            state.files[id].counter = state.page * state.pagesize + counter
        });


    const items = file_ids_by_order.map(
        (id) => {
            return (
                <tr key={id}>
                    <td>{files[id].counter}</td>
                    <td>
                        {files[id].issued && dayjs.utc(files[id].issued).format("MM/DD/YYYY")}
                    </td>
                    <td>{files[id].file_name}</td>
                    <td>
                        <BarcodeEditMenu id={id} onEdit={handleEdit} onDownload={handleDownload}  onDelete={handleDelete}/>
                    </td>
                </tr>
            )
        });

    return (
        <div className="inner-container">

            <Company8ViewHeader tab={1} companyId={companyId}/>

            <div className={deleteModalClass}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Устгах</p>
                        <button className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        Та устгахдаа итгэлтэй байна уу?
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={handleSaveDeleteModal}>Тийм</button>
                        <button className="button" onClick={handleCancelDeleteModal}>Үгүй</button>
                    </footer>
                </div>
            </div>

            <div className="buttons is-right">
                <Link to={`/company/${companyId}/product8-file-create`} className="button">
                    Үүсгэх
                </Link>
            </div>
            <hr/>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Олгосон</th>
                    <th>Файлын нэр</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

        </div>
    )
}

export default CompanyProduct8Files;

