/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useState} from "react";
import {connect} from "react-redux";
import { createAdminUser} from "./actions/actions";
import {Redirect} from "react-router-dom";


const AdminUserCreate = ({token, companyId,  add, isUpdating}) => {
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [pwd, setPwd] = useState("");
    const [cancelClicked, setCancelClicked] = useState(false);
    const [sendClicked, setSendClicked] = useState(false);


    const handleChangeUsername = (e) => {
        setUsername(e.target.value)
    };
    const handleChangeName = (e) => {
        setName(e.target.value)
    };
    const handleChangePwd = (e) => {
        setPwd(e.target.value)
    };

    const handleSave = () => {
        console.log('save');
        let dto = {
            username,
            name,
            pwd
        };
        add(token, dto)
        setSendClicked(true)
    };
    const handleCancel = () => {
        console.log('cancel');
        setCancelClicked(true)

    };

    return (
    <div style={{background: 'white'}}>
        <div className="level">
            <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                <h1 className="title is-size-4  has-text-info">Admin User add</h1>
            </div>
        </div>



        <div style={{background: 'white'}}>

            <div className="field">
                <div className="control has-icons-left">
                    <input className="input" type="text" value={username}
                              onChange={(e) => handleChangeUsername(e)} placeholder="Username оруулах"/>
                </div>
            </div>

            <div className="field">
                <div className="control has-icons-left">
                    <input className="input" type="text" value={name}
                           onChange={(e) => handleChangeName(e)} placeholder="Name оруулах"/>
                </div>
            </div>

            <div className="field">
                <div className="control has-icons-left">
                    <input className="input" type="text" value={pwd}
                           onChange={(e) => handleChangePwd(e)} placeholder="Password оруулах"/>
                </div>
            </div>

            <div className="buttons" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                <button className="button is-primary" type="primary" onClick={() => handleSave()}>Хадгалах</button>
                <button className="button is-primary" type="primary" onClick={() => handleCancel()}>Болих</button>
            </div>
        </div>
    </div>

    )

}


const mapStateToProps = (state, ownProps) => {
    const companyId = ownProps.match.params.id
    let isUpdating = state.ui.isUpdating
    let token = localStorage.getItem('id_token') || null;
    return {
        token,
        companyId,
        isUpdating
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

        add: (token,  info) => {
            dispatch(createAdminUser(token, info))
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AdminUserCreate)