import {ActionTypes, GTIN} from "../Constants";
import {useReducer} from "react";

const initialState = {
    product: {barcode_type: GTIN, barcode: "", exists_image_1:false, exists_image_2:false, exists_image_3:false},
    loading: false,
    image1:null,image2:null,image3:null
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_PRODUCT_REQUEST:
        case ActionTypes.DELETE_PRODUCT_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,

            }

        case ActionTypes.GET_PRODUCT_SUCCESS: {
            // if (action.product.exp_countries_office){
            //     let str = action.product.exp_countries_office; //'MN,RU,FR,';
            //     //remove the last comma
            //     str = str.replace(/,\s*$/, "");
            //
            //     let test = str.split(','); //['MN','RU','FR'];
            //     let ret = "";
            //     test.map(el => {
            //         const c = state.countries.find(e => e.id === el)
            //         if (c) {
            //             ret += c.name;
            //             ret += "/" + c.id;
            //             ret += ", ";
            //         }
            //     })
            //     if (ret !== "") {
            //         ret = ret.replace(/,\s*$/, "");
            //         action.product.exp = ret;
            //     }
            // }

            // if (action.product.exp_countries_json) {
            //     let ret = "";
            //     action.product.exp_countries_json.map(el => {
            //         const c = state.countries.find(e => e.id === el)
            //         if (c) {
            //             ret += c.name;
            //             ret += "/" + c.id;
            //             ret += ", ";
            //         }
            //     })
            //     if (ret !== "") {
            //         ret = ret.replace(/,\s*$/, "");
            //         action.product.exp = ret;
            //     }
            // }
            return {
                ...state,
                product: action.product,
                loading: false
            }
        }
        case     ActionTypes.GET_PRODUCT_IMAGE_SUCCESS: {
            let im1 = state.image1;
            let im2 = state.image2;
            let im3 = state.image3;
            if (action.side ===1) {
                im1 = action.data;
            }
            if (action.side ===2) {
                im2 = action.data;
            }
            if (action.side ===3) {
                im3 = action.data;
            }


            return {
                ...state,
                image1:im1,
                image2:im2,
                image3:im3,
            }

        }
        default:
            return state;
    }
};

export const getViewReducer = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useReducer(reducer, initialState);
};