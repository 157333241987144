/**
 * Created by gantushig on 11/6/15.
 */

import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {
    createNote,
    removeNote,
    createPayment,
    createPaymentNote,
    fetchPayments,
    removePayment,
    removePaymentNote,
    fetchNotes
} from "./actions/actions";

import {ActionTypes, TOKEN} from "./Constants";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import classnames from "classnames";
import "./CompanyPayments.scss"
import dayjs from "dayjs";
import CompanyViewHeader from "./CompanyViewHeader";
import {toast} from "react-toastify";
import {FaTimes} from "react-icons/fa";
import {confirm} from "react-confirm-box";



const initialState = {
    payments: [],
    notes:[],
    loading: false,
    asc: true,
    refresh: false,
    refreshNotes:false
};

const reducer = (state, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_PAYMENTS_REQUEST:
        case ActionTypes.CREATE_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_PAYMENTS_SUCCESS:
            // let o1 = state.payments[action.payment.year].order
            // action.payment.order = o1
            // state.payments[action.payment.year] = action.payment
            return Object.assign({}, state, {
                payments: action.payments,
            });
        case ActionTypes.CREATE_PAYMENT_SUCCESS:
        case ActionTypes.DELETE_PAYMENT_SUCCESS:
            {
            const ps = state.payments.map(a => ({...a}));

            const itemIndex = ps.findIndex(p => p.year === action.payment.year);
            if (itemIndex !== -1) {
                let note= ps[itemIndex].note;
                let noteDate= ps[itemIndex].note_date;
                ps[itemIndex] = action.payment;
                ps[itemIndex].note = note;
                ps[itemIndex].note_date = noteDate;
            }

            return Object.assign({}, state, {
                payments: ps,
            });
        }
        case ActionTypes.CREATE_PAYMENT_NOTE_SUCCESS:
        {
            const ps = state.payments.map(a => ({...a}));

            const itemIndex = ps.findIndex(p => p.year === action.note.year);
            if (itemIndex !== -1) {
                ps[itemIndex].note = action.note.note;
                ps[itemIndex].note_date = action.note.modified;
            }

            return Object.assign({}, state, {
                payments: ps,
            });
        }
        case ActionTypes.DELETE_PAYMENT_NOTE_SUCCESS:
        {
            const ps = state.payments.map(a => ({...a}));

            const itemIndex = ps.findIndex(p => p.year === action.note.year);
            if (itemIndex !== -1) {
                ps[itemIndex].note = null;
                ps[itemIndex].note_date = null;
            }

            return Object.assign({}, state, {
                payments: ps,
            });
        }
        case ActionTypes.FETCH_NOTES_SUCCESS:
            // let o1 = state.payments[action.payment.year].order
            // action.payment.order = o1
            // state.payments[action.payment.year] = action.payment
            return Object.assign({}, state, {
                notes: action.notes,
            });
        case 'REFRESH':
            return {
                ...state,
                refresh: !state.refresh
            }

        case 'REFRESH_NOTES':
            return {
                ...state,
                refreshNotes: !state.refreshNotes
            }

        default:
            return state;
    }
};


const refreshPage = () => {
    return {
        type: "REFRESH",
    }
}


const refreshNotesAction = () => {
    return {
        type: "REFRESH_NOTES",
    }
}


const CompanyPayments = () => {

    let {id, gcpId} = useParams();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        payments, notes, loading, refresh, refreshNotes

    } = state;

    const token = localStorage.getItem(TOKEN) || null;

    const [showModal, setShowModal] = React.useState(false);
    const [note2, setNote2] = React.useState('');

    const [isOpen, setIsOpen] = useState(false);
    const [paid, setPaid] = useState(new Date());
    const [year, setYear] = useState(0);
    const [note, setNote] = useState("");
    const [currNoteId, setCurrNoteId] = useState(0);
    const [isNoteCreateOpen, setIsNoteCreateOpen] = useState(false);
    const [isNoteDeleteOpen, setIsNoteDeleteOpen] = useState(false);

    useEffect(() => {
        console.log('useEffect called');
        fetchPayments(dispatch, token, gcpId);
    }, []);

    useEffect(() => {
        console.log('useEffect called');
        fetchNotes(dispatch,  gcpId, "PMT");
    }, [refreshNotes]);

    const handleChangePaid = (date) => {
        setPaid(date)
    };

    const handleOk = (pyear, ppaidDate) => {
        console.log('OK');
        setYear(pyear)
        setPaid(ppaidDate)
        setIsOpen(true)
    };
    const handleKo = (year) => {
        console.log('KO');
        let dto = {
            year
        };
        removePayment(dispatch, token, gcpId, dto)
    };

    const getStatus = (paid) => {
        if (paid) {
            return "Төлсөн"
        } else {
            return "Төлөөгүй"
        }
    }


    /*
    paid
     */
    let modalClass = classnames({
        modal: true,
        'is-active': isOpen
    });
    const handleYes = () => {
        console.log('remove invoice');

        let paid_str = null;
        if (paid !== null) {
            const p = paid.getTime()
            paid_str = dayjs(p).format('YYYY-MM-DD');
        }

        let dto = {
            year,
            paid: paid.getTime(),
            paid_str
        };
        createPayment(dispatch, token, gcpId, dto)
        setIsOpen(false)

    }
    const handleCancel = () => {
        setIsOpen(false)
    }

    /*
        note
     */

    const handleNote = (pyear, pnote) => {
        console.log('OK');
        setYear(pyear)
        setNote(pnote)
        setIsNoteCreateOpen(true)
    };


    let modalDeleteNoteClass = classnames({
        modal: true,
        'is-active': isNoteDeleteOpen
    });
    let modalCreateNoteClass = classnames({
        modal: true,
        'is-active': isNoteCreateOpen
    });

    const handleDeleteNote = (id) => {
        console.log('cancel');
        setIsNoteDeleteOpen(true)
        setCurrNoteId(id)

    };
    const handleChangeNote = (e) => {
        setNote(e.target.value)
    };

    const handleDeleteNoteYes = () => {
        removePaymentNote(dispatch, token, currNoteId);
        setIsNoteDeleteOpen(false)

    }
    const handleDeleteNoteCancel = () => {
        setIsNoteDeleteOpen(false)
    }

    const handleCreateNoteYes = () => {

        console.log('Save Note');
        let dto = {
            year,
            note
        };
        createPaymentNote(dispatch, token, gcpId, dto);
        setNote("");
        setIsNoteCreateOpen(false)

    }
    const handleCreateNoteCancel = () => {
        setIsNoteCreateOpen(false)
    }



    const optionsWithLabelChange = {
        closeOnOverlayClick: false,
        labels: {
            confirmable: "Устгах",
            cancellable: "Болих"
        }
    };

    const deleteNote = async (noteId, options) => {
        const result = await confirm("Тэмдэглэл устгах уу?", options);
        if (result) {
            console.log("You click yes!");
            removeNote(dispatch, noteId).then(response => {
                    toast.success("Амжилттай !");
                    //onUpdate()
                    dispatch(refreshNotesAction());
                }
            ).catch(error => {
                alert(error)
            });
        }
        console.log("You click No!");
    };

    const handleChangeNote2 = (e) => {
        setNote2(e.target.value)
    };
    const handleSaveNote = () => {
        //e.preventDefault();
        setShowModal(false);
//        setIsNoteCreateOpen(true)

        let dto = {
            note:note2,
            note_type:"PMT"
        };
        createNote(dispatch, gcpId, dto).then(response => {
                toast.success("Амжилттай !");
                setNote2("");
                //onUpdate()
                dispatch(refreshNotesAction());
            }
        ).catch(error => {
           alert(error)
       });
    }

    let counter = 0
    const items = payments.map(
        (payment) => {
            counter++;
            return (
                <tr key={payment.id}>
                    <td>{counter}</td>
                    <td>{payment.year}</td>
                    <td><span style={{color: payment.paid ? 'green' : 'red'}}>{getStatus(payment.paid)}</span></td>
                    <td>{payment.paid ? dayjs(payment.paid).format("MM/DD/YYYY") : ""}</td>
                    {/*{payment.note ? (*/}
                    {/*    <td>{payment.note} - <small>{payment.note_date ? dayjs(payment.note_date).format("MM/DD/YYYY") : ""}</small>*/}
                    {/*    </td>*/}
                    {/*) : (*/}
                    {/*    <td></td>*/}
                    {/*)*/}
                    {/*}*/}
                    <td>
                        <a className="button is-outlined"
                           onClick={(е) => handleOk(payment.year, payment.paid_date)}>Y</a> <a
                        className="button is-outlined" onClick={(е) => handleKo(payment.year)}>N</a>
                        {/*<a className="button is-outlined" onClick={(е) => handleNote(payment.year, payment.note)}>T</a>*/}
                    </td>
                </tr>
            )
        });

    const noteItems = notes.map(
        (p) => {
            return (
                <tr key={p.id}>
                    <td className="note-item-container">
                        <div className="note-element-container">{p.note}
                            <span><small>{dayjs(p.created).format("MM/DD/YYYY HH:mm")}</small></span>
                        </div>
                        <button
                            onClick={() => {
                                deleteNote(p.id, optionsWithLabelChange);
                            }}
                        >
                            {" "}
                            <FaTimes/>{" "}
                        </button>
                    </td>
                </tr>
            )
        });

    return (
        <>
        <div className="inner-container">
            <CompanyViewHeader tab={2} companyId={id}/>

            <h1>Төлбөр</h1>
            <div className="buttons is-right">
                <button
                    className="bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(true)}
                >
                    Шинээр Тэмдэглэл Үүсгэх
                </button>
            </div>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Жил</th>
                    <th>Статус</th>
                    <th>Төлсөн огноо</th>
                    {/*<th>Тэмдэглэл</th>*/}
                    <th>Өөрчлөх</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            {noteItems.length > 0 &&
            <>
                <h1 style={{marginBottom: "20px"}}>Тэмдэглэлүүд</h1>
                <table className="table is-bordered is-fullwidth">
                    <tbody>
                    {noteItems}
                    </tbody>
                </table>
            </>
            }
            <br/><br/>
            <div className={modalClass}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Төлсөн</p>
                        <button className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">

                        <div className="field">
                            <label className="label">Төлсөн огноо</label>
                            <div className="control has-icons-left">
                                <DatePicker className="input" selected={paid}
                                            onChange={handleChangePaid} placeholderText="Төлсөн огноо оруулах"/>
                            </div>
                        </div>
                        <br/><br/><br/><br/><br/><br/><br/>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={handleYes}>Тийм</button>
                        <button className="button" onClick={handleCancel}>Үгүй</button>
                    </footer>
                </div>
            </div>


            <div className={modalCreateNoteClass}>
                <div className="modal-background"></div>
                <div className="modal-content"
                     style={{borderRadius: "12px", padding: "20px", backgroundColor: "white"}}>

                    <div className="field">

                        <div className="control has-icons-left">
                            <textarea className="textarea" type="text" value={note}
                                      onChange={(e) => handleChangeNote(e)} placeholder="Тэмдэглэл оруулах"/>
                        </div>
                    </div>
                    <div className="buttons is-right">

                        <button className="button" onClick={handleCreateNoteCancel}>Болих</button>
                        <button className="button is-success" onClick={handleCreateNoteYes}>Хадгалах</button>
                    </div>
                </div>
            </div>

            <div className={modalDeleteNoteClass}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Тэмдэглэл Устгах?</p>
                        <button className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        Та тэмдэглэл устгахдаа итгэлтэй байна уу?
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={handleDeleteNoteYes}>Тийм</button>
                        <button className="button" onClick={handleDeleteNoteCancel}>Үгүй</button>
                    </footer>
                </div>
            </div>
        </div>

            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative my-2 mx-auto w-1/3 max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                <div className="relative p-2 flex-auto">
                                    <textarea className="form-control block w-full px-2 py-2         text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" rows="3" value={note2}
                                              onChange={(e) => handleChangeNote2(e)} placeholder="Тэмдэглэл оруулах"/>

                                </div>
                                {/*footer*/}
                                <div
                                    className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Хаах
                                    </button>
                                    <button
                                        className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => handleSaveNote()}
                                    >
                                        Хадгалах
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

        </>
    )

}


const mapStateToProps = (state, ownProps) => {
    let isUpdating = state.ui.isUpdating;
    let token = localStorage.getItem(TOKEN) || null;

    const companyId = ownProps.match.params.id
    const gcpId = ownProps.match.params.gcpId
    let payments = state.payments.payments;
    let counter = 0

    const payments_ids_by_order =
        Object.values(payments)
            .reduce((ordered_ids, b) => {
                ordered_ids[b.order] = b.year
                return ordered_ids
            }, []);

    payments_ids_by_order.map(
        (year) => {
            counter++
            payments[year].counter = counter
        });


    return {
        token,
        companyId,
        isUpdating,
        payments,
        payments_ids_by_order,
        gcpId
    }
}


export default connect(mapStateToProps)(CompanyPayments)


