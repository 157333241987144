import React from "react"

import logo from "../src/images/logo.png"
  
export default () => (
  
    <div className="header-top">
      <div className="container">
        <div className="logo">
          <div className="logo1">
            <a href="/">
              <img alt="GS1 logo" src={logo} />
            </a>

            <p class="large" style={{display:"inline-block"}}>
              <strong>
                <a href="/" title="Home">
                  Welcome to GS1 Mongolia
                </a>
              </strong>
              <br />
              The Global Language of Business{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  )

