/**
 * Created by gantushig on 11/6/15.
 */

import {Link} from "react-router-dom";
import React, {useEffect, useReducer} from "react";
import {fetchInvoiceMails, fetchInvoiceMailTemplateList} from "./actions/actions";
import dayjs from "dayjs";
import dayjsPluginUTC from 'dayjs-plugin-utc'
import qs from "query-string";
import {ActionTypes, Page, TOKEN} from "./Constants";
import ReactPaginate from "react-paginate";
import classnames from "classnames";
import history from "./history";
import {toast} from "react-toastify";


const initialState = {
    invoice_templates: [],
    meta: {},
    mails: [],
    current: '0',
    days: 30,
    isOpen: false,
    loading: false,
    totalPages: 0,
    totalElements: 0,
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_LIST_SUCCESS:
            let c = "0";
            if (action.invoice_templates.length > 0) {
                c = action.invoice_templates[0].id;
            }
            return {
                ...state,
                meta: action.meta,
                current:c,
                invoice_templates: action.invoice_templates,
                loading: false
            }
        case ActionTypes.LOAD_INVOICE_MAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_INVOICE_MAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                mails: action.invoice_mails,
                totalElements: action.meta.totalElements,
                totalPages: action.meta.totalPages,
            }
        case ActionTypes.CREATE_INVOICE_MAIL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.CREATE_INVOICE_MAIL_SUCCESS:
            let mails = state.mails;
            mails.unshift(action.mail);
            let te = state.totalElements + 1;
            return {
                ...state,
                loading: false,
                mails: mails,
                totalElements: te,
            }
        case 'CHANGE_CURRENT':
            return {
                ...state,
                current: action.current
            }
        case 'CHANGE_DAYS':
            return {
                ...state,
                days: action.days
            }
        case 'OPEN_DIALOG':
            return {
                ...state,
                isOpen: action.open
            }
        default:
            return state;
    }
};

const InvoicesMailPage = ({
                              location: {search}
}) => {
    let token = localStorage.getItem(TOKEN) || null;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let order = parsed[Page.ORDER];
    let pagesize = parsed[Page.PAGESIZE];
    let asc = parsed[Page.ASC];

    if (page === undefined) {
        page = "0";
    }
    if (order === undefined) {
        order = "created";
    }
    if (asc === undefined) {
        asc = "false";
    }
    if (pagesize === undefined) {
        pagesize = "25";
    }
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        mails, invoice_templates, days, current, isOpen, totalElements, totalPages
    } = state;
    useEffect(() => {
        console.log('useEffect called');
        fetchInvoiceMailTemplateList(dispatch, token);

    }, []);
    useEffect(() => {

        console.log('useEffect called');
        fetchInvoiceMails(dispatch, token, page, pagesize, order, asc);
    }, [page, pagesize, order, asc]);

    //let totalPages = 0;

    const templatesOptions = () => {
        let items = [];
        for (let key in invoice_templates) {
            let s = invoice_templates[key]
            items.push(<option key={s.id} value={s.id}>{s.name}</option>);
        }
        return items;
    };

    let modalClass = classnames({
        modal: true,
        'is-active': isOpen
    });

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed))
    };


    const handleCreateYes = () => {
        console.log('create invoice mails');

        // dispatch({type: 'OPEN_DIALOG', open: false});
        //
        // let dto = {}
        // dto.days = parseInt(days,10); //value;
        // dto.template_id = current; //value;
        //
        // createInvoiceMail(dispatch, token,  dto).then(response => {
        //         toast.success("Амжилттай уусгэлээ!");
        //
        //         //history.push(`/product/${productId}`)
        //     }
        // ).catch(error => {
        //     alert(error)
        // });
    }
    const handleCreateCancel = () => {
        dispatch({type: 'OPEN_DIALOG', open: false});
    }

    const handleOrderClick = (f) => {
        let parsed = qs.parse(search);
        if (parsed[Page.ORDER] === f) {
            let o = parsed[Page.ASC];
            if (o === "true") {
                o = "false";
            } else {
                o = "true";
            }
            parsed[Page.ASC] = o;
        } else {
            parsed[Page.ORDER] = f;
        }

        history.push("?" + qs.stringify(parsed))
    }
    const handleChangeCurrent = (e) => {
        dispatch({type: 'CHANGE_CURRENT', current: e.target.value});
    }
    const handleChangeDays = (e) => {
        dispatch({type: 'CHANGE_DAYS', days: e.target.value});
    }
    const handleOpen = (e) => {
        dispatch({type: 'OPEN_DIALOG', open: true});
    }
    const handleClose = (e) => {
        dispatch({type: 'OPEN_DIALOG', open: false});
    }
    const items = mails.map(
        (mail) => {
            return (
                <tr key={mail.id}>
                    <td>
                        <Link to={`/invoice-mail-items/${mail.id}`}>
                        {dayjs.utc(mail.created).format("YYYY-MM-DD")}
                    </Link>
                    </td>
                    <td>{mail.days}</td>
                    <td>
                        {mail.template_name}
                        {/*<Link to={`/invoice-mail-template/${mail.template_id}/edit`}>*/}
                        {/*    {mail.template_name}*/}
                        {/*</Link>*/}
                    </td>
                </tr>
            )
        });

    return (
        <div style={{background: 'white', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}>
            {/*<button*/}
            {/*    className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"*/}
            {/*    type="button"*/}
            {/*    onClick={() => handleOpen()}*/}
            {/*>*/}
            {/*    Create Mailing*/}
            {/*</button>*/}

            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>Created</th>
                    <th>Days</th>
                    <th>Template</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={page}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                               forcePage={page}
                />
            </nav>


            {isOpen ? (
                <>

                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        New
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => handleClose()}
                                    >
                    <span
                        className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">Template</label>
                                        <select className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="current" value={current}
                                                onChange={handleChangeCurrent}>
                                            {templatesOptions()}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">Days</label>
                                        <select className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="days" value={days}
                                                onChange={handleChangeDays}>
                                            <option key='7' value="7">7</option>
                                            <option key='30' value="30">30</option>
                                        </select>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => handleCreateCancel()}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => handleCreateYes()}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}


        </div>
    )

}


export default InvoicesMailPage


