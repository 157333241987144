/**
 * Created by gantushig on 11/6/15.
 */

import {connect, useDispatch, useSelector} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import classnames from "classnames";
import React, {useEffect, useState} from "react";
import {  fetchProductAppl   } from "./actions/actions";
import { TOKEN } from './Constants';
import ProductSenderFragment from "./ProductSenderFragment";

const ProductSSCCView = ({
                         p
                     }) => {


    return (

            <table className="table is-bordered is-fullwidth">
                <tbody>
                <tr>
                    <th className="company-title">Баркод/GTIN</th>
                    <td className="company-value">{p && p.barcode}</td>
                </tr>
                <tr>
                    <th>Бүтээгдэхүүний Нэр</th>
                    <td>{p && p.name}</td>
                </tr>
                <tr>
                    <th>Нэгжийн жин /кг, гр, ш, /</th>
                    <td>{p && p.unit_weight}</td>
                </tr>
                <tr>
                    <th>Хайрцаг дахь тоо</th>
                    <td>{p && p.package_quantity}</td>
                </tr>
                <tr>
                    <th>Тавиур дахь тоо</th>
                    <td>{p && p.pallet_quantity}</td>
                </tr>
                <tr>
                    <th>Экспорт хийж буй улс</th>
                    <td>{p && p.exporting_country}</td>
                </tr>

                <ProductSenderFragment p={p}/>

                </tbody>
            </table>
    )

}


export default ProductSSCCView

