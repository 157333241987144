/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {fetchGcpsAll, menuClicked} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, Menu, Page, PageTitle} from "./Constants";
import history from "./history";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import gcpOptionsData from "./data/gcps.json"
import gcpStatusData from "./data/gcp-status.json"


dayjs.extend(dayjsPluginUTC)


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const initialState = {
    gcps: [],
    meta:{totalPages:0},
    loading: false,
    refresh:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_GCPS_REQUEST:
            return {
                ...state,
                loading:true
            }
        case ActionTypes.LOAD_GCPS_SUCCESS:
            return {
                ...state,
                gcps: action.gcps,
                meta: action.meta,
                loading:false
            }
        default:
            return state;
    }
};

const gcpStatusOptions = () => {
    let items = [];
    gcpStatusData.map(item=>{
        items.push(<option key={item.id} value={item.id}>{item.name}</option>);
    })
    return items;
};

const GcpsPage = ({
                      location: {search}
                  }) => {
     const [barcodeType, setBarcodeType] = useState(0);
     const [prefix, setPrefix] = useState("");
     const [status, setStatus] = useState("A");
     const [size, setSize] = useState(0);

    const [state, dispatch] = useReducer(reducer, initialState);
    const {gcps, loading, meta
    } = state;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];
    let orderField = parsed[Page.ORDER];
    let isAsc = parsed[Page.ASC];
    let qsize = parsed["sz"];
    let qprefix = parsed["pre"];
    let qbarcodeType = parsed["bc"];
    let qstatus = parsed["st"];

    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "15";
    }
    if (orderField === undefined) {
        orderField = "id";
    }
    if (isAsc === undefined) {
        isAsc = "true";
    }
    if (qsize === undefined) {
        qsize = "0";
    }
    if (qstatus === undefined) {
        qstatus = "A";
    }
    if (qprefix === undefined) {
        qprefix = null;
    }
    if (qbarcodeType === undefined) {
        qbarcodeType = "0";
    }


    //const reduxDispatch = useDispatch();
    //let token = localStorage.getItem(TOKEN) || null;
    let counter = 0
    for (let key in gcps) {
        let item = gcps[key]
        counter++
        item.counter = Number(page) * Number(pagesize) + counter
    }

    useEffect(() => {
        dispatch(menuClicked(Menu.GCPS, PageTitle.GCPS));
    }, []);
    useEffect(() => {
        fetchGcpsAll(dispatch,
            qbarcodeType, qsize, qprefix, null, qstatus,
             page, pagesize, orderField, isAsc);
    }, [orderField, isAsc, page, pagesize, qbarcodeType, qsize, qprefix, qstatus]);


    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };

    const barcodeTypeLabel = (g) => {
        let ret = ""
        if (g === 0) {
            ret = "GTIN"
        } else if ( g === 1) {
            ret = "GLN"
        } else if ( g === 2) {
            ret = "SSCC"
        }

        return ret
    }

    const handleChangeBarcodeType = (e) => {
        setBarcodeType(Number(e.target.value))
    };

    const handleChangeSize = (e) => {
        setSize(Number(e.target.value));
    };
    const handleChangePrefix = (e) => {
        setPrefix(e.target.value);
    };
    const handleChangeStatus = (e) => {
        setStatus(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        let parsed = qs.parse(search);
        parsed["bc"] = barcodeType;
        parsed["pre"] = prefix;
        parsed["sz"] = size;
        parsed["st"] = status;
        parsed[Page.PAGE] = "0";
        history.push("?" + qs.stringify(parsed))
    };

    const handleOrderClick = (f) => {

        let lorderField = orderField;
        let lisAsc = isAsc;

        if (f === lorderField) {
            if (lisAsc === 'true') {
                lisAsc = 'false';
            } else {
                lisAsc = 'true';
            }
        } else {
            lorderField = f;
            lisAsc = true;
        }

        let parsed = qs.parse(search);
        parsed[Page.ORDER] =  lorderField;
        parsed[Page.ASC] = lisAsc;
        history.push("?" + qs.stringify(parsed))
    }

    const appts2 = gcps.map(p => {
        return (
            <tr key={p.id}>
                <td>
                    <Link to={`/company/${p.company_id}`}> {/* onClick={() => reduxDispatch(menuClickedTitle(p.name))}>*/}
                        {p.counter}
                    </Link>
                </td>
                <td>
                    <Link to={`/company/${p.company_id}`}> {/* onClick={() => reduxDispatch(menuClickedTitle(p.name))}>*/}
                        {p.company_name}
                    </Link>
                </td>
                <td>{barcodeTypeLabel(p.barcode_type)}</td>
                <td>
                    {p.prefix}
                </td>
                <td>{p.size}</td>
            </tr>
        )
    });

    return (

        <div className="inner-container">

            <GcpSearchPanel
                barcodeType={barcodeType} onChangeBarcodeType={handleChangeBarcodeType}
                size={size} onChangeSize={handleChangeSize}
                prefix={prefix} onChangePrefix={handleChangePrefix}
                status={status} onChangeStatus={handleChangeStatus}
                onClickSearch={handleSearch}

            />

            <br/>
            <div>
                <h5 style={{marginBottom: "10px"}}><strong>Нийт {meta.totalElements}</strong>
                </h5>

                <nav className="pagination">
                    <ReactPaginate previousLabel={"Өмнөх"}
                                   nextLabel={"Дараах"}
                                   initialPage={Number(page)}
                                   breakLabel={"..."}
                                   breakClassName={"pagination-ellipsis"}
                                   pageCount={meta.totalPages}
                                   marginPagesDisplayed={2}
                                   pageRangeDisplayed={5}
                                   activeLinkClassName={"is-current"}
                                   pageLinkClassName={"pagination-link"}
                                   onPageChange={(e) => handlePageClick(e)}
                                   previousClassName={"pagination-previous"}
                                   nextClassName={"pagination-next"}
                                   containerClassName={"pagination-list"}
                                   forcePage={Number(page)}
                    />
                </nav>


            </div>
            <br/>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th><a onClick={() => handleOrderClick("id")}>#</a></th>
                    <th><a onClick={() => handleOrderClick("name")}>Компани</a></th>
                    <th>Type</th>
                    <th><a onClick={() => handleOrderClick("prefix")}>GCP</a></th>
                    <th><a onClick={() => handleOrderClick("size")}>Size</a></th>
                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>
            <br/><br/>
        </div>
    )

}


const GcpSearchPanel = ({
                            barcodeType, size, prefix, status,
                            onChangeBarcodeType, onChangeSize, onChangePrefix,onChangeStatus,
                            onClickSearch
                        }) => {

    const gcpOptions = () => {
        let items = [];
        gcpOptionsData.map(item=>{
            return items.push(<option key={item.id} value={item.id}>{item.name}</option>);
        })
        return items;
    };

    return (
        <div>
            <div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Баркод төрөл</label>
                    </div>
                    <div className="field-body">
                        <div className="control">
                            <label className="radio">
                                <input type="radio" value="0" checked={barcodeType === 0}
                                       onChange={onChangeBarcodeType}/>
                                &nbsp;&nbsp;GTIN
                            </label>
                            <label className="radio">
                                <input type="radio" value="1" checked={barcodeType === 1}
                                       onChange={onChangeBarcodeType}/>
                                &nbsp;&nbsp;GLN
                            </label>
                            <label className="radio">
                                <input type="radio" value="2" checked={barcodeType === 2}
                                       onChange={onChangeBarcodeType}/>
                                &nbsp;&nbsp;SSCC
                            </label>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Gcp Төрөл</label>
                    </div>
                    <div className="field-body">
                        <div className="control has-icons-left">
                            <div className="select">
                                <select value={size} onChange={onChangeSize} style={{width: '400px'}}>
                                    <option value=""></option>
                                    {gcpOptions()}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Gcp</label>
                    </div>
                    <div className="field-body">
                        <div className="control">
                            <input className="input" type="text" value={prefix} style={{width: '400px'}}
                                   onChange={onChangePrefix} placeholder="Оруулах"/>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Төлөв</label>
                    </div>
                    <div className="field-body">
                    <div className="control">
                        <div className="select">
                            <select  name="status"  value={status} onChange={onChangeStatus}>
                                {gcpStatusOptions()}
                            </select>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label">

                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <div className="buttons">
                                    <button onClick={onClickSearch} className="button bg-orange">
                                        <span style={{color: "white"}}>Хайх</span>

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default GcpsPage

