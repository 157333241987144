/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import React, {Component, useEffect, useState} from "react";
import {
    fetchClassifs,
    updateProductsCat,
    resetErrors
} from "./actions/actions";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {toast} from "react-toastify";
import {TOKEN} from "./Constants";
import history from "./history";

dayjs.extend(dayjsPluginUTC)

const ProductsUpdateCat = () => {



    const dispatch = useDispatch();
    let token = localStorage.getItem(TOKEN) || null;

    const stateProducts = useSelector(state => state.products);
    const stateClassifs = useSelector(state => state.classifs);

    const [main, setMain] = useState(stateProducts.update_main);
    const [sub, setSub] = useState(stateProducts.update_sub);
    const [unit, setUnit] = useState(stateProducts.update_unit);

    const mainItems = stateClassifs.main;
    const subItems = stateClassifs.subCats.get(main);
    const unitItems = stateClassifs.unitCats.get(sub);
    let selections = stateProducts.selections;



    useEffect(() => {
        resetErrors()
        if (Object.keys(mainItems).length === 0 && mainItems.constructor === Object) {
            console.log('useEffect called');
            dispatch(fetchClassifs(token, "main", 0))
        }
    }, []);


    let main_ids_by_order = [];
    if (mainItems !== null  && mainItems !== undefined) {
        main_ids_by_order = Object.values(mainItems)
            .reduce((ordered_ids, category) => {
                ordered_ids[category.order] = category.id
                return ordered_ids
            }, []);
    }
    let sub_ids_by_order = [];
    if ( subItems !== null && subItems !== undefined) {
        sub_ids_by_order = Object.values(subItems)
            .reduce((ordered_ids, category) => {
                ordered_ids[category.order] = category.id
                return ordered_ids
            }, []);
    }
    let unit_ids_by_order = [];
    if ( unitItems !== null  && unitItems !== undefined) {
        unit_ids_by_order = Object.values(unitItems)
            .reduce((ordered_ids, category) => {
                ordered_ids[category.order] = category.id
                return ordered_ids
            }, []);
    }

    const handleUpdate = (e) => {
        e.preventDefault();

        var ids = [];
        for (let k of selections.keys()) {
            ids.push (k);
        }
        let dto = {
            ids:ids,
            main:main,
            sub: sub,
            unit: unit,
        };

        dispatch(updateProductsCat(token,  dto)).then(response => {
                toast.success("Амжилттай !");
                history.push("/products")
            }
        ).catch(error => {
            alert(error)
        })
    };


    const handleChangeMain = (e) => {

        setMain(e.target.value);
        setSub("0")
        setUnit("0")
    };
    const handleChangeSub = (e) => {
        setSub(e.target.value)
        setUnit("0")
    };
    const handleChangeUnit = (e) => {
        setUnit(e.target.value)
    };
    const handleClickSelectSub = (e) => {
        let c = stateClassifs.subCats.get(main);
        if ( c === null || c === undefined) {
            dispatch(fetchClassifs(token, "sub", main));
        }
    };
    const handleClickSelectUnit = (e) => {
        let c = stateClassifs.unitCats.get(sub);
        if ( c === null || c === undefined ) {
            dispatch(fetchClassifs(token, "unit", sub));
        }
    };



    return (

        <div className="inner-container">


            <div >

                <div className="field">
                    <label className="label">ҮНДСЭН АНГИЛАЛ</label>
                    <div className="control has-icons-left">
                        <div className="select">
                            <select style={{width: "400px"}} value={main} onChange={(e)=>handleChangeMain(e)}>
                                <option value="0"></option>
                                {main_ids_by_order.map(id => {
                                    return (
                                        <option key={id} value={id}>{mainItems[id].name} - [{mainItems[id].code}]</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div className="field">
                    <label className="label">ДЭД АНГИЛАЛ</label>
                    <div className="control has-icons-left">
                        <div className="select">
                            <select style={{width: "400px"}} value={sub} onChange={(e)=>handleChangeSub(e)} onClick={(e)=>handleClickSelectSub(e)}>
                                <option value="0"></option>
                                {main !== "0" && sub_ids_by_order.map(id => {
                                    return (
                                        <option key={id} value={id}>{subItems[id].name} - [{subItems[id].code}]</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                </div>


                <div className="field">
                    <label className="label">НЭГЖ АНГИЛАЛ</label>
                    <div className="control has-icons-left">
                        <div className="select">
                            <select style={{width: "400px"}} value={unit} onChange={(e)=>handleChangeUnit(e)} onClick={(e)=>handleClickSelectUnit(e)}>
                                <option value="0"></option>
                                {sub !== "0" &&
                                unit_ids_by_order.map(id => {
                                    return (
                                        <option key={id} value={id}>{unitItems[id].name} - [{unitItems[id].code}]</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label">

                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control buttons">

                                <button onClick={(e)=>handleUpdate(e)} className="button bg-orange">
                                    <span style={{color: "white"}}>Update</span>

                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}


export default ProductsUpdateCat

