/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {createProduct, fetchClassifsNouv, fetchCountries, fetchPackagingUnits, setUiTitle} from "./actions/actions";
import ProductEditComponent from "./ProductEditComponent";
import {toast} from "react-toastify";
import {GLN, GTIN, SSCC} from "./Constants"
import {createGlnDto, createGtinDto, createSsccDto, validateProduct} from "./utils/productUtils";
import history from "./history";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import {getReducer} from "./reducers/productReducer";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}
const changePackaging = (unit) => {
    return {
        type: 'CHANGE_PACKAGING',
        unit
    }
}
const changeGcp = (gcp) => {
    return {
        type: 'CHANGE_GCP',
        gcp
    }
}

const setGcpPrefix = (prefix, size) => {
    return {
        type: 'SET_GCP_PREFIX',
        prefix
    }
}


const addCountry = (tag) => {
    return {
        type: 'ADD_COUNTRY',
        tag
    }
}

const deleteCountry = (i) => {
    return {
        type: 'DELETE_COUNTRY',
        i
    }
}

const changeMain = (main) => {
    return {
        type: 'CHANGE_MAIN',
        main
    }
}

const changeSub = (sub) => {
    return {
        type: 'CHANGE_SUB',
        sub
    }
}


const changeUnit = (unit) => {
    return {
        type: 'CHANGE_UNIT',
        unit
    }
}

const clickMain = () => {
    return {
        type: 'CLICK_MAIN'
    }
}
const clickSub = () => {
    return {
        type: 'CLICK_SUB'
    }
}
const clickUnit = () => {
    return {
        type: 'CLICK_UNIT'
    }
}

const setGcp = (gcp) => {
    return {
        type: 'SET_GCP',
        gcp:gcp
    }
}

const ProductCreate = ({
                           match: {params: {id, gcpId, prefix, size}}
                       }) => {
    let reduxDispatch = useDispatch();
    const [state, dispatch] = getReducer(); //useReducer(reducer, initialState);
    const {product, loading, units, selectedCountries, gcps, showMainOptions, showSubOptions, showUnitOptions, mainOptions, subOptions, unitOptions,countries} = state;

    useEffect(() => {
        fetchCountries(dispatch);
    }, []);

    useEffect(() => {
        reduxDispatch(setUiTitle("Бүтээгдэхүүн үүсгэх"));
    }, []);

    useEffect(() => {
        let gcp = {}
        gcp.id = gcpId;
        gcp.prefix = prefix;
        gcp.size = size;
        dispatch(setGcp(gcp));
    }, []);

    useEffect(() => {
        dispatch(setGcpPrefix(prefix));
    }, []);

    useEffect(() => {
        console.log('useEffect fetchPackagingUnits');
        fetchPackagingUnits(dispatch)
    }, []);


    const handleChangeField = (e) => {
        if (e.target.name === 'is_14') {
            dispatch(changeField(e.target.name, !product.is_14)); //e.target.checked));
        } else {
            dispatch(changeField(e.target.name, e.target.value));
        }
    }

    const handleSave = () => {
        let validated = validateProduct(product);
        if (!validated) {
            return;
        }
        // let validated = false;
        // if (product.barcode.startsWith(prefix)) {
        //     validated = true;
        // } else {
        //     confirmAlert({
        //         title: 'Alert',
        //         message: 'Gcp зөрж байна.',
        //         buttons: [
        //             {
        //                 label: 'Yes',
        //                 onClick: () => {
        //                     return;
        //                 }
        //             },
        //         ]
        //     });
        // }
        // if (!validated) {
        //     return;
        // }

        let dto = {}
        if (product.barcode_type === null || product.barcode_type === GTIN) {
            dto = createGtinDto(product)
        } else if (product.barcode_type === GLN) {
            dto = createGlnDto(product)
        } else if (product.barcode_type === SSCC) {
            dto = createSsccDto(product)
        }
        dto.exp_countries = selectedCountries;
        // let ex = "";
        // selectedCountries.map(m => {
        //     ex += m.id;
        //     ex += ',';
        // })
        // dto.exp_countries_office = ex;

        dto.gcp_id = gcpId;
        dto.gcp_prefix = prefix;
        dto.gcp_size = size;

        createProduct(dispatch,  id, dto).then(response => {
                toast.success("Амжилттай илгээлээ!");
                history.push(`/company/${id}/products`)
            }
        ).catch(error => {
            alert(error)
        })
    };
    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
    };

    const handleCountryDelete = (i) => {
        dispatch(deleteCountry(i));
    }

    const handleCountryAdd = (tag) => {
        if (countries.some(e => e.id === tag.id)) {
            dispatch(addCountry(tag));
        }
    }
    const handleChangeGcp = (e) => {
        dispatch(changeGcp(e.target.value))
    };
    const handleChangePackagingUnit = (e) => {
        dispatch(changePackaging(e.target.value))
    };

    //main
    const handleClickMainInput = (e) => {
        dispatch(clickMain());
        fetchClassifsNouv(dispatch, "main",0);
    };
    const handleChangeMainOption = (e) => {
        let id = e.target.value;
        dispatch(changeMain(id));
        fetchClassifsNouv(dispatch, "sub",id);
    };

    //sub
    const handleClickSubInput = (e) => {
        dispatch(clickSub());
        fetchClassifsNouv(dispatch, "sub", product.main);
    };
    const handleChangeSubOption = (e) => {
        let id = e.target.value;
        dispatch(changeSub(id));
        fetchClassifsNouv(dispatch, "unit",id);
    };

    //unit
    const handleClickUnitInput = (e) => {
        dispatch(clickUnit());
        fetchClassifsNouv(dispatch, "unit", product.sub);
    };


    const handleChangeUnitOption = (e) => {
        let id = e.target.value;
        dispatch(changeUnit(id));
    };

    return (

        <div className="inner-container">
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>

            <ProductEditComponent
                product={product} onChangeField={handleChangeField}
                onSave={handleSave} onCancel={handleCancel}
                packaging_units={units} onChangePackagingUnit={handleChangePackagingUnit}
                countriesData={countries} selectedCountries={selectedCountries} onCountryAdd={handleCountryAdd}  onCountryDelete={handleCountryDelete}
                gcps={[]} onChangeGcp={handleChangeGcp} gcpDisabled={true}
                onClickMainInput = {handleClickMainInput} onClickSubInput={handleClickSubInput} onClickUnitInput={handleClickUnitInput}
                showMainOptions={showMainOptions} showSubOptions={showSubOptions} showUnitOptions={showUnitOptions}
                mainOptions={mainOptions} subOptions={subOptions} unitOptions={unitOptions}
                onChangeMainOption={handleChangeMainOption} onChangeSubOption={handleChangeSubOption} onChangeUnitOption={handleChangeUnitOption}
            />

        </div>

    )

}


// const mapStateToProps = (state, ownProps) => {
//     const companyId = ownProps.match.params.id
//     let isUpdating = state.ui.isUpdating
//
//
//     let isSuccess = state.products.isSuccessfulEdit
//     let isError = state.error.isError
//     let error = state.error.error
//     let errorMessage = state.error.message
//
//     let gcpId = ownProps.match.params.gcpId
//     let gcps = state.gcps.gcps;
//     let gcp = gcps[gcpId]
//     let gcpPrefix =gcp.prefix
//     return {
//
//         companyId,
//         isUpdating,
//         gcpId,
//         gcpPrefix,
//         isSuccess,
//         isError,
//         error,
//         errorMessage
//     }
// }
//


export default ProductCreate;