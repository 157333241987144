/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useReducer, useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {connect, useDispatch, useSelector} from "react-redux";
import {
    createInvoiceMailTemplate, fetchClassifs, fetchProductNew,
    resetCatInputTapped, resetErrors, resetProductUpdateStatus, fetchInvoiceMailTemplate, updateInvoiceMailTemplate
} from "./actions/actions";
import {
    checkGlnFieldsFilled,
    checkGtinFieldsFilled,
    checkSsccFieldsFilled,
    createGlnDto,
    createGtinDto, createSsccDto
} from "./utils/productUtils";
import history from "./history";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const InvoiceMailTemplateEditComponent = ({mail, subject, name, loading,
                                              onChangeName, onChangeMail, onChangeSubject, onSave}) => {

    let modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    }

    let    formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image'
        ]

    return (
        <div className="inner-container">

            <div className="level">
            <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                <h1 className="title is-size-4  has-text-info">Invoice Mail Template</h1>
            </div>
        </div>
        <hr/>
         <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <div className="field">
                <label className="label">Name</label>

                <input name="name" disabled  className="border-gray-500 border p-3" type="text" value={name}
                          onChange={onChangeName} placeholder="Enter template name"/>

            </div>
            <div className="field">
                <label className="label">Subject</label>

                    <input className="border-gray-500 p-3 border w-full" name="subject"  type="text" value={subject}
                           onChange={onChangeSubject} placeholder="Enter the subject"/>

            </div>
            <ReactQuill theme="snow" value={mail} modules={modules}
                        formats={formats} onChange={onChangeMail}/>
            <button className="bg-green-500 text-white p-3 mt-3" type="primary"
                    onClick={() => onSave()}>
                Хадгалах
            </button>
    </div>

    )

}


export default InvoiceMailTemplateEditComponent