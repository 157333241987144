/**
 * Created by gantushig on 4/16/17.
 */

import React, {useReducer, useState} from "react";
import {uploadProduct8File} from "./actions/actions";
import DatePicker from "react-datepicker";
import {css} from "@emotion/react";
import history from "./history";
import {ActionTypes, TOKEN} from "./Constants";
import {toast} from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const initialState = {
    loading: false,
    name:"",
    page: 0,
    pagesize: 15,
    files: {},
    meta: {},
    selectedFileId: 0
};

const reducer = (state, action) => {
    switch (action.type) {

        case ActionTypes.UPLOAD_PRODUCT8_FILE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.UPLOAD_PRODUCT8_FILE_SUCCESS:
            return {
                ...state,
                files: action.files,
                meta: action.meta,
                loading: false
            }
        default:
            return state;
    }
};

const Product8FileCreate = ({
                                match: {params: {companyId}}
                            }) => {
    const [issued, setIssued] = useState(new Date());
    const [selectedFile, setSelectedFile] = useState(null);
    const [state, dispatch] = useReducer(reducer, initialState);
    const {loading} = state;
    let token = localStorage.getItem(TOKEN) || null;

    const handleChangeIssued = (date) => {
        setIssued(date)
    };

    const handleChangeFile = (e) => {
        console.log(e.target.files[0])
        setSelectedFile(e.target.files[0])
    };

    const handleSave = () => {
        console.log('send');

        uploadProduct8File(dispatch, token, companyId, selectedFile, issued).then(response => {
                toast.success("Амжилттай үүсгэлээ!");
                history.push(`/company/${companyId}/product8-files`)
            }
        ).catch(error => {
            alert(error)
        });
    };
    const handleCancel = () => {
        history.goBack()
    };

    return (
        <div style={{
            background: 'white',
            paddingLeft: '20px',
            paddingTop: '20px',
            paddingRight: '20px',
            paddingBottom: "20px"
        }}>

            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <div style={{background: 'white'}}>

                <div className="field">
                    <label className="label">Олгогдсон огноо</label>
                    <div className="control has-icons-left">
                        <DatePicker utcOffset={0} className="input" selected={issued}
                                    onChange={handleChangeIssued} placeholderText="Олгогдсон огноо оруулах"/>
                    </div>
                </div>

                <div className="field">
                    <div className="control has-icons-left">
                        <div class="file has-name">
                            <label class="file-label">
                                <input type="file" className="file-input" name="file"
                                       onChange={(e) => handleChangeFile(e)}/>
                                <span class="file-cta">
      <span class="file-icon">
        <i class="fas fa-upload"></i>
      </span>
      <span class="file-label">
        Choose a file…
      </span>
    </span>
                                <span class="file-name">
                                {selectedFile && selectedFile.name}
                                    {selectedFile === null && <span>No file chosen</span>}
    </span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>


            <div class="buttons is-right" style={{paddingTop: '10px'}}>
                <button className="button" type="primary" onClick={() => handleCancel()}>Болих</button>
                <button className="button is-success" type="primary"
                        onClick={(e) => handleSave(e)}>
                    Хадгалах
                </button>
            </div>

        </div>
    )
}

export default Product8FileCreate;