/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useReducer, useState} from "react";
import {
    fetchAllProduct8s,
    fetchAllProducts, fetchCountries,
    fetchEditProducts,
    fetchPackagingUnits,
    updateProduct, updateProductMulti
} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, GTIN, Page, TOKEN} from "./Constants";
import history from "./history";
//import countriesData from "./data/countries3.json";
import {toast} from "react-toastify";
import {WithContext as ReactTags} from "react-tag-input";

dayjs.extend(dayjsPluginUTC)


const initialState = {
    products: [],
    meta: {totalPages: 0},
    product: {},
    loading: false,
    units: [],
    packaging_unit: "",
    packaging: "",
    brand_name: "",
    selectedCountries:[],
    countries:[]
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_PACKAGING_UNITS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.LOAD_COUNTRIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                countries: action.countries
            }
        }
        case ActionTypes.LOAD_PACKAGING_UNITS_SUCCESS: {
            return {
                ...state,
                units: action.units,
                loading: false
            }
        }
        case ActionTypes.LOAD_EDIT_PRODUCTS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.LOAD_EDIT_PRODUCTS_SUCCESS: {
            let arr = action.products;
            for ( let el of arr ) {
                if (!el.exp_countries_json) {
                    el.exp = null;
                } else {
                    let ret = "";
                    el.exp_countries_json.map(el => {
                        const c = state.countries.find(e => e.id === el)
                        if (c) {
                            ret += c.name;
                            ret += "/"+c.id;
                            ret += ", ";
                        }
                    })
                    if (ret !== "") {
                        ret = ret.replace(/,\s*$/, "");

                    }
                    el.exp = ret
                }
            }
            // if (!p.exp_countries_json) {
            //     return null;
            // }
            // // let str = p.exp_countries_office; //'MN,RU,FR,';
            // // //remove the last comma
            // // str = str.replace(/,\s*$/, "");
            // //
            // // let test = str.split(','); //['MN','RU','FR'];
            // let ret = "";
            // p.exp_countries_json.map(el => {
            //     const c = countries.find(e => e.id === el)
            //     ret += c.name;
            //     ret += "/"+c.id;
            //     ret += ", ";
            // })
            // if (ret !== "") {
            //     ret = ret.replace(/,\s*$/, "");
            //
            // }

            return {
                ...state,
                products: arr, //action.products,
                meta: action.meta,
                loading: false
            }
        }
        // case ActionTypes.UPDATE_PRODUCT_SUCCESS: {
        //     let p = action.product;
        //
        //     return {
        //         ...state,
        //         product: action.product,
        //         loading: false
        //     }
        // }
        case 'CHANGE_PACKAGING': {
            let arr = [...state.products];
            let c = arr[action.i];
            c.packaging_unit = action.unit;

            return {
                ...state,
                products: arr,
                loading: false
            }
        }
        case 'CHANGE_FIELD':
            let arr = [...state.products];
            let c = arr[action.i];
            c[action.name] = action.value
            return {
                ...state,
                products: arr,
                loading: false
            }
        case 'CHANGE_ALL_PACKAGING_UNIT': {
            let arr = [...state.products];
            arr.map(a => {
                a.packaging_unit = action.unit
            })
            return {
                ...state,
                products: arr,
                packaging_unit: action.unit,

            }
        }
        case 'CHANGE_ALL_PACKAGING_TEXT': {
            let arr = [...state.products];
            arr.map(a => {
                a.packaging = action.value
            })
            return {
                ...state,
                products: arr,
                packaging: action.value,

            }
        }
        case 'CHANGE_ALL_BRAND_NAME': {
            let arr = [...state.products];
            arr.map(a => {
                a.brand_name = action.value
            })
            return {
                ...state,
                products: arr,
                brand_name: action.value,

            }
        }
        case 'ADD_COUNTRY': {
            let arr = [...state.products];
            let s = [...state.selectedCountries, action.tag];
            let ret = ""
                s.map(el => {

                    ret += el.name;
                    ret += "/"+el.id;
                    ret += ", ";
                })
                if (ret !== "") {
                    ret = ret.replace(/,\s*$/, "");

                }
            arr.map(a => {
                a.exp = ret
            })

            return {
                ...state,
                products: arr,
                selectedCountries: [...state.selectedCountries, action.tag],
            }
        }
        case 'DELETE_COUNTRY': {
            let arr = [...state.products];
            let s = state.selectedCountries.filter((tag, index) => index !== action.i);
            let ret = ""
            s.map(el => {

                ret += el.name;
                ret += "/" + el.id;
                ret += ", ";
            })
            if (ret !== "") {
                ret = ret.replace(/,\s*$/, "");

            }
            arr.map(a => {
                a.exp = ret
            })
            return {
                ...state,
                products: arr,
                selectedCountries: state.selectedCountries.filter((tag, index) => index !== action.i)

            }
        }
        default:
            return state;
    }
};


const changeField = (p, i, name, value) => {
    return {
        type: 'CHANGE_FIELD',
        p, i,
        name,
        value
    }
}

const changePackaging = (p, i, unit) => {
    return {
        type: 'CHANGE_PACKAGING',
        p, i,
        unit
    }
}

const changeAllBrandName = (value) => {
    return {
        type: 'CHANGE_ALL_BRAND_NAME',
        value
    }
}

const changeAllPackagingText = (value) => {
    return {
        type: 'CHANGE_ALL_PACKAGING_TEXT',
        value
    }
}
const changeAllPackagingUnit = (unit) => {
    return {
        type: 'CHANGE_ALL_PACKAGING_UNIT',
        unit
    }
}

const KeyCodes = {
    comma: 188,
    enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const addCountry =(tag) => {
    return {
        type: 'ADD_COUNTRY',
        tag
    }
}

const deleteCountry =(i) => {
    return {
        type: 'DELETE_COUNTRY',
        i
    }

}

const ProductsEditAllPage = ({
                                 location: {search}
                             }) => {
    const [companyName, setCompanyName] = useState(null);
    const [state, dispatch] = useReducer(reducer, initialState);
    const {products, loading, units, meta, packaging, packaging_unit, brand_name, selectedCountries, countries} = state;


    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];
    let orderField = parsed[Page.ORDER];
    let isAsc = parsed[Page.ASC];
    let cname = parsed["co"];

    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "15";
    }
    if (orderField === undefined) {
        orderField = "id";
    }
    if (isAsc === undefined) {
        isAsc = "true";
    }
    if (cname === undefined) {
        cname = "";
    }
    useEffect(() => {
        fetchCountries(dispatch);
    }, []);

    useEffect(() => {
        fetchPackagingUnits(dispatch)
    }, []);

    useEffect(() => {
        console.log('useEffect called');
        fetchEditProducts(
            dispatch, cname, page, pagesize, orderField, isAsc);
    }, [cname, page, pagesize, orderField, isAsc]);

    const handleChangeCompanyName = (e) => {
        setCompanyName(e.target.value);
    };

    const getExpCountryLabel=(p)=> {

        // if (!p.exp_countries_json) {
        //     return null;
        // }
        // // let str = p.exp_countries_office; //'MN,RU,FR,';
        // // //remove the last comma
        // // str = str.replace(/,\s*$/, "");
        // //
        // // let test = str.split(','); //['MN','RU','FR'];
        // let ret = "";
        // p.exp_countries_json.map(el => {
        //     const c = countries.find(e => e.id === el)
        //     ret += c.name;
        //     ret += "/"+c.id;
        //     ret += ", ";
        // })
        // if (ret !== "") {
        //     ret = ret.replace(/,\s*$/, "");
        //
        // }

        return p.exp; //ret;
    }
    const handleSearch = (e) => {
        e.preventDefault();
        let parsed = qs.parse(search);
        parsed["co"] = companyName;
        parsed[Page.PAGE] = "0";
        history.push("?" + qs.stringify(parsed))
    };

    // const handleChangeName = (e) => {
    //     setName(e.target.value);
    // };
    //
    // const handleChangeBarcode = (e) => {
    //     setBarcode(e.target.value);
    // };


    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };
    const handleChangeField = (e, p, index) => {
        dispatch(changeField(p, index, e.target.name, e.target.value))
    }
    const handleChangePackagingUnit = (e, p, index,) => {
        dispatch(changePackaging(p, index, e.target.value))
    };

    const handleChangeAllPackagingUnit = (e) => {
        dispatch(changeAllPackagingUnit(e.target.value))
    };
    const handleChangeAllPackagingText = (e) => {
        dispatch(changeAllPackagingText(e.target.value))
    };
    const handleChangeAllBrandName = (e) => {
        dispatch(changeAllBrandName(e.target.value))
    };

    const handleCountryDelete = (i)=> {
        dispatch(deleteCountry(i));
    }

    const handleCountryAdd = (tag)=> {

        // check tag
       // if (countries.some(e => e.id === tag.id)) {
            dispatch(addCountry(tag));
        //}
    }
    const handleClickYes = (e, p) => {
        let dto = {}
        dto.packaging = p.packaging;
        dto.packaging_unit = p.packaging_unit;
        dto.barcode = p.barcode;
        dto.name = p.name;
        dto.brand_name = p.brand_name;
        updateProduct(dispatch,  p.id, dto).then(response => {
                toast.success("Амжилттай!");
            }
        ).catch(error => {
            alert(error)
        })
    }

    const handleUpdateAll = (e) => {
        let dto = {}
        let arr = [];

        products.map((p, i) => {
            let item = {};
            item.id = p.id;
            item.barcode = p.barcode;
            item.name = p.name;
            item.brand_name = p.brand_name;
            item.packaging = p.packaging;
            item.packaging_unit = p.packaging_unit;
            item.exp_countries = selectedCountries;
            // if (selectedCountries.length > 0) {
            //     let ex = "";
            //     selectedCountries.map(m=>{
            //         ex += m.id;
            //         ex += ',';
            //     })
            //     item.exp_countries_office = ex;
            // }

            arr.push(item);
        })
        dto.arr = arr;
        updateProductMulti(
            dispatch,
            dto);

    }


    const checkGtin = (sNumber) => {
        let output = []
        // separate numbers
        for (let i = 0, len = sNumber.length; i < len; i += 1) {
            output.push(+sNumber.charAt(i));
        }

        // gtin 13
        if (output.length === 13) {
            let sum = 0;
            for (let i = 0; i < output.length - 1; i++) {
                if (i % 2 === 0) {
                    sum += output[i] * 1
                } else {
                    sum += output[i] * 3
                }
            }
            //
            let ceil = Math.ceil(sum / 10) * 10;
            let check = ceil - sum;
            return check === output[12];
        } else {
            return false;
        }
    }

    const getCtrlNmbr = (sNumber) => {
        let output = []
        // separate numbers
        for (let i = 0, len = sNumber.length; i < len; i += 1) {
            output.push(+sNumber.charAt(i));
        }

        // gtin 13
        if (output.length === 13) {
            let sum = 0;
            for (let i = 0; i < output.length - 1; i++) {
                if (i % 2 === 0) {
                    sum += output[i] * 1
                } else {
                    sum += output[i] * 3
                }
            }
            //
            let ceil = Math.ceil(sum / 10) * 10;
            let check = ceil - sum;
            return check;
        } else {
            return '?';
        }
    }
    const isNumeric = (str) => {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const handleOrderClick = (f) => {
        let parsed = qs.parse(search);
        if (parsed[Page.ORDER] === f) {
            let o = parsed[Page.ASC];
            if (o === "true") {
                o = "false";
            } else {
                o = "true";
            }
            parsed[Page.ASC] = o;
        } else {
            parsed[Page.ORDER] = f;
        }


        history.push("?" + qs.stringify(parsed))

    }
    let counter1 = Number(page) * Number(pagesize)
    const appts2 = products.map((p, i) => {
        counter1++;
        return (
            <tr key={p.id}>

                <td>
                    <Link to={`/product/${p.id}`}>
                        {counter1}
                    </Link>
                </td>
                <td>
                    {p.company_name}
                </td>
                <td>
                    <div className={"relative"}>
                        <div className={"flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"}>
                            {checkGtin(p.barcode) ? <div className={"w-2 p-2 bg-green-500"}></div> :
                                <span
                                    className={"inline-block align-middle w-6 p-2 h-fit text-gray-200 bg-red-500  "}>   {getCtrlNmbr(p.barcode)}</span>}
                        </div>
                        <input name="barcode" className="p-1 pl-10 border border-gray-300" type="text"
                               value={p.barcode}
                               onChange={(e) => handleChangeField(e, p, i)}/>

                    </div>
                </td>

                <td>
                    <input name="brand_name" className="input" type="text" value={p.brand_name}
                           onChange={(e) => handleChangeField(e, p, i)}/>
                </td>
                <td>
                    <input name="name" className="input" type="text" value={p.name}
                           onChange={(e) => handleChangeField(e, p, i)}/>
                </td>
                <td>
                    <div className={"relative"}>
                        <div className={"flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"}>
                            {isNumeric(p.packaging) ? <div className={"w-2 p-2 bg-green-500"}></div> :
                                <div className={"w-2 p-2 bg-red-500 h-2 "}></div>}
                        </div>
                        <input name="packaging" className="p-1 pl-10 border border-gray-300" type="text"
                               value={p.packaging}
                               onChange={(e) => handleChangeField(e, p, i)}/>

                    </div>
                </td>
                <td>
                    <div className="select">
                        <select name="packaging_unit" value={p.packaging_unit}
                                onChange={(e) => handleChangePackagingUnit(e, p, i)}>
                            <option value="0"></option>
                            {units.map(unit => {
                                return (
                                    <option key={unit.code}
                                            value={unit.code}>{unit.name_mn} - {unit.code} ( {unit.name} )</option>
                                )
                            })
                            }
                        </select>
                    </div>
                </td>
<td>{getExpCountryLabel(p)}</td>
                <td>
                    <button className={"bg-green-500 p-2 text-white"} onClick={(e) => handleClickYes(e, p)}>
                        <svg className={"fill-current w-4 h-4"} xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" viewBox="0 0 24 24">
                            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/>
                        </svg>
                    </button>
                </td>
            </tr>
        )
    });

    return (

        <div className="inner-container">

            <div className="field">
                <label className="label">Байгууллагын нэр</label>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={companyName} style={{width: '400px'}}
                               onChange={handleChangeCompanyName} placeholder="Оруулах"/>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <div className="control buttons">

                            <button onClick={handleSearch} className="button bg-orange">
                                <span style={{color: "white"}}>Хайх</span>

                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <br/>

            <div>
                <h5 style={{marginBottom: "10px"}}><strong>Нийт: {meta.totalElements}</strong>
                </h5>

                <div>


                </div>
                <nav className="pagination">
                    <ReactPaginate previousLabel={"Өмнөх"}
                                   nextLabel={"Дараах"}
                                   initialPage={Number(page)}
                                   breakLabel={"..."}
                                   breakClassName={"pagination-ellipsis"}
                                   pageCount={meta.totalPages}
                                   marginPagesDisplayed={2}
                                   pageRangeDisplayed={5}
                                   activeLinkClassName={"is-current"}
                                   pageLinkClassName={"pagination-link"}
                                   onPageChange={(e) => handlePageClick(e)}
                                   disableInitialCallback={true}
                                   previousClassName={"pagination-previous"}
                                   nextClassName={"pagination-next"}
                                   containerClassName={"pagination-list"}
                                   forcePage={Number(page)}

                    />
                </nav>


            </div>
            <br/>
            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>

                    <th><a onClick={() => handleOrderClick("company")}>Компани</a></th>
                    <th><a onClick={() => handleOrderClick("barcode")}>GTIN</a></th>
                    <th>Brand Name</th>
                    <th>Name</th>
                    <th>Савлалтын хэмжээ багц</th>
                    <th>Савлалтын хэмжээ нэгж</th>
                    <th>Exp Uls</th>
                    <th></th>
                </tr>

                <tr>
                    <th></th>

                    <th></th>
                    <th></th>
                    <th>
                        <div>
                            <input name="brand_name" className="p-1 border border-gray-300" type="text"
                                   value={brand_name}
                                   onChange={(e) => handleChangeAllBrandName(e)}/>
                        </div>
                    </th>
                    <th></th>
                    <th>
                        <div>
                            <input name="packaging" className="p-1 border border-gray-300" type="text"
                                   value={packaging}
                                   onChange={(e) => handleChangeAllPackagingText(e)}/>
                        </div>
                    </th>
                    <th>
                        <div className="select">
                            <select name="packaging_unit" value={packaging_unit}
                                    onChange={(e) => handleChangeAllPackagingUnit(e)}>
                                <option value="0"></option>
                                {units.map(unit => {
                                    return (
                                        <option key={unit.code}
                                                value={unit.code}>{unit.name_mn} - {unit.code} ( {unit.name} )</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </th>
                    <th>
                        <ReactTags
                            classNames={{
                                tags: '',
                                tagInput: 'border text-white',
                                tagInputField: 'border bg-white text-black p-2 w-1/2',
                                selected: 'border bg-grey-500 p-2',
                                tag: 'border bg-gray-500 p-2 text-white p-2',
                                remove: ' p-2',
                                suggestions: 'border  bg-gray-200 p-2 text-gray-800 ',
                                activeSuggestion: 'border bg-gray-600 p-2 text-white'
                            }}
                            tags={selectedCountries}
                            suggestions={countries}
                            placeholder='Улсын нэрээ сонгоод Enter дарна уу!'
                            labelField={'name'}
                            handleDelete={handleCountryDelete}
                            handleAddition={handleCountryAdd}
                            delimiters={delimiters}/>
                    </th>
                    <th>
                        <button onClick={(e) => handleUpdateAll(e)}>Save All</button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               disableInitialCallback={true}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>
            <br/><br/>
        </div>
    )

}


export default ProductsEditAllPage

