/**
 * Created by gantushig on 11/6/15.
 */

import {Link, Redirect} from "react-router-dom";
import classnames from "classnames";
import React, {useEffect, useReducer, useState} from "react";
import {removeBarcode} from "./actions/actions";
import ReactPaginate from "react-paginate";

import BarcodeEditMenu from "./BarcodeEditMenu"
import CompanyViewHeader from "./CompanyViewHeader";
import dayjs from "dayjs";
import dayjsPluginUTC from 'dayjs-plugin-utc'
import timezone from 'dayjs-timezone-iana-plugin'
import {Endpoints, TOKEN} from "./Constants";
import history from "./history";
import axios from "axios";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import {toast} from "react-toastify";

const FileDownload = require('js-file-download');
dayjs.extend(dayjsPluginUTC)
dayjs.extend(timezone)

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const initialState = {
    loading:false,
    page:0,
    pagesize:15,
    barcodes:{},
    meta:{},
    currFileId:0,
    refresh:false
};

const filesReducer = (state, action) => {
    switch (action.type) {
        case 'LOADING':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_FILES_SUCCESS':
            return {
                ...state,
                barcodes: action.barcodes,
                meta: action.meta,
                loading: false
            }
        case 'CHANGE_PAGE':
            return {
                ...state,
                page:action.page
            };
        case 'SET_CURR_FILE_ID':
            return {
                ...state,
                currFileId:action.id
            };
        case 'DOWNLOAD_SUCCESS':
            return {
                ...state,
                loading:false
            };

        case 'REFRESH':
            return {
                ...state,
                refresh: !state.refresh
            }
        default:
            return state;
    }
};


const loadingStart =() => {
    return {
        type: 'LOADING'
    }
};

const downloadSuccess =() => {
    return {
        type: 'DOWNLOAD_SUCCESS'
    }
};

const fetchFilesSuccess =(barcodes, meta) => {
    return {
        type: 'FETCH_FILES_SUCCESS',
        barcodes,
        meta
    }
};

const changePage =(page) => {
    return {
        type: 'CHANGE_PAGE',
        page
    }
};



const setCurrFileId =(id) => {
    return {
        type: 'SET_CURR_FILE_ID',
        id
    }
};

const fetchFiles =( dispatch, token, gcpId, page, pagesize) =>{
    dispatch(loadingStart());
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.GCP}/${gcpId}/barcodes?page=${page}&pagesize=${pagesize}`;

    return axios(config)
        .then(response => {

            let p = response.data.barcodes.reduce((map, obj) => (map[obj.id] = obj, map), {});
            dispatch(fetchFilesSuccess(p, response.data.meta))
        })
        .catch(error => {
            throw(error);
        });
};

const download = (dispatch, token, id) => {
    dispatch(loadingStart());
    let header = {};
    header["Authorization"] = token;

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.BARCODE}/${id}/download`;
    config["responseType"] = "arraybuffer";

    return axios(config)
        .then(response => {
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader]
            let fn = decodeURIComponent(suggestedFileName);
            dispatch(downloadSuccess());
            FileDownload(response.data, fn)

        })
        .catch(error => {

                throw(error);

        });
}


const refreshPage = () => {
    return {
        type: 'REFRESH'
    }
}

const BarcodesPage = ({  match: {params: {id, gcpId}}   }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isShowEdit, setIsShowEdit] = useState(false);
    const [state, dispatch] = useReducer(filesReducer, initialState);

    const {barcodes, refresh, page, pagesize, currFileId, meta, loading} = state
    let token = localStorage.getItem(TOKEN) || null;

    let modalClass = classnames({
        modal: true,
        'is-active': isOpen
    });

     useEffect(() => {
         console.log('useEffect called');
        fetchFiles(dispatch, token, gcpId, state.page, state.pagesize);
     }, [page, refresh]);

    let counter = 0
    const barcodes_ids_by_order =
        Object.values(barcodes)
            .reduce((ordered_ids, b) => {
                ordered_ids[b.order] = b.id
                return ordered_ids
            }, []);

    barcodes_ids_by_order.map (
        (id) => {
            counter++
            barcodes[id].counter = page * pagesize + counter
        });

    if (barcodes_ids_by_order===null || barcodes_ids_by_order === undefined) {
        return (
            <div style={{
                background: 'white',
                paddingLeft: '20px',
                paddingTop: '20px',
                paddingRight: '20px',
                paddingBottom: "20px"
            }}>
                <CompanyViewHeader tab={2} companyId={id}/>

                <div className="buttons is-right">
                        <Link to={`/company/${id}/gcp/${gcpId}/barcode-create`} className="button">
                            Үүсгэх
                        </Link>
                </div>
                <hr/>
                <table className="table is-bordered is-fullwidth">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Олгосон</th>
                        <th>Файлын нэр</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
                <div className="subtitle">No Items</div>
                <ClipLoader color="#ffffff" loading={loading} css={override} size={150} />
            </div>
        )
    }
    ;

    const handleEdit = (e, fileId) => {
        console.log('edit');
        history.push(`/company/${id}/gcp/${gcpId}/barcode/${fileId}`)
    };

    const handleDownload = (e, id) => {
        console.log('download');
        download(dispatch, token, id);
    };

    const handleDelete = (e, id) => {
        console.log('delete');
        setIsOpen(true)
        dispatch(setCurrFileId(id))
    };

    const handleDeleteCodeYes = () => {
        setIsOpen(false)
        removeBarcode(dispatch, token, currFileId).then(response => {
                toast.success("Амжилттай !");
                dispatch(refreshPage());
            }
        ).catch(error => {
            alert(error)
        });
    }
    const handleDeleteCodeCancel = () => {
        setIsOpen(false)
    }

    const handlePageClick = (data) => {
        dispatch(changePage(data.selected))
    };

    if (isShowEdit) {
        return <Redirect to={`/company/${id}/gcp/${gcpId}/barcode/${currFileId}`}/>;
    }
    const items = barcodes_ids_by_order.map(
        (id) => {
            return (
                <tr key={id}>
                    <td>{barcodes[id].counter}</td>
                    <td>
                        {barcodes[id].issued && dayjs(barcodes[id].issued).tz("Asia/Ulaanbaatar").format("MM/DD/YYYY")}
                    </td>
                    <td>{barcodes[id].filename}</td>
                    <td>
                        <BarcodeEditMenu id={id} onEdit={handleEdit} onDownload={handleDownload}  onDelete={handleDelete}/>
                    </td>
                </tr>
            )
        });

    return (
        <div style={{
            background: 'white',
            paddingLeft: '20px',
            paddingTop: '20px',
            paddingRight: '20px',
            paddingBottom: "20px"
        }}>
            <CompanyViewHeader tab={2} companyId={id}/>

            <div className="buttons is-right">
                    <Link to={`/company/${id}/gcp/${gcpId}/barcode-create`} className="button">
                        Үүсгэх
                    </Link>
            </div>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Олгосон</th>
                    <th>Файлын нэр</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150} />
            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={page}
                               breakLabel={<a href="">...</a>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                />
            </nav>

            <div className={modalClass}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Устгах?</p>
                        <button className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        Та устгахдаа итгэлтэй байна уу?
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={handleDeleteCodeYes}>Тийм</button>
                        <button className="button" onClick={handleDeleteCodeCancel}>Үгүй</button>
                    </footer>
                </div>
            </div>
        </div>
    )
}

export default BarcodesPage;


