/**
 * Created by gantushig on 4/16/17.
 */
import {combineReducers} from "redux";

import {ActionTypes, GTIN} from "../Constants";

// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
function auth(state = {
    isFetching: false,
    isAuthenticated: localStorage.getItem('id_token') ? true : false,
    isAuthenticatedExpress: localStorage.getItem('auth_express') ? true : false
}, action) {
    switch (action.type) {
        case ActionTypes.LOGIN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                isAuthenticated: false,
                user: action.creds
            })
        case ActionTypes.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: true,
                errorMessage: ''
            })
        case ActionTypes.CHECK_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticatedExpress: true,
                errorMessage: ''
            })
        case ActionTypes.CHECK_LOGIN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticatedExpress: false,
                errorMessage: ''
            })
        case ActionTypes.LOGIN_EXPRESS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticatedExpress: true,
                isAuthenticated: true,
                errorMessage: ''
            })
        case ActionTypes.LOGIN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.message
            })
        case ActionTypes.LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                isAuthenticatedExpress: false,
            })
        default:
            return state
    }
}


function meinfo(state = {
    me: {},
    status: ""
}, action) {
    switch (action.type) {
        case ActionTypes.LOAD_ME_SUCCESS:
            return Object.assign({}, state, {
                me: action.me,
                status: action.status
            })

        case ActionTypes.UPDATE_ME_SUCCESS:
            return Object.assign({}, state, {
                me: action.me
            })

        default:
            return state
    }
}

function aimaksReducer(state = {
//     aimaks: new Map([
//         ["ULAANBAATAR", "Улаанбаатар хот"],
//         ["ARKHANGAI", "Архангай аймаг"],
//         ["BAYANOLGII", "Баян-Өлгий аймаг"],
//         ["BAYANKHONGOR", "Баянхонгор аймаг"],
//         ["BULGAN", "Булган аймаг"],
//         ["GOBI_ALTAI", "Говь-Алтай аймаг"],
//         ["GOBISUMBER", "Говьсүмбэр аймаг"],
//         ["DARKHAN_UUL", "Дархан-уул аймаг"],
//         ["DORNOGOBI", "Дорноговь аймаг"],
//         ["DORNOD", "Дорнод аймаг"],
//         ["DUNDGOBI", "Дундговь аймаг"],
//         ["ZAVKHAN", "Завхан аймаг"],
//         ["ORKHON", "Орхон аймаг"],
//         ["OVORKHANGAI", "Өвөрхангай аймаг"],
//         ["OMNOGOBI", "Өмнөговь аймаг"],
//         ["SUKHBAATAR", "Сүхбаатар аймаг"],
//         ["SELENGE", "Сэлэнгэ аймаг"],
//         ["TOV", "Төв аймаг"],
//         ["UVS", "Увс аймаг"],
//         ["KHOVD", "Ховд аймаг"],
//         ["KHOVSGOL", "Хөвсгөл аймаг"],
//         ["KHENTII", "Хэнтий аймаг"]
//     ]),
//     districts: new Map([
//         ["SBD", "Сүхбаатар дүүрэг"],
//         ["CHD", "Чингэлтэй дүүрэг"],
//         ["SHD", "Сонгинохайрхан дүүрэг"],
//         ["BGN", "Багануур дүүрэг"],
//         ["BGD", "Баянгол дүүрэг"],
//         ["BZD", "Баянзүрх дүүрэг"],
//         ["HUD", "Хан-Уул дүүрэг"],
//         ["NLH", "Налайх дүүрэг"]
//     ]),
//
//     arkhangai_soums: new Map([
//         ["BAT", "Батцэнгэл сум"],
//             ["BUL", "Булган сум"],
//             ["JAR", "Жаргалант сум"],
//             ["IKH", "Ихтамир сум"],
//             ["OGI", "Өгийнуур сум"],
//             ["OLZ", "Өлзийт сум"],
//             ["OND", "Өндөр-Улаан сум"],
//             ["TAR", "Тариат сум"],
//             ["TOV", "Төвшрүүлэх сум"],
//             ["KHA", "Хайрхан сум"],
//             ["KHG", "Хангай сум"],
//             ["KHS", "Хашаат сум"],
//             ["KHO", "Хотонт сум"],
//             ["TSA", "Цахир сум"],
//             ["TSH", "Цэнхэр сум"],
//             ["TSR", "Цэцэрлэг сум"],
//             ["CHU", "Чулуут сум"],
//             ["EBU", "Эрдэнэбулган сум"],
//             ["ERD", "Эрдэнэмандал сум"],
//     ]),
//     bayanolgii_soums: new Map([
//         ["ALT", "Алтай сум"],
//             ["ATC", "Алтанцөгц сум"],
//             ["BYN", "Баяннуур сум"],
//             ["BUG", "Бугат сум"],
//             ["BUL", "Булган сум"],
//             ["BYT", "Буянт сум"],
//             ["DEL", "Дэлүүн сум"],
//             ["NOG", "Ногооннуур сум"],
//             ["OLG", "Өлгий сум"],
//             ["SAG", "Сагсай сум"],
//             ["TSA", "Цагааннуур сум"],
//             ["TOL", "Толбо сум"],
//             ["ULA", "Улаанхус сум"],
//             ["TSE", "Цэнгэл сум"],
//     ]),
//     bayankhongor_soums: new Map([
//         ["BYK", "Баянхонгор сум"],
//             ["BAA", "Баацагаан сум"],
//             ["BYB", "Баянбулаг сум"],
//             ["BYG", "Баянговь сум"],
//             ["BYL", "Баянлиг сум"],
//             ["BYO", "Баян-Овоо сум"],
//             ["BYR", "Баян-Өндөр сум"],
//             ["BYT", "Баянцагаан сум"],
//             ["BOG", "Богд сум"],
//             ["BOM", "Бөмбөгөр сум"],
//             ["BUU", "Бууцагаан сум"],
//             ["GAL", "Галуут сум"],
//             ["GUR", "Гурванбулаг сум"],
//             ["JAR", "Жаргалант сум"],
//             ["JIN", "Жинст сум"],
//             ["ZAG", "Заг сум"],
//             ["OLZ", "Өлзийт сум"],
//             ["KHU", "Хүрээмарал сум"],
//             ["SHI", "Шинэжинст сум"],
//             ["ERD", "Эрдэнэцогт сум"],
//     ]),
//     bulgan_soums: new Map([
//         ["BUL", "Булган сум"],
//             ["BYA", "Баян-Агт сум"],
//             ["BYN", "Баяннуур сум"],
//             ["BUG", "Бугат сум"],
//             ["BUR", "Бүрэгхангай сум"],
//             ["GUR", "Гурванбулаг сум"],
//             ["DAS", "Дашинчилэн сум"],
//             ["MOG", "Могод сум"],
//             ["ORK", "Орхон сум"],
//             ["RAS", "Рашаант сум"],
//             ["SAI", "Сайхан сум"],
//             ["SEL", "Сэлэнгэ сум"],
//             ["TES", "Тэшиг сум"],
//             ["KHA", "Хангал сум"],
//             ["KHI", "Хишиг-Өндөр сум"],
//             ["KHU", "Хутаг-Өндөр сум"],
//     ]),
//     gobialtai_soums: new Map([
//         ["ALT", "Алтай сум"],
//             ["BYU", "Баян-Уул сум"],
//             ["BIG", "Бигэр сум"],
//             ["BUG", "Бугат сум"],
//             ["DAR", "Дарви сум"],
//             ["DEL", "Дэлгэр сум"],
//             ["ESO", "Есөнбулаг сум"],
//             ["JAR", "Жаргалан сум"],
//             ["TAI", "Тайшир сум"],
//             ["TON", "Тонхил сум"],
//             ["TOG", "Төгрөг сум"],
//             ["KHA", "Халиун сум"],
//             ["KHO", "Хөхморьт сум"],
//             ["TSO", "Цогт сум"],
//             ["TSE", "Цээл сум"],
//             ["CHA", "Чандмань сум"],
//             ["SHA", "Шарга сум"],
//             ["ERD", "Эрдэнэ сум"],
//     ]),
//
//     gobisumber_soums: new Map([
//         ["CHO", "Чойр"],
//         ["SUM", "Сүмбэр сум"],
//             ["BAY", "Баянтал сум"],
//             ["SHI", "Шивээговь сум"],
//     ]),
//
//     darkhan_soums: new Map([
//         ["DAR", "Дархан"],
//             ["KHO", "Хонгор сум"],
//             ["ORK", "Орхон сум"],
//             ["SHA", "Шарынгол сум"],
//     ]),
//     dornogobi_soums: new Map([
//         ["AIR", "Айраг сум"],
//             ["ALT", "Алтанширээ сум"],
//             ["DAL", "Даланжаргалан сум"],
//             ["DEL", "Дэлгэрэх сум"],
//             ["ZAM", "Замын-Үүд сум"],
//             ["IKH", "Иххэт сум"],
//             ["MAN", "Мандах сум"],
//             ["ORG", "Өргөн сум"],
//             ["SSH", "Сайншанд"],
//             ["SHA", "Сайхандулаан сум"],
//             ["ULA", "Улаанбадрах сум"],
//             ["KHA", "Хатанбулаг сум"],
//             ["SHO", "Хөвсгөл сум"],
//             ["ERD", "Эрдэнэ сум"],
//     ]),
//     dornod_soums: new Map([
//         ["BYD", "Баяндун сум"],
//             ["BYT", "Баянтүмэн сум"],
//             ["BYU", "Баян-Уул сум"],
//             ["BUL", "Булган сум"],
//             ["GUR", "Гурванзагал сум"],
//             ["DAS", "Дашбалбар сум"],
//             ["MAT", "Матад сум"],
//             ["SER", "Сэргэлэн сум"],
//             ["KHA", "Халхгол сум"],
//             ["KHO", "Хөлөнбуйр сум"],
//             ["KHE", "Хэрлэн (Сүмбэр)"],
//             ["TSA", "Цагаан-Овоо сум"],
//             ["CHU", "Чулуунхороот сум (Эрээнцав)"],
//             ["CHO", "Чойбалсан сум"],
//     ]),
//
//     dundgobi_soums: new Map([
//         ["ADA", "Адаацаг сум"],
//             ["BYJ", "Баянжаргалан сум"],
//             ["GOU", "Говь-Угтаал сум"],
//             ["GUR", "Гурвансайхан сум"],
//             ["DKH", "Дэлгэрхангай сум"],
//             ["DTS", "Дэлгэрцогт сум"],
//             ["DER", "Дэрэн сум"],
//             ["LUU", "Луус сум"],
//             ["OLZ", "Өлзийт сум"],
//             ["OND", "Өндөршил сум"],
//             ["STS", "Сайнцагаан сум"],
//             ["SAI", "Сайхан-Овоо сум"],
//             ["MAN", "Мандалговь (аймгийн төв)"],
//             ["KHU", "Хулд сум"],
//             ["TSA", "Цагаандэлгэр сум"],
//             ["ERD", "Эрдэнэдалай сум"],
// ]),
//     zavkhan_soums: new Map([
//         ["ALD", "Алдархаан сум"],
//             ["ASG", "Асгат сум"],
//             ["BYT", "Баянтэс сум"],
//             ["BYK", "Баянхайрхан сум"],
//             ["DOR", "Дөрвөлжин сум"],
//             ["ZAV", "Завханмандал сум"],
//             ["IDE", "Идэр сум"],
//             ["IKH", "Их-Уул сум"],
//             ["NOM", "Нөмрөг сум"],
//             ["OTG", "Отгон сум"],
//             ["SAN", "Сантмаргац сум"],
//             ["SON", "Сонгино сум"],
//             ["TOS", "Тосонцэнгэл сум"],
//             ["TUD", "Түдэвтэй сум"],
//             ["TEL", "Тэлмэн сум"],
//             ["TES", "Тэс сум"],
//             ["ULI", "Улиастай"],
//             ["URG", "Ургамал сум"],
//             ["TSK", "Цагаанхайрхан сум"],
//             ["TSC", "Цагаанчулуут сум"],
//             ["TSE", "Цэцэн-Уул сум"],
//             ["SHI", "Шилүүстэй сум"],
//             ["ERD", "Эрдэнэхайрхан сум"],
//             ["YAR", "Яруу сум"],
//     ]),
//     orkhon_soums: new Map([
//         ["BYO", "Баян-Өндөр сум"],
//             ["JAR", "Жаргалант сум"],
//     ]),
//     ovorkhangai_soums: new Map([
//         ["ARV", "Арвайхээр"],
//             ["BAR", "Баруунбаян-Улаан сум"],
//             ["BAT", "Бат-Өлзий сум"],
//             ["BYG", "Баянгол сум"],
//             ["BYO", "Баян-Өндөр сум"],
//             ["BOG", "Богд сум"],
//             ["BUR", "Бүрд сум"],
//             ["GUC", "Гучин-Ус сум"],
//             ["KHA", "Хархорин сум"],
//             ["KHI", "Хайрхандулаан сум"],
//             ["KHU", "Хужирт сум"],
//             ["NAR", "Нарийнтээл сум"],
//             ["OLZ", "Өлзийт сум"],
//             ["SAN", "Сант сум"],
//             ["TAR", "Тарагт сум"],
//             ["TOG", "Төгрөг сум"],
//             ["UYA", "Уянга сум"],
//             ["ESO", "Есөнзүйл сум"],
//             ["ZUB", "Зүүнбаян-Улаан сум"],
//     ]),
//     omnogobi_soums: new Map([
//         ["BYD", "Баяндалай сум"],
//             ["BYO", "Баян-Овоо сум"],
//             ["BUL", "Булган сум"],
//             ["GUR", "Гурвантэс сум"],
//             ["DAL", "Даланзадгад сум"],
//             ["MNO", "Мандал-Овоо сум"],
//             ["MNL", "Манлай сум"],
//             ["NOE", "Ноён сум"],
//             ["NOM", "Номгон сум"],
//             ["SEV", "Сэврэй сум"],
//             ["KHB", "Ханбогд сум"],
//             ["KHH", "Ханхонгор сум"],
//             ["KHU", "Хүрмэн сум"],
//             ["TSO", "Цогт-Овоо сум"],
//             ["TSE", "Цогтцэций сум"],
// ]),
//     sukhbaatar_soums: new Map([
//         ["ASG", "Асгат сум"],
//         ["BAR", "Баруун-урт сум"],
//             ["BAY", "Баяндэлгэр сум"],
//             ["DAR", "Дарьганга сум"],
//             ["MUN", "Мөнххаан сум"],
//             ["NAR", "Наран сум"],
//             ["ONG", "Онгон сум"],
//             ["SUK", "Сүхбаатар сум"],
//             ["TUV", "Түвшинширээ сум"],
//             ["TUM", "Түмэнцогт сум"],
//             ["UUL", "Уулбаян сум"],
//             ["KHA", "Халзан сум"],
//             ["ERD", "Эрдэнэцагаан сум"],
//     ]),
//     selenge_soums: new Map([
//         ["ALT","Алтанбулаг сум"],
//             ["BAR","Баруунбүрэн сум"],
//             ["BAY","Баянгол сум"],
//             ["ERO","Ерөө сум"],
//             ["JAV","Жавхлант сум"],
//             ["ZUU","Зүүнбүрэн сум"],
//             ["MAN","Мандал сум"],
//             ["ORK","Орхон сум"],
//             ["ORT","Орхонтуул сум"],
//             ["SAI","Сайхан сум"],
//             ["SAN","Сант сум"],
//             ["SUK","Сүхбаатар сум"],
//             ["TUS","Түшиг сум"],
//             ["KHU","Хүдэр сум"],
//             ["KHT","Хушаат сум"],
//             ["TSA","Цагааннуур сум"],
//             ["SHA","Шаамар сум"],
//     ]),
//     tov_soums: new Map([
//         ["ALT","Алтанбулаг сум"],
//             ["ARG","Аргалант сум"],
//             ["AKH","Архуст сум"],
//             ["BYN","Баян сум"],
//             ["BAT","Батсүмбэр сум"],
//             ["BYD","Баяндэлгэр сум"],
//             ["BYJ","Баянжаргалан сум"],
//             ["BYO","Баян-Өнжүүл сум"],
//             ["BYK","Баянхангай сум"],
//             ["BYT","Баянцагаан сум"],
//             ["BYC","Баянцогт сум"],
//             ["BYH","Баянчандмань сум"],
//             ["BOR","Борнуур сум"],
//             ["BUR","Бүрэн сум"],
//             ["DEL","Дэлгэрхаан сум"],
//             ["JAR","Жаргалант сум"],
//             ["ZAA","Заамар сум"],
//             ["ZUU","Зуунмод сум"],
//             ["LUN","Лүн сум"],
//             ["MUN","Мөнгөнморьт сум"],
//             ["UND","Өндөрширээт сум"],
//             ["SER","Сэргэлэн сум"],
//             ["SUM","Сүмбэр сум"],
//             ["UGT","Угтаал сум"],
//             ["TSE","Цээл сум"],
//             ["ERD","Эрдэнэ сум"],
//             ["ERS","Эрдэнэсант сум"],
//     ]),
//     uvs_soums: new Map([
//         ["BAR","Баруунтуруун сум"],
//             ["BOH","Бөхмөрөн сум"],
//             ["DAV","Давст сум"],
//             ["ZAV","Завхан сум"],
//             ["ZUG","Зүүнговь сум"],
//             ["ZKH","Зүүнхангай сум"],
//             ["MAL","Малчин сум"],
//             ["NAR","Наранбулаг сум"],
//             ["OLG","Өлгий сум"],
//             ["OMN","Өмнөговь сум"],
//             ["OND","Өндөрхангай сум"],
//             ["SAG","Сагил сум"],
//             ["TAR","Тариалан сум"],
//             ["TES","Тэс сум"],
//             ["TUR","Түргэн сум"],
//             ["ULG","Улаангом сум"],
//             ["KHO","Ховд сум"],
//             ["KHY","Хяргас сум"],
//             ["TSA","Цагаанхайрхан сум"],
//     ]),
//     khovd_soums: new Map([
//         ["KHJ", "Ховд (Жаргалант)"],
//             ["ALT", "Алтай сум"],
//             ["BUL", "Булган сум"],
//             ["BUY", "Буянт сум"],
//             ["DAR", "Дарви сум"],
//             ["DOR", "Дөргөн сум"],
//             ["DUU", "Дуут сум"],
//             ["ZER", "Зэрэг сум"],
//             ["MAN", "Манхан сум"],
//             ["MON", "Мөнххайрхан сум"],
//             ["MOS", "Мөст сум"],
//             ["MYN", "Мянгад сум"],
//             ["UYE", "Үенч сум"],
//             ["KHO", "Ховд сум"],
//             ["TSE", "Цэцэг сум"],
//             ["CHA", "Чандмань сум"],
//             ["ERD", "Эрдэнэбүрэн сум"],
//     ]),
//
//     khentii_soums: new Map([
//             ["BTN", "Батноров сум"],
//             ["BSH", "Батширээт сум"],
//             ["BYA", "Баян-Адрага сум"],
//             ["BYM", "Баянмөнх сум"],
//             ["BYO", "Баян-Овоо сум"],
//             ["BYK", "Баянхутаг сум"],
//             ["BIN", "Биндэр сум"],
//             ["GAL", "Галшар сум"],
//             ["DAD", "Дадал сум"],
//             ["DAR", "Дархан сум"],
//             ["DEL", "Дэлгэрхаан сум"],
//             ["JAR", "Жаргалтхаан сум"],
//             ["MUR", "Мөрөн сум"],
//             ["NOR", "Норовлин сум"],
//             ["OMN", "Өмнөдэлгэр сум"],
//             ["KHE", "Хэрлэн сум"],
//             ["CHI", "Чингис хот"],
//             ["TSE", "Цэнхэрмандал сум"],
//     ]),
//     khovsgol_soums: new Map([
//             ["AER", "Алаг-Эрдэнэ сум"],
//             ["ARB", "Арбулаг сум"],
//             ["BYZ", "Баянзүрх сум"],
//             ["BUR", "Бүрэнтогтох сум"],
//             ["GAL", "Галт сум"],
//             ["JAR", "Жаргалант сум"],
//             ["IKH", "Их-Уул сум"],
//             ["MUR", "Мөрөн сум"],
//             ["RAS", "Рашаант сум"],
//             ["REN", "Рэнчинлхүмбэ сум"],
//             ["TAR", "Тариалан сум"],
//             ["TOS", "Тосонцэнгэл сум"],
//             ["TOM", "Төмөрбулаг сум"],
//             ["TUN", "Түнэл сум"],
//             ["ULN", "Улаан-Уул сум"],
//             ["KHH", "Ханх сум"],
//             ["KHA", "Хатгал сум"],
//             ["TSN", "Цагааннуур сум"],
//             ["TSL", "Цагаан-Уул сум"],
//             ["TSR", "Цагаан-Үүр сум"],
//             ["TSE", "Цэцэрлэг сум"],
//             ["CHA", "Чандмань-Өндөр сум"],
//             ["SHI", "Шинэ-Идэр сум"],
//             ["ERD", "Эрдэнэбулган сум"],
//     ]),
//     countries: new Map([
//         ["AF", "Afghanistan"],
//         ["AX","Aland Islands"],
//         ["AL","Albania"],
//         ["DZ","Algeria"],
//         ["AS","American Samoa"],
//         ["AD","Andorra"],
//         ["AO","Angola"],
//         ["AI","Anguilla"],
//         ["AQ","Antarctica"],
//         ["AG","Antigua and Barbuda"],
//         ["AR","Argentina"],
//         ["AM","Armenia"],
//         ["AW","Aruba"],
//         ["AU","Australia"],
//         ["AT","Austria"],
//         ["AZ","Azerbaijan"],
//         ["BS","Bahamas"],
//         ["BH","Bahrain"],
//         ["BD","Bangladesh"],
//         ["BB","Barbados"],
//         ["BY","Belarus"],
//         ["BE","Belgium"],
//         ["BZ","Belize"],
//         ["BJ","Benin"],
//         ["BM","Bermuda"],
//         ["BT","Bhutan"],
//         ["BO","Bolivia"],
//         ["BA","Bosnia and Herzegovina"],
//         ["BW","Botswana"],
//         ["BV","Bouvet Island"],
//         ["BR","Brazil"],
//         ["VG","British Virgin Islands"],
//         ["IO","British Indian Ocean Territory"],
//         ["BN","Brunei Darussalam"],
//         ["BG","Bulgaria"],
//         ["BF","Burkina Faso"],
//         ["BI","Burundi"],
//         ["KH","Cambodia"],
//         ["CM","Cameroon"],
//         ["CA","Canada"],
//         ["CV","Cape Verde"],
//         ["KY","Cayman Islands"],
//         ["CF","Central African Republic"],
//         ["TD","Chad"],
//         ["CL","Chile"],
//         ["CN","China"],
//         ["HK","Hong Kong, SAR China"],
//         ["MO","Macao, SAR China"],
//         ["CX","Christmas Island"],
//         ["CC","Cocos (Keeling) Islands"],
//         ["CO","Colombia"],
//         ["KM","Comoros"],
//         ["CG","Congo (Brazzaville)"],
//         ["CD","Congo, (Kinshasa)"],
//         ["CK","Cook Islands"],
//         ["CR","Costa Rica"],
//         ["CI","Côte d'Ivoire"],
//         ["HR","Croatia"],
//         ["CU","Cuba"],
//         ["CY","Cyprus"],
//         ["CZ","Czech Republic"],
//         ["DK","Denmark"],
//         ["DJ","Djibouti"],
//         ["DM","Dominica"],
//         ["DO","Dominican Republic"],
//         ["EC","Ecuador"],
//         ["EG","Egypt"],
//         ["SV","El Salvador"],
//         ["GQ","Equatorial Guinea"],
//         ["ER","Eritrea"],
//         ["EE","Estonia"],
//         ["ET","Ethiopia"],
//         ["FK","Falkland Islands (Malvinas)"],
//         ["FO","Faroe Islands"],
//         ["FJ","Fiji"],
//         ["FI","Finland"],
//         ["FR","France"],
//         ["GF","French Guiana"],
//         ["PF","French Polynesia"],
//         ["TF","French Southern Territories"],
//         ["GA","Gabon"],
//         ["GM","Gambia"],
//         ["GE","Georgia"],
//         ["DE","Germany"],
//         ["GH","Ghana"],
//         ["GI","Gibraltar"],
//         ["GR","Greece"],
//         ["GL","Greenland"],
//         ["GD","Grenada"],
//         ["GP","Guadeloupe"],
//         ["GU","Guam"],
//         ["GT","Guatemala"],
//         ["GG","Guernsey"],
//         ["GN","Guinea"],
//         ["GW","Guinea-Bissau"],
//         ["GY","Guyana"],
//         ["HT","Haiti"],
//         ["HM","Heard and Mcdonald Islands"],
//         ["VA","Holy See (Vatican City State)"],
//         ["HN","Honduras"],
// ["HU","Hungary"],
// ["IS","Iceland"],
// ["IN","India"],
// ["ID","Indonesia"],
// ["IR","Iran"],
// ["IQ","Iraq"],
// ["IE","Ireland"],
// ["IM","Isle of Man"],
// ["IL","Israel"],
// ["IT","Italy"],
// ["JM","Jamaica"],
// ["JP","Japan"],
// ["JE","Jersey"],
// ["JO","Jordan"],
// ["KZ","Kazakhstan"],
// ["KE","Kenya"],
// ["KI","Kiribati"],
// ["KP","Korea (North)"],
// ["KR","Korea (South)"],
// ["KW","Kuwait"],
// ["KG","Kyrgyzstan"],
// ["LA","Lao PDR"],
// ["LV","Latvia"],
// ["LB","Lebanon"],
// ["LS","Lesotho"],
// ["LR","Liberia"],
// ["LY","Libya"],
// ["LI","Liechtenstein"],
// ["LT","Lithuania"],
// ["LU","Luxembourg"],
// ["MK","Macedonia"],
// ["MG","Madagascar"],
// ["MW","Malawi"],
// ["MY","Malaysia"],
// ["MV","Maldives"],
// ["ML","Mali"],
// ["MT","Malta"],
// ["MH","Marshall Islands"],
// ["MQ","Martinique"],
// ["MR","Mauritania"],
// ["MU","Mauritius"],
// ["YT","Mayotte"],
// ["MX","Mexico"],
// ["FM","Micronesia, Federated States of"],
// ["MD","Moldova"],
// ["MC","Monaco"],
// ["MN","Mongolia"],
// ["ME","Montenegro"],
// ["MS","Montserrat"],
// ["MA","Morocco"],
// ["MZ","Mozambique"],
// ["MM","Myanmar"],
// ["NA","Namibia"],
// ["NR","Nauru"],
// ["NP","Nepal"],
// ["NL","Netherlands"],
// ["AN","Netherlands Antilles"],
// ["NC","New Caledonia"],
// ["NZ","New Zealand"],
// ["NI","Nicaragua"],
// ["NE","Niger"],
// ["NG","Nigeria"],
// ["NU","Niue"],
// ["NF","Norfolk Island"],
// ["MP","Northern Mariana Islands"],
// ["NO","Norway"],
// ["OM","Oman"],
// ["PK","Pakistan"],
// ["PW","Palau"],
// ["PS","Palestinian Territory"],
// ["PA","Panama"],
// ["PG","Papua New Guinea"],
// ["PY","Paraguay"],
// ["PE","Peru"],
// ["PH","Philippines"],
// ["PN","Pitcairn"],
// ["PL","Poland"],
// ["PT","Portugal"],
// ["PR","Puerto Rico"],
// ["QA","Qatar"],
// ["RO","Romania"],
// ["RU","Russian Federation"],
// ["RW","Rwanda"],
// ["BL","Saint-Barthélemy"],
// ["SH","Saint Helena"],
// ["KN","Saint Kitts and Nevis"],
// ["LC","Saint Lucia"],
// ["MF","Saint-Martin"],
// ["PM","Saint Pierre and Miquelon"],
// ["VC","Saint Vincent and Grenadines"],
// ["WS","Samoa"],
// ["SM","San Marino"],
// ["ST","Sao Tome and Principe"],
// ["SA","Saudi Arabia"],
// ["SN","Senegal"],
// ["RS","Serbia"],
// ["SC","Seychelles"],
// ["SL","Sierra Leone"],
// ["SG","Singapore"],
// ["SK","Slovakia,"],
// ["SI","Slovenia"],
// ["SB","Solomon Islands"],
// ["SO","Somalia"],
// ["ZA","South Africa"],
// ["GS","South Georgia and the South Sandwich Islands"],
// ["SS","South Sudan"],
// ["ES","Spain"],
// ["LK","Sri Lanka"],
// ["SD","Sudan"],
// ["SR","Suriname"],
// ["SJ","Svalbard and Jan Mayen Islands"],
// ["SZ","Swaziland"],
// ["SE","Sweden"],
// ["CH","Switzerland"],
// ["SY","Syria"],
// ["TW","Taiwan, Republic of China"],
// ["TJ","Tajikistan-"],
// ["TZ","Tanzania"],
// ["TH","Thailand"],
// ["TL","Timor-Leste"],
// ["TG","Togo"],
// ["TK","Tokelau "],
// ["TO","Tonga"],
// ["TT","Trinidad and Tobago"],
// ["TN","Tunisia"],
// ["TR","Turkey"],
// ["TM","Turkmenistan"],
// ["TC","Turks and Caicos Islands"],
// ["TV","Tuvalu"],
// ["UG","Uganda"],
// ["UA","Ukraine"],
// ["AE","United Arab Emirates"],
// ["GB","United Kingdom"],
// ["US","United States of America"],
// ["UM","US Minor Outlying Islands"],
// ["UY","Uruguay"],
// ["UZ","Uzbekistan"],
// ["VU","Vanuatu"],
// ["VE","Venezuela"],
// ["VN","Viet Nam"],
// ["VI","Virgin Islands, US"],
// ["WF","Wallis and Futuna Islands"],
// ["EH","Western Sahara"],
// ["YE","Yemen"],
// ["ZM","Zambia"],
// ["ZW","Zimbabwe"]
//     ]),


    // countries_arr: [
    //     {id:"AF",name:"Afghanistan/AF"},
    //     {id:"AX",name:"Aland Islands/AX"},
    //     {id:"AL",name:"Albania/AL"},
    //     {id:"DZ",name:"Algeria/DZ"},
    //     {id:"AS",name:"American Samoa/AS"},
    //     {id:"AD",name:"Andorra/AD"},
    //     {id:"AO",name:"Angola/AO"},
    //     {id:"AI",name:"Anguilla/AI"},
    //     {id:"AQ",name:"Antarctica/AQ"},
    //     {id:"AG",name:"Antigua and Barbuda/AG"},
    //     {id:"AR",name:"Argentina/AR"},
    //     {id:"AM",name:"Armenia/AM"},
    //     {id:"AW",name:"Aruba/AW"},
    //     {id:"AU",name:"Australia/AU"},
    //     {id:"AT",name:"Austria/AT"},
    //     {id:"AZ",name:"Azerbaijan/AZ"},
    //     {id:"BS",name:"Bahamas/BS"},
    //     {id:"BH",name:"Bahrain/BH"},
    //     {id:"BD",name:"Bangladesh/BD"},
    //     {id:"BB",name:"Barbados/BB"},
    //     {id:"BY",name:"Belarus/BY"},
    //     {id:"BE",name:"Belgium/BE"},
    //     {id:"BZ",name:"Belize/BZ"},
    //     {id:"BJ",name:"Benin/BJ"},
    //     {id:"BM",name:"Bermuda/BM"},
    //     {id:"BT",name:"Bhutan/BT"},
    //     {id:"BO",name:"Bolivia/BO"},
    //     {id:"BA",name:"Bosnia and Herzegovina/BA"},
    //     {id:"BW",name:"Botswana/BW"},
    //     {id:"BV",name:"Bouvet Island/BV"},
    //     {id:"BR",name:"Brazil/BR"},
    //     {id:"VG",name:"British Virgin Islands/VG"},
    //     {id:"IO",name:"British Indian Ocean Territory/IO"},
    //     {id:"BN",name:"Brunei Darussalam/BN"},
    //     {id:"BG",name:"Bulgaria/BG"},
    //     {id:"BF",name:"Burkina Faso/BF"},
    //     {id:"BI",name:"Burundi/BI"},
    //     {id:"KH",name:"Cambodia/KH"},
    //     {id:"CM",name:"Cameroon/CM"},
    //     {id:"CA",name:"Canada/CA"},
    //     {id:"CV",name:"Cape Verde/CV"},
    //     {id:"KY",name:"Cayman Islands/KY"},
    //     {id:"CF",name:"Central African Republic/CF"},
    //     {id:"TD",name:"Chad/TD"},
    //     {id:"CL",name:"Chile/CL"},
    //     {id:"CN",name:"China/CN"},
    //     {id:"HK",name:"Hong Kong, SAR China/HK"},
    //     {id:"MO",name:"Macao, SAR China/MO"},
    //     {id:"CX",name:"Christmas Island/CX"},
    //     {id:"CC",name:"Cocos (Keeling) Islands/CC"},
    //     {id:"CO",name:"Colombia/CO+"},
    //     {id:"KM",name:"Comoros/KM"},
    //     {id:"CG",name:"Congo (Brazzaville)/CG"},
    //     {id:"CD",name:"Congo, (Kinshasa)/CD"},
    //     {id:"CK",name:"Cook Islands/CK"},
    //     {id:"CR",name:"Costa Rica/CR"},
    //     {id:"CI",name:"Côte d'Ivoire/CI"},
    //     {id:"HR",name:"Croatia/HR"},
    //     {id:"CU",name:"Cuba/CU"},
    //     {id:"CY",name:"Cyprus/CY"},
    //     {id:"CZ",name:"Czech Republic/CZ"},
    //     {id:"DK",name:"Denmark/DK"},
    //     {id:"DJ",name:"Djibouti/DJ"},
    //     {id:"DM",name:"Dominica/DM"},
    //     {id:"DO",name:"Dominican Republic/DO"},
    //     {id:"EC",name:"Ecuador/EC"},
    //     {id:"EG",name:"Egypt/EG"},
    //     {id:"SV",name:"El Salvador/SV"},
    //     {id:"GQ",name:"Equatorial Guinea/GQ"},
    //     {id:"ER",name:"Eritrea/ER"},
    //     {id:"EE",name:"Estonia/EE"},
    //     {id:"ET",name:"Ethiopia/ET"},
    //     {id:"FK",name:"Falkland Islands (Malvinas)/FK"},
    //     {id:"FO",name:"Faroe Islands/FO"},
    //     {id:"FJ",name:"Fiji/FJ"},
    //     {id:"FI",name:"Finland/FI"},
    //     {id:"FR",name:"France/FR"},
    //     {id:"GF",name:"French Guiana/GF"},
    //     {id:"PF",name:"French Polynesia/PF"},
    //     {id:"TF",name:"French Southern Territories/TF"},
    //     {id:"GA",name:"Gabon/GA"},
    //     {id:"GM",name:"Gambia/GM"},
    //     {id:"GE",name:"Georgia/GE"},
    //     {id:"DE",name:"Germany/DE"},
    //     {id:"GH",name:"Ghana/GH"},
    //     {id:"GI",name:"Gibraltar/GI"},
    //     {id:"GR",name:"Greece/GR"},
    //     {id:"GL",name:"Greenland/GL"},
    //     {id:"GD",name:"Grenada/GD"},
    //     {id:"GP",name:"Guadeloupe/GP"},
    //     {id:"GU",name:"Guam/GU"},
    //     {id:"GT",name:"Guatemala/GT"},
    //     {id:"GG",name:"Guernsey/GG"},
    //     {id:"GN",name:"Guinea/GN"},
    //     {id:"GW",name:"Guinea-Bissau/GW"},
    //     {id:"GY",name:"Guyana/GY"},
    //     {id:"HT",name:"Haiti/HT"},
    //     {id:"HM",name:"Heard and Mcdonald Islands/HM"},
    //     {id:"VA",name:"Holy See (Vatican City State)/VA"},
    //     {id:"HN",name:"Honduras/HN"},
    //     {id:"HU",name:"Hungary/HU"},
    //     {id:"IS",name:"Iceland/IS"},
    //     {id:"IN",name:"India/IN"},
    //     {id:"ID",name:"Indonesia/ID"},
    //     {id:"IR",name:"Iran/IR"},
    //     {id:"IQ",name:"Iraq/IQ"},
    //     {id:"IE",name:"Ireland/IE"},
    //     {id:"IM",name:"Isle of Man/IM"},
    //     {id:"IL",name:"Israel/IL"},
    //     {id:"IT",name:"Italy/IT"},
    //     {id:"JM",name:"Jamaica/JM"},
    //     {id:"JP",name:"Japan/JP"},
    //     {id:"JE",name:"Jersey/JE"},
    //     {id:"JO",name:"Jordan/JO"},
    //     {id:"KZ",name:"Kazakhstan/KZ"},
    //     {id:"KE",name:"Kenya/KE"},
    //     {id:"KI",name:"Kiribati/KI"},
    //     {id:"KP",name:"Korea (North)/KP"},
    //     {id:"KR",name:"Korea (South)/KR"},
    //     {id:"KW",name:"Kuwait/KW"},
    //     {id:"KG",name:"Kyrgyzstan/KG"},
    //     {id:"LA",name:"Lao PDR/LA"},
    //     {id:"LV",name:"Latvia/LV"},
    //     {id:"LB",name:"Lebanon/LB"},
    //     {id:"LS",name:"Lesotho/LS"},
    //     {id:"LR",name:"Liberia/LR"},
    //     {id:"LY",name:"Libya/LY"},
    //     {id:"LI",name:"Liechtenstein/LI"},
    //     {id:"LT",name:"Lithuania/LT"},
    //     {id:"LU",name:"Luxembourg/LU"},
    //     {id:"MK",name:"Macedonia/MK"},
    //     {id:"MG",name:"Madagascar/MG"},
    //     {id:"MW",name:"Malawi/MW"},
    //     {id:"MY",name:"Malaysia/MY"},
    //     {id:"MV",name:"Maldives/MV"},
    //     {id:"ML",name:"Mali/ML"},
    //     {id:"MT",name:"Malta/MT"},
    //     {id:"MH",name:"Marshall Islands/MH"},
    //     {id:"MQ",name:"Martinique/MQ"},
    //     {id:"MR",name:"Mauritania/MR"},
    //     {id:"MU",name:"Mauritius/MU"},
    //     {id:"YT",name:"Mayotte/YT"},
    //     {id:"MX",name:"Mexico/MX"},
    //     {id:"FM",name:"Micronesia, Federated States of/FM"},
    //     {id:"MD",name:"Moldova/MD"},
    //     {id:"MC",name:"Monaco/MC"},
    //     {id:"MN",name:"Mongolia/MN"},
    //     {id:"ME",name:"Montenegro/ME"},
    //     {id:"MS",name:"Montserrat/MS"},
    //     {id:"MA",name:"Morocco/MA"},
    //     {id:"MZ",name:"Mozambique/MZ"},
    //     {id:"MM",name:"Myanmar/MM"},
    //     {id:"NA",name:"Namibia/NA"},
    //     {id:"NR",name:"Nauru/NR"},
    //     {id:"NP",name:"Nepal/NP"},
    //     {id:"NL",name:"Netherlands/NL"},
    //     {id:"AN",name:"Netherlands Antilles/AN"},
    //     {id:"NC",name:"New Caledonia/NC"},
    //     {id:"NZ",name:"New Zealand/NZ"},
    //     {id:"NI",name:"Nicaragua/NI"},
    //     {id:"NE",name:"Niger/NE"},
    //     {id:"NG",name:"Nigeria/NG"},
    //     {id:"NU",name:"Niue/NU"},
    //     {id:"NF",name:"Norfolk Island/NF"},
    //     {id:"MP",name:"Northern Mariana Islands/MP"},
    //     {id:"NO",name:"Norway/NO"},
    //     {id:"OM",name:"Oman/OM"},
    //     {id:"PK",name:"Pakistan/PK"},
    //     {id:"PW",name:"Palau/PW"},
    //     {id:"PS",name:"Palestinian Territory/PS"},
    //     {id:"PA",name:"Panama/PA"},
    //     {id:"PG",name:"Papua New Guinea/PG"},
    //     {id:"PY",name:"Paraguay/PY"},
    //     {id:"PE",name:"Peru/PE"},
    //     {id:"PH",name:"Philippines/PH"},
    //     {id:"PN",name:"Pitcairn/PN"},
    //     {id:"PL",name:"Poland/PL"},
    //     {id:"PT",name:"Portugal/PT"},
    //     {id:"PR",name:"Puerto Rico/PR"},
    //     {id:"QA",name:"Qatar/QA"},
    //     {id:"RO",name:"Romania/RO"},
    //     {id:"RU",name:"Russian Federation/RU"},
    //     {id:"RW",name:"Rwanda/RW"},
    //     {id:"BL",name:"Saint-Barthélemy/BL"},
    //     {id:"SH",name:"Saint Helena/SH"},
    //     {id:"KN",name:"Saint Kitts and Nevis/KN"},
    //     {id:"LC",name:"Saint Lucia/LC"},
    //     {id:"MF",name:"Saint-Martin/MF"},
    //     {id:"PM",name:"Saint Pierre and Miquelon/PM"},
    //     {id:"VC",name:"Saint Vincent and Grenadines/VC"},
    //     {id:"WS",name:"Samoa/WS"},
    //     {id:"SM",name:"San Marino/SM"},
    //     {id:"ST",name:"Sao Tome and Principe/ST"},
    //     {id:"SA",name:"Saudi Arabia/SA"},
    //     {id:"SN",name:"Senegal/SN"},
    //     {id:"RS",name:"Serbia/RS"},
    //     {id:"SC",name:"Seychelles/SC"},
    //     {id:"SL",name:"Sierra Leone/SL"},
    //     {id:"SG",name:"Singapore/SG"},
    //     {id:"SK",name:"Slovakia,/SK"},
    //     {id:"SI",name:"Slovenia/SI"},
    //     {id:"SB",name:"Solomon Islands/SB"},
    //     {id:"SO",name:"Somalia/SO"},
    //     {id:"ZA",name:"South Africa/ZA"},
    //     {id:"GS",name:"South Georgia and the South Sandwich Islands/GS"},
    //     {id:"SS",name:"South Sudan/SS"},
    //     {id:"ES",name:"Spain/ES"},
    //     {id:"LK",name:"Sri Lanka/LK"},
    //     {id:"SD",name:"Sudan/SD"},
    //     {id:"SR",name:"Suriname/SR"},
    //     {id:"SJ",name:"Svalbard and Jan Mayen Islands/SJ"},
    //     {id:"SZ",name:"Swaziland/SZ"},
    //     {id:"SE",name:"Sweden/SE"},
    //     {id:"CH",name:"Switzerland/CH"},
    //     {id:"SY",name:"Syria/SY"},
    //     {id:"TW",name:"Taiwan, Republic of China/TW"},
    //     {id:"TJ",name:"Tajikistan/TJ"},
    //     {id:"TZ",name:"Tanzania/TZ"},
    //     {id:"TH",name:"Thailand/TH"},
    //     {id:"TL",name:"Timor-Leste/TL"},
    //     {id:"TG",name:"Togo/TG"},
    //     {id:"TK",name:"Tokelau /TK"},
    //     {id:"TO",name:"Tonga/TO"},
    //     {id:"TT",name:"Trinidad and Tobago/TT"},
    //     {id:"TN",name:"Tunisia/TN"},
    //     {id:"TR",name:"Turkey/TR"},
    //     {id:"TM",name:"Turkmenistan/TM"},
    //     {id:"TC",name:"Turks and Caicos Islands/TC"},
    //     {id:"TV",name:"Tuvalu/TV"},
    //     {id:"UG",name:"Uganda/UG"},
    //     {id:"UA",name:"Ukraine/UA"},
    //     {id:"AE",name:"United Arab Emirates/AE"},
    //     {id:"GB",name:"United Kingdom/GB"},
    //     {id:"US",name:"United States of America/US"},
    //     {id:"UM",name:"US Minor Outlying Islands/UM"},
    //     {id:"UY",name:"Uruguay/UY"},
    //     {id:"UZ",name:"Uzbekistan/UZ"},
    //     {id:"VU",name:"Vanuatu/VU"},
    //     {id:"VE",name:"Venezuela/VE"},
    //     {id:"VN",name:"Viet Nam/VN"},
    //     {id:"VI",name:"Virgin Islands, US/VI"},
    //     {id:"WF",name:"Wallis and Futuna Islands/WF"},
    //     {id:"EH",name:"Western Sahara/EH"},
    //     {id:"YE",name:"Yemen/YE"},
    //     {id:"ZM",name:"Zambia/ZM"},
    //     {id:"ZW",name:"Zimbabwe/ZW"}
    // ],

}, _) {
    return state

}


function productCatClickReducer(state = {
    main: false,
    sub: false,
    unit: false,
}, action) {
    switch (action.type) {
        case ActionTypes.MAIN_CAT_INPUT_TAPPED:
            return Object.assign({}, state, {
                main: true
            })
        case ActionTypes.SUB_CAT_INPUT_TAPPED:

            return Object.assign({}, state, {
                sub: state.main && true
            })
        case ActionTypes.UNIT_CAT_INPUT_TAPPED:
            return Object.assign({}, state, {
                unit: state.main && state.sub && true
            })
        case ActionTypes.RESET_CAT_INPUT_TAPPED:
            return Object.assign({}, state, {
                main: false,
                sub: false,
                unit: false
            })

        default:
            return state
    }
}


const companiesReducer = (state = {
    company: {},
    createdCompanyId: null,
    gcps: {},
    contacts: [],
    notes: [],
    status: "",
    isUpdating: false
}, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_COMPANIES_REQUEST:
            return Object.assign({}, state, {
                createdCompanyId: null,
            })


        case ActionTypes.GET_COMPANY_SUCCESS:
            return Object.assign({}, state, {
                company: action.company,
                //gcps: action.gcps,
                contacts: action.contacts,
                notes: action.notes,
                status: action.status
            })

        case ActionTypes.CREATE_COMPANY_REQUEST:
            return Object.assign({}, state, {
                createdCompanyId: null,
            })
        case ActionTypes.CREATE_COMPANY_SUCCESS:
            return Object.assign({}, state, {
                company: action.company,
                createdCompanyId: action.company.id,
                isUpdating: false,
                status: action.status
            })

        case ActionTypes.UPDATE_COMPANY_SUCCESS:
        case ActionTypes.DELETE_COMPANY_DOC_SUCCESS:
        case ActionTypes.UPLOAD_COMPANY_DOC_SUCCESS:
        case ActionTypes.CREATE_USER_SUCCESS:
        case ActionTypes.UPDATE_USER_SUCCESS:
            return Object.assign({}, state, {
                company: action.company,
                isUpdating: false,
                status: action.status
            })


        case ActionTypes.UPDATE_COMPANY_REQUEST:
        case ActionTypes.DELETE_COMPANY_REQUEST:

        case ActionTypes.CREATE_CONTACT_REQUEST:
        case ActionTypes.UPDATE_CONTACT_REQUEST:
        case ActionTypes.DELETE_CONTACT_REQUEST:

        case ActionTypes.CREATE_NOTE_REQUEST:
        case ActionTypes.DELETE_NOTE_REQUEST:

            return Object.assign({}, state, {
                isUpdating: true
            });


        case ActionTypes.CREATE_CONTACT_SUCCESS:
        case ActionTypes.UPDATE_CONTACT_SUCCESS:
        case ActionTypes.DELETE_CONTACT_SUCCESS:
            return Object.assign({}, state, {
                contacts: action.contacts,
                isUpdating: false,
                status: action.status
            })

        case ActionTypes.CREATE_NOTE_SUCCESS:
        case ActionTypes.DELETE_NOTE_SUCCESS:
            return Object.assign({}, state, {
                notes: action.notes,
                isUpdating: false,
                status: action.status
            })

        default:
            return state
    }
}

const companySearchInitialState = {
    name: "",
    phone: "",
    contact_email:"",
    email: "",
    regnumber: "",
    status: "",
    aimak: "",
    district: "",
    sector: "",
    joinedYear: 0,
    joinedMonth: 0,
    isExported: false,
    isCertificate: false,
    isGepir: false,
    page:0
};

const companySearchReducer = (state  = companySearchInitialState, action) => {
    switch (action.type) {
        case 'COMPANY_SEARCH_CHANGE_FIELD': {
            return {...state, [action.field]: action.payload};
        }
        case 'COMPANY_SEARCH_CHANGE_PAGE': {
            return {...state, page: action.page};
        }
        case ActionTypes.RESET_COMPANIES_SEARCH: {
            let c = {...companySearchInitialState}
            return {...state, ...companySearchInitialState};
        }
        default:
            return state
    }
}

// const searchReducer = (state = {
//     companies: [],
//     meta: {},
//     name: "",
//     phone: "",
//     email: "",
//     regnumber: "",
//     status: "",
//     aimak: "",
//     district: "",
//     sector: "",
//     joinedYear: 0,
//     joinedMonth: 0,
//     isExported: false,
//     isCertificate: false,
//     isGepir: false,
//     page: 0,
//     pagesize: 25,
//     orderField: "id",
//     isAsc: true,
//
// }, action) => {
//     switch (action.type) {
//         case ActionTypes.LOAD_COMPANIES_REQUEST:
//             return Object.assign({}, state, {
//                 companies: [],
//                 meta: {},
//                 name: action.name,
//                 phone: action.phone,
//                 email: action.email,
//                 regnumber: action.regnumber,
//                 status: action.status,
//                 aimak: action.aimak,
//                 district: action.district,
//                 sector: action.sector,
//                 joinedYear: action.joinedYear,
//                 joinedMonth: action.joinedMonth,
//                 isExported: action.isExported,
//                 isGepir: action.isGepir,
//                 isCertificate: action.isCertificate,
//                 page: action.page,
//                 pagesize: action.pagesize,
//                 orderField: action.orderField,
//                 isAsc: action.isAsc
//             });
//         case ActionTypes.LOAD_COMPANIES_SUCCESS:
//             return Object.assign({}, state, {
//                 companies: action.companies,
//                 meta: action.meta
//             })
//
//         case ActionTypes.RESET_COMPANIES_SEARCH:
//             return Object.assign({}, state, {
//                 companies: [],
//                 meta: {},
//                 name: '',
//                 phone: '',
//                 email: '',
//                 regnumber: '',
//                 status: '',
//                 aimak: '',
//                 district: '',
//                 sector: '',
//                 joinedYear: 0,
//                 joinedMonth: 0,
//                 page: 0,
//                 pagesize: 25,
//                 orderField: 'id',
//                 isAsc: true,
//             });
//
//         case ActionTypes.LOAD_COMPANIES_SET_ORDER:
//             let isAsc = state.isAsc
//             if (state.orderField === action.orderField) {
//                 isAsc = !isAsc
//             }
//             return Object.assign({}, state, {
//                 isAsc: isAsc,
//                 orderField: action.orderField,
//             });
//
//         case ActionTypes.COMPANIES_PAGE_SELECTED:
//             return Object.assign({}, state, {
//                 page: action.page,
//             });
//
//         default:
//             return state
//     }
// }

//
// const gcpsReducer = (state = {
//     gcps: {},
//     createdGcpId: null,
//     isSuccessfulEdit: false
// }, action) => {
//     switch (action.type) {
//         case ActionTypes.GET_COMPANY_SUCCESS:
//             return Object.assign({}, state, {
//                 gcps: action.gcps,
//             })
//
//         case ActionTypes.CREATE_GCP_REQUEST:
//             return Object.assign({}, state, {
//                 createdGcpId: null,
//                 isSuccessfulEdit: false
//             })
//         case ActionTypes.UPDATE_GCP_REQUEST:
//             return Object.assign({}, state, {
//                 createdGcpId: null,
//                 isSuccessfulEdit: false
//             })
//
//         case ActionTypes.CREATE_GCP_SUCCESS:
//             let m2 = 0;
//             for (const key in state.gcps) {
//                 let p = state.gcps[key];
//                 if (p.order > m2) {
//                     m2 = p.order
//                 }
//             }
//             action.gcp.order = m2 + 1
//             state.gcps[action.gcp.id] = action.gcp;
//             return Object.assign({}, state, {
//                 gcps: state.gcps,
//                 createdGcpId: action.gcp.id,
//                 isSuccessfulEdit: true
//             });
//         case ActionTypes.UPDATE_GCP_SUCCESS:
//             let o1 = state.gcps[action.gcp.id].order
//             action.gcp.order = o1
//             state.gcps[action.gcp.id] = action.gcp;
//             return Object.assign({}, state, {
//                 gcps: state.gcps,
//                 createdGcpId: null,
//                 isSuccessfulEdit: true
//             })
//
//         case ActionTypes.DELETE_GCP_SUCCESS:
//             delete state.gcps[action.gcp.id]
//             return Object.assign({}, state, {
//                 gcps: state.gcps,
//             });
//
//         default:
//             return state
//     }
// }


const dashboardReducer = (state = {
    companyActive: 0,
    companyTempClosed: 0,
    companyRemoved: 0,
    gcp: 0,
    gtin: 0,
    invoice: 0,
    productAppl: 0,
    companyAppl: 0,
}, action) => {
    switch (action.type) {
        case ActionTypes.GET_DASHBOARD_SUCCESS:
            return Object.assign({}, state, {
                companyActive: action.companyActive,
                companyTempClosed: action.companyTempClosed,
                companyRemoved: action.companyRemoved,
                gcp: action.gcp,
                gtin: action.gtin,
                invoice: action.invoice,
                productAppl: action.productAppl,
                companyAppl: action.companyAppl

            })

        default:
            return state
    }
}


const productsReducer = (state = {
    products: [],
    product: {},
    meta: {},
    status: "",
    images: {},
    isSuccessfulEdit: false,
    orderField: "barcode",
    isAsc: true,
    page: 0,
    main: null,
    sub: null,
    unit: null,
    isEmpty: false,
    searchClicked: false,
    selections: new Map(),
    update_main: null,
    update_sub: null,
    update_unit: null,
    name: null,
    barcode: null,
    created: null,
    companyName: null
}, action) => {
    switch (action.type) {

        case ActionTypes.SELECT_PRODUCT_HEADER:
            let n2 = new Map()
            if (state.selections.size == 0) {
                state.products.map(p => {
                    n2.set(p.id, p.id)
                })
            }
            return Object.assign({}, state, {
                selections: n2
            })

        case ActionTypes.SELECT_PRODUCT:
            let n1 = new Map(state.selections);
            if (n1.has(action.id)) {
                n1.delete(action.id)
            } else {
                n1.set(action.id, action.id)
            }
            return Object.assign({}, state, {
                selections: n1
            })

        case ActionTypes.RESET_PRODUCT_UPDATE_STATUS:
            return Object.assign({}, state, {
                isSuccessfulEdit: false
            })

        case ActionTypes.LOAD_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                products: action.products,
                meta: action.meta
            });
        case ActionTypes.LOAD_ALL_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                products: action.products,
                meta: action.meta,
                searchClicked: false
            });

        case ActionTypes.LOAD_ALL_PRODUCTS14_SUCCESS:
            return Object.assign({}, state, {
                products: action.products,
                meta: action.meta,
                searchClicked: false
            });

        case ActionTypes.INIT_TEMP_PRODUCT:
            return Object.assign({}, state, {
                temp_product: {},
                status: action.status
            });

        case ActionTypes.GET_PRODUCT_REQUEST:
            return Object.assign({}, state, {
                product: {},
                temp_product: {}
            });

        case ActionTypes.GET_PRODUCT_SUCCESS:
            return Object.assign({}, state, {
                product: action.product,
                temp_product: action.product,
                status: action.status
            });

        case ActionTypes.GET_PRODUCT_IMAGE_SUCCESS:
            state.images[action.id + "-" + action.side] = action.data
            return Object.assign({}, state, {
                images: state.images,
            });
        case ActionTypes.CREATE_PRODUCT_REQUEST:
        case ActionTypes.UPDATE_PRODUCT_REQUEST:
        case ActionTypes.UPDATE_PRODUCTS_CAT_REQUEST:
            return Object.assign({}, state, {
                isSuccessfulEdit: false
            });

        case ActionTypes.CREATE_PRODUCT_SUCCESS:
        case ActionTypes.UPDATE_PRODUCT_SUCCESS:

            return Object.assign({}, state, {
                product: action.product,
                status: action.status,

                isSuccessfulEdit: true
            });
        case ActionTypes.UPDATE_PRODUCTS_CAT_SUCCESS:

            return Object.assign({}, state, {
                status: action.status,
                selections: new Map(),
                update_main: action.main,
                update_sub: action.sub,
                update_unit: action.unit,
                isSuccessfulEdit: true
            });

        case ActionTypes.UPLOAD_PRODUCT_IMAGE_SUCCESS:
            state.images[action.product.id + "-" + action.side] = action.data
            return Object.assign({}, state, {
                product: action.product,
                images: state.images,
            });
        case ActionTypes.DELETE_PRODUCT_IMAGE_SUCCESS:
            delete state.images[action.product.id + "-" + action.side]
            return Object.assign({}, state, {
                product: action.product,
                images: state.images,
                status: action.status
            });
        case ActionTypes.DELETE_PRODUCT_SUCCESS:
            return Object.assign({}, state, {
                products: action.products,
                meta: action.meta,
                status: action.status
            })

        case ActionTypes.LOAD_PRODUCTS_PAGE_CHANGED:
            return Object.assign({}, state, {
                page: action.page
            });
        case ActionTypes.LOAD_PRODUCTS_ORDER_CHANGED:
            return Object.assign({}, state, {
                orderField: action.orderField,
                isAsc: action.isAsc
            });
        case ActionTypes.LOAD_PRODUCTS_ALL_SEARCH:
            return Object.assign({}, state, {
                page: 0,
                orderField: "barcode",
                isAsc: true,
                name: action.name,
                barcode: action.barcode,
                companyName: action.companyName,
                main: action.main,
                sub: action.sub,
                unit: action.unit,
                created:action.created,
                isEmpty: action.isEmpty,
                searchClicked: true
            });

        case ActionTypes.LOAD_PRODUCTS_RESET_PAGE_ORDER:
            return Object.assign({}, state, {
                page: 0,
                orderField: "barcode",
                isAsc: true,
                name: null,
                barcode: null,
                companyName: null,
                main: null,
                sub: null,
                unit: null,
                created:null,
                isEmpty:null,
                searchClicked: false
            });

        case ActionTypes.GET_COMPANY_REQUEST:
            return Object.assign({}, state, {
                isAsc: true,
                orderField: "barcode",
                page: 0
            });

        default:
            return state
    }
}

//
// function updateObjectInArray(array, action) {
//     return array.map((item, index) => {
//         if (index !== action.index) {
//             // This isn't the item we care about - keep it as-is
//             return item
//         }
//
//         // Otherwise, this is the one we want - return an updated value
//         return {
//             ...item,
//             ...action.item
//         }
//     })
// }

const applicationsReducer = (state = {
    companies: {},
    company: {},
    products: {},
    product: {},
    metaCompany: {},
    metaProduct: {},
    status: "",
    mainLabel: null,
    subLabel: null,
    unitLabel: null,
    main: null,
    sub: null,
    unit: null
}, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_PRODUCT_APPLICATIONS_SUCCESS:
            return Object.assign({}, state, {
                products: action.products,
                metaProduct: action.meta
            })
        case ActionTypes.GET_PRODUCT_APPLICATION_REQUEST:
            return Object.assign({}, state, {
                product: {}
            });

        case ActionTypes.GET_PRODUCT_APPLICATION_SUCCESS:
        case ActionTypes.CHANGE_PRODUCT_APPLICATION_SUCCESS:
        case ActionTypes.DENY_PRODUCT_APPLICATION_SUCCESS:
            return Object.assign({}, state, {
                product: action.request,
                status: action.status
            });
        case ActionTypes.ACTIVATE_PRODUCT_APPLICATION_SUCCESS:
            return Object.assign({}, state, {
                product: action.request,
                main: action.request.main,
                sub: action.request.sub,
                unit: action.request.unit,
                unitLabel: action.request.unit_name,
                subLabel: action.request.sub_name,
                mainLabel: action.request.main_name,
                status: action.status
            });

        case ActionTypes.COPY_PRODUCT_APPLICATION_CATS:
            return Object.assign({}, state, {
                main: action.request.main,
                sub: action.request.sub,
                unit: action.request.unit,
                unitLabel: action.request.unitLabel,
                subLabel: action.request.subLabel,
                mainLabel: action.request.mainLabel
            });


        case ActionTypes.UPDATE_PRODUCT_REQUEST_CAT_SUCCESS:

            // state.products.map((item) => {
            //     if (item.id !== action.request.id) {
            //         // This isn't the item we care about - keep it as-is
            //         return item
            //     }
            //
            //     // Otherwise, this is the one we want - return an updated value
            //     return {
            //         ...item,
            //         ...action.request
            //     }
            // });
            // replace
            let n = {}
            for (const key in state.products) {
                let p = state.products[key];
                n[key] = p
            }

            n[action.request.id] = action.request
            return Object.assign({}, state, {
                products: n,
            })

        case ActionTypes.LOAD_COMPANY_APPLICATIONS_SUCCESS:
            return Object.assign({}, state, {
                companies: action.companies,
                metaCompany: action.meta
            })
        case ActionTypes.GET_COMPANY_APPLICATION_REQUEST:
            return Object.assign({}, state, {
                company: {}
            });

        case ActionTypes.GET_COMPANY_APPLICATION_SUCCESS:
        case ActionTypes.ACTIVATE_COMPANY_APPLICATION_SUCCESS:
            return Object.assign({}, state, {
                company: action.request,
                status: action.status
            });
        case ActionTypes.DELETE_COMPANY_APPLICATION_SUCCESS:
            delete state.companies[action.request.id]
            return Object.assign({}, state, {
                companies: state.companies,
                status: action.status
            });
        default:
            return state
    }
}


const findSector = (arr, sector) => {
    let sectors = []
    for (let key in arr) {
        let s = (arr[key]);
        if (s.id === sector.id) {
            sectors.push(sector)
        } else {
            sectors.push(s)
        }
    }
    return sectors;
}


const sectorsReducer = (state = {
    sectors: [],
    sector: {},
    meta: {},
    metaProduct: {},
    status: "",
    isUpdating: false
}, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_SECTORS_ALL_SUCCESS:
        case ActionTypes.LOAD_SECTORS_SUCCESS:
            return Object.assign({}, state, {
                sectors: action.sectors,
                meta: action.meta
            })

        case ActionTypes.CREATE_SECTOR_SUCCESS:
            return Object.assign({}, state, {
                sectors: [...state.sectors, action.sector],
                sector: action.sector,
                isUpdating: false,
                status: action.status
            })
        case ActionTypes.UPDATE_SECTOR_SUCCESS:
            return Object.assign({}, state, {
                sectors: findSector(state.sectors, action.sector),
                isUpdating: false,
                status: action.status
            })
        case ActionTypes.DELETE_SECTOR_SUCCESS:
            return Object.assign({}, state, {
                sector: action.sector,
                status: action.status
            })

        default:
            return state
    }
}


const sectorsNoAuthReducer = (state = {
    sectors: [],
}, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_SECTORS_ALL_NO_AUTH_SUCCESS:
            return Object.assign({}, state, {
                sectors: action.sectors

            })


        default:
            return state
    }
}

const feesReducer = (state = {
    fees: {},
    status: "",
    isUpdating: false
}, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_FEES_SUCCESS:
            return Object.assign({}, state, {
                fees: action.fees,
            })

        case ActionTypes.UPDATE_FEE_SUCCESS:
            state.fees[action.fee.id] = action.fee;
            return Object.assign({}, state, {
                fees: state.fees,
                isUpdating: false,
                status: action.status
            })
        default:
            return state
    }
}


const barcodesReducer = (state = {
    barcodes: {},
    meta: {},
    status: "",
    isUpdating: false
}, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_BARCODES_SUCCESS:
            return Object.assign({}, state, {
                barcodes: action.barcodes,
                meta: action.meta
            })

        case ActionTypes.UPLOAD_BARCODE_REQUEST:
            return Object.assign({}, state, {
                isUpdating: true,
            });
        case ActionTypes.UPLOAD_BARCODE_FAILURE:
            return Object.assign({}, state, {
                isUpdating: false,
            });

        case ActionTypes.UPLOAD_BARCODE_SUCCESS:
            //state.barcodes[action.barcode.id] = action.barcode
            return Object.assign({}, state, {
              //  barcodes: state.barcodes,
                isUpdating: false,
            });

        case ActionTypes.UPDATE_BARCODE_SUCCESS:
            state.barcodes[action.barcode.id] = action.barcode
            return Object.assign({}, state, {
                barcodes: state.barcodes,
                isUpdating: false,
            });
        case ActionTypes.DELETE_BARCODE_SUCCESS:
            delete state.barcodes[action.barcode.id]
            return Object.assign({}, state, {
                barcodes: state.barcodes,
                isUpdating: false,
                status: action.status
            })
        default:
            return state
    }
}


const paymentsReducer = (state = {
    payments: {},
    status: "",
    isUpdating: false
}, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_PAYMENTS_REQUEST:
            return Object.assign({}, state, {
                payments: {}
            })

        case ActionTypes.LOAD_PAYMENTS_SUCCESS:
            return Object.assign({}, state, {
                payments: action.payments

            })

        case ActionTypes.CREATE_PAYMENT_SUCCESS:
        case ActionTypes.CREATE_PAYMENT_NOTE_SUCCESS:
            let o1 = state.payments[action.payment.year].order
            action.payment.order = o1

            state.payments[action.payment.year] = action.payment
            return Object.assign({}, state, {
                payments: state.payments,

            });
        case ActionTypes.DELETE_PAYMENT_SUCCESS:
        case ActionTypes.DELETE_PAYMENT_NOTE_SUCCESS:
            let o2 = state.payments[action.payment.year].order
            action.payment.order = o2

            state.payments[action.payment.year] = action.payment

//            delete state.payments[action.payment.year]
            return Object.assign({}, state, {
                payments: state.payments,
                isUpdating: false,
                status: action.status
            })
        default:
            return state
    }
}


const invoicesReducer = (state = {
    invoices: {},
    invoice: {},
    items: [],
    status: "",
    isUpdating: false,

}, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_INVOICES_REQUEST:
            return Object.assign({}, state, {
                invoices: {}
            })

        case ActionTypes.LOAD_INVOICES_SUCCESS:
            return Object.assign({}, state, {
                invoices: action.invoices

            })
        case ActionTypes.GET_INVOICE_SUCCESS:
            return Object.assign({}, state, {
                invoice: action.invoice,
                items: action.items,
                isUpdating: false,
            })


        case ActionTypes.CREATE_INVOICE_SUCCESS:

            let m2 = 0;
            for (const key in state.invoices) {
                let p = state.invoices[key];
                if (p.order > m2) {
                    m2 = p.order
                }
            }
            action.invoice.order = m2 + 1
            state.invoices[action.invoice.id] = action.invoice;

            return Object.assign({}, state, {
                invoices: state.invoices,

            });
        case ActionTypes.DELETE_INVOICE_SUCCESS:

            delete state.invoices[action.invoiceId]
            return Object.assign({}, state, {
                invoices: state.invoices,
                isUpdating: false,
                status: action.status
            })
        default:
            return state
    }
}


const allInvoicesReducer = (state = {
    invoices: [], //{},
    meta: {},
    total_amount:0.0,
    invoice: {},
    items: [],
    status: "",
    orderField: "id",
    orderDir: true,
    page: 0,
    pagesize: 25

}, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_INVOICES_SET_ORDER:
            let orderDir = state.orderDir
            if (state.orderField === action.orderField) {
                orderDir = !orderDir
            }
            return Object.assign({}, state, {
                orderDir: orderDir,
                orderField: action.orderField,
            });

        case ActionTypes.INVOICES_PAGE_SELECTED:
            return Object.assign({}, state, {
                page: action.page,
            });
        case ActionTypes.LOAD_ALL_INVOICES_REQUEST:
            return Object.assign({}, state, {
                invoices: [],
                meta: {},
                total_amount:0.0
            });
        case ActionTypes.LOAD_ALL_INVOICES_SUCCESS:
        case ActionTypes.CREATE_INVOICE_BULK_SUCCESS:
         return Object.assign({}, state, {
                invoices: action.invoices,
                total_amount:action.total_amount,
                meta: action.meta
            });

        case ActionTypes.DELETE_ALL_INVOICES_BULK_SUCCESS:
            return Object.assign({}, state, {
                invoices: [],
                meta: {},
                total_amount:0.0
            });

        default:
            return state
    }
}


const errorReducer = (state = {
    error: "",
    message: "",
    isError: false
}, action) => {
    switch (action.type) {

        case ActionTypes.RESET_ERRORS:
        case ActionTypes.CREATE_COMPANY_REQUEST:
        case ActionTypes.CREATE_COMPANY_SUCCESS:
        case ActionTypes.UPDATE_COMPANY_SUCCESS:
        case ActionTypes.DELETE_COMPANY_DOC_SUCCESS:
        case ActionTypes.UPLOAD_COMPANY_DOC_SUCCESS:
        case ActionTypes.CREATE_USER_SUCCESS:
        case ActionTypes.UPDATE_USER_SUCCESS:
            return Object.assign({}, state, {
                isError: false,
                error: "",
                message: ""
            })
        case ActionTypes.CREATE_COMPANY_FAILURE:
        case ActionTypes.FAILURE:
            return Object.assign({}, state, {
                isError: true,
                error: action.error,
                message: action.message
            })
        default:
            return state
    }
}


const uiReducer = (state = {
    tab: 0,
    menu: 0,
    title: "GS1 Mongolia",
    sidemenu: "",
    isUpdating: false,
    isSuccess: false,
    isError: false,
    error: "",
    errorMessage: ""
}, action) => {
    switch (action.type) {
        case ActionTypes.GET_DASHBOARD_REQUEST:
        case ActionTypes.LOAD_COMPANIES_REQUEST:
        case ActionTypes.GET_COMPANY_REQUEST:
        case ActionTypes.CREATE_COMPANY_REQUEST:
        case ActionTypes.UPDATE_COMPANY_REQUEST:
        case ActionTypes.CHANGE_COMPANY_REQUEST:
        case ActionTypes.DELETE_COMPANY_REQUEST:
        case ActionTypes.CREATE_CONTACT_REQUEST:
        case ActionTypes.UPDATE_CONTACT_REQUEST:
        case ActionTypes.DELETE_CONTACT_REQUEST:
        case ActionTypes.CREATE_GCP_REQUEST:
        case ActionTypes.UPDATE_GCP_REQUEST:
        case ActionTypes.DELETE_GCP_REQUEST:
        case ActionTypes.LOAD_ALL_INVOICES_REQUEST:
        case ActionTypes.CREATE_INVOICE_BULK_REQUEST:
        case ActionTypes.DELETE_INVOICES_BULK_REQUEST:
        case ActionTypes.DELETE_ALL_INVOICES_BULK_REQUEST:
        case ActionTypes.PRINT_INVOICES_BULK_REQUEST:
        case ActionTypes.PRINT_ALL_INVOICES_BULK_REQUEST:
        case ActionTypes.LOAD_INVOICES_REQUEST:
        case ActionTypes.GET_INVOICE_REQUEST:
        case ActionTypes.CREATE_INVOICE_REQUEST:
        case ActionTypes.DELETE_INVOICE_REQUEST:
        case ActionTypes.PRINT_INVOICE_REQUEST:
        case ActionTypes.LOAD_PAYMENTS_REQUEST:
        case ActionTypes.CREATE_PAYMENT_REQUEST:
        case ActionTypes.DELETE_PAYMENT_REQUEST:
        case ActionTypes.LOAD_BARCODES_REQUEST:
        case ActionTypes.UPLOAD_BARCODE_REQUEST:
        case ActionTypes.DELETE_BARCODE_REQUEST:
        case ActionTypes.DOWNLOAD_BARCODE_REQUEST:
        case ActionTypes.LOAD_PRODUCTS_REQUEST:
        case ActionTypes.GET_PRODUCT_REQUEST:
        case ActionTypes.CREATE_PRODUCT_REQUEST:
        case ActionTypes.UPDATE_PRODUCT_REQUEST:
        case ActionTypes.DELETE_PRODUCT_REQUEST:
        case ActionTypes.CHANGE_PRODUCT_CLASSIF_REQUEST:
        case ActionTypes.UPLOAD_PRODUCT_IMAGE_REQUEST:
        case ActionTypes.GET_PRODUCT_IMAGE_REQUEST:
        case ActionTypes.DELETE_PRODUCT_IMAGE_REQUEST:
        case ActionTypes.CREATE_NOTE_REQUEST:
        case ActionTypes.DELETE_NOTE_REQUEST:
        case ActionTypes.LOAD_SECTORS_REQUEST:
        case ActionTypes.LOAD_SECTORS_ALL_REQUEST:
        case ActionTypes.CREATE_SECTOR_REQUEST:
        case ActionTypes.UPDATE_SECTOR_REQUEST:
        case ActionTypes.DELETE_SECTOR_REQUEST:
        case ActionTypes.LOAD_CLASSIF_MAIN_REQUEST:
        case ActionTypes.LOAD_CLASSIF_SUB_REQUEST:
        case ActionTypes.LOAD_CLASSIF_UNIT_REQUEST:
        case ActionTypes.CREATE_CLASSIF_MAIN_REQUEST:
        case ActionTypes.CREATE_CLASSIF_SUB_REQUEST:
        case ActionTypes.CREATE_CLASSIF_UNIT_REQUEST:
        case ActionTypes.UPDATE_CLASSIF_MAIN_REQUEST:
        case ActionTypes.UPDATE_CLASSIF_SUB_REQUEST:
        case ActionTypes.UPDATE_CLASSIF_UNIT_REQUEST:
        case ActionTypes.DELETE_CLASSIF_MAIN_REQUEST:
        case ActionTypes.LOAD_FEES_REQUEST:
        case ActionTypes.UPDATE_FEE_REQUEST:
        case ActionTypes.CREATE_USER_REQUEST:
        case ActionTypes.UPDATE_USER_REQUEST:
        case ActionTypes.LOAD_COMPANY_APPLICATIONS_REQUEST:
        case ActionTypes.LOAD_PRODUCT_APPLICATIONS_REQUEST:
        case ActionTypes.GET_PRODUCT_APPLICATION_REQUEST:
        case ActionTypes.ACTIVATE_PRODUCT_APPLICATION_REQUEST:
        case ActionTypes.CHANGE_PRODUCT_APPLICATION_REQUEST:
        case ActionTypes.DELETE_PRODUCT_APPLICATION_REQUEST:
        case ActionTypes.UPLOAD_COMPANY_DOC_REQUEST:
        case ActionTypes.DOWNLOAD_COMPANY_DOC_REQUEST:
        case ActionTypes.DELETE_COMPANY_DOC_REQUEST:
        case ActionTypes.CREATE_COMPANY_SELF_REQUEST:
            return Object.assign({}, state, {
                isUpdating: true,
                isError: false,
                isSuccess: false,
                error: "",
                errorMessage: ""
            })

        case ActionTypes.GET_DASHBOARD_SUCCESS:
        case ActionTypes.LOAD_COMPANIES_SUCCESS:

        case ActionTypes.CREATE_COMPANY_SUCCESS:

        case ActionTypes.CHANGE_COMPANY_SUCCESS:
        case ActionTypes.DELETE_COMPANY_SUCCESS:
        case ActionTypes.CREATE_CONTACT_SUCCESS:
        case ActionTypes.UPDATE_CONTACT_SUCCESS:
        case ActionTypes.DELETE_CONTACT_SUCCESS:
        case ActionTypes.CREATE_GCP_SUCCESS:
        case ActionTypes.UPDATE_GCP_SUCCESS:
        case ActionTypes.DELETE_GCP_SUCCESS:
        case ActionTypes.LOAD_ALL_INVOICES_SUCCESS:
        case ActionTypes.CREATE_INVOICE_BULK_SUCCESS:
        case ActionTypes.DELETE_INVOICES_BULK_SUCCESS:
        case ActionTypes.DELETE_ALL_INVOICES_BULK_SUCCESS:
        case ActionTypes.PRINT_INVOICES_BULK_SUCCESS:
        case ActionTypes.PRINT_ALL_INVOICES_BULK_SUCCESS:
        case ActionTypes.LOAD_INVOICES_SUCCESS:
        case ActionTypes.GET_INVOICE_SUCCESS:
        case ActionTypes.CREATE_INVOICE_SUCCESS:
        case ActionTypes.DELETE_INVOICE_SUCCESS:
        case ActionTypes.PRINT_INVOICE_SUCCESS:
        case ActionTypes.LOAD_PAYMENTS_SUCCESS:
        case ActionTypes.CREATE_PAYMENT_SUCCESS:
        case ActionTypes.DELETE_PAYMENT_SUCCESS:
        case ActionTypes.LOAD_BARCODES_SUCCESS:
        case ActionTypes.UPLOAD_BARCODE_SUCCESS:
        case ActionTypes.DELETE_BARCODE_SUCCESS:
        case ActionTypes.DOWNLOAD_BARCODE_SUCCESS:
        case ActionTypes.GET_PRODUCT_SUCCESS:
        case ActionTypes.CREATE_PRODUCT_SUCCESS:
        case ActionTypes.UPDATE_PRODUCT_SUCCESS:
        case ActionTypes.DELETE_PRODUCT_SUCCESS:
        case ActionTypes.CHANGE_PRODUCT_CLASSIF_SUCCESS:
        case ActionTypes.UPLOAD_PRODUCT_IMAGE_SUCCESS:
        case ActionTypes.GET_PRODUCT_IMAGE_SUCCESS:
        case ActionTypes.DELETE_PRODUCT_IMAGE_SUCCESS:
        case ActionTypes.CREATE_NOTE_SUCCESS:
        case ActionTypes.DELETE_NOTE_SUCCESS:
        case ActionTypes.LOAD_SECTORS_SUCCESS:
        case ActionTypes.LOAD_SECTORS_ALL_SUCCESS:
        case ActionTypes.CREATE_SECTOR_SUCCESS:
        case ActionTypes.UPDATE_SECTOR_SUCCESS:
        case ActionTypes.DELETE_SECTOR_SUCCESS:
        case ActionTypes.LOAD_CLASSIF_MAIN_SUCCESS:
        case ActionTypes.LOAD_CLASSIF_SUB_SUCCESS:
        case ActionTypes.LOAD_CLASSIF_UNIT_SUCCESS:
        case ActionTypes.CREATE_CLASSIF_MAIN_SUCCESS:
        case ActionTypes.CREATE_CLASSIF_SUB_SUCCESS:
        case ActionTypes.CREATE_CLASSIF_UNIT_SUCCESS:
        case ActionTypes.UPDATE_CLASSIF_MAIN_SUCCESS:
        case ActionTypes.UPDATE_CLASSIF_SUB_SUCCESS:
        case ActionTypes.UPDATE_CLASSIF_UNIT_SUCCESS:
        case ActionTypes.DELETE_CLASSIF_MAIN_SUCCESS:
        case ActionTypes.LOAD_FEES_SUCCESS:
        case ActionTypes.UPDATE_FEE_SUCCESS:
        case ActionTypes.CREATE_USER_SUCCESS:
        case ActionTypes.UPDATE_USER_SUCCESS:
        case ActionTypes.LOAD_COMPANY_APPLICATIONS_SUCCESS:
        case ActionTypes.LOAD_PRODUCT_APPLICATIONS_SUCCESS:
        case ActionTypes.GET_PRODUCT_APPLICATION_SUCCESS:
        case ActionTypes.ACTIVATE_PRODUCT_APPLICATION_SUCCESS:
        case ActionTypes.CHANGE_PRODUCT_APPLICATION_SUCCESS:
        case ActionTypes.DENY_PRODUCT_APPLICATION_SUCCESS:
        case ActionTypes.DELETE_PRODUCT_APPLICATION_SUCCESS:
        case ActionTypes.UPLOAD_COMPANY_DOC_SUCCESS:
        case ActionTypes.DOWNLOAD_COMPANY_DOC_SUCCESS:
        case ActionTypes.DELETE_COMPANY_DOC_SUCCESS:
        case ActionTypes.CREATE_COMPANY_SELF_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                isSuccess: true,
                isError: false,
                error: "",
                errorMessage: ""
            })
        case ActionTypes.UPDATE_COMPANY_SUCCESS:
            return Object.assign({}, state, {
                title: action.company.name,
                isUpdating: false,
                isSuccess: true,
                isError: false,
                error: "",
                errorMessage: ""
            });

        case ActionTypes.GET_COMPANY_SUCCESS:
            return Object.assign({}, state, {
                title: action.company.name,
                isUpdating: false,
                isSuccess: true,
                isError: false,
                error: "",
                errorMessage: ""
            });

        case ActionTypes.FAILURE:
            return Object.assign({}, state, {
                isUpdating: false,
                isSuccess: false,
                isError: true,
                error: action.error,
                errorMessage: action.message
            })


        case ActionTypes.DETAIL_TAPPED:
            return Object.assign({}, state, {
                tab: 0
            })
        case ActionTypes.CONTACTS_TAPPED:
            return Object.assign({}, state, {
                tab: 1
            })
        case ActionTypes.GCPS_TAPPED:
            return Object.assign({}, state, {
                tab: 2
            })
        case ActionTypes.PRODUCTS_TAPPED:
            return Object.assign({}, state, {
                tab: 3
            })
        case ActionTypes.SIDEMENU_CLICKED:
            return Object.assign({}, state, {
                sidemenu: action.menu,
                title: action.title
            })
        case ActionTypes.SIDEMENU_COMPANY_CLICKED:
            return Object.assign({}, state, {
                title: action.title
            })
        case ActionTypes.LOAD_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                tab: 3
            })
        case ActionTypes.SET_UI_TITLE:
            return Object.assign({}, state, {
                title: action.title
            })
        default:
            return state
    }
}


// const gcpPageReducer = (state = {
//     items: [],
//     meta: {},
//     barcodeType: 0,
//     size: 0,
//     prefix: "",
//     name: "",
//     page: 0,
//     pagesize: 25,
//     orderField: "id",
//     isAsc: true,
// }, action) => {
//     switch (action.type) {
//
//         case ActionTypes.LOAD_GCPS_SUCCESS:
//             return Object.assign({}, state, {
//                 items: action.gcps,
//                 meta: action.meta
//             })
//         case ActionTypes.LOAD_GCPS_REQUEST:
//             return Object.assign({}, state, {
//                 barcodeType: action.barcodeType,
//                 size: action.size,
//                 prefix: action.prefix,
//                 name: action.name,
//                 page: action.page,
//                 pagesize: action.pagesize,
//                 orderField: action.orderField,
//                 isAsc: action.isAsc
//             });
//
//         case ActionTypes.RESET_GCPS_SEARCH:
//             return Object.assign({}, state, {
//                 items: [],
//                 meta: {},
//                 size: 0,
//                 prefix: '',
//                 name: '',
//                 page: 0,
//                 pagesize: 25,
//                 orderField: 'id',
//                 isAsc: true,
//             });
//         case ActionTypes.GCPS_PAGE_SELECTED:
//             return Object.assign({}, state, {
//                 page: action.page,
//             });
//         case ActionTypes.LOAD_GCPS_SET_ORDER:
//             let isAsc = state.isAsc
//             if (state.orderField === action.orderField) {
//                 isAsc = !isAsc
//             }
//             return Object.assign({}, state, {
//                 isAsc: isAsc,
//                 orderField: action.orderField,
//             });
//         default:
//             return state
//     }
// }

const classifsReducer = (state = {
    main: {},
    sub: {},
    unit: {},
    subCats: new Map(),
    unitCats: new Map(),
    status: "",
    isUpdating: false
}, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_CLASSIF_MAIN_SUCCESS:
            return Object.assign({}, state, {
                main: action.classifs,
            })
        case ActionTypes.LOAD_CLASSIF_SUB_SUCCESS:
            let n1 = new Map(state.subCats);
            n1.set(action.parent, action.classifs);
            return Object.assign({}, state, {
                sub: action.classifs,
                subCats: n1
            });
        case ActionTypes.LOAD_CLASSIF_UNIT_SUCCESS:
            let n2 = new Map(state.unitCats);
            n2.set(action.parent, action.classifs);
            return Object.assign({}, state, {
                unit: action.classifs,
                unitCats: n2
            });

        case ActionTypes.CREATE_CLASSIF_MAIN_SUCCESS:
            let m = 0 //Number.MAX_SAFE_INTEGER;
            for (const key in state.main) {
                let p = state.main[key];
                if (p.order > m) {
                    m = p.order
                }
            }
            action.classif.order = m + 1
            state.main[action.classif.id] = action.classif;
            return Object.assign({}, state, {
                main: state.main, // [...state.main, action.classif],
                isUpdating: false,
                status: action.status
            })

        case ActionTypes.UPDATE_CLASSIF_MAIN_SUCCESS:
            let o1 = state.main[action.classif.id].order
            action.classif.order = o1
            state.main[action.classif.id] = action.classif;
            return Object.assign({}, state, {
                main: state.main,
                isUpdating: false,
                status: action.status
            })

        case ActionTypes.CREATE_CLASSIF_SUB_SUCCESS:
            let m2 = 0;
            for (const key in state.sub) {
                let p = state.sub[key];
                if (p.order > m2) {
                    m2 = p.order
                }
            }
            action.classif.order = m2 + 1
            state.sub[action.classif.id] = action.classif;
            return Object.assign({}, state, {
                sub: state.sub, //[...state.sub, action.classif],
                isUpdating: false,
                status: action.status
            })
        case ActionTypes.UPDATE_CLASSIF_SUB_SUCCESS:
            let o2 = state.sub[action.classif.id].order
            action.classif.order = o2
            state.sub[action.classif.id] = action.classif;
            return Object.assign({}, state, {
                sub: state.sub,
                isUpdating: false,
                status: action.status
            })

        case ActionTypes.CREATE_CLASSIF_UNIT_SUCCESS:
            let m3 = 0 //Number.MAX_SAFE_INTEGER;
            for (const key in state.unit) {
                let p = state.unit[key];
                if (p.order > m3) {
                    m3 = p.order
                }
            }
            action.classif.order = m3 + 1
            state.unit[action.classif.id] = action.classif;
            return Object.assign({}, state, {
                unit: state.unit, //[...state.unit, action.classif],
                isUpdating: false,
                status: action.status
            })

        case ActionTypes.UPDATE_CLASSIF_UNIT_SUCCESS:
            let o3 = state.unit[action.classif.id].order
            action.classif.order = o3
            state.unit[action.classif.id] = action.classif;
            return Object.assign({}, state, {
                unit: state.unit,
                isUpdating: false,
                status: action.status
            })

        default:
            return state
    }
}


function glnReducer(state = {
    barcodeTypes: new Map([
        ["GTIN", "GTIN"],
        ["GLN", "GLN"],
        ["SSCC", "SSCC"]
    ]),
    locations: new Map([
        ["MAIN", "Үйл ажиллагаа явуулж буй байршил"],
        ["ADDRESS", "Бүртгэлтэй хаяг"],
        ["BRANCH", "Салбар, нэгжүүд"],
        ["ELECTRONIC", "Цахим байршил"]
    ]),
    locationTypes: new Map([
        ["MAIN", "Үндсэн оффис"],
        ["BRANCH", "Салбар оффис"],
        ["GROSS", "Бөөний төв"],
        ["LOGISTIC", "Түгээлтийн төв"],
        ["PRODUCTION", "Үйлдвэрийн байр"],
        ["DETAIL", "Жижиглэн худалдаа/дэлгүүр"],
        ["SHOW", "Үзүүлэнгийн өрөө"],
        ["WAREHOUSE", "Агуулах"],
        ["OTHER", "Бусад"]
    ])
}, _) {
    return state

}


const initialState = {
    barcode_type: GTIN
}

function tempProductReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.INIT_TEMP_PRODUCT:
            return Object.assign({}, state, {
                id:undefined,
                barcode:undefined,
                name:undefined,
                company_id: undefined,
                company_name: undefined
            });

        case ActionTypes.GET_PRODUCT_SUCCESS:
        case ActionTypes.GET_PRODUCT8_SUCCESS: {
            return {
                ...state,
                ...action.product
            }
        }
        case ActionTypes.SET_GCP_PREFIX: {
            return {
                ...state,
                gcp_prefix: action.gcp_prefix
            }
        }
        case ActionTypes.TEMP_PRODUCT_CHANGE: {

            switch (action.f) {
                case 'company_id':
                    return {...state, company_id: action.payload};
                case 'barcode_type':
                    return {...state, barcode_type: action.payload};
                case 'barcode':
                    return {...state, barcode: action.payload};
                case 'name':
                    return {...state, name: action.payload};
                case 'storage':
                    return {...state, storage: action.payload};
                case 'handling':
                    return {...state, handling: action.payload};
                case 'characteristics':
                    return {...state, characteristics: action.payload};
                case 'constituent':
                    return {...state, constituent: action.payload};
                case 'instruction':
                    return {...state, instruction: action.payload};
                case 'main':
                    return {...state, main: action.payload};
                case 'sub':
                    return {...state, sub: action.payload};
                case 'unit':
                    return {...state, unit: action.payload};
                case 'packaging':
                    return {...state, packaging: action.payload};
                case 'sender_name':
                    return {...state, sender_name: action.payload};
                case 'sender_position':
                    return {...state, sender_position: action.payload};
                case 'sender_phone':
                    return {...state, sender_phone: action.payload};
                case 'sender_email':
                    return {...state, sender_email: action.payload};

                case 'location':
                    return {...state, location: action.payload};
                case 'location_type':
                    return {...state, location_type: action.payload};
                case 'state':
                    return {...state, state: action.payload};
                case 'aimak':
                    return {...state, aimak: action.payload};
                case 'soum':
                    return {...state, soum: action.payload};
                case 'phone':
                    return {...state, phone: action.payload};
                case 'email':
                    return {...state, email: action.payload};
                case 'web':
                    return {...state, web: action.payload};
                case 'social':
                    return {...state, social: action.payload};
                case 'zip_code':
                    return {...state, zip_code: action.payload};


                case 'unit_weight':
                    return {...state, unit_weight: action.payload};
                case 'package_quantity':
                    return {...state, package_quantity: action.payload};
                case 'pallet_quantity':
                    return {...state, pallet_quantity: action.payload};
                case 'exporting_country':
                    return {...state, exporting_country: action.payload};

                default:
                    return state;
            }
        }
        default:
            return state
    }
}


const product8sReducerInitialState = {
    products: [],
    meta: {}
};

const product8sReducer = (state = product8sReducerInitialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_ALL_PRODUCT8S_REQUEST:
            return Object.assign({}, state, {
                products: [],
                meta: {}
            });

        case ActionTypes.LOAD_ALL_PRODUCT8S_SUCCESS:
            return Object.assign({}, state, {
                products: action.products,
                meta: action.meta
            });

        default:
            return state
    }
}


const product8InitialState = {}

function product8Reducer(state = product8InitialState, action) {
    switch (action.type) {
        case ActionTypes.GET_PRODUCT8_SUCCESS: {
            state = initialState;
            return {
                ...state,
                ...action.product
            }
        }
        case ActionTypes.TEMP_PRODUCT_CHANGE: {

            switch (action.f) {
                case 'barcode':
                    return {...state, barcode: action.payload};
                case 'name':
                    return {...state, name: action.payload};
                case 'storage':
                    return {...state, storage: action.payload};
                case 'handling':
                    return {...state, handling: action.payload};
                case 'characteristics':
                    return {...state, characteristics: action.payload};
                case 'constituent':
                    return {...state, constituent: action.payload};
                case 'instruction':
                    return {...state, instruction: action.payload};
                case 'main':
                    return {...state, main: action.payload};
                case 'sub':
                    return {...state, sub: action.payload};
                case 'unit':
                    return {...state, unit: action.payload};
                case 'packaging':
                    return {...state, packaging: action.payload};
                case 'sender_name':
                    return {...state, sender_name: action.payload};
                case 'sender_position':
                    return {...state, sender_position: action.payload};
                case 'sender_phone':
                    return {...state, sender_phone: action.payload};
                case 'sender_email':
                    return {...state, sender_email: action.payload};

                case 'location':
                    return {...state, location: action.payload};
                case 'location_type':
                    return {...state, location_type: action.payload};
                case 'state':
                    return {...state, state: action.payload};
                case 'aimak':
                    return {...state, aimak: action.payload};
                case 'soum':
                    return {...state, soum: action.payload};
                case 'phone':
                    return {...state, phone: action.payload};
                case 'email':
                    return {...state, email: action.payload};
                case 'web':
                    return {...state, web: action.payload};
                case 'social':
                    return {...state, social: action.payload};
                case 'zip_code':
                    return {...state, zip_code: action.payload};


                default:
                    return state;
            }
        }
        default:
            return state
    }
}

const rootReducer = combineReducers({
    auth,
    companies: companiesReducer,
    //gcpPage: gcpPageReducer,
//    search: searchReducer,
    //gcps: gcpsReducer,
    products: productsReducer,
    productCatClick: productCatClickReducer,
    sectors: sectorsReducer,
    sectorsNoAuth: sectorsNoAuthReducer,
    classifs: classifsReducer,
    error: errorReducer,
    ui: uiReducer,
    fees: feesReducer,
    applications: applicationsReducer,
    barcodes: barcodesReducer,
    payments: paymentsReducer,
    invoices: invoicesReducer,
    allInvoices: allInvoicesReducer,
    glns: glnReducer,
    tempProduct: tempProductReducer,
//    tab:tabReducer,
    aimaks: aimaksReducer,
    dashboard: dashboardReducer,
    meinfo,
    product8s: product8sReducer,
    product8: product8Reducer,
    companySearch:companySearchReducer
})

export default rootReducer