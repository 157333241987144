/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {fetchCountries, fetchPackagingUnits, fetchVerifyProducts, verifyProduct} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, Page} from "./Constants";
import history from "./history";
import {toast} from "react-toastify";

dayjs.extend(dayjsPluginUTC)


const initialState = {
    products: [],
    meta: {totalPages: 0},
    product: {},
    loading: false,
    units: [],
    packaging_unit: "",
    packaging: "",
    brand_name: "",
    selectedCountries: [],
    countries: []
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_PACKAGING_UNITS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.LOAD_COUNTRIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                countries: action.countries
            }
        }
        case ActionTypes.LOAD_PACKAGING_UNITS_SUCCESS: {
            return {
                ...state,
                units: action.units,
                loading: false
            }
        }
        case ActionTypes.VERIFY_PRODUCT_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.VERIFY_PRODUCT_SUCCESS: {
            let pr = action.product;
            let products = [...state.products];
            // find the product
            let p = products.find(x=>x.id === pr.id);
            p.hq_verified = pr.hq_verified;
            return {
                ...state,
                loading: false,
                products:products
            }
        }

        case ActionTypes.LOAD_VERIFY_PRODUCTS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.LOAD_VERIFY_PRODUCTS_SUCCESS: {
            let arr = action.products;
            for (let el of arr) {
                if (!el.exp_countries_json) {
                    el.exp = null;
                } else {
                    let ret = "";
                    el.exp_countries_json.map(el => {
                        const c = state.countries.find(e => e.id === el)
                        if (c) {
                            ret += c.name;
                            ret += "/" + c.id;
                            ret += ", ";
                        }
                    })
                    if (ret !== "") {
                        ret = ret.replace(/,\s*$/, "");

                    }
                    el.exp = ret
                }
            }

            return {
                ...state,
                products: arr, //action.products,
                meta: action.meta,
                loading: false
            }
        }
        case "REMOVE_IMAGE": {

            let products1  = [...state.products];
            let p1 = products1.find(x=>x.id === action.p.id);

            //let p1 = products1[pIndex];
            //let p1 = {...p};
            //let p = {...state.product}
            p1.nullify_image_link = true;
            p1.image_link = null;
            //products1[pIndex] = p1;

            //let p = action.product;
            // let products1  = [...state.products];
            // let pIndex = products1.findIndex(x=>x.id === action.p.id);
            //
            // let p1 = products1[pIndex];
            // //let p1 = {...p};
            // //let p = {...state.product}
            // p1.nullify_image_link = true;
            // p1.image_link = null;
            // products1[pIndex] = p1;

            return {
                ...state,
                products: products1
            }
        }

        default:
            return state;
    }
};

const removeImage = (p) => {
    return {
        type: 'REMOVE_IMAGE',
        p
    }
}


const ProductsEditVerifyAllPage = ({
                                       location: {search}
                                   }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {products, loading, meta} = state;

    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [isHq, setIsHq] = useState(false);


    let parsed = qs.parse(search);

    let qhq = parsed["hq"];
    let qfrom = parsed["fr"];
    let qto = parsed["to"];

    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];
    let orderField = parsed[Page.ORDER];
    let isAsc = parsed[Page.ASC];

    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "15";
    }
    if (orderField === undefined) {
        orderField = "id";
    }
    if (isAsc === undefined) {
        isAsc = "true";
    }
    if (qhq === undefined) {
        qhq = false;
    }
    if (qfrom === undefined) {
        qfrom = null;
    }
    if (qto === undefined) {
        qto = null;
    }

    useEffect(() => {
        fetchCountries(dispatch);
    }, []);

    useEffect(() => {
        fetchPackagingUnits(dispatch)
    }, []);

    useEffect(() => {
        console.log('useEffect called');
        if (qhq === 'true') {
            setIsHq(true);
        } else {
            setIsHq(false);
        }

        fetchVerifyProducts(
            dispatch,
            qhq, qfrom, qto,
            page, pagesize, orderField, isAsc);
    }, [qhq, qfrom, qto, page, pagesize, orderField, isAsc]);


    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };
    const handleChangeFrom = (e) => {
        setFrom(e.target.value);
    };
    const handleChangeTo = (e) => {
        setTo(e.target.value);
    };
    const handleChangeIsHq = (e) => {
        setIsHq(e.target.checked)
    };

    const handleSearch = (e) => {
        e.preventDefault();
        let parsed = qs.parse(search);
        parsed["hq"] = isHq;
        parsed["fr"] = from;
        parsed["to"] = to;
        parsed[Page.PAGE] = "0";
        history.push("?" + qs.stringify(parsed))
    };



    const checkGtin = (sNumber) => {
        let output = []
        // separate numbers
        for (let i = 0, len = sNumber.length; i < len; i += 1) {
            output.push(+sNumber.charAt(i));
        }

        // gtin 13
        if (output.length === 13) {
            let sum = 0;
            for (let i = 0; i < output.length - 1; i++) {
                if (i % 2 === 0) {
                    sum += output[i] * 1
                } else {
                    sum += output[i] * 3
                }
            }
            //
            let ceil = Math.ceil(sum / 10) * 10;
            let check = ceil - sum;
            return check === output[12];
        } else {
            return false;
        }
    }

    const getCtrlNmbr = (sNumber) => {
        let output = []
        // separate numbers
        for (let i = 0, len = sNumber.length; i < len; i += 1) {
            output.push(+sNumber.charAt(i));
        }

        // gtin 13
        if (output.length === 13) {
            let sum = 0;
            for (let i = 0; i < output.length - 1; i++) {
                if (i % 2 === 0) {
                    sum += output[i] * 1
                } else {
                    sum += output[i] * 3
                }
            }
            //
            let ceil = Math.ceil(sum / 10) * 10;
            let check = ceil - sum;
            return check;
        } else {
            return '?';
        }
    }
    const isNumeric = (str) => {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const handleVerify = (e, product) => {

        let dto = {}
        if (product.nullify_image_link) {
            dto.nullify_image_link = true;
        } else {
            dto.nullify_image_link = false;
        }
        verifyProduct(dispatch, product.id, dto).then(response => {
            toast.success("Success"); //history.push(`/products`)
        })
    }


    const handleRemoveImage = (e, product) => {

        dispatch(removeImage(product))
    }

    let counter1 = Number(page) * Number(pagesize)

    const appts3 = products.map((p, i) => {
        counter1++;
        return (
            <div className="m-3">
                <table key={p.id}
                       className={`table-fixed w-full text-sm text-left text-gray-600 dark:text-gray-500 border-2 ${p.hq_verified ? "border-green-500" : "border-gray-500"}`}>
                    <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row"
                            className="w-1/2 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">License
                        </th>
                        <td className="w-1/2 px-6 py-4">{p.license}</td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">GTIN
                        </th>
                        <td className="w-1/2 px-6 py-4">
                            <div className={"relative"}>
                                <div className={"flex absolute inset-y-0 left-0 items-center pointer-events-none"}>
                                    {checkGtin(p.barcode) ? <div className={"w-2 p-2 bg-green-500"}></div> :
                                        <span
                                            className={"inline-block align-middle w-6 p-2 h-fit text-gray-200 bg-red-500  "}>   {getCtrlNmbr(p.barcode)}</span>}
                                </div>
                                <div className={`pl-5`}>{p.barcode}</div>
                            </div>
                        </td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">Brand
                            Name
                        </th>
                        <td className="w-1/2 px-6 py-4">{p.brand_name}</td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">Name
                        </th>
                        <td className="w-1/2 px-6 py-4">{p.name}</td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">Савлалт
                        </th>
                        <td className="w-1/2 px-6 py-4">
                            <div className={"relative"}>
                                <div className={"flex absolute inset-y-0 left-0 items-center  pointer-events-none"}>
                                    {isNumeric(p.packaging) ? <div className={"w-2 p-2 bg-green-500"}></div> :
                                        <div className={"w-2 p-2 bg-red-500 h-2 "}></div>}
                                </div>

                            <div className={`pl-5`}>{p.packaging} {p.packaging_unit}</div>
                            </div>
                        </td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">Бүтээгдэхүүний
                            зураг байршсан линк
                        </th>
                        <td className="w-1/2 px-6 py-4">

                            {p.image_link &&
                                <div>
                                    <span className="p-2">{p.image_link}</span>

                                    <button onClick={(e) => handleRemoveImage(e,p)}
                                            className="w-32 bg-red-300  p-1 border border-gray-500">Устгах
                                    </button>
                                </div>
                            }
                        </td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">Тухайн
                            бараа бүтээгдэхүүн борлуулж байгаа улс
                        </th>
                        <td className="w-1/2 px-6 py-4">{p.exp}</td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">НЭГЖ
                            АНГИЛАЛ
                        </th>
                        <td className="w-1/2 px-6 py-4">{p.unit_name} - [{p.unit_code}]</td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Created [Modified]
                        </th>
                        <td className="w-1/2 px-6 py-4">{dayjs(p.created).format("YYYY/MM/DD HH:mm")}  [{dayjs(p.modified).format("YYYY/MM/DD HH:mm")}]</td>
                    </tr>
                    {p.hq_verified &&
                        <tr className="border-b border-gray-200 dark:border-gray-700">
                            <th scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                                Verified
                            </th>
                            <td className="w-1/2 px-6 py-4">{dayjs(p.hq_verified).format("YYYY/MM/DD HH:mm")}</td>
                        </tr>
                    }
                    </tbody>
                </table>
                {!p.hq_verified && <div className="flex flex-row-reverse">
                    <button onClick={(e) => handleVerify(e,p)} className="button bg-green-500">
                        <span style={{color: "white"}}>Verify</span>

                    </button>

                    <Link to={`/product/${p.id}/edit`} className="button bg-gray-500">
                        <span className="text-white">Өөрчлөх</span>
                    </Link>
                </div>
                }
            </div>
        )
    });

    return (

        <div className="inner-container">

            <ProductsSearchPanel
                sHq={isHq} from={from} to={to}
                onChangeHq={handleChangeIsHq}
                onChangeFrom={handleChangeFrom}
                onChangeTo={handleChangeTo}
                onClickSearch={handleSearch}
            />
            <br/>

            <div>
                <h5 style={{marginBottom: "10px"}}><strong>Нийт: {meta.totalElements}</strong>
                </h5>

                <nav className="pagination">
                    <ReactPaginate previousLabel={"Өмнөх"}
                                   nextLabel={"Дараах"}
                                   initialPage={Number(page)}
                                   breakLabel={"..."}
                                   breakClassName={"pagination-ellipsis"}
                                   pageCount={meta.totalPages}
                                   marginPagesDisplayed={2}
                                   pageRangeDisplayed={5}
                                   activeLinkClassName={"is-current"}
                                   pageLinkClassName={"pagination-link"}
                                   onPageChange={(e) => handlePageClick(e)}
                                   disableInitialCallback={true}
                                   previousClassName={"pagination-previous"}
                                   nextClassName={"pagination-next"}
                                   containerClassName={"pagination-list"}
                                   forcePage={Number(page)}

                    />
                </nav>


            </div>
            <br/>
            <div className="p-3">
                {appts3}
            </div>
            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               disableInitialCallback={true}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>
            <br/><br/>
        </div>
    )

}


const ProductsSearchPanel = ({
                                 isHq, from, to,
                                 onChangeHq,
                                 onChangeFrom,
                                 onChangeTo,
                                 onClickSearch,
                             }) => {

    return (
        <div>

            <div className="field">
                <label className="label">Эхлэх хугацаа YYYY/MM/DD</label>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={from} style={{width: '400px'}}
                               onChange={onChangeFrom} placeholder="Эхлэх хугацаа Оруулах"/>
                    </div>
                </div>
                <label className="label">Дуусах хугацаа YYYY/MM/DD</label>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={to} style={{width: '400px'}}
                               onChange={onChangeTo} placeholder="Дуусах хугацаа Оруулах"/>
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">Hq</label>
                <div className="control has-icons-left">
                    <input className="checkbox" type="checkbox" checked={isHq}
                           onChange={onChangeHq}/>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">

                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control buttons">

                            <button onClick={onClickSearch} className="button bg-orange">
                                <span style={{color: "white"}}>Хайх</span>

                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProductsEditVerifyAllPage

