/**
 * Created by gantushig on 11/6/15.
 */

import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import classnames from "classnames";
import ContactEditMenu from "./ContactEditMenu"
import CompanyViewHeader from "./CompanyViewHeader";
import {ActionTypes, Endpoints, TOKEN} from "./Constants";
import history from "./history";
import axios from "axios";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import {toast} from "react-toastify";



const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    companyName:"",
    contacts: [],
    loading: false,
    refresh:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_CONTACTS_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_CONTACTS_SUCCESS':
            return {
                ...state,
                contacts: action.contacts,
                loading: false
            }
        case ActionTypes.DELETE_CONTACT_REQUEST:{
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.DELETE_CONTACT_SUCCESS:{
            return {
                ...state,
                loading: false
            }
        }
        case 'REFRESH':
            return {
                ...state,
                refresh: !state.refresh
            }
        default:
            return state;
    }
};

const fetchContactsRequest = () => {
    return {
        type: 'FETCH_CONTACTS_REQUEST'
    }
};

const fetchContactsSuccess = (status, contacts) => {
    return {
        type: 'FETCH_CONTACTS_SUCCESS',
        status,
        contacts
    }
};

const fetchContacts = (dispatch, token, companyId) => {

    dispatch(fetchContactsRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.COMPANY}/${companyId}/contacts`;

    return axios(config)
        .then(response => {

            dispatch(fetchContactsSuccess(response.data.status, response.data.contacts))
        })
        .catch(error => {

            throw(error);

        });
}


const removeContactRequest = () => {
    return {
        type: ActionTypes.DELETE_CONTACT_REQUEST
    }
};

const removeContactSuccess = (contact) => {
    return {
        type: ActionTypes.DELETE_CONTACT_SUCCESS,
        contact
    }
};

const removeContact = (dispatch, token, contactId) => {

    return new Promise(function (resolve, reject) {
        dispatch(removeContactRequest())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.CONTACT}/${contactId}`;
        config["method"] = "delete";

        axios(config)
            .then(response => {

                dispatch(removeContactSuccess(response.data.contact))
                resolve(response);
            })
            .catch(error => {
                reject(error);
                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
            });
    });
};


const refreshPage = () => {
    return {
        type: "REFRESH",
    }
}

const CompanyContacts = ({match: {params: {companyId}}}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currContactId, setCurrContactId] = useState(0);


    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        contacts,
        loading,
        refresh,
        companyName
    } = state;

    let token = localStorage.getItem(TOKEN) || null;
    useEffect(() => {
        console.log('useEffect called');
        fetchContacts(dispatch, token, companyId);
    }, [companyId, refresh]);

    if (contacts === null || contacts === undefined) {
        return (
            <div>
                No Items
            </div>
        )
    }
    ;
    let modalClass = classnames({
        modal: true,
        'is-active': isOpen
    });

    const handleEdit = (e, lid) => {
        e.preventDefault();
        console.log('edit');
        history.push(`/company/${companyId}/contact/${lid}`)
    };
    const handleDelete = (e, lid) => {
        e.preventDefault();
        setIsOpen(true)
        setCurrContactId(lid)
    }

    const handleYes = () => {
        setIsOpen(false)
        removeContact(dispatch, token, currContactId).then(response => {
                toast.success("Амжилттай !");
                dispatch(refreshPage());
            }
        ).catch(error => {
            alert(error)
        });
    }
    const handleCancel = () => {
        setIsOpen(false)
    }

    const genderLabel = (g) => {
        let ret = ""
        if (g === "MALE") {
            ret = "Ноён"
        } else if ( g === "FEMALE") {
            ret = "Хатагтай"
        }

        return ret
    }
    const positionTypeLabel = (p) => {
        let ret = ""
        if (p.position_type === "CEO") {
            ret = "Захирал"
        } else if ( p.position_type === "GS1") {
            ret = "Код хариуцсан ажилтан"
        } else if ( p.position_type === "ACC") {
            ret = "Нябо"
        }

        if (p.position!==null && p.position !== "") {
            ret += " / " + p.position;
        }
        return ret
    }

    const items = contacts.map(
        (p) => {
            return (
                <tr key={p.id} style={{marginBottom:'10px'}}>
                    <td>{p.counter}</td>
                    <td>{genderLabel(p.gender)} {p.surname} {p.name}</td>
                    <td>{positionTypeLabel(p)}</td>
                    <td>{p.phone}</td>
                    <td> {p.email}</td>
                    <td>
                        <ContactEditMenu id={p.id} onEdit={handleEdit}  onDelete={handleDelete}/>
                    </td>
                </tr>
            )
        });

    return (
        <div className="inner-container">

            <span className = 'block text-2xl mb-10 font-bold text-black'>{companyName}</span>
            <CompanyViewHeader tab={1} companyId={companyId}/>

            <div className="buttons is-right">
                <Link to={`/company/${companyId}/contact-create`} className="button">
                    Үүсгэх
                </Link>
            </div>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Нэр</th>
                    <th>Албан Тушаал</th>
                    <th>Утас</th>
                    <th>Мэйл</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>


            <div className={modalClass}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Устгах?</p>
                        <button className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        Устгахдаа итгэлтэй байна уу?
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={handleYes}>Тийм</button>
                        <button className="button" onClick={handleCancel}>Үгүй</button>
                    </footer>
                </div>
            </div>
        </div>
    )

}


export default CompanyContacts;


