/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer, useState} from "react";
import {Link, useLocation, useParams, useRouteMatch} from "react-router-dom";
import dayjs from "dayjs";
import classnames from "classnames";
import {fetchGcp, removeGcp, verifyGcp} from "./actions/actions";
import {TOKEN} from "./Constants";
import {css} from "@emotion/react";
import dayjsPluginUTC from "dayjs-plugin-utc";
import {confirm} from "react-confirm-box";
import {toast} from "react-toastify";
import history from "./history";
import gcpStatusData from "./data/gcp-status.json"

dayjs.extend(dayjsPluginUTC)


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    gcp: {},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_GCP_REQUEST':
        case 'VERIFY_GCP_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_GCP_SUCCESS':
        case 'VERIFY_GCP_SUCCESS':
            return {
                ...state,
                gcp: action.gcp,
                loading: false
            }
        default:
            return state;
    }
};

const GcpVerify = () => {

    const { gcpId} = useParams();
    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        gcp,
        loading
    } = state;

    useEffect(() => {
        console.log('useEffect called');
        fetchGcp(dispatch, token, gcpId);
    }, [gcpId]);


    const optionsWithLabelChange = {
        closeOnOverlayClick: false,
        labels: {
            confirmable: "Устгах",
            cancellable: "Болих"
        }
    };
    const handleVerifyGcp = async () => {
        verifyGcp(dispatch, gcpId).then(response => {
                toast.success("Амжилттай !");
                //history.push(`/g/${companyId}/gcps`)
            }
        ).catch(error => {
            alert(error)
        });

        // const result = await confirm("Gcp устгах уу?", optionsWithLabelChange);
        // if (result) {
        //     console.log("You click yes delete Gcp!");
        // }
        // console.log("You click No!");
    };

    const barcodeTypeLabel = (g) => {
        let ret = ""
        if (g === 0) {
            ret = "GTIN"
        } else if ( g === 1) {
            ret = "GLN"
        } else if ( g === 2) {
            ret = "SSCC"
        }

        return ret
    }

    const statusLabel = (g) => {
        let ret = ""
        let ra = gcpStatusData.filter(b=>b.id === g);
        if (ra.length>0) {
            ret = ra[0].name;
        }
        return ret
    };


    return (
        <>
            <div className="buttons has-addons is-right">
            <Link to={`/gcp-edit/${gcpId}`} className="button">
                Өөрчлөх
            </Link>

            <a className="button" onClick={()=>handleVerifyGcp()}>
                Verify
            </a>
            </div>

            <table className="table is-bordered is-fullwidth">
                <tbody>
                <tr>
                    <th className="company-title">Баркод Төрөл</th>
                    <td className="company-value">{gcp && barcodeTypeLabel(gcp.barcode_type)}</td>
                </tr>
                <tr>
                    <th className="company-title">GCP</th>
                    <td className="company-value">{gcp && gcp.prefix}</td>
                </tr>
                <tr>
                    <th className="company-title">GCP Төрөл</th>
                    <td className="company-value">GCP{gcp && gcp.size}</td>
                </tr>
                <tr>
                    <th>Олгосон огноо</th>
                    <td>{gcp && gcp.issued && dayjs.utc(gcp.issued).format("MM/DD/YYYY")}</td>
                </tr>
                <tr>
                    <th>Төлөв</th>
                    <td>{gcp && statusLabel(gcp.status)}</td>
                </tr>

                </tbody>
            </table>
            {gcp.hq_verified &&
                <div className="border-2 border-green-300 p-2 w-64 m-2">
                    Verified: {dayjs(gcp.hq_verified).format("MM/DD/YYYY HH:mm")}
                </div>
            }
        </>
    )
}

export default GcpVerify;