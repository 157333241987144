/**
 * Created by gantushig on 4/16/17.
 */


import React, {Component, useEffect, useState} from "react";
import {connect, useSelector, useDispatch} from "react-redux";
import { loginUserExpress} from "./actions/actions";
import logo from "./images/logo.png"
import history from "./history"
import './tailwind.output.css';

const Waiting = () => {

    return (
        <div className="w-full">
            <div className="flex items-center  w-full p-0 bg-gray-300 h-28">
                <a href="/">
                    <img alt="GS1 logo" className="block p-4"
                         src={logo}/>
                </a>
                <p className="block">
                    <strong className="font-bold">
                        Welcome to GS1 Mongolia
                    </strong>
                    <br/>
                    The Global Language of Business</p>
            </div>
            <div className="flex  justify-center">
                WAITING....
            </div>

            <p className="text-center text-gray-500 text-xs">
                &copy;2020 GS1 Mongolia. All rights reserved.
            </p>
        </div>
    )
    //}
}




export default Waiting;