/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";
import {WithContext as ReactTags} from "react-tag-input";



const ProductEditComponentGtin = ({
                                      packaging_units, onChangePackagingUnit, product,
                                      onChangeField, disabled,
                                      countriesData, selectedCountries, onCountryDelete, onCountryAdd
                                  }) => {

    const KeyCodes = {
        comma: 188,
        enter: [10, 13],
    };

    const delimiters = [...KeyCodes.enter, KeyCodes.comma];



    return (
        <>
            <div className="field">
                <label className="label">Брэнд Нэр</label>
                <div className="control">
                    <input name="brand_name" className="input" type="text" value={product.brand_name}
                           onChange={onChangeField} placeholder="Брэнд Нэр оруулах" disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Савлалтын хэмжээ</label>
                <div className="control ">
                    <input name="packaging" className="input" type="number" value={product.packaging}
                              onChange={onChangeField} placeholder="Савлалтын хэмжээ оруулах" disabled={disabled}/>
                </div>

            </div>

            <div className="field">
                <label className="label">Савлалтын хэмжих нэгж</label>
                <div className="select">
                    <select name="packaging_unit" value={product.packaging_unit} disabled={disabled}
                            onChange={onChangePackagingUnit}>
                        <option value="0"></option>
                        {packaging_units.map(unit => {
                            return (
                                <option key={unit.code}
                                        value={unit.code}>{unit.name_mn} - {unit.code} ( {unit.name} )</option>
                            )
                        })
                        }
                    </select>
                </div>
            </div>


            <div className="field">
                <label className="label">Орц найрлага</label>
                <div className="control">
                    <textarea name="constituent" className="textarea"  value={product.constituent}
                              onChange={onChangeField} placeholder="Орц найрлага оруулах" disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Шинж чанар</label>
                <div className="control">
                <textarea name="characteristics" className="textarea"  value={product.characteristics}
                          onChange={onChangeField} placeholder="Шинж чанар оруулах" disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Зориулалт</label>
                <div className="control">
                <textarea name="handling" className="textarea"  value={product.handling}
                          onChange={onChangeField} placeholder="Зориулалт оруулах" disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Хэрэглэх заавар</label>
                <div className="control">
                <textarea name="instruction" className="textarea"  value={product.instruction}
                          onChange={onChangeField} placeholder="Хэрэглэх заавар оруулах" disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Хадгалах нөхцөл/хугацаа</label>
                <div className="control">
                <textarea name="storage" className="textarea"  value={product.storage}
                          onChange={onChangeField} placeholder="Хадгалах нөхцөл оруулах" disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Аль улсаас гарал үүсэлтэй /</label>
                <div className="control">
                    <input className="input" name="coo" type="text" value={product.coo}
                           onChange={onChangeField} placeholder="Аль улсаас гарал үүсэлтэй оруулах"
                           disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Бүтээгдэхүүний зураг байршсан линк</label>
                <div className="control">
                    <input name="image_link" className="input" type="text" value={product.image_link}
                           onChange={onChangeField} placeholder="Бүтээгдэхүүний зураг байршсан линк оруулах"
                           disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Тухайн бараа бүтээгдэхүүн борлуулж байгаа улс /member/</label>
                <div className="control">
                    <input name="exp_countries_member" className="input" type="text" value={product.exp_countries_member}
                           onChange={onChangeField} placeholder="Тухайн бараа бүтээгдэхүүн борлуулж байгаа улс оруулах"
                           disabled={disabled}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Тухайн бараа бүтээгдэхүүн борлуулж байгаа улс /office/</label>
                <ReactTags
                    classNames={{
                        tags: '',
                        tagInput: 'border text-white',
                        tagInputField: 'border bg-white text-black p-2 w-1/2',
                        selected: 'border bg-grey-500 p-2',
                        tag: 'border bg-gray-500 p-2 text-white p-2',
                        remove: ' p-2',
                        suggestions: 'border  bg-gray-200 p-2 text-gray-800 ',
                        activeSuggestion: 'border bg-gray-600 p-2 text-white'
                    }}
                    tags={selectedCountries}
                    suggestions={countriesData}
                    placeholder='Улсын нэрээ сонгоод Enter дарна уу!'
                    labelField={'name'}
                    handleDelete={onCountryDelete}
                    handleAddition={onCountryAdd}
                    delimiters={delimiters}/>
            </div>

        </>
    )

}

export default ProductEditComponentGtin

