/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import React, {Component, useEffect, useReducer, useState} from "react";
import {
    deleteHQGcps,
    deleteHQProducts,
    fetchAllProducts,
    fetchClassifs, menuClicked,
    printAllProducts, updateProductMulti,
} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, Menu, Page, PageTitle, TOKEN} from "./Constants";
import history from "./history";
import {confirm} from "react-confirm-box";
import {toast} from "react-toastify";

dayjs.extend(dayjsPluginUTC)



const initialState = {
    gtins: [],
    selections: new Map(),
    selectionsArr: [],
    batch:null
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.DELETE_HQ_PRODUCTS_REQUEST:{
            return {
                ...state,
                loading:true,
                batch: null
            }
        }
        case ActionTypes.DELETE_HQ_PRODUCTS_SUCCESS:{
            return {
                ...state,
                selectionsArr: [],
                gtins:[],
                loading:false,
                batch: action.batch
            }
        }
        case "ADD_ITEMS": {
            let g = [...state.gtins]
            let c = g.concat(action.items);
            var d = c.filter((item, pos) => c.indexOf(item) === pos)

            return {
                ...state,
                gtins: d,
                selectionsArr: []
            }
        }
        case "REMOVE_ITEMS": {
            let g = [...state.gtins]

            let toDel = [...state.selectionsArr]
            let toDelSet = new Set(toDel);
            const d = g.filter((name) => {
                // return those elements not in the namesToDeleteSet
                return !toDelSet.has(name);
            });

            return {
                ...state,
                gtins: d
            }
        }
        case "SELECT_HEADER": {
            // let n2 = new Map()
            // if (state.selections.size === 0) {
            //     state.products.map(p => {
            //         n2.set(p.id, p.id)
            //     })
            // }

            let n3 = []
            if (action.checked) {
                //if (state.selectionsArr.length === 0) {
                    state.gtins.map(p => {
                        n3.push(p)
                    })
                //}
            }
            return {
                ...state,
                selectionsArr: n3
            }
        }
        case "SELECT_ITEM": {

            let g = [...state.selectionsArr];
            let ind = g.indexOf(action.id)
            if ( ind >= 0 ) {
                g.splice(ind, 1);
            } else {
                //let c = g.concat(action.id);
                g.push(action.id)
//                let d = c.filter((item, pos) => c.indexOf(item) === pos)
            }

            // return {
            //     ...state,
            //     gtins: g
            // }
            // let n1 = new Map(state.selections);
            // if (n1.has(action.id)) {
            //     n1.delete(action.id)
            // } else {
            //     n1.set(action.id, action.id)
            // }
            return {
                ...state,
                selectionsArr: g
            }
        }
        default:
            return state;
    }
};



const addItems =(items) => {
    return {
        type: 'ADD_ITEMS',
        items
    }
}

const removeItems =() => {
    return {
        type: 'REMOVE_ITEMS'
    }
}

const selectItem =(id) => {
    return {
        type: 'SELECT_ITEM',
        id
    }
}

const selectHeader =(checked) => {
    return {
        type: 'SELECT_HEADER',
        checked
    }
}

const HqGtinDeletePage = () => {
    const [barcodes, setBarcodes] = useState(null);

    const [state, dispatch] = useReducer(reducer, initialState);
    const {gtins, selections, selectionsArr, batch} = state;

    useEffect(() => {
        console.log('useEffect called');
    }, []);

    const isItemSelected = id => selectionsArr.includes(id);



    // let counter = 0;
    // for (let key in gtins) {
    //     let item = gtins[key];
    //     counter++;
    //     item.counter = counter;
    // }

    const handleChangeBarcodes = (e) => {
        setBarcodes(e.target.value);
    };


    const handleAdd = (e) => {
        e.preventDefault();
        let n = barcodes.split("\n");
        let n2 = []
        for (let k of n ) {
            let a = k.trim()
            if ( a !== "") {
                n2.push(a)
            }
        }
        dispatch(addItems(n2))
        setBarcodes("")
    };

    const handleRemove = (e) => {
        e.preventDefault();
        dispatch(removeItems())
    };

    const handleSelect = (id) => {
        dispatch(selectItem(id))
    }

    const handleSelectHeader = (e) => {

        dispatch(selectHeader(e.target.checked))
    }

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    const optionsWithLabelChange = {
        closeOnOverlayClick: false,
        labels: {
            confirmable: "Устгах",
            cancellable: "Болих"
        }
    };
    const handleHQSend =  async () => {
        for (let a of gtins ) {
            if (!isNumeric(a)) {
                alert("check");
                return;
            }
        }
        let dto = {}
        dto.to_delete_list = gtins;
        const result = await confirm("Gtin устгахаар илгээх үү?", optionsWithLabelChange);
        if (result) {
            deleteHQProducts(
                dispatch,
                dto).then(response => {
                toast.success("Success"); //history.push(`/products`)
            });

            console.log("You click yes delete Gcp!");
        } else {
            console.log("You click No!");
        }

    }



    const appts2 = gtins.map((p,index) => {
        return (
            <tr key={index}>

                <td>
                    <input className="checkbox" type="checkbox" checked={isItemSelected(p)}
                           onChange={() => handleSelect(p)}/>
                </td>
                <td>{index+1}</td>
                <td>{p}</td>
            </tr>
        )
    });

    return (

        <div className="inner-container">

            <ProductsSearchPanel
                barcodes={barcodes}
                onChangeBarcodes={handleChangeBarcodes}
                onClickAdd={handleAdd}
                onClickRemove={handleRemove}
            />

            <br/>
            <div>
                <h5 style={{marginBottom: "10px"}}><strong>Нийт: {gtins.length}</strong>
                </h5>

            </div>
            <div>
                <span>{batch && <div>Batch Id: {batch}</div>}</span>

            </div>
            <br/>
            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th><input className="checkbox" type="checkbox"
                               onChange={(e) => handleSelectHeader(e)}/></th>
                    <th>#</th>
                    <th>Зур.код</th>
                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>

            <button onClick={handleHQSend} className="button bg-orange">
                <span style={{color: "white"}}>HQ Send </span>

            </button>
            <br/><br/>
        </div>
    )

}


const ProductsSearchPanel = ({
                                 barcodes,
                                 onChangeBarcodes,
                                 onClickAdd,
                                 onClickRemove
                             }) => {

    return (
        <div>

            <div className="field">
                <label className="label">Barcodes</label>
                <div className="field-body ">
                    <div className="control ">
                        <textarea className="input" value={barcodes} style={{width: '400px'}}
                               onChange={onChangeBarcodes} placeholder="Оруулах"/>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label">

                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control buttons">

                            <button onClick={onClickAdd} className="button bg-orange">
                                <span style={{color: "white"}}>Add</span>

                            </button>
                            <button onClick={onClickRemove} className="button bg-red-500">
                                <span style={{color: "white"}}>Remove</span>

                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HqGtinDeletePage

