/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {connect} from "react-redux";
import React, {Component, useEffect, useState} from "react";
import {createClassif, fetchClassifs, printAllProducts, printClassifs, updateClassif} from "./actions/actions";
import {createBrowserHistory} from "history";


const ProductClassificationConfig = ({token, mainItems, subItems, unitItems, loadClassifs, add, edit, print, main_ids_by_order, sub_ids_by_order, unit_ids_by_order}) => {
    const [main, setMain] = useState("0");
    const [sub, setSub] = useState("0");
    const [unit, setUnit] = useState("0");

    const [nameMain, setNameMain] = useState("");
    const [nameSub, setNameSub] = useState("");
    const [nameUnit, setNameUnit] = useState("");
    const [codeMain, setCodeMain] = useState("");
    const [codeSub, setCodeSub] = useState("");
    const [codeUnit, setCodeUnit] = useState("");

    useEffect(() => {
        console.log('useEffect called');
        loadClassifs(token, "main", 0)
    }, []);


    const handleChangeMain = (e) => {
        setMain(e.target.value);
        loadClassifs(token, "sub", e.target.value)
        setSub("0")
        setUnit("0")
        setNameSub("")
        setNameUnit("")
        setCodeSub("")
        setCodeUnit("")
        // empty unit
        if (e.target.value === "0") {
            setNameMain("")
            setCodeMain("")
        } else {
            setNameMain(mainItems[e.target.value].name)
            setCodeMain(mainItems[e.target.value].code)
        }

    };

    const handleExcel = (e) => {
        e.preventDefault();
        print()
    };

    const handleChangeSub = (e) => {
        setSub(e.target.value)
        loadClassifs(token, "unit", e.target.value)
        setUnit("0")
        setNameUnit("")
        setCodeUnit("")
        if (e.target.value === "0") {
            setNameSub("")
            setCodeSub("")
        } else {
            setNameSub(subItems[e.target.value].name)
            setCodeSub(subItems[e.target.value].code)
        }
    };
    const handleChangeUnit = (e) => {
        setUnit(e.target.value)
        if (e.target.value === "0") {
            setNameUnit("")
            setCodeUnit("")
        } else {
            setNameUnit(unitItems[e.target.value].name)
            setCodeUnit(unitItems[e.target.value].code)
        }
    };

    const handleChangeNameMain = (e) => {
        setNameMain(e.target.value)
    };
    const handleChangeCodeMain = (e) => {
        setCodeMain(e.target.value)
    };

    const handleChangeNameSub = (e) => {
        setNameSub(e.target.value)
    };
    const handleChangeCodeSub = (e) => {
        setCodeSub(e.target.value)
    };

    const handleChangeNameUnit = (e) => {
        setNameUnit(e.target.value)
    };
    const handleChangeCodeUnit = (e) => {
        setCodeUnit(e.target.value)
    };

    const handleSaveMain = () => {
        let dto = {
            name:nameMain,
            code:codeMain
        };
        if (main === "0") {
            add(token, "main", 0, dto)
        } else {
            edit(token, "main", main, dto)
        }

        setNameMain("")
        setCodeMain("")
    };

    const handleSaveSub = () => {
        if (main === "0") {
            return
        }

        let dto = {
            name:nameSub,
            code:codeSub
        };
        if (sub === "0") {
            add(token, "sub", main, dto)
        } else {
            edit(token, "sub", sub, dto)
        }

        setNameSub("")
        setCodeSub("")
    };

    const handleSaveUnit = () => {
        if (sub === "0") {
            return
        }
        let dto = {
            name:nameUnit,
            code:codeUnit
        };
        if (unit === "0") {
            add(token, "unit", sub, dto)

        } else {
            edit(token, "unit", unit, dto)
        }


        setNameUnit("")
        setCodeUnit("")
    };

    const handleCancel = () => {

    };



    return (
        <div style={{background: 'white', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}>
            <div className="field">
                <label className="label">ҮНДСЭН АНГИЛАЛ</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select value={main} onChange={(e) => handleChangeMain(e)}>
                            <option value="0">NEW</option>
                            {main_ids_by_order.map(id => {
                                    return (
                                        <option key={id} value={id}>{mainItems[id].name}-[{mainItems[id].code}]</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="field">
                <div className="control has-icons-left">
                    <input className="input" type="text" value={nameMain}
                           onChange={(e) => handleChangeNameMain(e)} placeholder="ҮНДСЭН АНГИЛАЛ оруулах"/>
                </div>
            </div>
            <div className="field">
                <div className="control has-icons-left">
                    <input className="input" type="text" value={codeMain}
                           onChange={(e) => handleChangeCodeMain(e)} placeholder="ҮНДСЭН АНГИЛАЛ код оруулах"/>
                </div>
            </div>
            <div className="buttons" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                <button className="button is-primary" type="primary" onClick={() => handleSaveMain()}>Хадгалах</button>
                <button className="button is-primary" type="primary" onClick={() => handleCancel()}>Болих</button>
            </div>
            <div className="field">
                <label className="label">ДЭД АНГИЛАЛ</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select value={sub}  onChange={(e) => handleChangeSub(e)}>
                            <option value="0">NEW</option>
                            {main !== "0" && sub_ids_by_order.map(id => {
                                    return (
                                        <option key={id} value={id}>{subItems[id].name}-[{subItems[id].code}]</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="field">
                <div className="control has-icons-left">
                    <input className="input" type="text" value={nameSub}
                           onChange={(e) => handleChangeNameSub(e)} placeholder="ДЭД АНГИЛАЛ оруулах"/>
                </div>
            </div>
            <div className="field">
                <div className="control has-icons-left">
                    <input className="input" type="text" value={codeSub}
                           onChange={(e) => handleChangeCodeSub(e)} placeholder="ДЭД АНГИЛАЛ код оруулах"/>
                </div>
            </div>
            <div className="buttons" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                <button className="button is-primary" type="primary" onClick={() => handleSaveSub()}>Хадгалах</button>
                <button className="button is-primary" type="primary" onClick={() => handleCancel()}>Болих</button>
            </div>

            <div className="field">
                <label className="label">НЭГЖ АНГИЛАЛ</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select value={unit} onChange={(e) => handleChangeUnit(e)}>
                            <option value="0">NEW</option>
                            {sub !== "0" &&
                                unit_ids_by_order.map(id => {
                                    return (
                                        <option key={id} value={id}>{unitItems[id].name}-[{unitItems[id].code}]</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="field">
                <div className="control has-icons-left">
                    <input className="input" type="text" value={nameUnit}
                           onChange={(e) => handleChangeNameUnit(e)} placeholder="НЭГЖ АНГИЛАЛ оруулах"/>
                </div>
            </div>
            <div className="field">
                <div className="control has-icons-left">
                    <input className="input" type="text" value={codeUnit}
                           onChange={(e) => handleChangeCodeUnit(e)} placeholder="НЭГЖ АНГИЛАЛ код оруулах"/>
                </div>
            </div>
            <div className="buttons" style={{paddingTop: '10px', marginLeft: '10px', marginRight: '10px'}}>
                <button className="button is-primary" type="primary" onClick={() => handleSaveUnit()}>Хадгалах</button>
                <button className="button is-primary" type="primary" onClick={() => handleCancel()}>Болих</button>
            </div>

            <button onClick={(e)=>handleExcel(e)} className="button bg-blue">
                <span style={{color: "white"}}>Excel</span>
            </button>
        </div>
    )

}


const mapStateToProps = (state, ownProps) => {
    const status = ownProps.match.params.status
    const mainItems = state.classifs.main
    const subItems = state.classifs.sub
    const unitItems = state.classifs.unit

    const main_ids_by_order =
        Object.values(mainItems)
            .reduce((ordered_ids, category) => {
                ordered_ids[category.order] = category.id
                return ordered_ids
            }, []);

    const sub_ids_by_order =
        Object.values(subItems)
            .reduce((ordered_ids, category) => {
                ordered_ids[category.order] = category.id
                return ordered_ids
            }, []);

    const unit_ids_by_order =
        Object.values(unitItems)
            .reduce((ordered_ids, category) => {
                ordered_ids[category.order] = category.id
                return ordered_ids
            }, []);

    let token = localStorage.getItem('id_token') || null;
    return {
        token,
        mainItems,
        subItems,
        unitItems,
        status,
        main_ids_by_order,
        sub_ids_by_order,
        unit_ids_by_order
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        loadClassifs: (token, type, id) => {
            dispatch(fetchClassifs(token, type, id))
        },
        add: (token, type, id, info) => {
            dispatch(createClassif(token, type, id, info))
        },
        edit: (token, type, id, info) => {
            dispatch(updateClassif(token, type, id, info))
        },
        print: () => {
            dispatch(printClassifs())
        }

    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductClassificationConfig)

